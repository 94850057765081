// B"H

// TrainProgress.js

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import axios from 'axios';

import { selectors, stopTraining, getListOfNetworks } from 'redux/reducers/datasets';
import { getNetworksIndexURL, getNetworkURL } from 'pages/urls';

import Heading from 'components/elements/Heading';
import Title from 'components/elements/Title';
import Message from 'components/elements/Message';
import Message3 from 'components/elements/Message3';

import Button from 'components/elements/Button';
import ProgressTime from 'components/graphics/ProgressTime';
import Chart from 'components/train/Chart';

import s from './TrainProgress.module.sass';


import {
  API_TRAINING_PROGRESS_URL,
} from 'pages/urls';



const axiosWithCookies = axios.create({
  withCredentials: true,
});

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}



class TrainProgress extends Component {
  constructor(props) {
    super(props);

    const { networkId, imagesList } = props;

    /*
    console.log(`props=${props}`);
    console.log('x');

    for (let [key, value] of Object.entries(props)) {
      console.log(`${key}: ${value}`);
    }

    console.log(`networkId=${networkId}`);
    */

    //for xProperty in props {
    //    console.log(`${xProperty}=${props[xProperty]}`)
    //}
    //const current_date_object = new Date();
    //const N_milliseconds_start_time = current_date_object.getTime();

    //const initial_expected_training_time = Math.round(2.5 * (25 + 0.5 * imagesList.length));


    this.state = {
      //isFetching: false,
      currentEpoch: 0,
      previousEpoch: 0,
      currentEpochStartTime: 0.0,
      trainingTimeLeft: `Network Setup in progress...`,

      percentDefectsFound: 0,
      percentFalseNegatives: 0,
      percentFalsePositives: 0,
      trainingComplete: false,
      progress: 0.0,
      trainingStartTime: 0.0, //set after Epoch1...otherwise setup time confuses seconds_per_epoch
      chartData: [
        { id: 'percentDefectsFound', data: [] },
        { id: 'percentFalsePositives', data: [] },
      ],
    };
    this.componentRef = React.createRef();

  }

  componentDidMount() {
    // TODO: clear interval when training complete
    this.interval = setInterval(() => this.getTrainingProgress(), 500);
    print_in_blue("MOUNT MOUNT MOUNT")
    print_in_blue("MOUNT MOUNT MOUNT")
    print_in_blue("MOUNT MOUNT MOUNT")
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    //clearInterval(this.interval);
  }


  getTrainingProgress = async () => {

    const { networkId, userId, datasetId } = this.props;

    //console.log(`networkId=${networkId}`);

    //const get_progress_url = API_TRAINING_PROGRESS_URL + "/" + userId.toString() + "/" + datasetId.toString() + "/" + networkId.toString()
    const get_progress_url =  API_TRAINING_PROGRESS_URL + '/' + networkId.toString();

    axiosWithCookies
      .get(get_progress_url)
      .then((response) => {
        this.updateChartData(
          response.data.current_epoch,
          response.data.percent_of_defects_found,
          response.data.percent_false_negatives,
          response.data.percent_false_positives,
          response.data.bool_trt_inference,
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };



  updateChartData(
    currentEpoch,
    percentDefectsFound,
    percentFalseNegatives,
    percentFalsePositives,
    bool_trt_inference
  ) {

    const { nEpochs, userId, datasetId, getListOfNetworks } = this.props;

    const { chartData } = this.state;

    let nextChart;

    if (currentEpoch === 0) {
      nextChart = [
        { id: 'percentDefectsFound', data: [] },
        { id: 'percentFalsePositives', data: [] },
      ];
    } else {
      nextChart = chartData;
    }

    if (currentEpoch >= 1) {

        nextChart[0].data.push({ x: currentEpoch, y: percentDefectsFound });
        nextChart[1].data.push({ x: currentEpoch, y: percentFalsePositives });
    }

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    // SET TRAINING_START_TIME @ EPOCH=1
    // SET CURRENT_EPOCH_START_TIME
    // SET SECONDS_PER_EPOCH
    // SET SECONDS_TO_GO

    const current_date_object = new Date();

    var new_trainingStartTime = this.state.trainingStartTime;
    var new_currentEpochStartTime = this.state.currentEpochStartTime;
    var new_secondsPerEpoch = this.state.secondsPerEpoch;

    var secondsToGo = 0;

    //set trainingStartTime after Epoch1
    // this way seconds_per_epoch isn't affected by training setup time
    if (currentEpoch >= 1 && this.state.trainingStartTime === 0.0)
    {
        new_trainingStartTime = current_date_object.getTime();

    } else if (currentEpoch !== this.state.currentEpoch && currentEpoch >= 2) {

        new_currentEpochStartTime = current_date_object.getTime();

        // trainingStartTime begins at Epoch 1
        const epochs_elapsed = currentEpoch - 1;

        const milliseconds_elapsed = new_currentEpochStartTime - new_trainingStartTime;
        const seconds_elapsed = milliseconds_elapsed * 0.001;

        new_secondsPerEpoch = seconds_elapsed / epochs_elapsed;
    }

    const epochs_to_go = 50 - currentEpoch;


    // ________________________________________________________________________ //

    // add estimated 5+ min for tensor-rt compile (if enabled)

    if (bool_trt_inference===true) {
        secondsToGo = Math.round(epochs_to_go * new_secondsPerEpoch) + 421;
    } else {
        secondsToGo = Math.round(epochs_to_go * new_secondsPerEpoch);
    }

    // ________________________________________________________________________ //

    // countdown timer for tensor-rt compile (if enabled)

    if (this.state.currentEpoch == nEpochs-1 && bool_trt_inference===true) {

        const current_time = current_date_object.getTime();

        const delta_t_seconds = Math.round((current_time - this.state.currentEpochStartTime) * 0.001);

        if (delta_t_seconds < 475) {

            secondsToGo = 5 + (475 - delta_t_seconds);

        } else {

            secondsToGo = 5;
        }

    }

    // ________________________________________________________________________ //

    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////

    // SET PROGRESS
    // SET TRAINING_TIME_LEFT_MESSAGE

    var new_trainingTimeLeftMessage = "";

    var progress = this.state.currentEpoch / nEpochs;

    const bool_training_setup_in_progress = (currentEpoch <= 1);
    const bool_training_complete = (currentEpoch === nEpochs);

    if (bool_training_complete) {
        new_trainingTimeLeftMessage = `Training Complete`
        progress = 1.0
        clearInterval(this.interval);
        // this is necessary so the network object that was just trained will
        // be retrieved from backend by the redux store
        getListOfNetworks(userId, datasetId);

    } else if (bool_training_setup_in_progress) {
        new_trainingTimeLeftMessage = `Network Setup in progress...`;
    } else {

        if (secondsToGo <= 60) {
            new_trainingTimeLeftMessage = `${secondsToGo} seconds remaining`;
        } else if (secondsToGo <= 3600) {
            const minutesToGo = Math.round(secondsToGo / 60);
            const new_secondsToGo = secondsToGo % 60;
            new_trainingTimeLeftMessage = `${minutesToGo} minutes, ${new_secondsToGo} seconds remaining`;
        } else {
            const hoursToGo = Math.round(secondsToGo / 3600);
            const minutesToGo = Math.round((secondsToGo % 3600)/60);
            new_trainingTimeLeftMessage = `${hoursToGo} hours, ${minutesToGo} minutes remaining`;
        }

        if (this.state.currentEpoch == nEpochs-1) {
            new_trainingTimeLeftMessage = `Optimizing model: ${new_trainingTimeLeftMessage}`;
        }

    }
    //////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////


    this.setState((prevState)=>({
        currentEpoch: currentEpoch,
        chartData: nextChart,

        previousEpoch: prevState.currentEpoch,

        currentEpochStartTime: new_currentEpochStartTime,

        trainingStartTime: new_trainingStartTime,
        secondsPerEpoch: new_secondsPerEpoch,

        progress: progress,
        trainingTimeLeft: new_trainingTimeLeftMessage,

        percentDefectsFound: Math.round(percentDefectsFound),
        percentFalseNegatives: Math.round(percentFalseNegatives),
        percentFalsePositives: Math.round(percentFalsePositives),
    }));
  }




  render() {
    const {
      isValidTraining,
      stopTraining,
      nEpochs,
      datasetId,
      networkId,
    } = this.props;

    //console.log(`datasetId=${datasetId}`)
    //if (!isValidTraining) {
    //  return <Redirect to={getNetworksIndexURL(datasetId)} />;
    //}

    const {
      trainingTimeLeft,
      currentEpoch,
      percentDefectsFound,
      percentFalseNegatives,
      percentFalsePositives,
      trainingStartTime,
      chartData,
    } = this.state;


    //current_speed = progress / (seconds_elapsed+1.0)
    //const secondsToGo = Math.round((1-progress) * current_speed)
    //trainingTimeLeftMessage = `${secondsToGo} seconds remaining`

    //console.log(`currentEpoch=${currentEpoch}`)
    //console.log(`nEpochs=${nEpochs}`)

    //  Math.round(
    //    ((currentEpoch - MIN_EPOCHS) / (MAX_EPOCHS - MIN_EPOCHS)) * 100
    //  ) / 100;

    return (
      <div className={s.component}>
        <aside className={s.side}>
          <div className={s.progress}>
            <header className={s.header}>
              <Heading parentClass={s.heading}>
                Training progress
              </Heading>

              {/*<Button
                parentClass={s.stop}
                color="danger"
                iconLeft="stop-circle"
                onClick={stopTraining}
              >
                Stop training
              </Button>
              */}

            </header>

            <ProgressTime
              value={this.state.progress}
              type="success"
              time={`${trainingTimeLeft}`}
            />
          </div>

          <div className={s.terms}>
            <Title size="2">Key accuracy terms</Title>

            <Message3
              value={`${percentDefectsFound}%`}
              title="Accuracy"
              hue="200"
            >
              <p>
                % of defects found
              </p>
            </Message3>

            <Message3
              value={`${percentFalseNegatives}%`}
              title="False Negatives"
              hue="250"
            >
              <p>% of GOOD PARTS failed by the network</p>
            </Message3>

            <Message3
              value={`${percentFalsePositives}%`}
              title="False Positives"
              hue="300"
            >
              <p> % of BAD PARTS passed by the network</p>
            </Message3>

            <br />
            <br />
            { (currentEpoch===nEpochs) &&
              <div className={s.network_button}>
                <Button
                  color="success"
                  iconRight="arrow-right"
                  to_x={getNetworkURL(datasetId, networkId)}
                >
                  Review network predictions
                </Button>
              </div>
            }

          </div>


        </aside>

        <main className={s.main}>
          <Chart chartData={chartData} nEpochs={nEpochs} />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: selectors.getUserId(state),
  datasetId: selectors.getDatasetId(state),
  isValidTraining: selectors.isValidTraining(state),
  imagesList: selectors.getDatasetImages(state),
});

export default connect(mapStateToProps, {
  stopTraining,
  getListOfNetworks,
})(TrainProgress);
