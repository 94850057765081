// B"H

import React from 'react';
import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import s from './Modal.module.sass';

ReactModal.setAppElement('#root');

const Modal = (props) => {
  const { isOpen, onAfterOpen, onRequestClose, children } = props;

  return (
    <ReactModal
      className={s.content}
      overlayClassName={s.overlay}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnEsc={true}
    >
      {children}
      <button className={s.button} onClick={onRequestClose}>
        <span className={s.label}>Close</span>
        <FontAwesomeIcon className={s.icon} icon="times-circle" />
      </button>
    </ReactModal>
  );
};

export default Modal;
