// B"H

import React from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { selectors, uploadImage } from 'redux/reducers/datasets';

import Title from 'components/elements/Title';
import Button from 'components/elements/Button';

import cx from 'classnames';
import s from './DropZoneOnClick.module.sass';

const Dropzone = (props) => {
  const { uploadImage, userId, datasetId, bool_multi_class, class_label } = props;

  const dropzoneConfig = {
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => uploadImage(file, userId, datasetId, bool_multi_class, class_label));
    },
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneConfig
  );

  const componentClass = cx({
    [s.component]: true,
    [s.isDragActive]: isDragActive,
  });

  return (
    <div {...getRootProps({ className: componentClass })}>

        <input {...getInputProps()} />
          { (bool_multi_class===false) &&

            ( (class_label==="PASS") ?
                (
                   <Button
                        color="success"
                        iconLeft="plus-square"
                        size="medium"
                        onClick={()=>{
                            console.log("UPLOAD PASS");
                        }}

                      >
                        Upload Pass
                      </Button>
                )
                :
                (
                   <Button
                        color="danger"
                        iconLeft="plus-square"
                        size="medium"
                        onClick={()=>{
                            console.log("UPLOAD FAIL");
                        }}

                      >
                        Upload Fail
                      </Button>
                )
              )
            }
          { (bool_multi_class===true) &&

                   <Button
                        color={`${class_label}`}
                        iconLeft="plus-square"
                        size="medium"
                        onClick={()=>{
                            console.log("UPLOAD MULTI CLASS");
                        }}

                      >
                        Upload Class {class_label}
                      </Button>


          }
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: selectors.getUserId(state),
  isLoading: selectors.isLoading(state),
});

export default connect(mapStateToProps, {
  uploadImage,
})(Dropzone);
