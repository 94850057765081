// Predictions.js

import React from 'react';

import Title from 'components/elements/Title';
import Label from 'components/elements/Label';
import GalleryGrid from 'components/photos/GalleryGrid';
import Toggles from 'components/form/Toggles';

import cx from 'classnames';
import s from './Predictions.module.sass';

const Predictions = (props) => {
  const {
    filterType,
    parentClass,
    imagesList,
    filteredList,
    type,
    onFilterClick,
    onGalleryClick,
    bool_multi_class,
    bool_multi_class_detection=false,
  } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  const totalCount = imagesList.length;
  const passCount = imagesList.filter((image) => image.labelClass === 'PASS')
    .length;
  const failCount = imagesList.filter((image) => image.labelClass === 'FAIL')
    .length;

  const showOptions = [
    {
      value: 'all',
      label: <Label type="all" parentClass={s.radio} />,
    },
    {
      value: 'PASS',
      label: (
        <Label type="pass" parentClass={s.radio}>
          {passCount}
        </Label>
      ),
      type: 'PASS',
    },
    {
      value: 'FAIL',
      label: (
        <Label type="fail" parentClass={s.radio}>
          {failCount}
        </Label>
      ),
      type: 'FAIL',
    },
  ];

  console.log(`filteredList=${filteredList}`);

  return (
    <div className={componentClass}>
      <header className={s.header}>
        <div className={s.progress}>
          <Title parentClass={s.count} size="1">
            {totalCount} <Label parentClass={s.label} type={type} /> predictions
          </Title>
        </div>

        { (bool_multi_class!==true) &&
          ( <div className={s.radios}>
            <Toggles
              currentValue={filterType}
              options={showOptions}
              onChange={(value) => onFilterClick(value)}
            />
          </div> )
        }
      </header>

      <div className={s.gallery}>
        <GalleryGrid
          step="review"
          items={filteredList}
          onClick={onGalleryClick}
          bool_multi_class_detection={bool_multi_class_detection}
        />
      </div>
    </div>
  );
};

export default Predictions;
