import React from 'react';

import cx from 'classnames';
import s from './Radios.module.sass';

const Radios = (props) => {
  const { currentValue, options, onChange, parentClass } = props;

  const items = options.map((option) => {
    const radioClass = cx({
      [s.radio]: true,
      [s[`type-${option.type}`]]: option.type,
      [s.isCurrent]: currentValue === option.value,
    });

    return (
      <div
        key={option.value}
        className={radioClass}
        onClick={() => onChange(option.value)}
      >
        <label className={s.body}>
          <div className={s.circle} />
          <span className={s.label}>{option.label}</span>
        </label>
      </div>
    );
  });

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return <div className={componentClass}>{items}</div>;
};

export default Radios;
