// NetworksList.js

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import { getNetworkURL } from 'pages/urls';
import { selectors, getListOfNetworks } from 'redux/reducers/datasets';


import Button from 'components/elements/Button';
import Title from 'components/elements/Title';

import s from './NetworksList.module.sass';

import Input2 from 'components/form/Input2';


import axios from 'axios';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
  DELETE_NETWORK_URL,
  DOWNLOAD_JOB_FILE_URL,
  SET_NETWORK_NAME_URL,
} from 'pages/urls';



class NetworksList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list_of_network_names: [],
            list_of_deleted_dataset_IDs: [],
            delete_dataset_ID: null,
            //initial_render: true,
            bool_editing_network_name: false,
            active_network_name_object: null,

        };
    }

    componentDidMount() {
        const { user_ID, dataset_ID, getListOfNetworks } = this.props;
        console.log(`user_ID=${user_ID} dataset_ID=${dataset_ID}`)

        getListOfNetworks(user_ID, dataset_ID);


        //this.setState({
        //    //initial_render: true,
        //});

    }

    onOutsideClick() {
        console.log(`OUTSIDE_CLICK`);

        const { user_ID, dataset_ID } = this.props;


        const NEW_list_of_network_names = this.state.list_of_network_names.map((obj) => {
            return (obj.id === this.state.active_network_name_object.id) ? this.state.active_network_name_object : obj;
        });

        console.log(`NEW_list_of_network_names=${JSON.stringify(NEW_list_of_network_names)}`);

        const set_network_name_URL = `${SET_NETWORK_NAME_URL}/${user_ID}/${dataset_ID}/${this.state.active_network_name_object.id}/${this.state.active_network_name_object.name}`

        this.setState({
            //prev_outside_click_count: outside_click_count,
            bool_editing_network_name: false,
            list_of_network_names: NEW_list_of_network_names,
            bool_editing_network_name: false,
            active_network_name_object: null,
        });

        axios
        .get(set_network_name_URL)
        .then((response) => {
            console.log('RECV SET_NETWORK_NAME');
            console.log(JSON.stringify(response.data));
            console.log('RECV SET_NETWORK_NAME');

            getListOfNetworks(user_ID, dataset_ID);

        })
        .catch((error) => {
            console.log("[ERROR] set network_name")
        });


    }

    render() {
        //const NetworksList = (props) => {
        const { user_ID, dataset_ID, networks } = this.props;

        ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////




        const listItems = networks.filter((network)=>{
        // FILTER
            if (this.state.list_of_deleted_dataset_IDs.includes(network.id)) {
                return false; // skip
            }
            return true;
        // MAP
        }).map((network) => {

            //console.log(`network=${JSON.stringify(network)}`)

            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////
            var bool_update_list_of_network_names = false;

            //if (this.state.list_of_network_names.length != networks.length) {
            //    bool_update_list_of_network_names = true;
            //}

            for (var i = 0; i < networks.length; i++) {
                var xx = networks[i].id;
                var xx_found = false;

                for (var j = 0; j < this.state.list_of_network_names.length; j++) {
                    if (this.state.list_of_network_names[j].id === xx) {
                        xx_found = true;
                    }
                }
                if (xx_found===false) {
                    bool_update_list_of_network_names = true;
                }
            }

            //console.log(`bool_update_list_of_network_names=${bool_update_list_of_network_names}`)
            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////
            ////////////////////////////////////////////////////

            if (bool_update_list_of_network_names) {

                const network_names = networks.map((network) => {
                    return {
                        name: network.name,
                        id: network.id,
                    };
                });
                //console.log(`network_names=${JSON.stringify(network_names)}`)

                this.setState({
                               list_of_network_names: network_names,
                               //initial_render: false,
                              });

            }

            const network_name_object = this.state.list_of_network_names.find(x => x.id === network.id);
            const network_name = network_name_object ? network_name_object.name : network.name;


            //console.log(`this.state=${JSON.stringify(this.state)}`)

            return (
              <div key={network.id} className={s.network} onClick={(e)=>{
                    console.log("dataset CLICK!");
                    console.log(`dataset CLICK! bool_editing_network_name=${this.state.bool_editing_network_name}`);
                    if (this.state.bool_editing_network_name===true) {
                        //e.stopPropogation();
                        //e.stopPropogation();
                        //e.preventDefault();
                        this.onOutsideClick();

                    }
                }}>

                { (this.state.bool_editing_network_name===true)
                  ? (<NavLink to='#' className={s.link}/>)
                  :  <NavLink to={getNetworkURL(dataset_ID, network.id)} className={s.link} /> }


                <div className={s.container}>
                  <div className={s.text}>


                    <Title parentClass={s.title} noMargin>


                          {/*******************************************************/}
                              { (this.state.bool_editing_network_name && (this.state.active_network_name_object.id===network.id) )? (
                                    <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={this.state.active_network_name_object.name}
                                            onChange={(e) => {
                                                console.log("change network display name");
                                                //set_dataset_display_name(e.target.value)
                                                this.setState({
                                                    active_network_name_object: { name: e.target.value, id: this.state.active_network_name_object.id }
                                                });

                                            }}
                                            placeholder="Network Alpha"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                   <span className={s.titleText}> {network_name} </span>
                              )}
                          {/*******************************************************/}







                         <span className={s.iconEdit} onClick={(e)=>{
                             console.log(`EDIT CLICK`);
                             this.setState({
                                 bool_editing_network_name: true,
                                 active_network_name_object: network_name_object ? network_name_object : { name: network.name, id: network.id },
                             });

                             /*
                             this.setState({
                                 prev_outside_click_count: outside_click_count,
                                 bool_editing_display_name: true,
                                 active_dataset_ID: item.id,
                                 active_dataset_display_name: NEW_item ? NEW_item.display_name : item.name,
                             });
                             */
                             e.stopPropagation();
                         }}>
                             <FontAwesomeIcon icon="pen" />
                         </span>



                    </Title>


                    <ul className={s.info}>
                      <li>
                        Trained with <strong>{network.trainedWith}</strong> images
                      </li>
                      <li>
                        Tested on <strong>{network.testedOn}</strong> images
                      </li>
                      <li className="is-correct">
                        Accuracy <strong>{network.accuracy}%</strong>
                      </li>
                      <li className="is-wrong">
                        False positives <strong>{network.falsePositives}%</strong>
                      </li>
                    </ul>
                  </div>

                  <div className={s.buttons}>
                    <Button
                      parentClass={s.button}
                      iconLeft="chart-pie"
                      to={getNetworkURL(dataset_ID, network.id)}
                    >
                      View Results
                    </Button>
                    <Button
                      parentClass={s.button}
                      color="success"
                      iconLeft="arrow-down"
                      onClick={()=>{
                          console.log("DOWNLOAD BUTTON!");
                          const downloadJobFileUrl = `${DOWNLOAD_JOB_FILE_URL}/${user_ID}/${dataset_ID}/${network.id}`;
                          window.location.href = downloadJobFileUrl;
                      }}
                    >
                      Download
                    </Button>

                    <Button
                      parentClass={s.button}
                      color="danger"
                      iconLeft="times-circle"
                      onClick={()=>{
                          console.log("DELETE BUTTON!");

                          this.setState({
                                          delete_dataset_ID: network.id,
                                        });

                          ////////////////////////////////////////////

                          const url = `${DELETE_NETWORK_URL}/${user_ID}/${dataset_ID}/${network.id}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("RECV DELETE_JOB ACK")
                              console.log(JSON.stringify(response.data));
                              console.log("RECV DELETE_JOB ACK")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")


                              this.setState({
                                              list_of_deleted_dataset_IDs: [...this.state.list_of_deleted_dataset_IDs, this.state.delete_dataset_ID],
                                           });
                              /*
                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });
                              */

                          })
                          .catch((error) => {
                              console.log("[ERROR] deleteJob");
                          });

                      }}
                    >
                      Delete
                    </Button>


                  </div>
                </div>
              </div>
            );
         });

        return <div className={s.component}>{listItems}</div>;
    };
};

const mapStateToProps = (state, ownProps) => ({
  user_ID: selectors.getUserId(state),
  networks: selectors.getNetworksList(state, ownProps.dataset_ID),
});

export default connect(mapStateToProps, {
    getListOfNetworks,
})(NetworksList);
