import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  HISTORY_URL,
  getHistoryMonthsURL,
  getHistoryDatesURL,
  getHistoryBrowseURL,
} from 'pages/urls';
import { selectors } from 'redux/reducers/datasets';

import s from './HistoryBreadcrumb.module.sass';

const HistoryBreadcrumb = (props) => {
  const {
    job_slot,
    job_name,
    month_str,
    date_str,
  } = props;


  let items = [
    {
      id: 'history',
      label: 'History',
      path: HISTORY_URL,
      exact: true,
    },
  ];


  if (job_name) {
    items.push({
      id: 'history_job',
      label: job_name,
      path: getHistoryMonthsURL(job_slot),
    });

    if (month_str) {
      items.push({
        id: 'history_month',
        label: month_str,
        path: getHistoryDatesURL(job_slot, month_str),
      });

      if (date_str) {
        items.push({
          id: 'history_date',
          label: date_str,
          path: getHistoryBrowseURL(job_slot, month_str, date_str),
        });
      }

    }

  }


  const navItems = items.map((item, index) => {
    let content = [];

    if (index > 0) {
      content.push(
        <span key="sep" className={s.sep}>
          /
        </span>
      );
    }

    content.push(
      <NavLink
        key={item.id}
        className={s.link}
        to={item.path}
        exact={item.exact}
      >
        <strong>{item.label}</strong>
      </NavLink>
    );

    return content;
  });

  return <div className={s.component}>{navItems}</div>;
  /*
  return <div className={s.component}>


      <NavLink
        key={68}
        className={s.link}
        to={'/training'}
        exact={41}
      >
        <strong>{"YOOO"}</strong>
      </NavLink>

        <span key="sep" className={s.sep}>
          /
        </span>

      <NavLink
        key={69}
        className={s.link}
        to={'#'}
        exact={42}
      >
        <strong>{"YOOO"}</strong>
      </NavLink>
         </div>;
  */

};

const mapStateToProps = (state, ownProps) => ({
  dataset: selectors.getDataset(state, ownProps.datasetId),
  network: selectors.getNetwork(state, ownProps.networkId),
});

export default connect(mapStateToProps)(HistoryBreadcrumb);
