import React from 'react';

import s from './Hr.module.sass';

const Hr = props => {
  return (
    <div className={s.component}>
      <hr />
    </div>
  )
}

export default Hr;
