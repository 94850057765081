// B"H

// ProgramSelect.js

import React, {Component} from 'react';

import Button from 'components/elements/Button';
import Tag from 'components/elements/Tag';

import cx from 'classnames';
import s from './ProgramSelect.module.sass';

import axios from 'axios';

import Modal from 'components/elements/Modal';

import Label from 'components/form/Label';
import Input from 'components/form/Input';
import Title from 'components/elements/Title';



import { useDropzone } from 'react-dropzone';

import ProgressBar from 'components/graphics/ProgressBar';

import {
  GET_LIST_OF_JOBS_URL,
  UPLOAD_JOB_URL,
  ACTIVATE_JOB_URL,
  DELETE_JOB_URL,
  GET_BOOL_PRODUCTION_MODE_URL,
  EDIT_JOB_NAME_URL,
} from 'pages/urls';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input3 from 'components/form/Input3';


const JobDropzone = (props) => {

  const { saveJobFile } = props;

  const dropzoneConfig = {
    accept: '.job',
    onDrop: (acceptedFiles) => {

      if (acceptedFiles.length === 1) {
          const fileX=acceptedFiles[0];
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log(`fileX=${fileX}`);
          console.log(JSON.stringify(fileX));
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          acceptedFiles.map((file) => saveJobFile(file));
      } else {
          // TODO: error message (?)
          console.log("1 job File only")
      }


    },
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneConfig
  );

  const componentClass = cx({
    [s.dropzone_component]: true,
    [s.isDragActive]: isDragActive,
  });


  return (

    <div {...getRootProps({ className: componentClass })}>
      <div className={s.dropzone_border}>
        <div className={s.dropzone_content}>
          <input {...getInputProps()} />

          {isDragActive ? (
            <Title parentClass={s.title}>
              Drop Job file <strong>here</strong>
            </Title>
          ) : (
            <Title parentClass={s.dropzone_title}>
              <strong>Drop Job file here</strong>
              <br/>
              <br/>
              OR
            </Title>
          )}


          <div className={s.cta}>
            <Button
              iconRight="mouse-pointer"
              size="small"
              color={isDragActive ? 'success' : null}
            onClick={(e)=>{
              console.log("DropZone click");
              e.preventDefault();
            } }>
              Select Job file
            </Button>

          </div>
        </div>
      </div>
    </div>



  );
};









//   { slot: 0, id: 'default', name: 'CAMERA-ONLY mode' },

const defaultProgramsList = [
  { slot: 0 },
  { slot: 1 },
  { slot: 2 },
  { slot: 3 },
  { slot: 4 },
  { slot: 5 },
  { slot: 6 },
  { slot: 7 },
  { slot: 8 },
];



class ProgramSelect extends Component {
  constructor(props) {
    super(props);

   this.state = {
     programsList: defaultProgramsList,
     bool_production_mode: null,
     show_load_job_modal: false,
     load_job_submit: false,
     load_job_filename: '',
     load_job_name: '',
     load_job_slot: -1,
     load_job_progress: 0.0,

     bool_editing_job_name: false,
     editing_job_name: '',
     editing_job_slot: -1,
   };

  ///////////////////////////////////////////////////////

  // Get bool_production_mode

    axios
    .get(GET_BOOL_PRODUCTION_MODE_URL)
    .then((response) => {
      console.log('RECV BOOL_PRODUCTION_MODE');
      console.log(response.data);
      console.log(response.data.bool_production_mode);
      console.log('RECV BOOL_PRODUCTION_MODE');

      this.setState({
        bool_production_mode: response.data.bool_production_mode,
      });

   })
   .catch((error) => {
       console.log("[ERROR] get bool_production_mode")
   });


  ///////////////////////////////////////////////////////

  // Get list of jobs

    axios
    .get(GET_LIST_OF_JOBS_URL)
    .then((response) => {
      console.log('_________________');
      console.log(response.data);
      console.log('_________________');

      this.setState({
        programsList: response.data,
      });

   })
   .catch((error) => {
       console.log("[ERROR] return list_of_jobs")
   });

  ///////////////////////////////////////////////////////



  }

  handleChange(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }


  saveJobFile(file) {

    console.log("@@@@@@@@@@@@@@@");
    console.log(`filename=${file.path}`);
    console.log("@@@@@@@@@@@@@@@");

    let form_data = new FormData();
    form_data.append('file', file, file.fileName);

    this.setState({
      load_job_filename: file.path,
    });



    const url = `${UPLOAD_JOB_URL}/${file.path}/${this.state.load_job_slot}`;

    console.log("UPLOADJOB UPLOADJOB UPLOADJOB");
    console.log("UPLOADJOB UPLOADJOB UPLOADJOB");
    console.log("UPLOADJOB UPLOADJOB UPLOADJOB");


    // TODO: clear interval when training complete
    this.interval = setInterval(() => {

        if (this.state.load_job_progress < 0.8) {
            this.setState((prevState, props) => ({
                load_job_progress: prevState.load_job_progress + 0.05,
            }));
        }

    }, 800);



    axios
    .post(url, form_data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`,
        },
    })
    .then((response) => {
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
         console.log(response.data);
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

         clearInterval(this.interval);

         this.setState({
             load_job_name: file.path,
             load_job_progress: 1,
         });

         /////////////////////////////////////////////////////////////////

         // update list_of_jobs

          axios
          .get(GET_LIST_OF_JOBS_URL)
          .then((response) => {
            console.log('_________________');
            console.log(response.data);
            console.log('_________________');

            this.setState({
              programsList: response.data,
            });
          })
          .catch((error) => {
            console.log("[ERROR] return list_of_jobs")
          });

         /////////////////////////////////////////////////////////////////


         //this.setState({
         //    load_job_success: true,
         //});

    })
    .catch((error) => {
        console.log("[ERROR] uploadJob");
    });
  };









//const ProgramSelect = (props) => {

  render() {

  if (this.state.bool_production_mode===true) {
    return (
                 <div className={s.production_mode_error_message}>
                   Program Select not available in production mode
                 </div>

    );
  }


  if (this.state.show_load_job_modal===true) {
    return (
      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");

          this.setState({
            show_load_job_modal: false,
            load_job_submit: false,
            load_job_filename: '',
            load_job_name: '',
            load_job_slot: -1,
            load_job_progress: 0.0,
          });

          // ________________________________________________________ //
          // ________________________________________________________ //


          // ________________________________________________________ //
          // ________________________________________________________ //

        }}
      >

          <div className={s.modal_component}>
            <form className={s.form}>
              <Title>Load Job</Title>


              { (this.state.load_job_submit && this.state.load_job_name==='') &&
                 <div className={s.load_job_error_message}>
                   Please enter job name
                 </div>
              }

              { (this.state.load_job_submit && this.state.load_job_name!=='' && this.state.load_job_filename==='') &&
                 <div className={s.load_job_error_message}>
                   Please upload neural network
                 </div>
              }


              <div className={s.fieldX}>

                <div className={s.label_component}>
                  <label htmlFor="load_job_name">Job Name</label>
                </div>

                <Input
                  id="load_job_name"
                  name="load_job_name"
                  type="text"
                  value={this.state.load_job_name}
                  onChange={(e) => this.handleChange(e, 'load_job_name')}
                  placeholder="Job 1"
                />
              </div>

              { (this.state.load_job_filename!=='') &&

                 <div className={s.load_job_progress_bar}>
                      <ProgressBar value={this.state.load_job_progress}/>
                 </div>
              }


              <br />
              <JobDropzone saveJobFile={(file) => { this.saveJobFile(file)    }     } />

              <br />




              {/*
              <Button isFullWidth={true} onClick={(e) => {
                //console.log(`dataset_name=${this.state.dataset_name}`);
                //console.log(`password=${this.state.password}`);
                this.setState({
                  load_job_submit: true,
                  });
                e.preventDefault();
              }}
              >
                Load Job
                </Button>
              */}

                  </form>
                </div>



                </Modal>
            );
        }



   console.log(`programsList=${JSON.stringify(this.state.programsList)})`)


   return (
    <div className={s.component} onClick={()=>{

        console.log("outside click");

                          const url = `${EDIT_JOB_NAME_URL}/${this.state.editing_job_slot}/${this.state.editing_job_name}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                   bool_editing_job_name: false,
                                   editing_job_name: '',
                                   editing_job_slot: -1,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////

                          })
                          .catch((error) => {
                              console.log("[ERROR] editJobName");
                          });

                      }}


                      /////////////////////////////////////////////////////////





    >
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.slot}>Slot</th>
            <th>Job Name</th>
            <th> Activate Job</th>
            <th> Delete Job</th>
          </tr>
        </thead>
        <tbody>
          {this.state.programsList.map((program) => {

            const key = program.slot;
            const isActive = program.bool_is_active === true;
            const isDefault = program.slot === 0;
            const hasJob = (program.id !== null) && program.name;
            console.log(`key=${key}`);
            console.log(`isDefault=${isDefault}`);
            console.log(`isActive=${isActive}`);
            console.log(`hasJob=${hasJob}`);

            if (hasJob) {
              return (
                <tr className={s.row} key={key}>
                  <td className={s.slot}>{program.slot}</td>
                  <td>

                    { (isDefault===false) &&

                      <div className={s.nameLine}>
                        <span className={s.iconEdit} onClick={(e)=>{
                            console.log(`EDIT CLICK`);

                                this.setState({
                                    bool_editing_job_name: true,
                                    editing_job_name: program.name,
                                    editing_job_slot: program.slot,
                                    //programsList: response.data,
                                });

                            //set_bool_editing_dataset_display_name(true);
                            e.stopPropagation();
                        }}>
                            <FontAwesomeIcon icon="pen" />
                        </span>

                              { (this.state.bool_editing_job_name && this.state.editing_job_slot===program.slot) ? (

                                    <div className={s.field}>

                                        <Input3
                                            id="job_name"
                                            name="job_name"
                                            type="text"
                                            value={this.state.editing_job_name}
                                            onChange={(e) => { this.setState({ editing_job_name: e.target.value }); } }

                                            placeholder="Dataset Alpha"
                                            onClick={(e)=>{
                                                console.log("Input3 CLICK");

                                                this.setState({
                                                                bool_editing_job_name: true,
                                                              });

                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                  <span key="name" className={s.name}>
                                      {program.name} &nbsp; &nbsp;
                                  </span>

                              )}

                      </div>
                  }




                    {isDefault && (
                      <div>
                        <span key="name" className={s.name}>
                           {program.name} &nbsp; &nbsp;
                        </span>

                        <Tag className={s.defaultTag} key="default" parentClass={cx(s.tag, s.defaultTag)}>
                          <div className={s.tagText}>
                            Default
                          </div>
                        </Tag>
                      </div>

                    )}


                  </td>
                  <td>
                    {isActive ? (
                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Active
                        </div>
                      </Tag>
                    ) :
                       (
                        <div className={s.activateText}>



                    {(isActive===false) && (
                      <Button parentClass={cx(s.button, s.loadJob)} onClick={()=>{
                          console.log("activate_click");
                          console.log("activate_click");

                          const url = `${ACTIVATE_JOB_URL}/${program.slot}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////



                          })
                          .catch((error) => {
                              console.log("[ERROR] deleteJob");
                          });

                      }}


                      /////////////////////////////////////////////////////////
                      >
                        Activate
                      </Button>
                    )}






                        </div>
                      )}
                  </td>
                  <td>
                    {(program.slot > 0) && (

                      <Button parentClass={cx(s.button, s.deleteJob)} color="danger" onClick={()=>{
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");

                          const url = `${DELETE_JOB_URL}/${program.slot}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")


                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////

                          })
                          .catch((error) => {
                              console.log("[ERROR] deleteJob");
                          });

                      }}
                      >
                        Delete
                      </Button>
                  )}
                  </td>


                </tr>
              );
            }
            return (
              <tr className={s.row} key={key}>
                <td className={s.slot}>{program.slot}</td>
                <td colSpan="3">
                  {/* <p>[No Job] Load Job from Training / Network Review </p> */}

                  <Button parentClass={cx(s.button, s.loadJob)}
                   onClick={() => {
                     this.setState({
                       show_load_job_modal: true,
                       load_job_slot: program.slot,
                     });
                   }}
                  >
                    Load job
                  </Button>

                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}};

export default ProgramSelect;
