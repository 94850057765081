import React from 'react';

import s from './Empty.module.sass';

const Empty = (props) => {
  const { children } = props;

  return (
    <div className={s.component}>
      <div className={s.body}>{children}</div>
    </div>
  );
};

export default Empty;
