import React from 'react';

import { buildStyle, buildText } from './ProgressBar.js';

import cx from 'classnames';
import s from './ProgressBar.module.sass';

const ProgressShares = (props) => {
  const { values, total, type, size, label = 'complete', parentClass } = props;

  const componentClass = cx({
    [s.component]: true,
    [s.isMultiple]: true,
    [s[`type-${type}`]]: type,
    [s[`size-${size}`]]: size,
    [parentClass]: parentClass,
  });

  let sharesPercentage = 0;

  const shareItems = values.map((share) => {
    const percentage = Math.round((share.value / total) * 100);
    sharesPercentage += percentage;

    const shareClass = cx({
      [s.share]: true,
      [s[`type-${share.type}`]]: share.type,
    });
    const shareStyle = buildStyle(percentage);
    const key = share.type + percentage;

    return <div key={key} className={shareClass} style={shareStyle} />;
  });
  if (sharesPercentage > 100) {
      sharesPercentage = 100;
  }

  const overview = buildText(s.overview, sharesPercentage, label);

  return (
    <div className={componentClass}>
      <div className={s.bar}>{shareItems}</div>
      {overview}
    </div>
  );
};

export default ProgressShares;
