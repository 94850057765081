import React from 'react';

import cx from 'classnames';
import s from './ConfidenceScore.module.sass';

const ConfidenceScore = (props) => {
  const { score, type, parentClass } = props;

  var confidenceType = 'na';

  /*
  if (score === "N/A") {
      var confidenceType = 'na';
  }
  else {
      //var confidenceType = score > 80 ? 'pass' : score < 30 ? 'fail' : 'average';
      if (type==="FAIL") {
          var confidenceType = 'fail';
      }
      else if (type==="PASS") {
          var confidenceType = 'pass';
      }
      else {
          var confidenceType = 'na';
      }
  }
  */
  const confidenceClass = cx({
    [s.component]: true,
    [s[`type-${confidenceType}`]]: confidenceType,
    [parentClass]: parentClass,
  });

  if (score === "N/A")
     return <span className={confidenceClass}>{score}</span>;
  else
     return <span className={confidenceClass}>{score}%</span>;

};

export default ConfidenceScore;
