// NewNetwork.js

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectors, startTraining } from 'redux/reducers/datasets';
import {
  getImagesURL,
  getNetworkProgressURL,
  getNetworksIndexURL,
  REQUEST_NAMED_CLASSES_URL,
} from 'pages/urls';

import Empty from 'components/elements/Empty';
import Heading from 'components/elements/Heading';
import Setting from 'components/train/Setting';
import Label from 'components/form/Label';
import Toggles from 'components/form/Toggles';
import Slider2 from 'components/form/Slider2';
import Button from 'components/elements/Button';
import BackLink from 'components/navigation/BackLink';

import ListHeader2 from 'components/list/ListHeader2';

import axios from 'axios';


import s from './NewNetwork.module.sass';




const SPLIT_CONTENT = (
  <p>
    50/50 is recommended, can go up to 80/20, meaning 80% of labelled images are
    used to train network, 20% are used to test network once training is
    complete. Testing ensures network generalizes well to new images.
  </p>
);

const SPLIT_MARKS = {
  20: '20%',
  80: '80%',
};

const EPOCHS_MARKS = {
  50: '50',
  200: '200',
};

const RATE_CONTENT = (
  <p>
    This is how much the network adapts to each image it trains on. Higher
    learning rate allows quicker training, lower learning rate enables more fine
    grained control for more precise defect recognition.
  </p>
);

const EPOCHS_CONTENT = (
  <p>
    This is how many times the neural network will train on each image in the
    dataset. A happy medium is best: too little training and the network won't
    work well, too much training and the network may overly adapt to the nuances
    of the training data.
  </p>
);

const LEARNING_OPTIONS = [
  { value: 0.5, label: '0.5x' },
  { value: 1, label: '1x' },
  { value: 2, label: '2x' },
];

const NewNetwork = (props) => {
  const {
    user_ID,
    dataset_ID,
    network_ID,
    imagesList,
    isLoading,
    isValidTraining,
    startTraining,
    dataset,
  } = props;

  const [split, setSplit] = useState(80);
  const [rate, setRate] = useState(1.0);
  const [epochs, setEpochs] = useState(50);
  const [bool_new_network_button_click, set_bool_new_network_button_click] = useState(false);

  const [bool_multi_class_job_settings_received,  SET_bool_multi_class_job_settings_received] = useState(false);
  const [bool_multi_class_job_settings_requested, SET_bool_multi_class_job_settings_requested] = useState(false);

  const [pass_fail_check_toggle_value, SET_pass_fail_check_toggle_value] = useState("NOT_SET");

  //const [N_image_classes, set_N_image_classes] = useState(2);

  const [N_CLASSES_IN_JOB_SETTINGS_DICT, set_N_CLASSES_IN_JOB_SETTINGS_DICT] = useState(0);

  const [N_classes_named, set_N_classes_named] = useState(0);


  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  // FOR [CLASSIFICATION, MULTI_CLASS_DETECTION] GET JOB_SETTINGS_DICT
  //
  // -> N_classes
  // -> N_CLASSES_IN_JOB_SETTINGS_DICT
  // -> job_type: [PASS_CHECK, FAIL_CHECK]

  // run once, at start
  useEffect(() => {

    if (dataset.bool_multi_class===true && bool_multi_class_job_settings_requested===false) {
    //if (dataset.bool_multi_class===true) {

      SET_bool_multi_class_job_settings_requested(true);
      const request_named_classes_url = `${REQUEST_NAMED_CLASSES_URL}/${user_ID}/${dataset_ID}`;

      axios
      .get(request_named_classes_url)
      .then((response) => {
          console.log("[NEW_NETWORK: REQUEST NAMED_CLASSES] RESPONSE RECEIVED");
          console.log(`[NEW_NETWORK] response.data=${JSON.stringify(response.data)}`);

          if (response.data.named_classes!=undefined) {
              set_N_CLASSES_IN_JOB_SETTINGS_DICT(JSON.parse(response.data.named_classes).length);

              var list_of_class_objects = JSON.parse(response.data.named_classes);

              // N_classes_named
              const N_class_names_in_job_settings_dict = list_of_class_objects.reduce((counter, obj) => {
                  if (obj.name != "") {
                      counter += 1;
                  }
                   return counter;
              }, 0);

              if (response.data.bool_pass_check===true) {
                  SET_pass_fail_check_toggle_value("PASS_CHECK");
              } else if (response.data.bool_fail_check===true) {
                  SET_pass_fail_check_toggle_value("FAIL_CHECK");
              }

              set_N_classes_named(N_class_names_in_job_settings_dict);

              SET_bool_multi_class_job_settings_received(true);
              console.log(`N_class_names_in_job_settings_dict=${N_class_names_in_job_settings_dict}`);
              console.log("OK1");
          }

      })
      .catch((error) => {
          console.log(`[REQUEST_NAMED_CLASSES] error=${error}`);
      });
    }

  }, []);

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  console.log("OK2");

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  // CHECK1: N_IMAGES > 0

  if (imagesList.length === 0) {
      return [
          <div key="component" className={s.component}>
              <Empty key="empty">
                  <Heading surtitle="Error">You have no images</Heading>
                  <Button to={getImagesURL(dataset_ID)}>Upload images</Button>
              </Empty>
          </div>,
      ];
  }

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  // CHECK2: ALL IMAGES ARE LABELLED AS [PASS, FAIL, CLASS_N]

  // (2) CLASSIFICATION
  if (dataset.bool_multi_class === true && dataset.bool_detection===false) {
      var unlabeledCount = imagesList.filter((image) => (image.labelClass === -1 || image.labelClass === "NONE")).length;
  }
  // (1) PASS/FAIL
  // (3) PASS DETECTION
  // (4) FAIL DETECTION
  // (5) MULTI CLASS DETECTION (PASS_CHECL / FAIL_CHECK)
  else {
      var passCount = imagesList.filter((image) => image.labelClass === 'PASS').length;
      var failCount = imagesList.filter((image) => image.labelClass === 'FAIL').length;
      var unlabeledCount = imagesList.length - passCount - failCount;
  }

  if (unlabeledCount !== 0) {
      return [
                <div key="component" className={s.component}>
                  <Empty key="empty">
                    <Heading surtitle="Error">Please label all images before training</Heading>
                    <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                    </Empty>
                </div>,
             ];
  }

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  // CHECK3: FOR [PASS_DETECTION, FAIL_DETECTION] DETECTION IMAGES HAVE 1+ BOX LABEL

  {/* FAIL DETECTION */}

  if (dataset.bool_detection===true && dataset.bool_pass_detection===false && dataset.bool_multi_class===false) {

      var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'FAIL' && image.labelPolygons && image.labelPolygons.length > 0)).length;

      if (detectionLabelCount!=failCount) {
          return [
                    <div key="component" className={s.component}>
                        <Empty key="empty">
                            <Heading surtitle="Error">Please add detection labels for all fail images before training ({detectionLabelCount}/{failCount} completed)</Heading>
                            <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                        </Empty>
                    </div>,
                 ];
      }
  }

  {/* PASS DETECTION */}

  if (dataset.bool_detection===true && dataset.bool_pass_detection===true) {

      var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'PASS' && image.labelPolygons && image.labelPolygons.length > 0)).length;

      if (detectionLabelCount!=passCount) {

          return [
                    <div key="component" className={s.component}>
                        <Empty key="empty">
                            <Heading surtitle="Error">Please add detection labels for all pass images before training ({detectionLabelCount}/{passCount} completed)</Heading>
                            <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                        </Empty>
                    </div>,
                 ];
      }
  }

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  //________________________ //
  //________________________ //


  // SET N_IMAGE_CLASSES TO MAX_IMAGE_LABEL (checked below)

  var N_image_classes = 2
  // (1) PASS/FAIL
  // (3) PASS DETECTION
  // (4) FAIL DETECTION
  // (5) MULTI CLASS DETECTION (PASS_CHECL / FAIL_CHECK)

  // (2) CLASSIFICATION
  if (dataset.bool_multi_class === true && dataset.bool_detection===false) {
      const max_image_label = Math.max.apply(Math, imagesList.map(function(image) { return image.labelClass; }))
      N_image_classes = max_image_label;
  }

  //________________________ //
  //________________________ //

  // CHECK4 FOR [CLASSIFICATION] CHECK MAX_IMAGE_LABEL <= N_CLASSES_IN_JOB_SETTINGS_DICT

  if (dataset.bool_multi_class===true && dataset.bool_detection===false && bool_multi_class_job_settings_received===true) {

      const max_image_label = Math.max.apply(Math, imagesList.map(function(image) { return image.labelClass; }))
      // CLASSIFICATION: check MAX_IMAGE_LABEL <= N_CLASSES
      if (max_image_label>N_CLASSES_IN_JOB_SETTINGS_DICT) {
          return [
                     <div key="component" className={s.component}>
                         <Empty key="empty">
                             <Heading surtitle="Error">There is a label [{max_image_label}] that's not in the set of classes [Number of classes: {N_image_classes}]</Heading>
                             <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                         </Empty>
                     </div>,
              ];
      }
  }

  //________________________ //
  //________________________ //

  // CHECK5 FOR [CLASSIFICATION/DETECTION] CHECK N_CLASSES_IN_JOB_SETTINGS_DICT >= 2

  if (dataset.bool_multi_class==true && bool_multi_class_job_settings_received===true && N_CLASSES_IN_JOB_SETTINGS_DICT<2) {
      return [
                 <div key="component" className={s.component}>
                     <Empty key="empty">
                         <Heading surtitle="Error">Please set number of classes to 2 or more [number of classes: {N_CLASSES_IN_JOB_SETTINGS_DICT}] </Heading>
                         <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                     </Empty>
                 </div>,
             ];
  }

  // CHECK6 MULTI_CLASS_DETECTION: check job_type set to PASS_CHECK or FAIL CHECK
  if ((dataset.bool_multi_class==true && dataset.bool_detection===true && bool_multi_class_job_settings_received===true) &&
      (pass_fail_check_toggle_value!=="PASS_CHECK" && pass_fail_check_toggle_value!=="FAIL_CHECK")) {

      console.log(`CHECK6: job_type not set pass_fail_check_toggle_value=${pass_fail_check_toggle_value}`);
      return [
                 <div key="component" className={s.component}>
                     <Empty key="empty">
                         <Heading surtitle="Error">Please set job type to PASS CHECK or FAIL CHECK in job settings </Heading>
                         <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                     </Empty>
                 </div>,
             ];
  }

  //________________________ //
  //________________________ //

  // CHECK9 [CLASSIFICATION/DETECTION]: ALL CLASSES NAMED
  if (dataset.bool_multi_class==true && bool_multi_class_job_settings_received===true && N_classes_named!==N_CLASSES_IN_JOB_SETTINGS_DICT) {
      return [
                 <div key="component" className={s.component}>
                     <Empty key="empty">
                         <Heading surtitle="Error">Please name all classes ({N_classes_named}/{N_CLASSES_IN_JOB_SETTINGS_DICT} classes named) </Heading>
                         <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                     </Empty>
                 </div>,
             ];
  }

  console.log("OK3");

  //________________________ //
  //________________________ //



  /*
  if (dataset.bool_detection===false && dataset.bool_multi_class===true && xx) {
      return [
                <div key="component" className={s.component}>
                  <Empty key="empty">
                    <Heading surtitle="Error">Please add detection labels for all fail images before training ({detectionLabelCount}/{failCount} completed)</Heading>
                    <Button to={getImagesURL(dataset_ID)}>Label images</Button>
                    </Empty>
                </div>,
             ];
  }
  */

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //


  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
  //console.log(`dataset=${JSON.stringify(dataset)}`);
  //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");

  //const totalCount = imagesList.length;

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  /*

  if (dataset.bool_detection === true) {
      if (dataset.bool_pass_detection === true) {
          var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'PASS' && image.labelPolygons && image.labelPolygons.length > 0)).length;
      } else {
          var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'FAIL' && image.labelPolygons && image.labelPolygons.length > 0)).length;
      }
  }

  //var bool_multi_class_detection = false;
  //console.log("DATASET:");
  //console.log(JSON.stringify(dataset));

  // PASS / FAIL COUNT
  if (dataset.bool_multi_class === false) {
      var passCount = imagesList.filter((image) => image.labelClass === 'PASS').length;
      var failCount = imagesList.filter((image) => image.labelClass === 'FAIL').length;
      var unlabeledCount = totalCount - passCount - failCount;
  }
  else if (dataset.bool_multi_class && dataset.bool_detection===true) {
      var passCount = 0;
      var failCount = 0;
      var unlabeledCount = 0;
      bool_multi_class_detection = true;
  }
  else {
      var unlabeledCount = imagesList.filter((image) => image.labelClass === -1).length;
      var labeledCount = totalCount - unlabeledCount;

      //max_label = imagesList.filter((image) => image.labelClass === 'PASS').length;
      const max_label = Math.max.apply(Math, imagesList.map(function(image) { return image.labelClass; }))
      N_classes = max_label;

      // ______________________________________________________________________ //

      // TODO: specify number of classes(?)

      if ((!N_classes || N_classes<2) && bool_multi_class_detection===false) {
          return [
                   <div key="component" className={s.component}>
                     <Empty key="empty">
                       <Heading surtitle="Error">Please label at least two classes of images</Heading>
                       <Button to={getImagesURL(dataset_ID)}>Upload images</Button>
                       </Empty>
                   </div>,
                 ];
      }
     // ______________________________________________________________________ //

  }
  */


  // ______________________________________________________________________ //
  // ______________________________________________________________________ //


  if (isValidTraining && bool_new_network_button_click) {
    return <Redirect to={getNetworkProgressURL(dataset_ID, network_ID)} />;
  }

  const networkURL = getNetworksIndexURL(dataset_ID);

  return [
    <div key="component" className={s.component}>
      <main className={s.main}>
        <div className={s.back}>
          <BackLink parentClass={s.backLink} to={networkURL}>
            Back
          </BackLink>
        </div>

        <ListHeader2 title="Training settings">
                <Button
                  color="success"
                  iconRight="arrow-right"
                  isLoading={isLoading}
                  onClick={() => {
                      startTraining(split, rate, epochs, user_ID, dataset_ID, N_image_classes);
                      set_bool_new_network_button_click(true);
                  }}
                >
                  Start training
            </Button>
        </ListHeader2>


        <br/>

        <Setting
          icon="code-branch"
          title="Split dataset into training images and test images"
          content={SPLIT_CONTENT}
        >
          <Label>Percentage of training images</Label>
          <Slider2
            min={20}
            max={80}
            marks={SPLIT_MARKS}
            defaultValue={80}
            disabled={false}
            onChange={(sliderValue) => setSplit(sliderValue)}
          />
        </Setting>
        <br/>

        <Setting
          icon="tachometer-alt"
          title="Set Neural Network Learning rate (disabled)"
          content={RATE_CONTENT}
        >
          <Label>Learning rate</Label>
          <Toggles
            currentValue={rate}
            options={LEARNING_OPTIONS}
            onChange={(value) => setRate(value)}
          />
        </Setting>
        <br/>

        <Setting
          icon="sync-alt"
          title="Set number of epochs (disabled)"
          content={EPOCHS_CONTENT}
        >
          <Label>Number of epochs</Label>
          <Slider2
            min={50}
            max={200}
            marks={EPOCHS_MARKS}
            defaultValue={50}
            disabled={true}
            //onChange={ (sliderValue) => setEpochs(sliderValue)}
          />
        </Setting>
        <br/>



      </main>

      {/*
      <aside className={s.side}>
        <Heading surtitle="Knowledge">How a neural network works</Heading>
      </aside>*/}
    </div>,
  ];
};

const mapStateToProps = (state, ownProps) => ({
  user_ID: selectors.getUserId(state),
  network_ID: selectors.getNetworkId(state),
  imagesList: selectors.getDatasetImages(state),
  isLoading: selectors.isLoading(state),
  isValidTraining: selectors.isValidTraining(state),
  dataset: selectors.getDataset(state, ownProps.dataset_ID),
});

export default connect(mapStateToProps, {
  startTraining,
})(NewNetwork);
