import React from 'react';

import ConfidenceScore from 'components/elements/ConfidenceScore';
import PredictionType from 'components/elements/PredictionType';

import cx from 'classnames';
import s from './RecentImage.module.sass';

const RecentImage = (props) => {
  const {
    time,
    predictionType,
    confidenceScore,
    trigger,
    inferenceTime,
    imgSrc,
  } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${predictionType}`]]: predictionType,
  });

  return (
    <div className={componentClass}>
      <figure className={s.figure}>
        { (imgSrc==="") ?
          <div className={s.no_image}>
             --
          </div>
          :
          <img
            className={s.image}
            alt="Live camera view"
            src={imgSrc}
          />
        }
      </figure>

      <div className={s.body}>
        <div className={s.time}>
          <p>{time}</p>
        </div>
        <div className={s.item}>
          <p>Prediction</p>
          <PredictionType parentClass={s.value} type={predictionType} />
        </div>
        <div className={s.item}>
          <p>Confidence</p>
          <ConfidenceScore parentClass={s.value} score={confidenceScore} type={predictionType} />
        </div>
        <div className={s.item}>
          <p>Trigger</p>
          <p>{trigger}</p>
        </div>
        <div className={s.item}>
          <p>Inference time</p>
          <p>{inferenceTime}ms</p>
        </div>
      </div>
    </div>
  );
};

export default RecentImage;
