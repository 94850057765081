import React from 'react';

import cx from 'classnames';
import s from './Loader.module.sass';

const Loader = (props) => {
  const { parentClass } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return <div className={componentClass} />;
};

export default Loader;
