import React from 'react';
import {
  NavLink,
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDoubleDown,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBackspace,
  faCamera,
  faPen,
  faChartPie,
  faCheck,
  faCog,
  faCodeBranch,
  faHdd,
  faMousePointer,
  faPlusSquare,
  faRedo,
  faStopCircle,
  faSyncAlt,
  faTachometerAlt,
  faTimesCircle,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';

import { TRAINING_BASE_PATH, CAMERA_BASE_PATH } from 'pages/urls';
import Training from './pages/Training';
import Camera from './pages/Camera';
import Login from './pages/Login';

import s from './App.module.sass';

library.add(
  faAngleDoubleDown,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBackspace,
  faCamera,
  faPen,
  faChartPie,
  faCheck,
  faCog,
  faCodeBranch,
  faHdd,
  faMousePointer,
  faPlusSquare,
  faRedo,
  faStopCircle,
  faSyncAlt,
  faTachometerAlt,
  faTimesCircle,
  faUndo,
);

function App() {
  return (
    <Router>
      <div className={s.component}>
        <Switch>
          <Route exact path="/">
          <Login boolPreviousLoginFailed={false} previousLoginErrorMessage={""} />
          </Route>

          <Route exact path="/choose-app">
            <div className={s.links}>
              <NavLink className={s.link} to={TRAINING_BASE_PATH}>
                Training
              </NavLink>
              <NavLink className={s.link} to={CAMERA_BASE_PATH}>
                Camera
              </NavLink>
            </div>
          </Route>

          <Route path={TRAINING_BASE_PATH}>
            <Training />
          </Route>

          <Route path={CAMERA_BASE_PATH}>
            <Camera />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
