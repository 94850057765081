// B"H

import React, {Component} from 'react';

import Button from 'components/elements/Button';
import Tag from 'components/elements/Tag';

import cx from 'classnames';
import s from './ProgramSelect2.module.sass';

import axios from 'axios';

import Modal from 'components/elements/Modal';

import Label from 'components/form/Label';
import Input from 'components/form/Input';
import Title from 'components/elements/Title';



import { useDropzone } from 'react-dropzone';

import ProgressBar from 'components/graphics/ProgressBar';

import {
  GET_LIST_OF_JOBS_URL,
  UPLOAD_JOB_URL,
  ACTIVATE_JOB_URL,
  DELETE_JOB_URL,
  GET_BOOL_PRODUCTION_MODE_URL,
  EDIT_JOB_NAME_URL,
  LOAD_NETWORK_TO_JOB_SLOT_URL,
} from 'pages/urls';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input3 from 'components/form/Input3';



//   { slot: 0, id: 'default', name: 'CAMERA-ONLY mode' },

const defaultProgramsList = [
  { slot: 0 },
  { slot: 1 },
  { slot: 2 },
  { slot: 3 },
  { slot: 4 },
  { slot: 5 },
  { slot: 6 },
  { slot: 7 },
  { slot: 8 },
];



class ProgramSelect2 extends Component {
  constructor(props) {
    super(props);

   this.state = {
     programsList: defaultProgramsList,
     bool_production_mode: null,
     bool_job_loaded: false,

     load_job_name: '',
     load_job_slot: -1,
     load_job_progress: 0.0,

     bool_editing_job_name: false,
     editing_job_name: '',
     editing_job_slot: -1,
   };

  ///////////////////////////////////////////////////////

  // Get bool_production_mode

    axios
    .get(GET_BOOL_PRODUCTION_MODE_URL)
    .then((response) => {
      console.log('RECV BOOL_PRODUCTION_MODE');
      console.log(response.data);
      console.log(response.data.bool_production_mode);
      console.log('RECV BOOL_PRODUCTION_MODE');

      this.setState({
        bool_production_mode: response.data.bool_production_mode,
      });

   })
   .catch((error) => {
       console.log("[ERROR] get bool_production_mode")
   });


  ///////////////////////////////////////////////////////

  // Get list of jobs

    axios
    .get(GET_LIST_OF_JOBS_URL)
    .then((response) => {
      console.log('_________________');
      console.log(response.data);
      console.log('_________________');

      this.setState({
        programsList: response.data,
      });

   })
   .catch((error) => {
       console.log("[ERROR] return list_of_jobs")
   });

  ///////////////////////////////////////////////////////



  }

  handleChange(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }











//const ProgramSelect = (props) => {

  render() {

  const { user_ID, dataset_ID, network_ID, network_name, network_type, } = this.props;


  if (this.state.bool_production_mode===true) {
    return (
                 <div className={s.production_mode_error_message}>
                   Load Job not available in production mode
                 </div>

    );
  }

   console.log(`programsList=${JSON.stringify(this.state.programsList)})`)


   return (
    <div className={s.component} onClick={()=>{

        console.log("outside click");

                    if (this.state.bool_editing_job_name) {
                          const url = `${EDIT_JOB_NAME_URL}/${this.state.editing_job_slot}/${this.state.editing_job_name}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                   bool_editing_job_name: false,
                                   editing_job_name: '',
                                   editing_job_slot: -1,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////

                          })
                          .catch((error) => {
                              console.log("[ERROR] editJobName");
                          });

                      }}


                      /////////////////////////////////////////////////////////

                 }



    >
      <table className={s.table}>
        <thead>
          <tr>
            <th className={s.slot}>Slot</th>
            <th>Job Name</th>
            <th> Activate Job</th>
            <th> Delete Job</th>
          </tr>
        </thead>
        <tbody>
          {this.state.programsList.map((program) => {

            const key = program.slot;
            const isActive = program.bool_is_active === true;
            const isDefault = program.slot === 0;
            const hasJob = (program.id !== null) && program.name;
            console.log(`key=${key}`);
            console.log(`isDefault=${isDefault}`);
            console.log(`isActive=${isActive}`);
            console.log(`hasJob=${hasJob}`);

            if (hasJob) {
              return (
                <tr className={s.row} key={key}>
                  <td className={s.slot}>{program.slot}</td>
                  <td>

                    { (isDefault===false) &&

                      <div className={s.nameLine}>
                        <span className={s.iconEdit} onClick={(e)=>{
                            console.log(`EDIT CLICK`);

                                this.setState({
                                    bool_editing_job_name: true,
                                    editing_job_name: program.name,
                                    editing_job_slot: program.slot,
                                    //programsList: response.data,
                                });

                            //set_bool_editing_dataset_display_name(true);
                            e.stopPropagation();
                        }}>
                            <FontAwesomeIcon icon="pen" />
                        </span>

                              { (this.state.bool_editing_job_name && this.state.editing_job_slot===program.slot) ? (

                                    <div className={s.field}>

                                        <Input3
                                            id="job_name"
                                            name="job_name"
                                            type="text"
                                            value={this.state.editing_job_name}
                                            onChange={(e) => { this.setState({ editing_job_name: e.target.value }); } }

                                            placeholder="Dataset Alpha"
                                            onClick={(e)=>{
                                                console.log("Input3 CLICK");

                                                this.setState({
                                                                bool_editing_job_name: true,
                                                              });

                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                  <span key="name" className={s.name}>
                                      {program.name} &nbsp; &nbsp;
                                  </span>

                              )}

                      </div>
                  }




                    {isDefault && (
                      <div>
                        <span key="name" className={s.name}>
                           {program.name} &nbsp; &nbsp;
                        </span>

                        <Tag className={s.defaultTag} key="default" parentClass={cx(s.tag, s.defaultTag)}>
                          <div className={s.tagText}>
                            Default
                          </div>
                        </Tag>
                      </div>
                    )}

                  </td>
                  <td>
                    {isActive ? (
                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Active
                        </div>
                      </Tag>
                    ) :
                       (
                        <div className={s.activateText}>



                    {(isActive===false) && (
                      <Button parentClass={cx(s.button, s.loadJob)} onClick={()=>{
                          console.log("activate_click");
                          console.log("activate_click");

                          const url = `${ACTIVATE_JOB_URL}/${program.slot}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////



                          })
                          .catch((error) => {
                              console.log("[ERROR] deleteJob");
                          });

                      }}


                      /////////////////////////////////////////////////////////
                      >
                        Activate
                      </Button>
                    )}






                        </div>
                      )}
                  </td>
                  <td>
                    {(program.slot > 0) && (

                      <Button parentClass={cx(s.button, s.deleteJob)} color="danger" onClick={()=>{
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");
                          console.log("delete_click");

                          const url = `${DELETE_JOB_URL}/${program.slot}`;

                          axios
                          .get(url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")


                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////

                          })
                          .catch((error) => {
                              console.log("[ERROR] deleteJob");
                          });

                      }}
                      >
                        Delete
                      </Button>
                  )}
                  </td>


                </tr>
              );
            }
            return (
              <tr className={s.row} key={key}>
                <td className={s.slot}>{program.slot}</td>
                <td colSpan="3">

                { (this.state.bool_job_loaded===false) &&
                  <Button parentClass={cx(s.button2, s.loadJob)}


                   onClick={() => {


                          const load_network_url = `${LOAD_NETWORK_TO_JOB_SLOT_URL}/${network_name}/${program.slot}/${user_ID}/${dataset_ID}/${network_ID}/${network_type}`;

                          console.log(`load_network_url=${load_network_url}`)

                          axios
                          .get(load_network_url)
                          .then((response) => {
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log(response.data);
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
                              console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

                              // this.setState({
                              //
                              // });

                              ////////////////////////////////////////////////////

                              // update list_of_jobs

                              axios
                              .get(GET_LIST_OF_JOBS_URL)
                              .then((response) => {
                                console.log('_________________');
                                console.log(response.data);
                                console.log('_________________');

                                this.setState({
                                   programsList: response.data,
                                   bool_job_loaded: true,
                                   load_job_slot: program.slot,
                                });
                              })
                              .catch((error) => {
                                console.log("[ERROR] return list_of_jobs")
                              });

                              ////////////////////////////////////////////

                          })
                          .catch((error) => {
                              console.log("[ERROR] editJobName");
                          });

                      }}


                      /////////////////////////////////////////////////////////


                  >
                    Load Network to Job slot
                  </Button>
                }
                { /*(this.state.bool_job_loaded && this.state.load_job_slot===program.slot) &&

                      <Tag className={s.defaultTag} key="job_loaded" parentClass={cx(s.tag, s.defaultTag)}>
                        <div className={s.tagText}>
                          Job Loaded
                        </div>
                      </Tag>

                */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}};

export default ProgramSelect2;
