import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from 'components/elements/Loader';
import Label from 'components/elements/Label';
import Keyboard from 'components/elements/Keyboard';

import cx from 'classnames';
import s from './Toggle.module.sass';

const Toggle = (props) => {
  const {
    children,
    type,
    isActive,
    isReadOnly,
    isLoading,
    onClick,
    parentClass,
    keyboard,
  } = props;

  const componentClass = cx({
    [s.component]: true,
    [s.isActive]: isActive,
    [s.isLoading]: isLoading,
    [s.isInteractive]: !isReadOnly && !isLoading,
    [s.isReadOnly]: isReadOnly,
    [s[`type-${type}`]]: type,
    [parentClass]: parentClass,
  });

  const icons = {
    pass: 'check',
    fail: 'times-circle',
  };

  const buttonClick = isReadOnly ? null : onClick;

  return (
    <button
      className={componentClass}
      onClick={buttonClick}
      disabled={isLoading}
    >
      {icons.hasOwnProperty(type) && (
        <div className={s.icon}>
          <FontAwesomeIcon icon={icons[type]} />
        </div>
      )}

      <div className={s.main}>
        <Label parentClass={s.label} type={type} />
        {children && <p className={s.body}>{children}</p>}
      </div>

      {keyboard && <Keyboard parentClass={s.keyboard}>{keyboard}</Keyboard>}

      {isLoading && (
        <div className={s.loading}>
          <Loader parentClass={s.loader} />
        </div>
      )}
    </button>
  );
};

export default Toggle;
