import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  DATASETS_INDEX_URL,
  getDatasetURL,
  getImagesURL,
  getNetworksIndexURL,
  getNetworkURL,
  getNewNetworkURL,
} from 'pages/urls';
import { selectors } from 'redux/reducers/datasets';

import s from './DatasetBreadcrumb.module.sass';

const DatasetBreadcrumb = (props) => {
  const {
    dataset,
    datasetId,
    network,
    networkId,
    showImages = false,
    showNetworks = false,
    showNewNetwork = false,
  } = props;

  let items = [
    {
      id: 'datasets',
      label: 'Applications',
      path: DATASETS_INDEX_URL,
      exact: true,
    },
    {
      id: 'dataset',
      label: dataset.name,
      path: getDatasetURL(datasetId),
    },
  ];

  if (showImages) {
    items.push({
      id: 'images',
      label: 'Images',
      path: getImagesURL(datasetId),
    });
  }

  if (showNetworks || showNewNetwork || networkId) {
    items.push({
      id: 'networks',
      label: 'Networks',
      path: getNetworksIndexURL(datasetId),
    });
  }

  if (showNewNetwork) {
    items.push({
      id: 'new-network',
      label: 'New network',
      path: getNewNetworkURL(datasetId),
    });
  }

  if (networkId) {
    items.push({
      id: 'network',
      label: network.name,
      path: getNetworkURL(datasetId, networkId),
    });
  }

  const navItems = items.map((item, index) => {
    let content = [];

    if (index > 0) {
      content.push(
        <span key="sep" className={s.sep}>
          /
        </span>
      );
    }

    content.push(
      <NavLink
        key={item.id}
        className={s.link}
        to={item.path}
        exact={item.exact}
      >
        <strong>{item.label}</strong>
      </NavLink>
    );

    return content;
  });

  return <div className={s.component}>{navItems}</div>;
};

const mapStateToProps = (state, ownProps) => ({
  dataset: selectors.getDataset(state, ownProps.datasetId),
  network: selectors.getNetwork(state, ownProps.networkId),
});

export default connect(mapStateToProps)(DatasetBreadcrumb);
