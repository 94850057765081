// GalleryItem.js

import React from 'react';

import Label from 'components/elements/Label';
import ProgressBar from 'components/graphics/ProgressBar';

import cx from 'classnames';
import s from './GalleryItem.module.sass';

const print_time = (message, v = '') => {
  const date_object = new Date();
  const t = date_object.getTime() / 1000;
  console.log(`${message}: ${t} ${v}`);
};



const GalleryItem = (props) => {
  const { step, item, onClick = null, bool_detection, bool_pass_detection, bool_multi_class_detection=false } = props;

  const { blob, imageFilename, labelClass, labelPolygons, predictionClass } = item;

  let type = 'default';

  if (!blob) {
    type = 'uploading';
  } else if (step === 'upload' || step === 'browse') {
    type = 'displaying';
  } else if (step === 'label') {
    type = labelClass ? 'labeling' : 'displaying';
    //console.log(`labelClass=${labelClass}`);
    ////////////////////////////////////////////////////////////////////
    if (type === 'labeling' && labelClass === 'PASS') {

        if (bool_detection===false || bool_pass_detection===false || bool_multi_class_detection===true) {
            type='labelingPASS';
        } else {
            if (labelPolygons && labelPolygons.length > 0) {
                // fully labelled
                type='labelingPASS';
            } else {
                // needs detection label
                type='labelingPASSX';
            }

        }
    }
    if (type === 'labeling' && labelClass === 'FAIL') {

        //console.log("______________");
        //console.log(`GALLERY_ITEM bool_multi_class_detection=${bool_multi_class_detection}`);
        //console.log("______________");

        if (bool_detection===false || bool_pass_detection===true || bool_multi_class_detection===true) {
            type='labelingFAIL';
        }
        else {
            if (labelPolygons && labelPolygons.length > 0) {
                // fully labelled
                type='labelingFAIL';
            } else {
                // needs detection label
                type='labelingFAILX';
            }
        }
    }
    if (type === 'labeling' && labelClass === 'NONE') {
        type='labelingNONE';
    }
    ////////////////////////////////////////////////////////////////////

    if (labelClass == -1) {
        var unlabelled_multiclass = "NONE"
        type = "unlabeledMulticlass"
    }
    if (Number.isInteger(labelClass) && labelClass >= 1) {
      //console.log(`==> labelClass=${labelClass}`);
      type="multiclass";
    }
  } else if (step === 'review') {

        type = predictionClass
            ? 'predicting'
            : labelClass
            ? 'labeling'
            : 'displaying';

        //if (bool_multi_class_detection) {
        //    //type = 'predictingMultiClass';
        //    //type = "unlabeledMulticlass"
        // }
  }

  if (type==='predicting') {
    if (Number.isInteger(predictionClass) && predictionClass >= 1) {
      //console.log(`==> labelClass=${labelClass}`);
      type="predictingMulticlass";
    }
  }


  //console.log(`step=${step}`)
  //console.log(`type=${type}`)
  //print_time("G item");

  const types = {
    displaying: {
      content: <span className={s.text}>{imageFilename}</span>,
    },
    uploading: {
      content: <span className={s.text}>Uploading…</span>,
    },
    labelingPASS: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    labelingPASSX: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    labelingFAIL: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    labelingFAILX: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    labelingNONE: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    unlabeledMulticlass: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={unlabelled_multiclass} />
        </span>
      ),
    },
    multiclass: {
      content: (
        <span className={s.text}>
          Label: <Label parentClass={s.label} type={labelClass} />
        </span>
      ),
    },
    predictingMulticlass: {
      content: (
        <span className={s.text}>
          Prediction: <Label parentClass={s.label} type={predictionClass} />
        </span>
      ),
    },
    predicting: {
      content: (
        <span className={s.text}>
          <span>
            {/* Label: <Label parentClass={s.label} type={labelClass} />
            <br /> */ }
            Prediction: <Label parentClass={s.label} type={predictionClass} />
          </span>
        </span>
      ),
    },
  };

  if (!types.hasOwnProperty(type)) {
    return null;
  }

  const isClickable = step === 'label' || step === 'review';

  const componentClass = cx({
    [s.component]: true,
    [s.isClickable]: isClickable,
    [s[`type-${type}`]]: type,
    [s[`label-${labelClass}`]]: (type!=="predictingMulticlass") ? labelClass : predictionClass,
    [s.isNumber]: (type!=="unlabeledMulticlass" && type !=="multiclass" && type !== "predictingMulticlass") ? (!isNaN(labelClass)) : false,
    [s[`prediction-${predictionClass}`]]: predictionClass,
  });

  return (
    <div className={componentClass} onClick={onClick}>
      {type === 'uploading' ? (
        <div className={s.uploading}>
          <div className={s.progress}>
            <ProgressBar value={0.2} parentClass={s.bar} hideText />
          </div>
        </div>
      ) : (
        <div className={s.media}>
          <figure className={s.photo}>
            <img src={blob} alt="" />
          </figure>
        </div>
      )}

      <div className={s.meta}>
        <div className={s.content}>{types[type].content}</div>
      </div>
    </div>
  );
};

export default GalleryItem;
