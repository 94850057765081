// B"H

// Images.js

// todo: pass_fail_check_toggle_value
//       --> rename detection_job_type

// todo: see src/components/form/IPAddress.js
//       --> error checking width/height inputs

import React, { Component } from 'react';
import { connect } from 'react-redux';

import Toggle from 'react-toggle';
import "react-toggle/style.css"

//import Dropdown from 'react-dropdown';
//import 'react-dropdown/style.css';

import Select from 'react-select'

import Toggles from 'components/form/Toggles';
import Toggles2 from 'components/form/Toggles2';

import Radios from 'components/form/Radios';

import RangeSlider from 'components/form/RangeSlider';

import { selectors, deleteTrainingImageRedux, postImageLabel } from 'redux/reducers/datasets';

import {
  getNewNetworkURL,
  GET_DATASET_IMAGES_ZIP_URL,
  LOAD_TRAINING_IMAGE_FROM_SERVER_URL,
  UPDATE_NAMED_CLASSES_URL,
  REQUEST_NAMED_CLASSES_URL,
  DELETE_TRAINING_IMAGE_URL,
  RETURN_DATASET_CROP_URL,
} from 'pages/urls';

import ProceedNextStep from 'components/buttons/ProceedNextStep';
import Heading from 'components/elements/Heading';
import Label from 'components/elements/Label';
import Label2 from 'components/elements/Label2';
import Label3 from 'components/elements/Label3';
import Button from 'components/elements/Button';
import ProgressShares from 'components/graphics/ProgressShares';
import KeyValue from 'components/elements/KeyValue';
import Empty from 'components/elements/Empty';
import GalleryGrid from 'components/photos/GalleryGrid';
import Modal from 'components/elements/Modal';
import Hr from 'components/graphics/Hr';
import Viewer from 'components/label/Viewer';

import DropzoneB from 'components/form/DropzoneB';
import DropZoneOnClick from 'components/form/DropZoneOnClick';

import Title from 'components/elements/Title';
import ProgressTime from 'components/graphics/ProgressTime';
import Input2 from 'components/form/Input2';
import axios from 'axios';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import s from './Images.module.sass';

import Slider2 from 'components/form/Slider2';

import { return_bool_EACH_CLASS_has_label_present_within_required_region } from 'components/images/check_labels_present_within_region'
import { return_bool_ANY_CLASS_has_label_present_within_required_region } from 'components/images/check_labels_present_within_region'

/*
const CLASS_LABEL_MARKS = {
  1:  'class=1',
  32: 'class=32',
};
*/

/*
const defaultProgramsList = [
  { slot: 1, name: "" },
  { slot: 2, name: "" },
  { slot: 3, name: "" },
  { slot: 4, name: "" },
  { slot: 5, name: "AX-ZR" },
  { slot: 6, name: "" },
  { slot: 7, name: "" },
  { slot: 8, name: "" },
  { slot: 9, name: "" },
  { slot: 10, name: "" },
  { slot: 11, name: "" },
  { slot: 12, name: "" },
  { slot: 13, name: "" },
  { slot: 14, name: "" },
  { slot: 15, name: "" },
  { slot: 16, name: "" },
  { slot: 17, name: "" },
  { slot: 18, name: "" },
  { slot: 19, name: "" },
  { slot: 20, name: "" },
];
*/

const NAMED_CLASS_OPTIONS = [
  { value: false, label: 'Named Classes OFF' },
  { value: true, label: 'Named Classes ON' },
];

/*
const PASS_FAIL_CHECK_OPTIONS = [
  { value: "PASS_CHECK", label: 'PASS CHECK' },
  { value: "FAIL_CHECK", label: 'FAIL CHECK' },
];
*/
const JOB_TYPE_OPTIONS = [
  { value: 'PASS_CHECK', label: 'PASS_CHECK' },
  { value: 'FAIL_CHECK', label: 'FAIL_CHECK' },
  //{ value: 'MEASUREMENT_CHECK', label: 'MEASUREMENT_CHECK' },
];

const MEASUREMENT_MARKS = {
  0: 'ANY',
  400: 'ANY',
};


const ROI_SELECT_OPTIONS = [
  { value: false, label: 'ROI OFF' },
  { value: true, label: 'ROI ON' },
];

// placeholder
// const DROPDOWN_OPTIONS = [ "dog", "cat"];

const REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}
const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}
const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}

const customStyles = {
    control: (provided) => ({
      ...provided,
      width: '100%',
      margin: '0 !important',
      borderColor: 'green', // Example: change border color
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the menu appears above other elements
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Change text color for options
      backgroundColor: state.isSelected ? 'blue' : 'white', // Change background color for selected option
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'black', // Change text color for the selected option
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#e0e0e0', // Change background color for multi-select options
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: 'black', // Change text color for multi-select labels
    }),
  };


class ImagesPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageId: '',
      currentImageIndex: -1,
      boolCloseModal: true,
      upload_class_i: 1,

      N_classes: 0,
      boolCloseSettingsModal: true,
      //bool_named_classes: false,

      bool_editing_class_name: false,

      bool_editing_field_of_view_height: false,
      bool_editing_field_of_view_width: false,
      field_of_view_width: 0.0,
      field_of_view_height: 0.0,
      tmp_min_x: 0.0,

      class_number_editing: -1,
      class_name_editing: "",

      list_of_class_objects: [],
      // list_of_class_objects: [ { slot: 1, name: "" }, { slot: 2, name: "" }, ],

      // multi class detection ("NOT_SET" OR "PASS_CHECK" OR "FAIL CHECK")
      pass_fail_check_toggle_value: "NOT_SET",

      DROPDOWN_OPTIONS: [],

      bool_roi_select_enabled: false,

      N_crops: 0,
      list_of_crops: [],
    };

    this.update_job_settings(props);
  }

  update_job_settings = (props) => {

        const { datasetId, userId } = props;

        const request_named_classes_url = `${REQUEST_NAMED_CLASSES_URL}/${userId}/${datasetId}`;

        axios
        .get(request_named_classes_url)
        .then((response) => {
            //print_in_blue("[REQUEST NAMED_CLASSES] RESPONSE RECEIVED");
            //print_in_blue(`bool_named_classes=${response.data.bool_named_classes}`);
            //print_in_blue(`default_program_list=${JSON.parse(response.data.named_classes)}`);
            //print_in_blue(`default_program_list=${JSON.parse(response.data.named_classes)}`);
            //print_in_blue(`N_classes=${JSON.parse(response.data.named_classes).length}`);
            //print_in_blue(`response.data=${JSON.stringify(response.data)}`);

            var pass_fail_check_toggle_value = "NOT_SET";

            var recvd_field_of_view_width = 0;
            var recvd_field_of_view_height = 0;

            if (response.data.bool_pass_check===true) {
                pass_fail_check_toggle_value = "PASS_CHECK";
            } else if (response.data.bool_fail_check===true) {
                pass_fail_check_toggle_value = "FAIL_CHECK";
            } else if (response.data.bool_measurement_check===true) {

                pass_fail_check_toggle_value = "MEASUREMENT_CHECK";

                //print_in_red("recv MEASUREMENT_CHECK","red");

                if (response.data.field_of_view_width) {
                    //print_in_red("recv MEASUREMENT_CHECK width", "red");

                    recvd_field_of_view_width = response.data.field_of_view_width;
                }
                if (response.data.field_of_view_height) {
                    //print_in_red("recv MEASUREMENT_CHECK height", "red");

                    recvd_field_of_view_height = response.data.field_of_view_height;
                }
            }

            var bool_roi_select_enabled = false;
            if (response.data.bool_roi_select_enabled===true) {
                bool_roi_select_enabled = true;
            }

            if (response.data.named_classes!=undefined) {
                this.setState({
                    //bool_named_classes: response.data.bool_named_classes,
                    list_of_class_objects: JSON.parse(response.data.named_classes),
                    N_classes: JSON.parse(response.data.named_classes).length,
                    pass_fail_check_toggle_value: pass_fail_check_toggle_value,
                    bool_roi_select_enabled: bool_roi_select_enabled,
                    field_of_view_width: recvd_field_of_view_width,
                    field_of_view_height: recvd_field_of_view_height,
                });
            } else {
                this.setState({
                    //bool_named_classes: false,
                    list_of_class_objects: [],
                    N_classes: 0,
                    pass_fail_check_toggle_value: pass_fail_check_toggle_value,
                    bool_roi_select_enabled: bool_roi_select_enabled,
                    field_of_view_width: recvd_field_of_view_width,
                    field_of_view_height: recvd_field_of_view_height,

                });
            }
        })
        .catch((error) => {
            console.log(`[REQUEST_NAMED_CLASSES] error=${error}`);
        });

    //
        axios
        .get(`${RETURN_DATASET_CROP_URL}/${userId}/${datasetId}`)
        .then((response) => {
          //console.log('_________________');
          //console.log(JSON.stringify(response.data));
          //console.log('_________________');

          //console.log("images.js get list_of_crops from backend");

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////

          if (response.data && response.data.list_of_crop_polygons) {

              let list_of_options = [];
              for (let crop_i=0; crop_i<response.data.list_of_crop_polygons.length; crop_i+=1) {
                  //list_of_options.push(`CROP${crop_i}`)
                  list_of_options.push({
                      value: `CROP${crop_i}`,
                      label: `CROP${crop_i}`
                  });

              }

              this.setState({
                  DROPDOWN_OPTIONS: list_of_options,
                  N_crops: response.data.list_of_crop_polygons.length,
                  list_of_crops: response.data.list_of_crop_polygons,
              });

          }

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////

        })
        .catch((error) => {
          console.log("[ERROR] set bool_stream_video")
        })

  }



  openViewer = (imageId) => {
    const { imagesList } = this.props;

    const currentImageIndex = imageId
      ? imagesList.findIndex((image) => image.id === imageId)
      : false;

    this.setState({
      currentImageId: imageId,
      currentImageIndex,
      boolCloseModal: false,
    });
  };

  closeViewer = () => {
    //this.setState({
    //  currentImageId: '',
    //  currentImageIndex: -1,
    //});

    // ______________________________________________ //
    // multi class detection
    //if (this.state.pass_fail_check_toggle_value=="FAIL_CHECK" || this.state.pass_fail_check_toggle_value=="PASS_CHECK") {
    //
    //    this.update_multi_class_image_labels();
    //}
    // ______________________________________________ //


    this.setState({
        boolCloseModal: true,
    });

  };


  openSettingsModal = () => {

    this.update_job_settings(this.props);

    this.setState({
      boolCloseSettingsModal: false,
    });
  };

  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //

  // moved to check_labels_present_within_region.js

  /*
  return_bool_point_within_crop = (crop_object_i, point_x, point_y) => {

      print_in_orange(` (6) crop_object_i=${JSON.stringify(crop_object_i)}`);
      print_in_orange(` (6) point_x=${point_x} point_y=${point_y}`);

      let crop_x1 = crop_object_i.x;
      let crop_y1 = crop_object_i.y;
      let crop_x2 = crop_object_i.x + crop_object_i.width;
      let crop_y2 = crop_object_i.y + crop_object_i.height;

      if ( ((crop_x1 <= point_x) && (point_x <= crop_x2)) &&
           ((crop_y1 <= point_y) && (point_y <= crop_y2)) ) {
          return true;
      }
      return false;
  }

  return_bool_list_of_labels_contains_label_within_crop = (crop_object_i,  list_of_class_i_labels) => {

      for (let label_i in list_of_class_i_labels) {
          let label_object_i = list_of_class_i_labels[label_i];

          // top left
          let point1_x = label_object_i.x
          let point1_y = label_object_i.y

          // top right
          let point2_x = label_object_i.x + label_object_i.width;
          let point2_y = label_object_i.y

          // bottom right
          let point3_x = label_object_i.x + label_object_i.width;
          let point3_y = label_object_i.y + label_object_i.height;

          // bottom left
          let point4_x = label_object_i.x
          let point4_y = label_object_i.y + label_object_i.height;

          // top_left within crop
          let bool_point1_within_crop = this.return_bool_point_within_crop(crop_object_i, point1_x, point1_y);

          // top_right within crop
          let bool_point2_within_crop = this.return_bool_point_within_crop(crop_object_i, point2_x, point2_y);

          // bottom right within crop
          let bool_point3_within_crop = this.return_bool_point_within_crop(crop_object_i, point3_x, point3_y);

          // bottom left within crop
          let bool_point4_within_crop = this.return_bool_point_within_crop(crop_object_i, point4_x, point4_y);

          if ((bool_point1_within_crop===true) ||
              (bool_point2_within_crop===true) ||
              (bool_point3_within_crop===true) ||
              (bool_point4_within_crop===true)) {
              print_in_green("(5) BOOL_TRUE");
              return true;
          }

      }

      print_in_green("(5) BOOL_FALSE");

      return false;
  }



  return_bool_image_contains_class_i_label_within_region = (class_object_i, image_labels, list_of_crop_objects) => {

      //print_in_blue(`    (3)class_object_i=${JSON.stringify(class_object_i)}`);

      // ____
      let list_of_class_i_labels = [];
      for (let label_i in image_labels) {
          let label_object_i = image_labels[label_i];
          //print_in_green(`    (3)label_object_i=${JSON.stringify(label_object_i)}`);
          if (label_object_i.detection_label_class===class_object_i.slot) {
              list_of_class_i_labels.push(label_object_i);
          }
      }
      // ____
      //print_in_green(`        (4)list_of_class_i_labels=${JSON.stringify(list_of_class_i_labels)}`);
      // ################################################################# //
      // ################################################################# //
      if ((!class_object_i.roi_select_value) ||
          (class_object_i.roi_select_value.startsWith("CROP") === false) ||
          (parseInt(class_object_i.roi_select_value.slice(4))===NaN)) {
          // _________________________________________________ //
          // if no region set, class_i object can be anywhere
          if (list_of_class_i_labels.length > 0) {
              return true;
          } else {
              return false;
          }
          // _________________________________________________ //
      } else {
          let crop_i = parseInt(class_object_i.roi_select_value.slice(4));
          if (crop_i >= list_of_crop_objects.length) {
              print_in_red(`[VIEWER] LIST_OF_CROPS[${crop_i}] not present`);
              return false;
          }
          let crop_object_i = list_of_crop_objects[crop_i];
          //print_in_orange(`        (4)crop_object_i=${JSON.stringify(crop_object_i)}`);
          let bool_list_of_labels_contains_label_within_crop = this.return_bool_list_of_labels_contains_label_within_crop(crop_object_i, list_of_class_i_labels);
          return bool_list_of_labels_contains_label_within_crop;

      }
      // ################################################################# //
      // ################################################################# //

  }

  return_bool_each_class_has_label_present_within_required_region = (list_of_class_objects, image_labels, list_of_crops) => {
      // for each class
      // -> bool_image_contains_class_i_label_within_region
      for (let class_i in list_of_class_objects) {
          let class_object_i = list_of_class_objects[class_i];
          let bool_image_contains_class_i_label_within_region = this.return_bool_image_contains_class_i_label_within_region(class_object_i, image_labels, list_of_crops);
          if (bool_image_contains_class_i_label_within_region===false) {
              return false;
          }
      }
      return true;
  }
  */

  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //
  // ____________________________________________________________________________________ //


  // can call on close settings modal,
  // but not on imageTransition as Viewer is also posting label & therefore can overwrite // race condition
  update_multi_class_image_labels() {

      if (this.state.pass_fail_check_toggle_value=="FAIL_CHECK" || this.state.pass_fail_check_toggle_value=="PASS_CHECK") {

          const { datasetId, userId, postImageLabel, imagesList } = this.props;

          //print_in_red("UPDATE_MULTI_CLASS_IMAGE_LABEL");

          for (var i=0; i<imagesList.length; i+=1) {

              let image = imagesList[i];

              var new_label_class = "NONE";

              //console.log(`    image.labelClass=${image.labelClass}`);

              // ____
              if (this.state.pass_fail_check_toggle_value=="FAIL_CHECK") {

                  // classes found
                  if (image.labelPolygons && image.labelPolygons.length > 0) {

                      // ROI SELECT OFF
                      if (this.state.bool_roi_select_enabled===false) {
                          new_label_class = "FAIL"
                      }
                      // ROI SELECT ON
                      else {

                          let bool_any_class_has_label_present_within_required_region = return_bool_ANY_CLASS_has_label_present_within_required_region(this.state.list_of_class_objects, image.labelPolygons, this.state.list_of_crops)

                          if (bool_any_class_has_label_present_within_required_region===true) {
                              new_label_class = "FAIL"
                          } else {
                              new_label_class = "PASS";
                          }

                      }
                  }

                  // no classes found
                  else {
                      new_label_class = "PASS"
                  }
              }
              // ____

              if (this.state.pass_fail_check_toggle_value=="PASS_CHECK") {
                  new_label_class = "FAIL";

                  if (image.labelPolygons && image.labelPolygons.length > 0) {
                      print_in_blue("____");
                      print_in_blue(`labelPolygons${JSON.stringify(image.labelPolygons)}`);

                      const class_labels_in_image = [...new Set(image.labelPolygons.map(item => item.detection_label_class))]

                      const expected_class_labels_in_image = Array.from({length: this.state.list_of_class_objects.length}, (_, i) => i + 1)

                      print_in_blue(`class_labels_in_image${JSON.stringify(class_labels_in_image)}`);
                      print_in_blue(`expected_class_labels_in_image${JSON.stringify(expected_class_labels_in_image)}`);

                      const bool_all_classes_present_in_image = (class_labels_in_image.sort().join(',') === expected_class_labels_in_image.sort().join(','));

                      // _____________________________________________________ //
                      // _____________________________________________________ //
                      // ROI SELECT OFF
                      if (this.state.bool_roi_select_enabled===false) {

                          if (bool_all_classes_present_in_image) {
                              new_label_class = "PASS";
                          }
                          else {
                              new_label_class = "FAIL";
                          }
                      }
                      // _____________________________________________________ //
                      // _____________________________________________________ //
                      // ROI SELECT ON
                      //print_in_orange(`(1) list_of_crops=${JSON.stringify(this.state.list_of_crops)}`);
                      //print_in_green(`(1) image.labelPolygons=${JSON.stringify(image.labelPolygons)}`);

                      if (this.state.bool_roi_select_enabled===true) {

                          //print_in_orange(`(2)list_of_crops=${JSON.stringify(this.state.list_of_crops)}`);
                          //print_in_green(`(2)image.labelPolygons=${JSON.stringify(image.labelPolygons)}`);
                          //print_in_blue(`(2)this.state.list_of_class_objects=${JSON.stringify(this.state.list_of_class_objects)}`);

                          let bool_each_class_has_label_present_within_required_region = return_bool_EACH_CLASS_has_label_present_within_required_region(this.state.list_of_class_objects, image.labelPolygons, this.state.list_of_crops)

                          if (bool_each_class_has_label_present_within_required_region===true) {
                              new_label_class = "PASS"
                          } else {
                              new_label_class = "FAIL";
                          }


                      }

                      // _____________________________________________________ //
                      // _____________________________________________________ //

                  }
              }
              // ____

              // ____________________________________________________________ //
              // ____________________________________________________________ //

              // post image label

              if (image.labelClass != new_label_class) {
                  if (image.labelPolygons && image.labelPolygons.length > 0) {

                      postImageLabel(image.id, new_label_class, image.labelPolygons, userId, datasetId);

                  } else {

                      postImageLabel(image.id, new_label_class, [], userId, datasetId);

                  }
              }

              // ____________________________________________________________ //
              // ____________________________________________________________ //

              print_in_red(`UPDATE_MULTI_CLASS_IMAGE_LABEL new_label_class=${new_label_class}`);

          }
      }
  }


  closeSettingsModal = () => {

    // post list_of_class_objects
    const { datasetId, userId, imagesList } = this.props;

    const update_named_classes_url = `${UPDATE_NAMED_CLASSES_URL}/${userId}/${datasetId}`;
    //const tmp_x = this.state.bool_named_classes;
    print_in_red(`named_classes =${JSON.stringify(this.state.list_of_class_objects)}`);

    axios
    .post(
        update_named_classes_url,
        {
          //bool_named_classes: this.state.bool_named_classes,
          //bool_named_classes: "x",
          bool_pass_check: (this.state.pass_fail_check_toggle_value=="PASS_CHECK"),
          bool_fail_check: (this.state.pass_fail_check_toggle_value=="FAIL_CHECK"),
          bool_measurement_check: (this.state.pass_fail_check_toggle_value=="MEASUREMENT_CHECK"),
          bool_roi_select_enabled: (this.state.bool_roi_select_enabled),
	  named_classes: JSON.stringify(this.state.list_of_class_objects),
          field_of_view_width: (this.state.field_of_view_width),
          field_of_view_height: (this.state.field_of_view_height),

        },
        REQUEST_CONFIG
      )
      .then((response) => {
          print_in_red("[UPDATE_NAMED_CLASSES] RESPONSE RECEIVED");
      })
      .catch((error) => {
          print_in_red(`[UPDATE_NAMED_CLASSES] ERROR=${error}`);
      });

    // ______________________________________________ //
    // multi class detection
    if (this.state.pass_fail_check_toggle_value=="FAIL_CHECK" || this.state.pass_fail_check_toggle_value=="PASS_CHECK") {

        this.update_multi_class_image_labels();
    }
    // ______________________________________________ //


    this.setState({
        boolCloseSettingsModal: true,
        bool_editing_class_name: false,
        class_number_editing: -1,
    });

  };


  UpdateDefaultProgramsList = () => {
    console.log("update_programs_list_callback");

    this.setState((prevState)=>({
        list_of_class_objects:
            prevState.list_of_class_objects.map(class_object => {
                // if this task has the same ID as the edited task
                if (prevState.class_number_editing === class_object.slot) {
                    return {...class_object, name: prevState.class_name_editing}
                }
                return class_object;
            }),

        class_number_editing: -1,
        class_name_editing: "",
    }));


  };




  update_list_of_class_objects = () => {
    console.log("check_number_classes_callback");
    //console.log(`    this.state.N_classes=${this.state.N_classes}`);
    //console.log(`    this.state.list_of_class_objects.length=${this.state.list_of_class_objects.length}`);

    const {dataset} = this.props;

    //if (this.state.bool_named_classes === true || (dataset.bool_detection===true && dataset.bool_multi_class===true)) {
    if (true) {

        //print_in_blue(`this.state.N_classes=${this.state.N_classes}`);
        //print_in_blue(`this.state.list_of_class_objects.length=${this.state.list_of_class_objects.length}`);

        if (this.state.N_classes === this.state.list_of_class_objects.length) {
            print_in_blue("    N_classes===array_length")

        } else {
            console.log("    N_classes != list_of_class_objects.length")
            let new_list = [];

            for (var i = 0; i < this.state.N_classes; i++) {

               if (i<this.state.list_of_class_objects.length) {
                    new_list.push({ slot: i+1,
                                    name: this.state.list_of_class_objects[i].name,
                                    roi_select_value: (this.state.bool_roi_select_toggle ? this.state.list_of_class_objects[i].roi_select_value : []),
                                  });
                }
                else {
                    new_list.push({ slot: i+1,
                                    name: "",
                                    roi_select_value: [],
                                  });

                }
            }
            this.setState({
                list_of_class_objects: new_list,
            });
            // list_of_class_objects pushed to backend in CloseSettingsModal
        }

    } else {
        print_in_blue("    N/A: named classes OFF");
    }

    /*
    this.setState((prevState)=>({
        list_of_class_objects:
            prevState.list_of_class_objects.map(class_object => {
                // if this task has the same ID as the edited task
                if (prevState.class_number_editing === class_object.slot) {
                    return {...class_object, name: prevState.class_name_editing}
                }
                return class_object;
            }),

        class_number_editing: -1,
        class_name_editing: "",
    }));
    */

  };











  deleteImageCallback = (imageId_to_delete) => {

    ///////////////////////////////////////////////////////////

    const { datasetId, userId } = this.props;

    console.log(`imageId_to_delete=${imageId_to_delete}`);

    const {deleteTrainingImageRedux} = this.props;

    //console.log(`image=${JSON.stringify(image)}`)
    //console.log(`userId=${userId}`)
    //console.log(`datasetId=${datasetId}`)

    axios
    .get(`${DELETE_TRAINING_IMAGE_URL}/${userId}/${datasetId}/${imageId_to_delete}`)
    .then((response) => {
                            console.log('RECV DELETE_TRAINING_IMAGE');
                            console.log(response.data);
                            console.log('RECV DELETE_TRAINING_IMAGE');

                            deleteTrainingImageRedux(imageId_to_delete);

                        })
                        .catch((error) => {
                            console.log("[ERROR] delete training image")
                        });


    ///////////////////////////////////////////////////////////

    this.closeViewer();
  };

  imageTransition = (direction) => {

    //console.log('imageTransition')
    //console.log('imageTransition')
    //console.log('imageTransition')

    if (!direction) {
      return;
    }

    const { imagesList } = this.props;
    const { currentImageIndex } = this.state;
    const lastIndex = imagesList.length - 1;

    var nextIndex =
      direction === 'next'
        ? currentImageIndex + 1
        : direction === 'previous'
        ? currentImageIndex - 1
        : currentImageIndex;

    // Bail if out of bounds
    if (nextIndex > lastIndex) {
      nextIndex = lastIndex;
      this.closeViewer();
      return;
    } else if (nextIndex < 0) {
      nextIndex = 0;
      this.closeViewer();
      return;
    }

    const nextId = imagesList[nextIndex].id;

    this.setState({
      currentImageId: nextId,
      currentImageIndex: nextIndex,
    });
  };




  render() {
    const { imagesList, datasetId, dataset, userId } = this.props;
    const { currentImageId, currentImageIndex } = this.state;

    //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@")
    //console.log(`dataset=${JSON.stringify(dataset)}`)
    //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@")
    //console.log(`dataset.bool_multi_class=${dataset.bool_multi_class}`)
    //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@")

    var max_label = null;

    const totalCount = imagesList.length;

    // PASS_DETECTION, FAIL_DETECTION
    if (dataset.bool_detection === true && dataset.bool_multi_class === false) {
        var passCount = imagesList.filter((image) => image.labelClass === 'PASS').length;
        var failCount = imagesList.filter((image) => image.labelClass === 'FAIL').length;
        if (dataset.bool_pass_detection === true) {
            var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'PASS' && image.labelPolygons && image.labelPolygons.length > 0)).length;
        } else {
            var detectionLabelCount = imagesList.filter((image) => (image.labelClass === 'FAIL' && image.labelPolygons && image.labelPolygons.length > 0)).length;
        }
    }
    // MULTI_CLASS_DETECTION
    else if (dataset.bool_detection == true && dataset.bool_multi_class === true) {
        console.log("MULTI_CLASS")
        var passCount = imagesList.filter((image) => image.labelClass === 'PASS').length;
        var failCount = imagesList.filter((image) => image.labelClass === 'FAIL').length;
        //var labeledCount = imagesList.filter((image) => (image.labelPolygons && image.labelPolygons.length > 0)).length;

    }
    // PASS/FAIL CLASSIFICATION
    else if (dataset.bool_detection===false && dataset.bool_multi_class === false) {
        var passCount = imagesList.filter((image) => image.labelClass === 'PASS').length;
        var failCount = imagesList.filter((image) => image.labelClass === 'FAIL').length;
        var unlabeledCount = totalCount - passCount - failCount;
    }
    // CLASSIFICATION
    else {
        var unlabeledCount = imagesList.filter((image) => image.labelClass === -1).length;
        var labeledCount = totalCount - unlabeledCount;

        //max_label = imagesList.filter((image) => image.labelClass === 'PASS').length;
        max_label = Math.max.apply(Math, imagesList.map(function(image) {
            if (image && image.labelClass && typeof image.labelClass === 'number') {
                return image.labelClass;
            } else {
                return 0;
            }
        }))
        //console.log(`yyyyyyyyyyyyyyyyyyyyyyyyyy`);
        //console.log(`max_label=${max_label}`);
        //console.log(`yyyyyyyyyyyyyyyyyyyyyyyyyy`);
        if (!max_label || max_label<3) {
            max_label = 3;
        }
    }


    //console.log(`max_label=${max_label}`);

    const currentImage =
      imagesList.find((image) => image.id === currentImageId) || null;
    const imageCount = imagesList.length;
    const firstUnlabeledImage = imagesList.find(
      (image) => image.labelClass !== 'PASS' && image.labelClass !== 'FAIL'
    );
    const buttonOnClick = firstUnlabeledImage
      ? () => this.openViewer(firstUnlabeledImage.id)
      : null;

    const shares = [
      { type: 'PASS', value: passCount },
      { type: 'FAIL', value: failCount },
    ];

    const canProceed = unlabeledCount === 0;


    //console.log(`imagesList=${JSON.stringify(imagesList)}`)
    //console.log(`Images.js dataset=${JSON.stringify(dataset)}`);


    var dictx = new Map();
    dictx.set("1", "class=1");
    dictx.set(`${this.state.N_classes}`, `class=${this.state.N_classes}`);

    // N_class_names (progress naming classes)
    const N_class_names = this.state.list_of_class_objects.reduce((counter, obj) => {
        if (obj.name != "") {
            counter += 1;
        }
        return counter;
    }, 0);

    return [

      <div key="component" className={s.component}>
            <aside className={s.side}>


        { (imagesList.length === 0) && (

            <div>
              <Heading surtitle="Step 1">Upload Images</Heading>
              <div className={s.data}>
                <KeyValue value={totalCount}>Images uploaded</KeyValue>
              </div>
            <Hr />

            </div>

          )}


        { (imagesList.length !== 0) && (

        <div>
          <Heading surtitle="Step 2">Label Images</Heading>


          <div className={s.data}>

           { (dataset.bool_multi_class===false) && (
               <div>
                 <KeyValue value={totalCount}>Images uploaded</KeyValue>
                 <ProgressShares
                   values={shares}
                   total={totalCount}
                   label="labeling complete"
                 />
               </div>
           )}
            <Hr />

            {
              (dataset.bool_detection===true && dataset.bool_pass_detection===true) &&
               (
                <div>
                  <KeyValue value={passCount} type="PASS">
                    <Label type="PASS" iconLeft="check" /> images
                  </KeyValue>
                  <KeyValue value={failCount} type="FAIL">
                    <Label type="FAIL" iconLeft="times-circle" /> images
                  </KeyValue>
                  <br />
                  <KeyValue value={`${detectionLabelCount}/${passCount}`} type="PASS">
                    <Label type="PASS" iconLeft="check" /> detection labels
                  </KeyValue>

                </div>
               )

            }

            {
              (dataset.bool_detection===true && dataset.bool_pass_detection===false && dataset.bool_multi_class===false) &&
               (
                <div>
                  <KeyValue value={passCount} type="PASS">
                    <Label type="PASS" iconLeft="check" /> images
                  </KeyValue>
                  <KeyValue value={failCount} type="FAIL">
                    <Label type="FAIL" iconLeft="times-circle" /> images
                  </KeyValue>
                  <br />
                  <KeyValue value={`${detectionLabelCount}/${failCount}`} type="FAIL">
                    <Label type="FAIL" iconLeft="times-circle" /> detection labels
                  </KeyValue>

                </div>
               )

            }


            {
              (dataset.bool_detection===false && dataset.bool_multi_class===false) &&
              (
                <div>
                  <KeyValue value={unlabeledCount} type="primary">
                    Unlabeled images
                  </KeyValue>
                  <KeyValue value={passCount} type="PASS">
                    Labeled as <Label type="PASS" iconLeft="check" />
                  </KeyValue>
                  <KeyValue value={failCount} type="FAIL">
                    Labeled as <Label type="FAIL" iconLeft="times-circle" />
                  </KeyValue>
                </div>
              )
            }


            {/* CLASSIFICATION (modal header settings)*/}

            {
              (dataset.bool_multi_class===true && dataset.bool_detection===false) && (

                <div>
                  {/*******************************************/}

                  { (this.state.N_classes>0) && (
                      <KeyValue value={`${this.state.N_classes}`} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Number of classes:
                      </KeyValue>
                  )}

                  { (this.state.N_classes<=0) && (
                      <KeyValue value={`NOT SET`} type="FAIL">
                          <Label2 type="FAIL" iconLeft="times-circle" /> Number of classes:
                      </KeyValue>
                  )}
                  <Hr/>

                  {/*******************************************/}

                  {(N_class_names===this.state.N_classes) && (
                      <KeyValue value={`${N_class_names}/${this.state.N_classes}`} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Classes named:
                      </KeyValue>
                  )}
                  {(N_class_names!==this.state.N_classes) && (
                      <KeyValue value={`${N_class_names}/${this.state.N_classes}`} type="FAIL">
                          <Label2 type="FAIL" iconLeft="times-circle" /> Classes named:
                      </KeyValue>
                  )}

                  <Hr/>

                  {/*******************************************/}
                  <KeyValue value={`${labeledCount}/${totalCount}`} type="PASS">

                    {(labeledCount===totalCount) ? (
                         <div>
                             <Label2 type="PASS" iconLeft="check" /> Images labeled
                         </div>
                     ) : (
                         <div>
                             <Label2 type="FAIL" iconLeft="times-circle" /> Images labeled
                         </div>
                     )}
                  </KeyValue>

                  <Hr/>
                  {/*******************************************/}


                </div>

              )
            }

            {
              (dataset.bool_multi_class===true && dataset.bool_detection===true) &&
              (
                <div>
                  {/*
                  <KeyValue value={`${labeledCount}/${totalCount}`} type="PASS">
                    <Label iconLeft="check" /> images labeled
                  </KeyValue>
                  */}
                  {/*******************************************/}

                  { (this.state.N_classes>0) && (
                      <KeyValue value={`${this.state.N_classes}`} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Number of classes:
                      </KeyValue>
                  )}

                  { (this.state.N_classes<=0) && (
                      <KeyValue value={`NOT SET`} type="FAIL">
                          <Label2 type="FAIL" iconLeft="times-circle" /> Number of classes:
                      </KeyValue>
                  )}

                  {/*******************************************/}
                  <Hr/>

                  {(N_class_names===this.state.N_classes) && (
                      <KeyValue value={`${N_class_names}/${this.state.N_classes}`} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Classes named:
                      </KeyValue>
                  )}
                  {(N_class_names!==this.state.N_classes) && (
                      <KeyValue value={`${N_class_names}/${this.state.N_classes}`} type="FAIL">
                          <Label2 type="FAIL" iconLeft="times-circle" /> Classes named:
                      </KeyValue>
                  )}

                  {/*******************************************/}
                  <Hr/>

                  { (this.state.pass_fail_check_toggle_value==="PASS_CHECK") && (
                      <KeyValue value={"PASS CHECK"} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Job Type:
                      </KeyValue>
                  )}
                  { (this.state.pass_fail_check_toggle_value==="FAIL_CHECK") && (
                      <KeyValue value={"FAIL CHECK"} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Job Type:
                      </KeyValue>
                  )}
                  { (this.state.pass_fail_check_toggle_value==="MEASUREMENT_CHECK") && (
                      <KeyValue value={"MEASUREMENT CHECK"} type="PASS">
                          <Label2 type="PASS" iconLeft="check" /> Job Type:
                      </KeyValue>
                  )}
                  { (this.state.pass_fail_check_toggle_value==="NOT_SET") && (
                      <KeyValue value={"NOT SET"} type="FAIL">
                          <Label2 type="FAIL" iconLeft="times-circle" /> Job Type:
                      </KeyValue>
                  )}

                  <Hr/>

                  {/*******************************************/}
                  { (this.state.pass_fail_check_toggle_value!=="NOT_SET") && (

                        <KeyValue value={passCount} type="PASS">
                            Labeled as <Label type="PASS" iconLeft="check" />
                        </KeyValue>
                  )}
                  { (this.state.pass_fail_check_toggle_value!=="NOT_SET") && (

                        <KeyValue value={failCount} type="FAIL">
                            Labeled as <Label type="FAIL" iconLeft="times-circle" />
                        </KeyValue>
                  )}
                  {/*******************************************/}

                  {/*
                  <br />
                  <div>
                      <KeyValue value={totalCount}>Images added</KeyValue>
                      <ProgressShares
                        values={shares}
                        total={totalCount}
                        label="pass/fail labels"
                      />
                  </div>
                  */}
                  {/*******************************************/}

                  {/*
                  <Hr/>
                      <KeyValue value={""} type="PASS">
                        <p><u> Job Type: </u></p>
                        { (this.state.pass_fail_check_toggle_value==="PASS_CHECK") && (
                            <Label3 type={"PASS"} msg_str={"PASS CHECK"} iconLeft="check" />
                        )}

                        { (this.state.pass_fail_check_toggle_value==="FAIL_CHECK") && (
                          <Label3 type={"FAIL"} msg_str={"FAIL CHECK"} iconLeft="check" />
                        )}

                        { (this.state.pass_fail_check_toggle_value==="NOT_SET") && (
                          <Label3 type="FAIL" msg_str={"NOT SET"} iconLeft="times-circle" />
                        )}
                      </KeyValue>

                  <Hr/>
                  */}
                  {/*******************************************/}
                </div>
              )
            }


            <Hr />
            <Hr />
          </div>
        </div>

        )}


          <DropzoneB datasetId={datasetId}/>

          {/*
          <Button
            isSuccess
            iconRight="plus-square"
          >
            Add Images
          </Button>
          <Button
            iconLeft="arrow-right"
            isDisabled={!firstUnlabeledImage}
            onClick={buttonOnClick}
          >
            Label Images
          </Button>
          */}


            { (dataset.bool_multi_class===false) &&
              (

                  <div className={s.two_buttons}>

                    <div className={s.buttonA}>
                      <DropZoneOnClick
                        bool_multi_class={dataset.bool_multi_class}
                        class_label="PASS"
                        datasetId={datasetId}
                      />

                       {/*
                      <Button
                        color="success"
                        iconLeft="plus-square"
                        size="medium"
                        onClick={()=>{
                            console.log("UPLOAD PASS");
                        }}
                      >
                        Upload Pass
                      </Button>
                       */}

                    </div>

                    <div className={s.buttonB}>
                      <DropZoneOnClick
                        bool_multi_class={dataset.bool_multi_class}
                        class_label="FAIL"
                        datasetId={datasetId}
                      />

                      {/*<Button
                        color="danger"
                        iconLeft="plus-square"
                        size="medium"
                        onClick={()=>{
                            console.log("UPLOAD FAIL");
                        }}
                      >
                        Upload Fail
                      </Button>
                       */}
                    </div>

                  </div>

              )
            }


        { (dataset.bool_multi_class == false) &&

          (canProceed ? (

          <div>
            <br />
            <br />
               <Button
                  iconRight="arrow-right"
                  isFullWidth
                  isTall
                  size="medium"
                  to={getNewNetworkURL(datasetId)}
                  //isActive={canProceed}
               >
                  Proceed to next step
                </Button>
          </div>
                 ) : (

          <div>
            <br />
            <br />

                <Button
                  iconRight="arrow-right"
                  isDisabled={imageCount===0}
                  isFullWidth
                  isTall
                  size="medium"
                  onClick={buttonOnClick}
                >
                  Label Images
                </Button>
          </div>

                 )
          )
       }


       { (dataset.bool_multi_class===true && dataset.bool_detection===false) && (

         <div>
          <br/>
          <Slider2
            min={1}
            max={this.state.N_classes}
            //marks={CLASS_LABEL_MARKS}
            marks={dictx}
            //marks={{
            //             1:  'class=1',
            //        this.state.N_classes: `class=${this.state.N_classes}`,
            //}}
            value={this.state.upload_class_i}
            defaultValue={this.state.upload_class_i}
            onChange={(sliderValue) => {
                //console.log(`slider change value=${sliderValue}`);
                this.setState({
                    upload_class_i: sliderValue,
                });

            } }
            />

                    <div className={s.buttonC}>
                      <DropZoneOnClick
                        bool_multi_class={dataset.bool_multi_class}
                        class_label={this.state.upload_class_i}
                        datasetId={datasetId}
                      />


                    </div>

                   <br/>
                   <br/>
                   <Button
                      color="success"
                      iconLeft="cog"
                      isDisabled={false}
                      isFullWidth
                      isTall
                      size="medium"
                      onClick={()=>{
                          console.log("CLICK SETTINGS");
                          this.openSettingsModal()

                          //const date_timestamp_str = new Date().toISOString()
                          //const getZipUrl = GET_DATASET_IMAGES_ZIP_URL + `/${userId}/${datasetId}/${date_timestamp_str}`;
                          //window.location.href = getZipUrl;
                      }
                      }>
                    SET CLASS NAMES
                    </Button>

              </div>
         )
       }


         { (dataset.bool_multi_class===true && dataset.bool_detection===true) && (

             <div>
               <br/>
               <br/>
                   <Button
                      color="success"
                      iconLeft="cog"
                      isDisabled={false}
                      isFullWidth
                      isTall
                      size="medium"
                      onClick={()=>{
                          console.log("CLICK SETTINGS");
                          this.openSettingsModal()

                          //const date_timestamp_str = new Date().toISOString()
                          //const getZipUrl = GET_DATASET_IMAGES_ZIP_URL + `/${userId}/${datasetId}/${date_timestamp_str}`;
                          //window.location.href = getZipUrl;
                      }
                      }>
                    EDIT JOB SETTINGS
                    </Button>

              </div>
            )
          }


              {/*
              <Button
                  color="success"
                  iconLeft="arrow-down"
                  isDisabled={imageCount===0}
                  isFullWidth
                  isTall
                  size="medium"
                  onClick={()=>{
                      //console.log("CLICK DOWNLOAD");
                      const date_timestamp_str = new Date().toISOString()
                      const getZipUrl = GET_DATASET_IMAGES_ZIP_URL + `/${userId}/${datasetId}/${date_timestamp_str}`;
                      window.location.href = getZipUrl;
                  }
              }>
                Download Images
              </Button>
              */}



        </aside>

        <section className={s.main}>
          <div className={s.gallery}>
            <GalleryGrid
              step="label"
              items={imagesList}
              onClick={this.openViewer}
              boolSort={(this.state.boolCloseModal===true)}
              bool_detection={dataset.bool_detection}
              bool_pass_detection={dataset.bool_pass_detection}
              bool_multi_class_detection={dataset.bool_multi_class && dataset.bool_detection}
            />
          </div>
        </section>
      </div>,
      <Modal
        key="modal"
        isOpen={this.state.boolCloseModal===false}
        onRequestClose={this.closeViewer}
      >
        <Viewer
          image={currentImage}
          img_src={LOAD_TRAINING_IMAGE_FROM_SERVER_URL + `/${userId}/${datasetId}/${currentImageId}`}
          imageIndex={currentImageIndex}
          imageCount={imageCount}
          onNextImageCallback={() => this.imageTransition('next')}
          onPreviousImageCallback={() => this.imageTransition('previous')}
          predictedDefects={[]}
          isReadOnly={false}
          boolSegmentationEnabled={dataset.bool_detection}
          userId={userId}
          datasetId={datasetId}
          boolMultiClass={dataset.bool_multi_class}
          max_label={max_label}
          onDelete={this.deleteImageCallback}
          bool_all_classes_named={N_class_names===this.state.N_classes}
          list_of_class_objects={this.state.list_of_class_objects}
          bool_detection={dataset.bool_detection}
          bool_pass_detection={dataset.bool_pass_detection}
          bool_multi_class_detection={dataset.bool_detection===true && dataset.bool_multi_class===true}
          bool_multi_class={dataset.bool_multi_class}
        />
      </Modal>,

















      <Modal
        key="modal2"
        isOpen={this.state.boolCloseSettingsModal===false}
        onRequestClose={this.closeSettingsModal}
      >




       <div className={s.classifysettings} onClick={(e)=>{
           console.log("CLICK MODAL DIV");

           // class_number_editing gets set to -1 by UpdateDefaultProgramsList
           this.setState({
               //class_number_editing: -1,
               bool_editing_class_name: false,
           }, this.UpdateDefaultProgramsList);
           e.stopPropagation()
       }}>





         <div className={s.modal_header}>




         {dataset.bool_detection===false && <Title>Classification Settings:</Title>}
         {dataset.bool_detection===true && <Title>Multi-Class Detection Settings:</Title>}

          <br/>
          <br/>

           {(dataset.bool_detection === true && dataset.bool_multi_class) && (

           <div>
            <p className={s.title2}> (1) Required: Set number of classes: </p>

               <div className={s.slider2_container}>
                <Slider2
                  min={1}
                  max={8}
                  marks={{
                     1:  'class=1',
                     8: `class=${8}`,
                  }}
                  value={this.state.N_classes}
                  defaultValue={this.state.N_classes}
                  onChange={(N_classes_slider_value) => {
                       //console.log(`slider change value=${sliderValue}`);
                       this.setState({
                          N_classes: N_classes_slider_value,
                       }, this.update_list_of_class_objects);
                  } }
                />
              </div>
            </div>
           )}




           {(dataset.bool_detection === false && dataset.bool_multi_class) && (

           <div>
            <p className={s.title2}> (1) Required: Set number of classes: </p>

            <br/>
               <div className={s.slider2_container}>
                <Slider2
                  min={1}
                  max={64}
                  marks={{
                     1:  'class=1',
                     64: `class=${64}`,
                  }}
                  value={this.state.N_classes}
                  defaultValue={this.state.N_classes}
                  onChange={(N_classes_slider_value) => {
                       //console.log(`slider change value=${sliderValue}`);
                       this.setState({
                          N_classes: N_classes_slider_value,
                       }, this.update_list_of_class_objects);
                  } }
                />
              </div>

             <br/>
             <p className={s.title2}>
                (2) Required: Set class names:
             </p>

           <br/>
           {/*
           <Toggles

              currentValue={this.state.bool_named_classes}
              options={NAMED_CLASS_OPTIONS}
              onChange={(new_toggle_value)=>{
                  console.log(new_toggle_value);
                  this.setState({
                      bool_named_classes: new_toggle_value,
                  }, this.update_list_of_class_objects);

              }}
            />
            */}
           </div>

           )}




        {/********************************************************************/}
        {/********************************************************************/}

         {/* MULTI CLASS DETECTION SETTINGS */}

        {(dataset.bool_detection===true && dataset.bool_multi_class===true) && (

         <div>
           <Hr/>
           <Hr/>
           <p className={s.title2}>
                (2) Required: Set Job type:
           </p>

           <Hr/>
           <p className={s.title2_instructions} style={{ paddingLeft: '15px' }}>
                <b>PASS CHECK </b> → Ensure all parts are present
           </p>
           <p className={s.title2_instructions} style={{ paddingLeft: '15px' }}>
                <b>FAIL CHECK:</b> → Ensure no defects are present
           </p>
           <Hr/>
           {/*
           <p className={s.title2_instructions}>
                <b>MEASUREMENT:</b> → Measure box width/height
           </p>
           */}
           <Hr/>

          <Radios
            currentValue={this.state.pass_fail_check_toggle_value}
            options={JOB_TYPE_OPTIONS}
            onChange={(new_toggle_value)=>{
                  //console.log(`PASS_FAIL_CHECK_TOGGLE: new_toggle_value=${new_toggle_value}`);
                  if (new_toggle_value === "MEASUREMENT_CHECK") {
                      this.setState({
                          pass_fail_check_toggle_value: new_toggle_value,
                          bool_roi_select_enabled: false,
                      }, this.update_list_of_class_objects);
                  } else {
                      this.setState({
                          pass_fail_check_toggle_value: new_toggle_value,
                      }, this.update_list_of_class_objects);
                  }
            }}
          />
           {/*
           <Toggles

              currentValue={this.state.pass_fail_check_toggle_value}
              options={PASS_FAIL_CHECK_OPTIONS}
              onChange={(new_toggle_value)=>{
                  //console.log(`PASS_FAIL_CHECK_TOGGLE: new_toggle_value=${new_toggle_value}`);
                  if (new_toggle_value === "FAIL_CHECK") {
                      this.setState({
                          pass_fail_check_toggle_value: new_toggle_value,
                          bool_roi_select_enabled: false,
                      }, this.update_list_of_class_objects);
                  } else {
                      this.setState({
                          pass_fail_check_toggle_value: new_toggle_value,
                      }, this.update_list_of_class_objects);
                  }
              }}
            />
           */}
           <Hr/>

           <br/>

           { (this.state.pass_fail_check_toggle_value == "PASS_CHECK" || this.state.pass_fail_check_toggle_value == "FAIL_CHECK") && (
             <div>
               <p className={s.title2}>
                   (3) Optional: Set Region of Interest ON/OFF
               </p>
               <Hr/>
               {/*<p className={s.title2_instructions}>
                   <b>Region of Interest </b> → requires <b>PASS CHECK</b> and <b>2+ crops</b>
               </p>*/}

               <p className={s.title2_instructions} style={{ paddingLeft: '15px' }}>
                   <b>Region of Interest </b> → requires <b>1+ crop(s) within image</b>
               </p>

               <Hr/>

                <ul className={s.title2_instructions}>
                  <li style={{ paddingLeft: '15px' }}>
                    <b>PASS CHECK </b> (with ROI ON):
                  </li>
                  <li style={{ paddingLeft: '30px' }}>
                    <b> → Ensure each part is present </b> in selected regions
                  </li>
                 </ul>

               <Hr/>

                <ul className={s.title2_instructions}>
                  <li style={{ paddingLeft: '15px' }}>
                    <b>FAIL CHECK:</b> (with ROI ON):
                  </li>
                  <li style={{ paddingLeft: '30px' }}>
                    <b> → Ensure no defects are present </b> in selected regions
                  </li>

                </ul>

               <Hr/>


               <Toggles

                   currentValue={this.state.bool_roi_select_enabled}
                   options={ROI_SELECT_OPTIONS}
                   onChange={(new_toggle_value)=>{
                       //console.log(`PASS_FAIL_CHECK_TOGGLE: new_toggle_value=${new_toggle_value}`);

                       //if (this.state.N_crops > 1 && this.state.pass_fail_check_toggle_value==="PASS_CHECK") {
                       this.setState({
                           bool_roi_select_enabled: new_toggle_value,
                       }, this.update_list_of_class_objects);
                   }}
               />
               <Hr/>
               <br/>

             </div>
           )}




           { (this.state.pass_fail_check_toggle_value == "MEASUREMENT_CHECK") && (
             <div onClick={()=> { this.setState({
                                      bool_editing_field_of_view_width: false,
                                      bool_editing_field_of_view_height: false,
                                  }); }}
             >

               <p className={s.title2}>
                   (3) Required: Set Camera field of view width/height
               </p>
               <Hr/>
               <p className={s.title2_instructions}>
                   <b>MEASUREMENT:</b> → Camera visual area (width/height) in mm
               </p>
               <Hr/>
                            {/************************************************/}
                            <Title parentClass={s.editable_field_of_view} noMargin>

                            <span className={s.iconEdit} onClick={(e)=>{
                                 console.log(`EDIT CLICK`);
                                 e.stopPropagation();
                                   this.setState({
                                       bool_editing_field_of_view_width: true,
                                   });
                                 }}
                             >
                               <FontAwesomeIcon icon="pen" />
                            </span>

                                width: &nbsp;

                            { (this.state.bool_editing_field_of_view_width) ? (

                                  <div className={s.field}>
                                        <Input2
                                            id="field_of_view_width"
                                            name="field_of_view_width"
                                            type="text"
                                            value={this.state.field_of_view_width}
                                            onChange={(e) => { this.setState({ field_of_view_width: e.target.value }); } }

                                            //placeholder="class name"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {this.state.field_of_view_width} mm
                                     </div>
                                )}
                            </Title>
                            {/************************************************/}
                            <Title parentClass={s.editable_field_of_view} noMargin>

                            <span className={s.iconEdit} onClick={(e)=>{
                                 console.log(`EDIT CLICK`);
                                 e.stopPropagation();
                                   this.setState({
                                       bool_editing_field_of_view_height: true,
                                   });
                                 }}
                             >
                               <FontAwesomeIcon icon="pen" />
                            </span>

                                height: &nbsp;

                            { (this.state.bool_editing_field_of_view_height) ? (

                                  <div className={s.field}>
                                        <Input2
                                            id="field_of_view_height"
                                            name="field_of_view_height"
                                            type="text"
                                            value={this.state.field_of_view_height}
                                            onChange={(e) => { this.setState({ field_of_view_height: e.target.value }); } }

                                            //placeholder="class name"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {this.state.field_of_view_height} mm
                                     </div>
                                )}
                            </Title>
                            {/************************************************/}

               <Hr/>
               <br/>
                   {/*
                   <RangeSlider
                       min={0.000}
                       max={400.000}
                       marks={MEASUREMENT_MARKS}
                       //value={this.state.tmp_min_x}
                       //defaultValue={this.state.tmp_min_x}
                       onChange={(sliderValue) => { console.log(`rangeSliderValue${sliderValue}`); this.setState({ tmp_min_x: sliderValue }); } }
                   />
                   */}
             </div>
           )}

         </div>

        )}

        {/* completion of multi class detection settings */}

        {/********************************************************************/}
        {/********************************************************************/}



        <br/>

              <div className={s.progress_bar_container}>
                <ProgressTime
                  value={N_class_names/this.state.N_classes}
                  type="success"
                  time={`${"Please name all classes"}`}
                />
              </div>


        </div>

        {/* completion of s.modal_header */}



        {/********************************************************************/}
        {/********************************************************************/}
        {/********************************************************************/}
        {/********************************************************************/}



        {/* CLASSIFICATION TABLE */}


        {(this.state.N_classes > 0 && dataset.bool_detection===false && dataset.bool_multi_class===true) && (

         <div className={s.table_container}>

            <br/>

            <table className={s.table} onClick={(e)=>{

                console.log("CLICK TABLE");

                // class_number_editing gets set to -1 by UpdateDefaultProgramsList
                this.setState({
                    //class_number_editing: -1,
                    bool_editing_class_name: false,
                }, this.UpdateDefaultProgramsList);


            }}>

              <thead>
                <tr>
                  <th className={s.class_slot}>#</th>
                  <th className={s.class_name}>Class Name</th>
                </tr>
              </thead>

          <tbody>
            {    this.state.list_of_class_objects.map((program) => {

                   const key = program.slot;
                   return (
                       <tr className={s.row} key={key} onClick={(e)=>{
                           console.log("CLICK ROW");

                           // class_number_editing gets set to -1 by UpdateDefaultProgramsList
                           this.setState({
                               //class_number_editing: -1,
                               bool_editing_class_name: false,
                           }, this.UpdateDefaultProgramsList);

                           e.stopPropagation();
                       }}>
                         <td className={s.class_slot}>{program.slot}</td>
                         <td className={s.class_name}>

                           <Title parentClass={s.editable_class_name} noMargin>

                            <span className={s.iconEdit} onClick={(e)=>{
                                 console.log(`EDIT CLICK`);
                                 e.stopPropagation();
                                   this.setState({
                                       bool_editing_class_name: true,
                                       class_number_editing: program.slot,
                                       class_name_editing: program.name,
                                   });
                                 }}
                             >
                               <FontAwesomeIcon icon="pen" />
                            </span>

                            { (this.state.bool_editing_class_name && this.state.class_number_editing==program.slot) ? (
                                  <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={this.state.class_name_editing}
                                            onChange={(e) => { this.setState({ class_name_editing: e.target.value }); } }

                                            placeholder="class name"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {program.name}
                                     </div>
                                )}


                            </Title>




                         </td>
                       </tr>
                   );

            })}


            </tbody>

          </table>
         </div>


        )}


        {/********************************************************************/}
        {/********************************************************************/}
        {/********************************************************************/}
        {/********************************************************************/}

        {/* MULTI CLASS DETECTION TABLE */}

        {(dataset.bool_detection===true && dataset.bool_multi_class===true) && (

         <div className={s.table_container}>

            <br/>

            <table className={s.table} onClick={(e)=>{

                console.log("CLICK TABLE");

                // class_number_editing gets set to -1 by UpdateDefaultProgramsList
                this.setState({
                    //class_number_editing: -1,
                    bool_editing_class_name: false,
                }, this.UpdateDefaultProgramsList);


            }}>


              <thead>
                <tr>
                  <th className={s.class_slot}>#</th>
                  <th className={s.class_name}>Class Name</th>
                  {/*<th className={s.class_pass_fail_toggle}>{this.state.pass_fail_check_toggle_value}</th>*/}
                  {(this.state.bool_roi_select_enabled && this.state.pass_fail_check_toggle_value==="PASS_CHECK") && (
                       <th className={s.class_roi_select}>ROI Select</th>
                    )}
                  {(this.state.bool_roi_select_enabled && this.state.pass_fail_check_toggle_value==="FAIL_CHECK") && (
                      //this.state.list_of_crops.map((crop, index) => (
                      //     <th key={index} className={s.class_crop}>Crop{index}</th>
                      //))
                       <th className={s.class_roi_select}>ROI Select</th>
                    )}



                  {this.state.pass_fail_check_toggle_value==="MEASUREMENT_CHECK" && <th className={s.set_box_width}>Width min/max (mm)</th>}
                  {this.state.pass_fail_check_toggle_value==="MEASUREMENT_CHECK" && <th className={s.set_box_height}>Height min/max (mm)</th>}
                </tr>
              </thead>


          <tbody>
            {    this.state.list_of_class_objects.map((program) => {

                   const key = program.slot;
                   return (
                       <tr className={s.row} key={key} onClick={(e)=>{
                           console.log("CLICK ROW");

                           // class_number_editing gets set to -1 by UpdateDefaultProgramsList
                           this.setState({
                               //class_number_editing: -1,
                               bool_editing_class_name: false,
                           }, this.UpdateDefaultProgramsList);

                           e.stopPropagation();
                       }}>
                         <td className={s.class_slot}>{program.slot}</td>
                         <td className={s.class_name}>

                           <Title parentClass={s.editable_class_name} noMargin>

                            <span className={s.iconEdit} onClick={(e)=>{
                                 console.log(`EDIT CLICK`);
                                 e.stopPropagation();
                                   this.setState({
                                       bool_editing_class_name: true,
                                       class_number_editing: program.slot,
                                       class_name_editing: program.name,
                                   });
                                 }}
                             >
                               <FontAwesomeIcon icon="pen" />
                            </span>

                            { (this.state.bool_editing_class_name && this.state.class_number_editing==program.slot) ? (
                                  <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={this.state.class_name_editing}
                                            onChange={(e) => { this.setState({ class_name_editing: e.target.value }); } }

                                            placeholder="class name"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {program.name}
                                     </div>
                                )}


                            </Title>




                         </td>
                         {/*
                         <td className={s.class_pass_fail_toggle}>
                           <Toggle
                             defaultChecked={false}
                             icons={false}

                             onChange={(e)=>{
                               console.log(`program.toggle_value=${program.toggle_value}`);
                               console.log(`program.slot=${program.slot}`);

                               console.log(`pass_fail_check_toggle e=${e.target.value}`);
                               console.log(`pass_fail_check_toggle e=${e.target.value}`);
                               console.log(`pass_fail_check_toggle e=${e.target.value}`);

                               this.setState((prevState)=>({
                                   list_of_class_objects:
                                       prevState.list_of_class_objects.map(class_object => {
                                       // if this task has the same ID as the edited task
                                       if (prevState.slot === program.slot) {
                                           return {...class_object, toggle_value: e.target.value}
                                       }
                                       return class_object;
                                   }),
                               }));

                               //this.setState({
                               //    N_classes: N_classes_slider_value,
                               //}, this.update_list_of_class_objects);

                             }}
                             checked={program.toggle_value}

                           />
                         </td>
                         */}
                         {/*this.state.bool_roi_select_enabled && (

                           <td className={s.class_roi_select}>

                               <Dropdown
                                   options={this.state.DROPDOWN_OPTIONS}
                                   onChange={(e)=>{
                                       print_in_red(`roi_select e=${JSON.stringify(e)}`);

                                       // __________________________________________//
                                       this.setState((prevState)=>({
                                           list_of_class_objects:
                                               prevState.list_of_class_objects.map(class_object => {
                                                   // if this task has the same ID as the edited task
                                                   if (program.slot === class_object.slot) {
                                                       return {...class_object, roi_select_value: e.value}
                                                   }
                                                   return class_object;
                                               }),
                                       }));
                                       // __________________________________________//
                                   }}
                                   value={program.roi_select_value}
                                   placeholder={"Select ↓"}
                               />
                           </td>
                         )*/}
                         {this.state.bool_roi_select_enabled && (

                           <td className={s.class_roi_select}>

                               <Select
                                   isMulti
                                   name="select_roi"
                                   options={this.state.DROPDOWN_OPTIONS}
                                   styles={customStyles}

                                   value={
                                      Array.isArray(program.roi_select_value)
                                      ? program.roi_select_value.map(value => ({ value, label: value }))
                                      : []
                                   }

                                   onChange={(selectedOptions) => {
                                       const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                       console.log(`roi_select selectedValues=${JSON.stringify(selectedValues)}`);

                                       this.setState((prevState) => ({
                                           list_of_class_objects: prevState.list_of_class_objects.map(class_object => {
                                               if (program.slot === class_object.slot) {
                                                   return { ...class_object, roi_select_value: selectedValues };  // Update value here
                                               }
                                               return class_object;
                                           }),
                                       }));
                                   }}

                               />

                           </td>
                         )}

                         {(this.state.pass_fail_check_toggle_value==="MEASUREMENT_CHECK") && (

                           <td className={s.set_box_width}>

                               <RangeSlider
                                   min={0.0}
                                   max={this.state.field_of_view_width}
                                   marks={MEASUREMENT_MARKS}
                                   //value={this.state.tmp_min_x}
                                   defaultValue={[0,400]}
                                   onChange={(sliderValue) => { console.log(`rangeSliderValue${sliderValue}`); this.setState({ tmp_min_x: sliderValue }); } }
                               />

                           </td>
                         )}
                         {(this.state.pass_fail_check_toggle_value==="MEASUREMENT_CHECK") && (

                           <td className={s.set_box_height}>
                               <RangeSlider
                                   min={0.0}
                                   max={this.state.field_of_view_height}
                                   marks={MEASUREMENT_MARKS}
                                   //value={this.state.tmp_min_x}
                                   //defaultValue={this.state.tmp_min_x}
                                   defaultValue={[0,400]}
                                   onChange={(sliderValue) => { console.log(`rangeSliderValue${sliderValue}`); this.setState({ tmp_min_x: sliderValue }); } }
                               />

                           </td>
                         )}

                       </tr>
                   );

            })}


            </tbody>
          </table>

          </div>
          )}
        {/* completion of s.classifysettings div */}

       </div>
      </Modal>,
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dataset: selectors.getDataset(state, ownProps.datasetId),
    userId: selectors.getUserId(state),
    imagesList: selectors.getDatasetImages(state),
  };
};

export default connect(mapStateToProps,{
  deleteTrainingImageRedux,
  postImageLabel,
})(ImagesPage);
