// Camera.js

import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  PRODUCTION_VIEW_URL,
  CAPTURE_ONLY_URL,
  HISTORY_URL,
  HISTORY_MONTHS_URL,
  HISTORY_DATES_URL,
  HISTORY_IMAGESETS_URL,
  HISTORY_BROWSE_URL,
  USER_URL,
} from 'pages/urls';
import ProductionView from 'pages/camera/ProductionView';
import CaptureOnlyView from 'pages/camera/CaptureOnlyView';
import HistoryJobs from 'pages/camera/HistoryJobs';
import HistoryMonths from 'pages/camera/HistoryMonths';
import HistoryDates from 'pages/camera/HistoryDates';
import HistoryImageSets from 'pages/camera/HistoryImageSets';
//import History from 'pages/camera/History';
import BrowseHistory from 'pages/camera/BrowseHistory';
import User from 'pages/camera/User';
import CameraHeader from 'components/global/CameraHeader';
import Settings from 'components/camera/Settings';

import s from './Camera.module.sass';

const Training = () => {
  const [settingsAreOpen, toggleSettings] = useState(false);

  return [
    <div key="header" className={s.header}>
      <CameraHeader onSettingsClick={() => toggleSettings(true)} />
    </div>,
    <main key="main" className={s.main}>
      <Switch>
        <Route exact path={HISTORY_URL}>
          <HistoryJobs />
        </Route>

        <Route exact path={HISTORY_MONTHS_URL}>
          <HistoryMonths />
        </Route>

        <Route exact path={HISTORY_DATES_URL}>
          <HistoryDates />
        </Route>

        <Route exact path={HISTORY_IMAGESETS_URL}>
          <HistoryImageSets />
        </Route>

        <Route exact path={HISTORY_BROWSE_URL}>
          <BrowseHistory />
        </Route>

        <Route path={CAPTURE_ONLY_URL}>
          <CaptureOnlyView />
        </Route>

        <Route exact path={PRODUCTION_VIEW_URL}>
          <ProductionView />
        </Route>

        <Route exact path={USER_URL}>
          <User />
        </Route>

      </Switch>
    </main>,
    <Settings
      key="settings"
      isOpen={settingsAreOpen}
      onClose={() => toggleSettings(false)}
    />,
  ];
};

export default Training;
