import React from 'react';

import cx from 'classnames';
import s from './ProgressBar.module.sass';

export function buildText(className, percentage, label) {
  return (
    <p className={className}>
      <strong>{percentage}% </strong>
      {label}
    </p>
  );
}

export function buildStyle(percentage) {
  return {
    width: `${percentage}%`,
  };
}

const ProgressBar = (props) => {
  const {
    value,
    hideText = false,
    type,
    size,
    label = 'complete',
    parentClass,
  } = props;

  const boundValue = Math.max(0, Math.min(value, 1));
  const boundPercentage = Math.round(boundValue * 100);
  const text = hideText ? null : buildText(s.text, boundPercentage, label);

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${type}`]]: type,
    [s[`size-${size}`]]: size,
    [parentClass]: parentClass,
    [s.isSingle]: true,
    [s.isComplete]: boundPercentage === 100,
    [s.hideText]: hideText,
  });

  const progressStyle = buildStyle(boundPercentage);

  return (
    <div className={componentClass}>
      <div className={s.bar}>
        {text}
        <div className={s.progress} style={progressStyle}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
