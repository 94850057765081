// GalleryGrid.js

import React from 'react';

import GalleryItem from './GalleryItem';
import s from './GalleryGrid.module.sass';

const GalleryGrid = (props) => {
  const { items = [], onClick = () => {}, step, boolSort, bool_detection, bool_pass_detection, bool_multi_class_detection=false } = props;

  function compare( a, b ) {
    //console.log(JSON.stringify(a, null, 4));
    //console.log(a.labelClass)
    //console.log(b.labelClass)
    //console.log("___")
    if ( parseInt(a.labelClass) < parseInt(b.labelClass) ){
      return -1;
    }
    if ( parseInt(a.labelClass) > parseInt(b.labelClass) ){
      return 1;
    }
    ///////////////////////////////////////////////
    if (a.labelClass==="NONE") {
      return -1;
    }
    if (b.labelClass==="NONE") {
      return 1;
    }
    if (a.labelClass==="PASS") {
      return -1;
    }
    if (b.labelClass==="PASS") {
      return 1;
    }
    ///////////////////////////////////////////////
    return 0;
  }
  //console.log("YOOOOO")

  //console.log(`items[0]=${JSON.stringify(items[0])}`)

  //if (items[0].labelClass) {
  //    console.log(`SORTING BY labelClass`)
  //console.log(`GalleryGrid: boolSort=${boolSort}`)

  if (boolSort===true) {
      //items.sort( compare );
  }

  //} else {
  //    console.log(`NOT SORTING BY labelClass`)
  //
  //}
  //console.log(`bool_detection=${bool_detection}`);
  //console.log(`bool_pass_detection=${bool_pass_detection}`);
  //items.map((item) => console.log(`item=${JSON.stringify(item)}`) );


  return (
    <div className={s.component}>
      {items.map((item) => (
        <div key={item.id} className={s.cell}>
          <GalleryItem
            step={step}
            item={item}
            onClick={() => onClick(item.id)}
            bool_detection={bool_detection}
            bool_pass_detection={bool_pass_detection}
            bool_multi_class_detection={bool_multi_class_detection}
          />
        </div>
      ))}
    </div>
  );
};

export default GalleryGrid;
