import React from 'react';

import Title from 'components/elements/Title';
import Label from 'components/elements/Label';
import Confidence from 'components/elements/Confidence';
import Polygon from 'components/graphics/Polygon';
import Toggles from 'components/form/Toggles';
import Keyboard from 'components/elements/Keyboard';

import cx from 'classnames';
import s from './PredictionAside.module.sass';

const PredictionAside = (props) => {
  const {
    image,
    showLabelDefects,
    showPredictionDefects,
    setDefectsVisibility,
  } = props;
  const {
    labelClass,
    predictionClass,
    predictionClassNamedClass,
    predictionClassConfidence,
    labelPolygons,
    predictionPolygons,
  } = image;

  //const predictionType = labelClass === predictionClass ? 'correct' : 'wrong';

  console.log(`predictionClass=${predictionClass}`)

  const predictionClassname = cx({
    [s.prediction]: true,
    [s[`prediction-${predictionClass}`]]: predictionClass,
  });

  //const hasLabelDefects =
  //  labelClass === 'FAIL' && Object.keys(labelPolygons).length > 0;
  //const hasPredictionDefects =
  //  predictionClass === 'FAIL' && Object.keys(predictionPolygons).length > 0;

  const showOptions = [
    { value: true, label: 'Show' },
    { value: false, label: 'Hide' },
  ];

  return (
    <div className={s.component}>
      <div className={predictionClassname}>

        <p>
          Neural Network prediction: <Label type={predictionClass} />
        </p>

      </div>

      <div className={s.columns}>
        <div className={s.column}>
          <Title parentClass={s.columnTitle} size="1">
            Prediction
          </Title>
          <p className={s.columnSubtitle}>By the neural network</p>
          <p>
            { (predictionClassNamedClass && predictionClassNamedClass!="" && predictionClassNamedClass.length>0) ? (

              <div>
                <Label type={`${predictionClassNamedClass}`}/>
                <br/>
                <Label type={`[class ${predictionClass}]`}/>
              </div>

            ) : (

              <Label type={predictionClass} />

            )}
          </p>

          {/*
          {hasLabelDefects && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon type="label" />
                <span>Labeled defects</span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showLabelDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('label', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↑</Keyboard>
              </div>
            </div>
         )}
        */}
        </div>

        <div className={s.column}>

          <Title parentClass={s.columnTitle} size="1">
            Confidence
          </Title>
          <p className={s.columnSubtitle}>Degree of certainty</p>
          <p>
            <Confidence type={predictionClass+"-confidence"} confidence={`${predictionClassConfidence}%`} />
          </p>


          {/*
          {hasPredictionDefects && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon type="prediction" />
                <span>Predicted defects</span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showPredictionDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('prediction', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↓</Keyboard>
              </div>
            </div>
          )}
          */}
        </div>
      </div>
    </div>
  );
};

export default PredictionAside;
