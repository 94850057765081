import React from 'react';

import s from './ListHeader.module.sass';

const ListHeader = (props) => {
  const { title, children } = props;

  return (
    <div className={s.component}>
      <div className={s.container}>
        <h1 className={s.title}>{title}</h1>
        {children}
      </div>
    </div>
  );
};

export default ListHeader;
