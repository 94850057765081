import React from 'react';

import s from './FilterLabel.module.sass';

const FilterLabel = (props) => {
  const { children } = props;

  return <label className={s.label}>{children}</label>;
};

export default FilterLabel;
