import React from 'react';

import cx from 'classnames';
import s from './Toggles.module.sass';

const Toggles = (props) => {
  const { currentValue, options, onChange, parentClass } = props;


  const items = options.map((option) => {
    const toggleClass = cx({
      [s.toggle]: true,
      [s[`type-${option.type}`]]: option.type,
      [s.isCurrent]: currentValue === option.value,
    });
    //console.log(`option.type=${option.type}`)
    //console.log(`option.value=${option.value}`)
    //console.log(`currentValue=${currentValue}`)


    return (
      <button
        key={option.value}
        className={toggleClass}
        onClick={() => onChange(option.value)}
      >
        {option.label}
      </button>
    );
  });

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return <div className={componentClass}>{items}</div>;
};

export default Toggles;
