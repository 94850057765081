// B"H

// SaveImagesToTraining.js

// TODO: if add back save images to new dataset,
//       calls to create dataset form list_of_datasets and here
//       should call one common file

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import Modal from 'components/elements/Modal';
//import CameraSettings from 'components/camera/CameraSettings';
//import IOSettings from 'components/camera/IOSettings';
import ProgramSelect from 'components/camera/ProgramSelect';

import cx from 'classnames';
import s from './SaveImagesToTraining.module.sass';


import Title from 'components/elements/Title';
import Input from 'components/form/Input';
import Button from 'components/elements/Button';

import { ADD_IMAGES_TO_TRAINING_DATASET_URL,
         getDatasetURL,
       }
from 'pages/urls';

import { selectors, createNewDatasetWithImages, clearActiveDatasetID, getListOfDatasets } from 'redux/reducers/datasets';

import Tag from 'components/elements/Tag';

import axios from 'axios';

import ToggleFilter from 'components/filters/ToggleFilter';

import Slider2 from 'components/form/Slider2';


/*
const TABS = [
  { id: 'save_new', label: 'Save Images to New Dataset' },
  { id: 'save_existing', label: 'Save Images to Existing Dataset' },
];
*/
const TABS = [
  { id: 'save_existing', label: 'Save Images to Training Application' },
];


const PASS_FAIL_LABEL_OPTIONS = [
  { value: 'NONE', label: 'None' },
  { value: 'PASS', label: 'Pass', type: 'pass' },
  { value: 'FAIL', label: 'Fail', type: 'fail' },
];





const SaveImagesToTraining = (props) => {
  const { isOpen, onClose, user_ID, dataset_ID, createNewDatasetWithImages } = props;

  const [activeTab, setTab] = useState('save_existing');
  const [dataset_name, set_dataset_name] = useState('');

  const [create_dataset_submit, set_create_dataset_submit] = useState(false);
  const [create_dataset_type, set_create_dataset_type] = useState('');

  const [bool_dataset_created, set_bool_dataset_created] = useState(false);
  const [bool_go_to_new_dataset, set_bool_go_to_new_dataset] = useState(false);

  const [bool_images_added, set_bool_images_added] = useState(false);
  const [bool_go_to_existing_dataset, set_bool_go_to_existing_dataset] = useState(false);

  const [bool_first_render, set_bool_first_render] = useState(true);

  const [active_dataset_ID, set_active_dataset_ID] = useState(-1);

  const [pass_fail_label_value, set_pass_fail_label_value] = useState("NONE");


  const [multi_class_label_value, set_multi_class_label_value] = useState(-1);

  const [bool_label_selected, set_bool_label_selected] = useState(false);
  const [label_selected_dataset_ID, set_label_selected_dataset_ID] = useState(false);


  // const [SAVED_DATASET_bool_multi_class, set_SAVED_DATASET_bool_multi_class] = useState(null);

  if (bool_first_render === true) {

      const { datasets, getListOfDatasets, user_ID } = props;

      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      //console.log(`DatasetsList user_ID=${user_ID}`)
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      getListOfDatasets(user_ID)

      set_bool_first_render(false);

      const { clearActiveDatasetID } = props;
      clearActiveDatasetID()



  }


  const { datasets } = props;

  //console.log(`datasets=${JSON.stringify(datasets)}`)

    //const handleChange = (event, key) => {
    //  this.setState({
    //    [key]: event.target.value,
    //  });
    //}

  //console.log(`user_ID=${user_ID}`)
  //console.log(`dataset_ID=${dataset_ID}`)


  const tabs = TABS.map((item, index) => {
    const tabClass = cx({
      [s.tab]: true,
      [s[item.id]]: true,
      [s.activeTab]: activeTab === item.id,
    });

    return (
      <div key={item.id} className={tabClass} onClick={() => setTab(item.id)}>
        {item.label}
      </div>
    );
  });
        /*
        // this.state.initial_render===false is checked,
        // because otherwise when dataset_ID is cleared on mounting, it doesnt
        // take effect until the second render
        if (dataset_ID !==null && this.state.initial_render===false) {
          const new_dataset_overview_url = getDatasetURL(dataset_ID)
          console.log(`new_dataset_overview_url=${new_dataset_overview_url}`)
          return (
              <Redirect to={new_dataset_overview_url} />
          )
        }
        */

        if (bool_dataset_created===true && bool_go_to_new_dataset===true && dataset_ID !==null) {

          const new_dataset_overview_url = getDatasetURL(dataset_ID)
          return (
              <Redirect to={new_dataset_overview_url} />
          )
        }

        if (bool_images_added===true && bool_go_to_existing_dataset===true) {

          const new_dataset_overview_url = getDatasetURL(active_dataset_ID)
          return (
              <Redirect to={new_dataset_overview_url} />
          )
        }


        if (activeTab==='save_new' && dataset_name !=='' && create_dataset_submit===true && bool_dataset_created===false) {
            //console.log("*****************************************************")
            //console.log("SUBMIT!")
            //console.log(`create_dataset_type=${create_dataset_type}`)

            const {job_slot_str, month_str, images_date, imageset_name} = props;
            const { createNewDatasetWithImages } = props;

            if (create_dataset_type==="PASS_FAIL") {

                createNewDatasetWithImages(user_ID, dataset_name, job_slot_str, month_str, images_date, imageset_name)
                console.log("CREATE PASS_FAIL DATASET")

                //const { clearActiveDatasetID } = props;
                //clearActiveDatasetID()

                set_bool_dataset_created(true);

            }
            if (create_dataset_type==="CLASSIFICATION") {

                createNewDatasetWithImages(user_ID, dataset_name, job_slot_str, month_str, images_date, imageset_name, true)
                console.log("CREATE CLASSIFICATION DATASET")

                //const { clearActiveDatasetID } = props;
                //clearActiveDatasetID()

                set_bool_dataset_created(true);

            }
            if (create_dataset_type==="PASS_DETECTION") {

                createNewDatasetWithImages(user_ID, dataset_name, job_slot_str, month_str, images_date, imageset_name, false, true, true)
                console.log("CREATE PASS_DETECTION DATASET")

                //const { clearActiveDatasetID } = props;
                //clearActiveDatasetID()

                set_bool_dataset_created(true);

            }
            if (create_dataset_type==="FAIL_DETECTION") {

                createNewDatasetWithImages(user_ID, dataset_name, job_slot_str, month_str, images_date, imageset_name, false, true, false)
                console.log("CREATE FAIL_DETECTION DATASET")

                //const { clearActiveDatasetID } = props;
                //clearActiveDatasetID()

                set_bool_dataset_created(true);

            }

            //if (create_dataset_type==="CLASSIFICATION") {
            //    createNewDataset(user_ID, dataset_name, true)
            //}


            //console.log("*****************************************************")
            //const new_dataset_overview_url = getDatasetURL(dataset_ID)
            //console.log(`new_dataset_overview_url=${new_dataset_overview_url}`)
            //return (
            //    <Redirect to={new_dataset_overview_url} />
            //)
          }

/*
const defaultProgramsList = [
  { slot: 0, name: 'x', bool_is_active: true, },
  { slot: 1, name: 'x', },
  { slot: 2, name: 'x', },
  { slot: 3, name: 'x', },
  { slot: 4, name: 'x', },
  { slot: 5, name: 'x', },
  { slot: 6, name: 'x', },
  { slot: 7, name: 'x', },
  { slot: 8, name: 'x', },
];
*/
  console.log(`multi_class_label_value=${multi_class_label_value}`);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={s.component}>
        <nav className={s.tabs}>{tabs}</nav>
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

          {/*activeTab === 'save_new' &&


                <div className={s.modal_component}>
                  <form className={s.form}>
                    <Title>Save images to new dataset</Title>

                    { (create_dataset_submit && dataset_name==='') &&
                        <div className={s.create_dataset_error_message}>
                          Please enter Dataset Name
                        </div>
                    }

                    <div className={s.field}>

                    <div className={s.label_component}>
                        <label htmlFor="dataset_name">Dataset Name</label>
                    </div>

                      <Input
                        id="dataset_name"
                        name="dataset_name"
                        type="text"
                        value={dataset_name}
                        onChange={(e) => set_dataset_name(e.target.value)}
                        placeholder="Dataset Alpha"
                      />
                    </div>

                    <br />

                    { (bool_dataset_created===false) &&

                      <div>
                        <Button isFullWidth={true} onClick={(e) => {
                          //console.log(`dataset_name=${this.state.dataset_name}`);
                          //console.log(`password=${this.state.password}`);

                          set_create_dataset_submit(true)
                          set_create_dataset_type("PASS_FAIL")

                          e.preventDefault();

                        }}
                      >
                        Create Pass / Fail Dataset
                      </Button>

                      <br />

                      <Button isFullWidth={true} onClick={(e) => {
                        //console.log(`dataset_name=${this.state.dataset_name}`);
                        //console.log(`password=${this.state.password}`);

                        set_create_dataset_submit(true)
                        set_create_dataset_type("CLASSIFICATION")

                        e.preventDefault();

                      }}
                      >
                        Create Classification Dataset
                      </Button>

                      <br />

                      <Button isFullWidth={true} onClick={(e) => {
                        //console.log(`dataset_name=${this.state.dataset_name}`);
                        //console.log(`password=${this.state.password}`);

                        set_create_dataset_submit(true)
                        set_create_dataset_type("PASS_DETECTION")

                        e.preventDefault();

                      }}
                      >
                        Create Pass Detection Dataset
                      </Button>

                      <br />

                      <Button isFullWidth={true} onClick={(e) => {
                        //console.log(`dataset_name=${this.state.dataset_name}`);
                        //console.log(`password=${this.state.password}`);

                        set_create_dataset_submit(true)
                        set_create_dataset_type("FAIL_DETECTION")

                        e.preventDefault();

                      }}
                      >
                        Create Fail Detection Dataset
                      </Button>
                    </div>

                    }





                    { bool_dataset_created &&
                        <div>
                          <div className={s.create_dataset_success_message}>
                            Dataset '{dataset_name}' created successfully
                          </div>

                        <br />
                        <Button isFullWidth={true} iconLeft='arrow-left' onClick={(e) => {

                            //console.log("RETURN");
                            onClose()
                            e.preventDefault();

                        }}>
                            Return to images
                        </Button>

                    <br />

                    <Button isFullWidth={true} color='success' iconRight='arrow-right' onClick={(e) => {


                      console.log("GO TO NEW DATASET");
                      set_bool_go_to_new_dataset(true)


                      e.preventDefault();

                  }}
                  >
                    Go to new training dataset
                  </Button>

                </div>


                    }



                  <br />


                  </form>
                </div>

          */}

        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

          {activeTab === 'save_existing' &&



   <div className={s.componentX}>
      <table className={s.table}>
        <thead>

          {(active_dataset_ID == -1) && (
            <tr>
              <th className={s.dataset_nameX}>Application Name</th>
              <th className={s.dataset_buttonX}>Add Images to Application</th>
              <th className={s.dataset_spaceX}></th>
              <th className={s.dataset_label_buttonX}>Label Images</th>

            </tr>
          )}

          {(active_dataset_ID != -1) && (
            <tr>
              <th className={s.dataset_nameXY}>Application Name</th>
              <th className={s.dataset_buttonXY}>Add Images to Application</th>
              <th className={s.dataset_label_buttonXY}>Image Label</th>
              <th className={s.go_to_dataset_buttonXY}>Go to application</th>
            </tr>
          )}

        </thead>
        <tbody>

         { datasets.map((dataset) => {

            const key = dataset.id;
            const isActive = (active_dataset_ID===dataset.id)
            const isInactive = (active_dataset_ID!==-1)
            //console.log(`dataset=${dataset}`);
            console.log(`dataset=${JSON.stringify(dataset)}`);


              if (isActive) {


              return (
                <tr className={s.active_row} key={key}>

                  <td className={s.dataset_nameXY}>
                    <span key="name" className={s.name}>
                      {dataset.name} &nbsp; &nbsp;
                    </span>
                  </td>

                  <td className={s.dataset_buttonXY}>

                   <div className={s.activateText}>

                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Images Added to Training Application
                        </div>
                      </Tag>

                    </div>

                  </td>

                  <td className={s.dataset_label_buttonXY}>

                    {(dataset.bool_multi_class===false) && (
                      <div className={s.setLabel}>

                        <ToggleFilter
                          currentValue={pass_fail_label_value}
                          onChange={(new_pass_fail_label_value)=>{
                              //set_pass_fail_label_value(new_pass_fail_label_value);
                          }}
                          options={PASS_FAIL_LABEL_OPTIONS}

                        />

                      </div>
                    )}

                    {(dataset.bool_multi_class===true) && (
                      <div className={s.setLabel2}>

                        {/*(multi_class_label_value!==-1) && (
                          <Slider2
                            min={1}
                            max={32}
                            marks={{
                                 1:  'class=1',
                                32: `class=${32}`,
                            }}
                            disabled={true}
                            value={multi_class_label_value}
                            defaultValue={multi_class_label_value}
                          />
                        )*/}
                        {(multi_class_label_value <= 0) && (
                            <div className={s.setLabel3}> N/A </div>
                        )}
                        {(multi_class_label_value > 0) && (
                            <div className={s.setLabel3}> {multi_class_label_value} </div>
                        )}


                      </div>
                    )}

                  </td>
                  <td className={s.go_to_dataset_buttonXY}>

                      <div className={s.go_to_button}>
                        <Button isFullWidth={true} color='success' iconRight='arrow-right' onClick={() => {
                            console.log("go_to_dataset_click");
                            console.log("go_to_dataset_click");

                            set_bool_go_to_existing_dataset(true)

                        }}>
                          <div className={s.button_text}> Go to Application </div>
                        </Button>
                      </div>

                  </td>

                </tr>
              );


              }
              else if (isInactive) {


              return (
                <tr className={s.inactive_row} key={key}>
                  <td className={s.dataset_nameXY}>
                    <span key="name" className={s.name}>
                      {dataset.name} &nbsp; &nbsp;
                    </span>
                  </td>

                  <td className={s.dataset_buttonXY}>
                    <div className={s.activateText}>


                     </div>

                  </td>

                  <td className={s.dataset_label_buttonXY}>
                  </td>

                  <td className={s.go_to_dataset_buttonXY}>
                  </td>

                </tr>
              );
            }


              else  {

              return (
                <tr className={s.row} key={key}>
                  <td className={s.dataset_nameX}>
                    <span key="name" className={s.name}>
                      {dataset.name} &nbsp; &nbsp;
                    </span>
                  </td>

                  <td className={s.dataset_buttonX}>
                    <div className={s.activateText}>

                    {(bool_label_selected===false || label_selected_dataset_ID === dataset.id) && (
                        <div className={s.button}>
                            <Button isFullWidth={true} onClick={()=>{
                            /////////////////////////////////////////////////////////
                              console.log("save_images_click");
                              console.log("save_images_click");


                              // Add images to training dataset

                              const { user_ID } = props;

                              const {job_slot_str, month_str, images_date, imageset_name} = props;

                              // add timestamp to backend calls to prevent caching URL calls (as imagesets may update between backend URL calls)
                              const date_timestamp_str = new Date().toISOString()

                              if (dataset.bool_multi_class === true) {
                                  var get_images_url = `${ADD_IMAGES_TO_TRAINING_DATASET_URL}/${user_ID}/${dataset.id}/${job_slot_str}/${month_str}/${images_date}/${imageset_name}/${dataset.bool_multi_class}/${multi_class_label_value}/${date_timestamp_str}`
                              } else {
                                  var get_images_url = `${ADD_IMAGES_TO_TRAINING_DATASET_URL}/${user_ID}/${dataset.id}/${job_slot_str}/${month_str}/${images_date}/${imageset_name}/${dataset.bool_multi_class}/${pass_fail_label_value}/${date_timestamp_str}`
                              }
                              console.log(`get_images_url=${get_images_url}`);

                              axios
                              .get(get_images_url)
                              .then((response) => {
                                  console.log('RECV ADD_IMAGES_TO_TRAINING_DATASET');
                                  console.log(JSON.stringify(response.data));
                                  console.log('RECV ADD_IMAGES_TO_TRAINING_DATASET');
                              })
                              .catch((error) => {
                                  console.log("[ERROR] get bool_production_mode")
                              });

                              set_bool_images_added(true);
                              set_active_dataset_ID(dataset.id)
                              //set_SAVED_DATASET_bool_multi_class(dataset.bool_multi_class);
                            /////////////////////////////////////////////////////////
                            }}>
                            <div className={s.button_text}> Save Images to Training Application </div>
                         </Button>
                       </div>
                     )}

                     </div>

                  </td>

                  <td className={s.dataset_spaceX}>
                  </td>

                  <td className={s.dataset_label_buttonX}>

                    {(dataset.bool_multi_class===false && (bool_label_selected===false || label_selected_dataset_ID === dataset.id)) && (
                      <div className={s.setLabel}>

                        <ToggleFilter
                          currentValue={pass_fail_label_value}
                          onChange={(new_pass_fail_label_value)=>{
                              set_pass_fail_label_value(new_pass_fail_label_value);
                              if (new_pass_fail_label_value==="NONE") {
                                  set_bool_label_selected(false);
                                  set_label_selected_dataset_ID(-1);
                              } else {
                                  set_bool_label_selected(true);
                                  set_label_selected_dataset_ID(dataset.id);
                              }
                          }}
                          options={PASS_FAIL_LABEL_OPTIONS}

                        />

                      </div>
                    )}


                    {(dataset.bool_multi_class===true && (bool_label_selected===false || label_selected_dataset_ID === dataset.id)) && (

                      <div className={s.setLabel2}>

                        <Slider2
                          min={0}
                          max={128}
                          marks={{
                                1:  'class=1',
                              128: `class=${128}`,
                          }}
                          value={multi_class_label_value}
                          defaultValue={multi_class_label_value}
                          onChange={(new_slider_value) => {

                              //console.log(`new_slider_value=${new_slider_value}`);
                              set_multi_class_label_value(new_slider_value);

                              if (new_slider_value===0) {
                                  set_bool_label_selected(false);
                                  set_label_selected_dataset_ID(-1);
                              } else {
                                  set_bool_label_selected(true);
                                  set_label_selected_dataset_ID(dataset.id);
                              }


                              //console.log(`slider change value=${sliderValue}`);
                              //this.setState({
                              //N_classes: N_classes_slider_value,
                              //}, this.CheckNumberClasses);
                          } }
                        />


                      </div>
                    )}

                  </td>

                  {/*<td className={s.go_to_dataset_buttonXY}>
                  </td>
                   */}
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>

          }




        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

      </div>
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
  dataset_ID: selectors.getDatasetId(state),
  datasets: selectors.getDatasetsList(state),
});

export default connect(mapStateToProps, {
    createNewDatasetWithImages,
    clearActiveDatasetID,
    getListOfDatasets,
})(SaveImagesToTraining);


