import React from 'react';

import s from './ProgressTime.module.sass';

const ProgressTime = (props) => {
  const { value, time } = props;

  const boundValue = Math.max(0, Math.min(value, 1));
  const percentage = Math.round(boundValue * 100);
  const text = (
    <div className={s.text}>
      <p className={s.percentage}>
        <strong>{percentage}% </strong>complete
      </p>
      <p className={s.time}>{time}</p>
    </div>
  );
  const progressStyle = {
    clipPath: `polygon(0% 0%, ${percentage}% 0%, ${percentage}% 100%, 0% 100%)`,
  };

  return (
    <div className={s.component}>
      <div className={s.off}>{text}</div>
      <div className={s.on} style={progressStyle}>
        {text}
      </div>
    </div>
  );
};

export default ProgressTime;
