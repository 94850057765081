import React, { Component } from 'react';

import RecentImage from 'components/production/RecentImage';

import s from './RecentImages.module.sass';

/*
import axios from 'axios';

const image_url = `http://0.0.0.0:6002/most_recent_image`;

axios
    .get(image_url, { responseType: 'arraybuffer' })
    .then((response) => {

        const blob = new Blob([response.data], {
            type: response.headers['content-type'],
        });
    })
    .catch((error) => {
        print_in_red("[ERROR]")
        print_in_red("[ERROR]")
        print_in_red("[ERROR]")
        print_in_red("[ERROR]")
        print_in_red("[ERROR]")
    });

*/

/*
const recentImagesObjects = [];

for (let i = 0; i < 20; i++) {
  recentImagesObjects.push(
    {
      key: i,
      time: props.timestamp,
      predictionType: props.predictionType,
      confidenceScore: props.confidenceScore,
      trigger: "PLC",
      inferenceTime: 250,
      imgSrc: props.imgSrc,
  }
  );
}
*/

/*
const return_blob = (img_src) => {
    fetch(img_src)
    .then(function (response) {
       return response.blob();
   });

}
*/

class RecentImages extends Component {
  constructor(props) {
    super(props);

    console.log("CONSTRUCTOR")
    console.log("CONSTRUCTOR")
    console.log("CONSTRUCTOR")
    console.log("CONSTRUCTOR")
    console.log("CONSTRUCTOR")


    const recentImages = [];

    for (let i = 0; i < 20; i++) {
      recentImages.push(
        <RecentImage
          key={i}
          time={props.timestamp}
          predictionType={props.predictionType}
          confidenceScore={props.confidenceScore}
          trigger="PLC"
          inferenceTime={props.inspectionTime}
          imgSrc={props.imgSrc}
        />
      );
    }

    this.state = {
      recentImages: recentImages,
    };
  }


  componentWillReceiveProps(nextProps) {
      console.log("WILLRECV")
      const {timestamp} = nextProps;
      console.log(`${timestamp}`)

      //const blobx = return_blob("http://0.0.0.0:6002/recent_image_thumbnail")

      //var blob = fetch(nextProps.imgSrc).then(r => r.blob());

      if (nextProps.imgSrc==="")
          return;

      if (nextProps.timestamp !== this.props.timestamp) {
          var recentImagesCopy = [...this.state.recentImages]
          recentImagesCopy.pop()
          recentImagesCopy.unshift(
              <RecentImage
                key={nextProps.timestamp}
                time={nextProps.timestamp}
                predictionType={nextProps.predictionType}
                confidenceScore={nextProps.confidenceScore}
                trigger="MANUAL"
                inferenceTime={nextProps.inspectionTime}
                imgSrc={nextProps.imgSrc}
              />
          )
          this.setState({
              recentImages: recentImagesCopy
          })
      }
      else {
          console.log("DUP DUP DUP")
          console.log("DUP DUP DUP")
          console.log("DUP DUP DUP")
      }

  }


  render() {

      console.log("RENDER")
      const {timestamp} = this.props;
      console.log(`${timestamp}`)



      return (
        <aside className={s.component}>
          <p className={s.title}>Recent images</p>
          {this.state.recentImages}
        </aside>
      );
  }


 };

/*
{this.state.recentImages.map(recentImage => <div>{recentImage}</div>)}
*/

/*
const RecentImages = (props) => {
  const recentImages = [];



  for (let i = 0; i < 20; i++) {
    recentImages.push(
      <RecentImage
        key={i}
        time={props.timestamp}
        predictionType={props.predictionType}
        confidenceScore={props.confidenceScore}
        trigger="PLC"
        inferenceTime={250}
        imgSrc={props.imgSrc}
      />
    );
  }

  return (
    <aside className={s.component}>
      <p className={s.title}>Recent images</p>
      {recentImages}
    </aside>
  );
};
*/

export default RecentImages;
