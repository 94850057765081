// HistoryImageSets.js

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { useParams } from 'react-router-dom';


import { getNetworkURL } from 'pages/urls';

import Button from 'components/elements/Button';
import Title from 'components/elements/Title';

import cx from 'classnames';
//import s from './HistoryImageSets.module.sass';
import s from './History.module.sass';

import Input2 from 'components/form/Input2';

import BackLink from 'components/navigation/BackLink';

import axios from 'axios';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import ImageSetLine from 'pages/camera/ImageSetLine';

import HistoryBreadcrumb from 'components/navigation/HistoryBreadcrumb';

import ListHeader2 from 'components/list/ListHeader2';



import {
  GET_JOB_NAME_URL,
  GET_IMAGE_HISTORY_IMAGESETS_URL,
  NEW_IMAGESET_URL,
  BOOL_TODAY_URL,
  DELETE_IMAGESET_URL,
  ACTIVATE_IMAGESET_URL,
  EDIT_IMAGESET_NAME_URL,
  GET_BOOL_PRODUCTION_MODE_URL,
  getHistoryDatesURL,
  getHistoryBrowseURL,
} from 'pages/urls';


const HistoryImageSets = (props) => {
  //console.log("YOOOOOOOOOOOOOOOOOOOOO!");

  const { job_slot, month_str, date_str } = useParams();

    //console.log(`ZLOG useParams()=${JSON.stringify(useParams())}`);

    return ( <HistoryImageSetsX job_slot={job_slot} month_str={month_str} date_str={date_str} / >);

};



class HistoryImageSetsX extends Component {
    constructor(props) {
        super(props);

        const { job_slot, month_str, date_str } = this.props;

        this.state = {
            list_of_imageset_names: [],

            bool_editing_imageset_name: false,
            imageset_name_editing: null,
            //active_network_name_object: null,

            imageset_objects: null,
            imageset_lines: null,

            searchType: "IMAGESET",
            job_slot: job_slot,
            month_str: month_str,
            date_str: date_str,

            bool_today: null,

            bool_production_mode: false,
        };


    this.getBoolToday(month_str, date_str);
    this.getJobName(job_slot);
    this.getImageSets();
    this.getBoolProductionMode();
  }



  ////////////////////////////////////////////////////////////////////////////////

  getBoolToday = (month_str, date_str) => {
    axios
    .get(BOOL_TODAY_URL + '/' + month_str + '/' + date_str)
    .then((response) => {
        //console.log('_________________');
        //console.log(JSON.stringify(response.data));
        //console.log('_________________');

        this.setState({
            bool_today: response.data.bool_today,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get job_name")
    });
  }

  ////////////////////////////////////////////////////////////////////////////////

  getJobName = (jobSlotSelected) => {
    axios
    .get(GET_JOB_NAME_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        //console.log('_________________');
        //console.log(JSON.stringify(response.data));
        //console.log('_________________');

        this.setState({
            job_name: response.data.job_name,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get job_name")
    });
  }


  ////////////////////////////////////////////////////////////////////////////////

  getImageSets = () => {
    const job_slot_str = this.state.job_slot.toString();

    // add timestamp to backend calls to prevent caching URL calls (as imagesets may update between backend URL calls)
    const date_timestamp_str = new Date().toISOString()

    axios
    .get(GET_IMAGE_HISTORY_IMAGESETS_URL + '/' + job_slot_str + '/' + this.state.month_str + '/' + this.state.date_str + '/' + date_timestamp_str)

    .then((response) => {
        //console.log('_________________');
        //console.log(`JSON.stringify(response.data)`);
        //console.log('_________________');

        const lines = [];

        let imageset_objects = response.data;
        //console.log(`${JSON.stringify(imageset_objects)}`)

        for (let i = 0; i < imageset_objects.length; i++) {
          let current_imageset = imageset_objects[i]

          //console.log(`current_imageset=${JSON.stringify(current_imageset)}`)

          lines.push(
            <ImageSetLine
              key={i}
              isTodayX={current_imageset.bool_is_today}
              isActiveX={current_imageset.bool_is_active}
              isFullX={current_imageset.bool_is_full}
              jobSlot={job_slot_str}
              month={this.state.month_str}
              date={this.state.date_str}
              imageset_object={current_imageset}
              lineType={"IMAGESET"}
              onClickRow={this.onClickImageSet}
              onClickActivate={this.activateImageSet}
              onClickDelete={this.deleteImageSet}
              onClickEdit={this.onClickEditImageSet}
              bool_stop_editing={false}
              onClickUpdateImageSetName={this.updateImageSetName}
              imageset_display_nameX={current_imageset.display_name}
              bool_production_mode={this.state.bool_production_mode}
            />
          );
        }

        //console.log(`################################################`)
        //console.log(`################################################`)
        //console.log(`imageset_lines=${JSON.stringify(lines)}`)
        //console.log(`################################################`)
        //console.log(`################################################`)

        this.setState({
            searchType: "IMAGESET",
            imageset_objects: response.data,
            imageset_lines: lines,
            //monthSelected: month_str,
        });


        //date_strings = date_strings.sort().reverse();

        //console.log(`${JSON.stringify(imageset_strings)}`, 'background: red; color: white; display: block;')

        /*
        for (let i = 0; i < date_strings.length; i++) {
          let current_date = date_strings[i]

          //console.log(`current_month=${current_month}`)

          lines.push(
            <Line
              key={i}
              jobSlot={job_slot_str}
              month={month_str}
              date={current_date}
              lineType={"DATE"}
              onClickRow={this.onClickDate}
            />
          );
        }

        this.setState({
            searchType: "DATE",
            response_dict: response.data,
            imageset_lines: lines,
            monthSelected: month_str,
        });
        */
    })
    .catch((error) => {
        console.log("[ERROR] get list_of_imagesets")
    });
  }

  ////////////////////////////////////////////////////////////////////////////////

  getBoolProductionMode = () => {
    axios
    .get(GET_BOOL_PRODUCTION_MODE_URL)
    .then((response) => {
        //console.log('_________________');
        //console.log(JSON.stringify(response.data));
        //console.log('_________________');

        this.setState({
            bool_production_mode: response.data.bool_production_mode,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get bool_production_mode")
    });
  }


  ////////////////////////////////////////////////////////////////////////////////


  // _______________________________________________________________________ //
  // _______________________________________________________________________ //
  // _______________________________________________________________________ //
  // _______________________________________________________________________ //

  activateImageSet = (imageset_name_to_activate) => {
        // delete imageset
        axios
        .get(ACTIVATE_IMAGESET_URL+'/'+this.state.job_slot+'/'+this.state.month_str+'/'+this.state.date_str+'/'+imageset_name_to_activate)
        .then((response) => {
            console.log('_________________');
            console.log(JSON.stringify(response.data));
            console.log('_________________');
            this.getImageSets();
        })
        .catch((error) => {
            console.log("[ERROR] activate imageset")
        });

  }
  deleteImageSet = (imageset_name_to_delete) => {

        /*
        console.log("_________________________________");
        console.log(`this.state.imagesets=${JSON.stringify(this.state.imageset_objects)})`);
        console.log("_________________________________");
        console.log(`imageset_name_to_delete=${imageset_name_to_delete}`);
        console.log("_________________________________");
        console.log(`imageset_lines=${JSON.stringify(this.state.imageset_lines)}`);
        console.log("_________________________________");
        */

        /*

        var new_list_of_imageset_objects = [];
        for (let i = 0; i < this.state.imageset_objects.length; i++) {
          let current_imageset = this.state.imageset_objects[i]

          //console.log(`current_imageset=${JSON.stringify(current_imageset)}`)

          //new_list_of_imageset_objects.push(current_imageset);
          lines.push(
            <Line
              key={i}
              jobSlot={job_slot_str}
              month={this.state.month_str}
              date={this.state.date_str}
              imageset_object={current_imageset}
              lineType={"IMAGESET"}
              onClickRow={this.onClickImageSet}
            />
          );
        }
        */

        //var updatedList = this.state.imageset_objects.filter(function( obj ) {
        //    return obj.name !== imageset_name_to_delete;
        //});

        /*
        var updatedLines = this.state.imageset_lines.filter(function( obj ) {
            //console.log("____");
            //console.log(`obj=${JSON.stringify(obj)}`);
            //console.log(`props=${JSON.stringify(obj.props)}`);
            //console.log(`name=${obj.props.imageset_object.name}`);
            //return true;
            //return obj.name !== imageset_name_to_delete;
            return obj.props.imageset_object.name !== imageset_name_to_delete;
        });

        this.setState({
            //imageset_objects: updatedList,
            imageset_lines: updatedLines,
        });
        */
        //    imageset_objects: new_list_of_imageset_objects,
        //    imageset_lines: lines,
        //    //monthSelected: month_str,
        //});

        // delete imageset
        axios
        .get(DELETE_IMAGESET_URL+'/'+this.state.job_slot+'/'+this.state.month_str+'/'+this.state.date_str+'/'+imageset_name_to_delete)
        .then((response) => {
            console.log('_________________');
            console.log(JSON.stringify(response.data));
            console.log('_________________');
            this.getImageSets();
        })
        .catch((error) => {
            console.log("[ERROR] delete imageset")
        });

  }



  onClickEditImageSet = (imageset_name_to_edit) => {

    //console.log(`imageset_name_to_edit=${imageset_name_to_edit}`)

    if (this.state.bool_editing_imageset_name === true) {
        return true;
    } else {


        const new_lines = [];

        for (let i = 0; i < this.state.imageset_lines.length; i++) {
          let current_line = this.state.imageset_lines[i]

           if (current_line.props.imageset_object.name === imageset_name_to_edit) {
               const job_slot_str = this.state.job_slot.toString();
               new_lines.push(
                   <ImageSetLine
                       key={i}
                       jobSlot={job_slot_str}
                       month={this.state.month_str}
                       date={this.state.date_str}
                       imageset_object={current_line.props.imageset_object}
                       lineType={"IMAGESETX"}
                       onClickRow={this.onClickImageSet}
                       onClickDelete={this.deleteImageSet}
                       onClickEdit={this.onClickEditImageSet}
                       bool_stop_editing={false}
                       onClickUpdateImageSetName={this.updateImageSetName}
                       bool_production_mode={this.state.bool_production_mode}
                    />
                  );
           } else {
             new_lines.push(current_line);
           }
          }

            this.setState({
                bool_editing_imageset_name: true,
                imageset_name_editing: imageset_name_to_edit,
                imageset_lines: new_lines,
            });
            return false;
        }
        /*
        var updatedLines = this.state.imageset_lines.filter(function( obj ) {
            console.log("____");
            console.log(`obj=${JSON.stringify(obj)}`);
            //console.log(`props=${JSON.stringify(obj.props)}`);
            //console.log(`name=${obj.props.imageset_object.name}`);
            return true;
            //return obj.name !== imageset_name_to_delete;
            //return obj.props.imageset_object.name !== imageset_name_to_delete;
        });
        */

        /*
        var updatedLines = this.state.imageset_lines.filter(function( obj ) {

            return obj.props.imageset_object.name !== imageset_name_to_edit;

        });

        var updatedLines = this.state.imageset_lines;

        updatedLines.map((obj) => {
            if(obj.props.imageset_object.name === imageset_name_to_edit){
                obj.props.imageset_object.display_name = updated_display_name;
                obj.props.bool_editing_imageset_name = true;
                //obj.baz[0] = 11;
                //obj.baz[1] = 22;
                //obj.baz[2] = 33;
            }
        })

        this.setState({
            //imageset_objects: updatedList,
            imageset_lines: updatedLines,
        });
        */

  }

  // _______________________________________________________________________ //
  // _______________________________________________________________________ //
  // _______________________________________________________________________ //
  // _______________________________________________________________________ //

  updateImageSetName = (imageset_display_name) => {

        // edit imageset name
        axios
        .get(EDIT_IMAGESET_NAME_URL+'/'+this.state.job_slot+'/'+this.state.month_str+'/'+this.state.date_str+'/'+this.state.imageset_name_editing+'/'+imageset_display_name)
        .then((response) => {
            console.log('_________________');
            console.log(JSON.stringify(response.data));
            console.log('_________________');
            this.getImageSets();
        })
        .catch((error) => {
            console.log("[ERROR] edit imageset name")
        });

        this.setState({
            bool_editing_imageset_name: false,
            imageset_name_editing: null,
        });

  }


  onClickImageSet = (imageset_object) => {

    /*
    console.log("____________________________________");
    console.log("____________________________________");
    console.log("____________________________________");
    console.log("____________________________________");
    console.log(`imageset_name_editing=${this.state.imageset_name_editing}`)
    //console.log(`imageset_display_name=${imageset_display_name}`)
    console.log("____________________________________");
    console.log("____________________________________");
    console.log("____________________________________");
    console.log("____________________________________");
    */

    if (this.state.bool_editing_imageset_name === true) {

        const new_lines = [];

        for (let i = 0; i < this.state.imageset_lines.length; i++) {
          let current_line = this.state.imageset_lines[i]

           if (current_line.props.imageset_object.name === this.state.imageset_name_editing) {
               const job_slot_str = this.state.job_slot.toString();

               new_lines.push(
                   <ImageSetLine
                       key={i}
                       jobSlot={job_slot_str}
                       month={this.state.month_str}
                       date={this.state.date_str}
                       imageset_object={current_line.props.imageset_object}
                       lineType={"IMAGESETX"}
                       onClickRow={this.onClickImageSet}
                       onClickDelete={this.deleteImageSet}
                       onClickEdit={this.onClickEditImageSet}
                       bool_stop_editing={true}
                       onClickUpdateImageSetName={this.updateImageSetName}
                    />
                  );
           } else {
             new_lines.push(current_line);
           }
        }

        this.setState({
            imageset_lines: new_lines,
        });

    } else {

      this.setState({
          searchType: "IMAGESET_SELECTED",
          imageset_name: imageset_object.name,
          //lines: null,
          //dateSelected: date_str,
      });

    }
  }


  render() {

    if (this.state.searchType === "DATE") {
        return (
                 <Redirect to={getHistoryDatesURL(this.state.job_slot, this.state.month_str)} />
	       );
    }


    if (this.state.imageset_objects === null)
    {    return (<div / >);    }


    if (this.state.searchType == "IMAGESET") {

      return [

        <div className={s.component} key="component">
         {  // only add imageset if bool_production_mode == False
           (this.state.bool_production_mode === true ) ?

           (
             <header className={s.header}>
              <div className={s.backlink}>

                <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                  //console.log("BACK2!");
                  this.setState({
                                 searchType: "DATE",
                               });
                  }}>
                  Select Date
                </BackLink>
              </div>
              <p className={s.title}> <span className={s.liteweight}>Browse History: Select ImageSet </span></p>
              <br/>
              <p className={s.note_production_mode}> Production mode is active: History features are limited </p>

            </header>
          )
          :
          (
           <div>
             <header className={s.header2}>
              <div className={s.backlink}>

                <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                  //console.log("BACK2!");
                  this.setState({
                                 searchType: "DATE",
                                });
                  }}>
                  Select Date
                </BackLink>
              </div>
            </header>

            <ListHeader2 title="BROWSE HISTORY: SELECT IMAGESET">

              <Button
                color="success"
                iconLeft="plus-square"
                onClick={() => {
                  console.log("CREATE NEW IMAGESET CLICK");

                  // add new imageset
                  axios
                  .get(NEW_IMAGESET_URL+'/'+this.state.month_str+'/'+this.state.date_str+'/'+this.state.job_slot.toString())
                  .then((response) => {
                      console.log('_________________');
                      console.log(JSON.stringify(response.data));
                      console.log('_________________');

                      this.getImageSets();
                  })
                  .catch((error) => {
                      console.log("[ERROR] new imageset")
                  });

                }}
              >
              Create new Imageset
              </Button>

            </ListHeader2>
           </div>

          )}



          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.month)}>ImageSet Name</p>
              <p className={cx(s.cell, s.action)}></p>
              <p className={cx(s.cell, s.action)}></p>
              <p className={cx(s.cell, s.action)}>Action</p>
            </div>
          </div>
          {this.state.imageset_lines}
        </div>,
       <HistoryBreadcrumb key="breadcrumb" job_slot={this.state.job_slot} job_name={this.state.job_name} month_str={this.state.month_str} date_str={this.state.date_str} />,

      ];
    }
    else if (this.state.searchType == "IMAGESET_SELECTED") {
      return (
                 <Redirect to={ getHistoryBrowseURL(this.state.job_slot.toString(), this.state.month_str, this.state.date_str, this.state.imageset_name) } />

             );
    }



   return (<div / >);


  }
}


export default HistoryImageSets;
