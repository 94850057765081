import React, { Component } from 'react';

import { connect } from 'react-redux';


import { selectors, getUserId } from 'redux/reducers/datasets';

import Carousel from 'nuka-carousel';


import {
  LOAD_TRAINING_IMAGE_FROM_SERVER_URL,
} from 'pages/urls';




const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}




class ImagePreviewCarousel extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        const { user_ID, dataset_ID, list_of_image_IDs } = this.props;

        //print_in_blue(`user_ID=${user_ID}`)
        //print_in_blue(`dataset_ID=${dataset_ID}`)
        //print_in_blue(`list_of_image_IDs=${list_of_image_IDs}`)

        const listImages = list_of_image_IDs.map((image_ID) => {
            return (
				<img src={LOAD_TRAINING_IMAGE_FROM_SERVER_URL + `/${user_ID}/${dataset_ID}/${image_ID}`} key={image_ID} />
            );
        });

        return (
			<Carousel wrapAround
				renderCenterLeftControls={({ previousSlide }) => (
				<button onClick={previousSlide}>
				  {'<'}
				</button>
			  )}
			  	renderCenterRightControls={({ nextSlide }) => (
					<button onClick={nextSlide}>
				  	{'>'}
					</button>
			  	)}
			>
				{listImages}
			</Carousel>
		)
    };
};


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
});

export default connect(mapStateToProps)(ImagePreviewCarousel);
