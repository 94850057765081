// Slider.js

import React from 'react';
import RCSlider from 'rc-slider/lib/Slider';

import s from './Slider.module.sass';

const Slider = (props) => {
  const { min, max, marks, defaultValue, onChange, disabled, step_interval=0.001 } = props;

  if (disabled === true) {
    return (
    <div className={s.component}>
      <RCSlider
        min={min}
        max={max}
        marks={marks}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={true}
      />
    </div>
    );
  }

  return (
    <div className={s.component}>
      <RCSlider
        step={step_interval}
        min={min}
        max={max}
        marks={marks}
        defaultValue={defaultValue}
        onAfterChange={onChange}
      />
    </div>
  );
};

export default Slider;
