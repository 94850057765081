import React from 'react';
import { NavLink } from 'react-router-dom';

import s from './DeepViewLogo.module.sass';

const DeepViewLogo = () => (
  <NavLink to="/choose-app" className={s.logo}>
    <img
      alt="DeepView logo"
      src="'/brand/deepview-logo.png"
      srcSet="/brand/deepview-logo.png, /brand/deepview-logo@2x.png 2x, /brand/deepview-logo@3x.png 3x"
      width="175"
      height="42"
    />
  </NavLink>
);

export default DeepViewLogo;
