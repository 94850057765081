import React from 'react';

import FilterLabel from 'components/history/FilterLabel';

import s from './RangeFilter.module.sass';

const RangeFilter = (props) => {
  const { label, start, end } = props;

  return (
    <div className={s.component}>
      <FilterLabel>{label}</FilterLabel>

      <div className={s.values}>
        <p className={s.start}>{start}</p>
        <p className={s.sep}>→</p>
        <p className={s.end}>{end}</p>
      </div>
    </div>
  );
};

export default RangeFilter;
