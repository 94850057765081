import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';

import {
  PRODUCTION_VIEW_URL,
  HISTORY_URL,
  USER_URL,
  CAPTURE_ONLY_URL }
from 'pages/urls';
import DeepViewLogo from 'components/graphics/DeepViewLogo';

import History from 'pages/camera/History';

import cx from 'classnames';
import s from './CameraHeader.module.sass';

const LINKS = [
  {
    id: 'camera',
    label: 'Production View',
    path: PRODUCTION_VIEW_URL,
    exact: true,
  },
  {
    id: 'history',
    label: 'History',
    path: HISTORY_URL,
  },
  {
    id: 'settings',
    label: 'Settings',
  },
  {
    id: 'user',
    label: 'User',
    path: USER_URL,
  },
];

const CameraHeader = (props) => {
  const { onSettingsClick } = props;

  const navItems = LINKS.map((item, index) => {
    if (item.id === 'settings') {
      return (
        <div
          key={item.id}
          className={cx(s.item, s[item.id])}
          onClick={onSettingsClick}
        />
      );
    }

    if (item.path) {

      return (
        <NavLink
          key={item.id}
          className={cx(s.item, s[item.id])}
          activeClassName={s.isActive}
          to={item.path}
          exact={item.exact}
        />
      );
    }

    return <div key={item.id} className={cx(s.item, s[item.id])} />;
  });

  return (
    <div className={s.component}>
      <DeepViewLogo />
      <nav className={s.nav}>{navItems}</nav>
    </div>
  );
};

export default CameraHeader;
