import React from 'react';
import { NavLink } from 'react-router-dom';

import s from './Tabs.module.sass';

const Tabs = (props) => {
  const { items } = props;

  const navItems = items.map((item, index) => {
    return (
      <NavLink
        key={item.id}
        className={s.link}
        activeClassName={s.active}
        to={item.to}
        exact={item.exact}
      >
        {item.label}
      </NavLink>
    );
  });

  return <div className={s.component}>{navItems}</div>;
};

export default Tabs;
