import React from 'react';

import cx from 'classnames';
import s from './Content.module.sass';

const Content = props => {
  const {
    parentClass,
    children,
  } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return (
    <div className={componentClass}>
      {children}
    </div>
  )
}

export default Content;
