import React, { Component } from 'react';
import moment from 'moment';

import Button from 'components/elements/Button';
import RecentImages from 'components/production/RecentImages';
import Toggles from 'components/form/Toggles';

import s from './CaptureOnlyView.module.sass';


import axios from 'axios';

import Request from 'axios-request-handler';



const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}



const TRIGGER_OPTIONS = [
  { value: 'manual', label: 'Manual trigger' },
  { value: 'plc', label: 'PLC trigger' },
];

/*

const getAllImagePredictions = () => {
  return function () {

    return axios
      .get('http://0.0.0.0:6002/capture_image_no_inference')
      .then((response) => {
        console.log('=================================================');
        console.log(response);
        console.log('=================================================');
      })
      .catch((error) => {
        console.log("[ERROR] capture_image")
      });
  };
};


*/

class CaptureOnlyView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      currentTimeStr: "",
      recentImagePath: "",
    };

    const reviews = new Request('http://0.0.0.0:6002/subscribe_image_capture_notifications');

    // 'long poll'
    // async continuous request sent from browser to backend
    // --> backend sleeps until an image is ready
    // --> when image is captured, backend responds to poll
    // --> ... image_capture_callback
    const interval_ms = 1

    reviews.poll(interval_ms).get((response) => {
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        print_in_orange(response.data);

        for (var key in response.data) {
            print_in_blue(`%c  ${key}: ${response.data[key]}`);
        }
        const timestamp = response.data['timestamp']
        const current_time_str = moment.unix(timestamp).format('h:mm:ss.SSS a')
        print_in_blue(current_time_str)

        this.setState({
            recentImagePath: response.data['img_path'],
            currentTimeStr: current_time_str,
        })

        this.forceUpdate()
        // you can cancel polling by returning false
    });

  }

  render() {
    return (
      <div className={s.component}>
        <main className={s.main}>
          <figure className={s.live}>
            <img alt="Live camera view" src="http://0.0.0.0:6002/video_feed" />
          </figure>

          <footer className={s.footer}>
            <Button
              iconLeft="camera"
              isFullWidth
              isTall
              size="large"
              color="info"
              onClick={()=>{
                console.log("CLICK");
                axios
                  .get('http://0.0.0.0:6002/capture_image_no_inference')
                  .then((response) => {
                    console.log('=================================================');
                    console.log(response);
                    console.log('=================================================');
                  })
                  .catch((error) => {
                    console.log("[ERROR] capture_image")
                  });
              }}
            >
              Capture Image
            </Button>

            <div className={s.tools}>
              <div className={s.tool}>
                <Toggles
                  currentValue="manual"
                  options={TRIGGER_OPTIONS}
                  onChange={null}
                />
              </div>

              <div className={s.tool}>
                <p className={s.keyValue}>
                  <span>Exposure</span>
                  <strong>9ms</strong>
                </p>
              </div>

              <div className={s.tool}>
                <p className={s.keyValue}>
                  <span>Exposure</span>
                  <strong>9ms</strong>
                </p>
              </div>

              <div className={s.tool}>
                <p className={s.time}>8:16:02 AM</p>
              </div>
            </div>
          </footer>
        </main>

        <RecentImages
          timestamp={this.state.currentTimeStr}
          imgSrc={`http://0.0.0.0:6002/recent_image_thumbnail/${this.state.recentImagePath}`}
          predictionType={"N/A"}
          confidenceScore={"N/A"}
        />
      </div>
    );
  }
}

export default CaptureOnlyView;
