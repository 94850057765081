import React, { useState, } from 'react';
import { connect } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { selectors, getListOfDatasets } from 'redux/reducers/datasets';


import DatasetTabs from 'components/navigation/DatasetTabs';
import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';

import moment from 'moment';
import Button from 'components/elements/Button';
import Title from 'components/elements/Title';
import LoadDataset from 'components/buttons/LoadDataset';

import s from './DatasetOverview.module.sass';
import cx from 'classnames';

import ImagePreviewCarousel from 'components/list/ImagePreviewCarousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  getImagesURL,
  getNewNetworkURL,
  SET_DATASET_NAME_URL,
  DELETE_DATASET_URL,
  GET_DATASET_IMAGES_ZIP_URL,
} from 'pages/urls';

import Input2 from 'components/form/Input2';

import axios from 'axios';

import Modal from 'components/elements/Modal';
import ConfirmationModal from 'components/elements/ConfirmationModal';

//import {
//  LOAD_TRAINING_IMAGE_FROM_SERVER_URL,
//} from 'pages/urls';


const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}



//import default_image from 'default_image.png';

//const handleChange = (event, key) => {
//      console.log('HANDLE CHANGE');
//      //this.setState({
//      //  [key]: event.target.value,
//      //});
//}


const DatasetOverview = (props) => {


    const { uploadImage, user_ID, datasets_object } = props;

    //const { imagesList } = props;

    //const id1 = "x"
    //const id2 = "y"
    //const id1 = imagesList[0].id;
    //const id2 = imagesList[1].id;

    const { datasetId } = useParams();

    const [bool_editing_dataset_display_name, set_bool_editing_dataset_display_name] = useState(false);
    const [bool_delete_dataset_confirmation_modal_open, set_bool_delete_dataset_confirmation_modal_open] = useState(false);
    const [bool_redirect_to_datasets_list, set_bool_redirect_to_datasets_list] = useState(false);


    //print_in_orange(`user_ID=${user_ID}`)
    //print_in_orange(`datasetId=${datasetId}`)
    //print_in_orange(`datasets_object=${datasets_object}`)

    const firstTenImageIDs = datasets_object.byId[datasetId].firstTenImageIDs

    const dataset_object = datasets_object.byId[datasetId]
    //console.log(`dataset_object=${JSON.stringify(dataset_object)}`)


    const [dataset_display_name, set_dataset_display_name] = useState(dataset_object.name);



    const N_images = dataset_object.imageCount


    const labellingPercentage = 100;
    const xview_lastModified = 1594685367;


    const statusType = labellingPercentage === 100 ? 'success' : 'warning';

    const statusClass = cx({
      [s.status]: true,
      [`is-${statusType}`]: statusType,
    });

    /*
    let user_message = "";
    if (labellingPercentage===0){
        user_message = "Upload Images";
    }
    else if (labellingPercentage===100){
        user_message = "Train Neural Network";
    }
    else {
        user_message = "Finish Labelling";
    }
    */

    //console.log(`%c id1=${id1}`, 'background: green; color: white; display: block;')
    //console.log(`%c id2=${id2}`, 'background: green; color: white; display: block;')

    //console.log(LOAD_TRAINING_IMAGE_FROM_SERVER_URL + `/1/1/6ae343da6ba14bd1`)
    const IMAGES_URL = getImagesURL(datasetId)
    const NEW_NETWORK_URL = getNewNetworkURL(datasetId)
    //console.log(`IMAGES_URL=${IMAGES_URL}`)

    if (bool_redirect_to_datasets_list === true) {
        return ( <Redirect to={"/training"} /> )
    }

    return [
        <div key="component" className={s.component} onClick={()=>{

            //console.log("outside click");
            set_bool_editing_dataset_display_name(false);

            if (dataset_display_name != dataset_object.name) {
                //console.log(`dataset_display_name changed`);

                 ///////////////////////////////////////////////////////

                 // change dataset name

                 axios
                 .get(`${SET_DATASET_NAME_URL}/${user_ID}/${dataset_object.id}/${dataset_display_name}`)
                 .then((response) => {
                     //console.log('RECV SET_DATASET_NAME');
                     //console.log(JSON.stringify(response.data));
                     //console.log('RECV SET_DATASET_NAME');

                 })
                 .catch((error) => {
                     console.log("[ERROR] set dataset_name")
                 });

                 ///////////////////////////////////////////////////////
            }

        }}>
            <DatasetTabs datasetId={datasetId} />

            <div className={s.container}>
                <figure className={s.figure}>

                    <ImagePreviewCarousel dataset_ID={datasetId} list_of_image_IDs={firstTenImageIDs}/>

                </figure>

                <div className={s.text}>
                    <div>
                        <header className={s.header}>
                            <Title noMargin parentClass={s.title}>


                              { bool_editing_dataset_display_name ? (
                                    <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={dataset_display_name}
                                            onChange={(e) => set_dataset_display_name(e.target.value) }
                                            placeholder="Dataset Alpha"
                                            onClick={(e)=>{
                                                //console.log("Input2 CLICK");
                                                set_bool_editing_dataset_display_name(true);
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                  <span className={s.titleText}>
                                      {dataset_display_name}
                                  </span>
                              )}


                                <span className={s.iconEdit} onClick={(e)=>{
                                    //console.log(`EDIT CLICK`);
                                    set_bool_editing_dataset_display_name(true);
                                    e.stopPropagation();
                                }}>
                                    <FontAwesomeIcon icon="pen" />
                                </span>

                              { (bool_editing_dataset_display_name===false) && (

                                <span className={s.iconDelete} onClick={()=>{
                                    //console.log(`DELETE CLICK!`);
                                    set_bool_delete_dataset_confirmation_modal_open(true);

                                }}>
                                    <FontAwesomeIcon icon="times-circle" />
                                </span>
                               )}

                            </Title>

                            <ul className={s.info}>
                                <li>
                                    {N_images} images
                                </li>
                                <li>
                                    {/*<em> Size:</em> 20 MB*/}
                                    <em> Size:</em> {N_images} MB
                                </li>
                                <li>

                                  <em>Last modified:</em>{' '}
                                  {moment.unix(dataset_object.lastModified).format('MMMM D, YYYY h:mm a')}
                                </li>
                                </ul>

                        </header>
                        <div className={s.neighborButtons}>
                            <Button color="success" to_x={IMAGES_URL} iconLeft="plus-square">
                                Add Images
                            </Button>
                            {/*
                            <Button to_x={IMAGES_URL} iconLeft="camera">
                                Label Images
                            </Button>
                            */}
                            <Button
                              iconLeft="arrow-down"
                              //isDisabled={N_images===0}
                              onClick={()=>{
                                //console.log("CLICK DOWNLOAD");
                                if (N_images > 0) {
                                  const date_timestamp_str = new Date().toISOString()
                                  const getZipUrl = GET_DATASET_IMAGES_ZIP_URL + `/${user_ID}/${datasetId}/${date_timestamp_str}`;
                                  window.location.href = getZipUrl;
                                }
                              }
                            }>
                              Download
                            </Button>

                        </div>
                    </div>

                    <div className={s.nextStep}>
                        <div className={s.nextStepText}>
                        Next step
                        <br />
                        </div>


                      { (dataset_object.imageCount===0) ?
                          ( <Button iconRight="arrow-right" to_x={IMAGES_URL}>
                              Upload Images
                            </Button> )
                          : ( (dataset_object.labellingPercentage===100) ?
                              ( <Button iconRight="arrow-right" to_x={NEW_NETWORK_URL}>
                                  Train Neural Network
                                </Button> )
                              : ( <Button iconRight="arrow-right" to_x={IMAGES_URL}>
                                  Label Images
                                  </Button> ) ) }




                    </div>


                </div>
            </div>
        </div>,

        <DatasetBreadcrumb key="breadcrumb" datasetId={datasetId} />,
        <LoadDataset dataset_ID={datasetId} user_ID={user_ID}/>,

        <Modal key="modal" isOpen={bool_delete_dataset_confirmation_modal_open} onRequestClose={()=>{set_bool_delete_dataset_confirmation_modal_open(false)}}>
            <ConfirmationModal datasetName={dataset_object.name} onSubmit={(e, datasetName)=>{
                e.preventDefault();
                //console.log("DELETE CONFIRMATION onSubmit() called");

                ///////////////////////////////////////////////////////

                // delete dataset

                axios
                .get(`${DELETE_DATASET_URL}/${user_ID}/${dataset_object.id}`)
                .then((response) => {
                    //console.log('RECV DELETE_DATASET ACK');
                    //console.log(JSON.stringify(response.data));
                    //console.log('RECV DELETE_DATASET ACK');

                    getListOfDatasets(user_ID)
                    set_bool_redirect_to_datasets_list(true);


                })
                .catch((error) => {
                    console.log("[ERROR] delete_dataset")
                });

                ///////////////////////////////////////////////////////



            }} />
        </Modal>,


  ];
};


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
  datasetId: selectors.getDatasetId(state),
  datasets_object: selectors.getDatasetsObject(state),
  //imagesList: selectors.getDatasetImages(state),
  //imagesListSorted: selectors.getDatasetImagesSorted(state),
});

export default connect(mapStateToProps)(DatasetOverview);
