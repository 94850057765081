import React, { Component } from 'react';

import s from './IPAddress.module.sass';

const getRange = (el) => {
  let cuRange,
    tbRange,
    headRange,
    range,
    dupRange,
    ret = {};

  if (el.setSelectionRange) {
    // standard
    ret.begin = el.selectionStart;
    ret.end = el.selectionEnd;
    ret.result = el.value.substring(ret.begin, ret.end);
  } else if (document.selection) {
    // ie
    if (el.tagName.toLowerCase() === 'input') {
      cuRange = document.selection.createRange();
      tbRange = el.createTextRange();
      tbRange.collapse(true);
      tbRange.select();
      headRange = document.selection.createRange();
      headRange.setEndPoint('EndToEnd', cuRange);
      ret.begin = headRange.text.length - cuRange.text.length;
      ret.end = headRange.text.length;
      ret.result = cuRange.text;
      cuRange.select();
    } else if (el.tagName.toLowerCase() === 'textarea') {
      range = document.selection.createRange();
      dupRange = range.duplicate();
      dupRange.moveToElementText(el);
      dupRange.setEndPoint('EndToEnd', range);
      ret.begin = dupRange.text.length - range.text.length;
      ret.end = dupRange.text.length;
      ret.result = range.text;
    }
  }
  el.focus();

  return ret;
};

const isValidIPItemValue = (val) => {
  val = parseInt(val);
  return !isNaN(val) && val >= 0 && val <= 255;
};

class IPAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    let { defaultValue = '...' } = this.props;
    if (!Array.isArray(defaultValue)) {
      defaultValue = defaultValue.split('.');
    }
    this.setState({ value: defaultValue });
  }

  handleChange(e, i) {
    let val = parseInt(e.target.value);

    if (isNaN(e.target.value)) {
      return e.preventDefault();
    }

    if (e.target.value !== '' && !isValidIPItemValue(val)) {
      val = 255;
    }

    let value = this.state.value;
    value[i] = val;
    this.setState({ value }, () => this.onPropsChange());

    if (!isNaN(val) && String(val).length === 3 && i < 3) {
      this[`_input-${i + 1}`].focus();
    }
  }

  handleKeyDown(e, i) {
    /* 37 = ←, 39 = →, 8 = backspace */
    let domId = i;
    if (
      (e.keyCode === 37 || e.keyCode === 8) &&
      getRange(e.target).end === 0 &&
      i > 0
    ) {
      domId = i - 1;
    }
    if (
      e.keyCode === 39 &&
      getRange(e.target).end === e.target.value.length &&
      i < 3
    ) {
      domId = i + 1;
    }
    this[`_input-${domId}`].focus();
  }

  handlePaste(e, i) {
    if (!e.clipboardData || !e.clipboardData.getData) {
      return;
    }

    const pasteData = e.clipboardData.getData('text/plain');
    if (!pasteData) {
      return;
    }

    const value = pasteData.split('.').map((v) => parseInt(v));
    if (value.length !== 4 - i) {
      return;
    }

    if (!value.every(isValidIPItemValue)) {
      return;
    }

    const { value: oldValue } = this.state;
    value.forEach((val, j) => {
      oldValue[i + j] = val;
    });

    this.setState({ value: oldValue }, () => this.onPropsChange());
    return e.preventDefault();
  }

  onPropsChange() {
    const { value } = this.state;
    const ip = value.map((val) => (isNaN(val) ? '' : val)).join('.');
    return this.props.onChange(ip);
  }

  render() {
    const { value } = this.state;

    return (
      <div className={s.component}>
        {value.map((val, i) => {
          const input = (
            <input
              key={`input${i}`}
              className={s.input}
              ref={(el) => (this[`_input-${i}`] = el)}
              type="text"
              value={isNaN(val) ? '' : val}
              onChange={(e) => this.handleChange(e, i)}
              onKeyDown={(e) => this.handleKeyDown(e, i)}
              onPaste={(e) => this.handlePaste(e, i)}
            />
          );

          if (i !== 3) {
            return [
              input,
              <i key={`sep${i}`} className={s.sep}>
                .
              </i>,
            ];
          }

          return input;
        })}
      </div>
    );
  }
}

export default IPAddress;
