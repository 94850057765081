import React from 'react';

import Toggle from 'components/form/Toggle';

import s from './Voting.module.sass';

const Voting = (props) => {
  const {
    currentLabel,
    isReadOnly,
    isLoading,
    onPassClick,
    onFailClick,
  } = props;

  return (
    <div className={s.toggles}>
      <Toggle
        parentClass={s.toggle}
        keyboard="↑"
        type="PASS"
        isActive={currentLabel === 'PASS'}
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        onClick={onPassClick}
      />

      <Toggle
        parentClass={s.toggle}
        keyboard="↓"
        type="FAIL"
        isActive={currentLabel === 'FAIL'}
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        onClick={onFailClick}
      />
    </div>
  );
};

export default Voting;
