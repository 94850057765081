import React from 'react';

import PredictionType from 'components/elements/PredictionType';
import ConfidenceScore from 'components/elements/ConfidenceScore';
import cx from 'classnames';
import s from './ProductionResults.module.sass';

const ProductionResults = (props) => {
  const { predictionType, confidenceScore, time } = props;

  //if (predictionType!=="N/A")
  //    console.log("NO")
  //else
  //    console.log("YA")

  //const componentClass = cx({
  //  [s.component]: true,
  //  [s[`type-${predictionType}`]]: (predictionType!=="N/A")? predictionType : "na",
  //});

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${predictionType}`]]: predictionType,
  });

  console.log(`${componentClass}`)

  console.log(`predictionType=${predictionType}`)

  return (
    <div className={componentClass}>
      <p className={cx(s.item, s.prediction)}>
        <span className={s.label}>Prediction</span>
        <PredictionType parentClass={s.value} type={predictionType} />
      </p>
      <p className={s.item}>
        <span className={s.label}>Confidence</span>
        <ConfidenceScore parentClass={s.value} score={confidenceScore} />
      </p>
      <p className={cx(s.item, s.time)}>
        <span className={s.label}>{time}</span>
      </p>
    </div>
  );
};

export default ProductionResults;
