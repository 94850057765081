import React from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { selectors, uploadImage } from 'redux/reducers/datasets';

import Title from 'components/elements/Title';
import Button from 'components/elements/Button';

import cx from 'classnames';
import s from './DropzoneB.module.sass';

const Dropzone = (props) => {
  const { uploadImage, userId, datasetId } = props;

  const dropzoneConfig = {
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) => uploadImage(file, userId, datasetId));
    },
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneConfig
  );

  const componentClass = cx({
    [s.component]: true,
    [s.isDragActive]: isDragActive,
  });

  return (
    <div {...getRootProps({ className: componentClass })}>
      <div className={s.border}>
        <div className={s.content}>
          <input {...getInputProps()} />

          {isDragActive ? (
            <Title parentClass={s.title}>
              Drop your images <strong>here</strong>
            </Title>
          ) : (
            <Title parentClass={s.title}>
              <strong>Drop images here</strong>
              <br/>
              <br/>
              OR
            </Title>
          )}


          <div className={s.cta}>
            <Button
              iconRight="mouse-pointer"
              size="small"
              color={isDragActive ? 'success' : null}
            >
              Select Files
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: selectors.getUserId(state),
  isLoading: selectors.isLoading(state),
});

export default connect(mapStateToProps, {
  uploadImage,
})(Dropzone);
