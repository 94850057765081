import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Content from 'components/elements/Content';
import s from './Setting.module.sass';

const Setting = props => {
  const {
    icon,
    title,
    content,
    children,
  } = props;

  return (
    <div className={s.component}>
      <figure className={s.icon}>
        <FontAwesomeIcon icon={icon} />
      </figure>

      <div className={s.main}>
        <h3 className={s.title}>{title}</h3>

        <div className={s.body}>
          <div className={s.control}>
            {children}
          </div>

          <div className={s.description}>
            <Content>
              {content}
            </Content>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setting;
