// B"H

// datasets.js

import axios from 'axios';
import produce from 'immer';

// API config


import {
  CREATE_NEW_DATASET_URL,
  CREATE_NEW_DATASET_WITH_IMAGES_URL,

  UPLOAD_IMAGE_URL,
  GET_LIST_OF_DATASETS_URL,
  GET_LIST_OF_NETWORKS_URL,

  GET_DATASET_INFO_URL,
  LOAD_BINARY_IMAGE_THUMBNAIL_FROM_SERVER_URL,
  IMAGE_PREDICTION_URL,
  START_TRAINING_URL,

  GET_DATASET_IMAGE_METADATA_DICT_URL,
  GET_DATASET_THUMBNAILS_ZIP_URL,
  NEW_IMAGE_LABEL_URL,

} from 'pages/urls';





const REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};

// Utilities

const print_time = (message, v = '') => {
  const date_object = new Date();
  const t = date_object.getTime() / 1000;
  console.log(`${message}: ${t} ${v}`);
};

// Action types

const SET_USER_ID = 'SET_USER_ID';

const RECEIVE_NEW_DATASET_OBJECT = 'RECEIVE_NEW_DATASET_OBJECT';
const CLEAR_ACTIVE_DATASET_ID = 'CLEAR_ACTIVE_DATASET_ID';

const RECEIVE_LIST_OF_DATASETS = 'RECEIVE_LIST_OF_DATASETS';
const RECEIVE_LIST_OF_NETWORKS = 'RECEIVE_LIST_OF_NETWORKS';

const HANDLE_ERROR = 'HANDLE_ERROR';
const START_LOADING = 'START_LOADING';
const RECEIVE_IMAGES = 'RECEIVE_IMAGES';
const RECEIVE_IMAGE_LABEL = 'RECEIVE_IMAGE_LABEL';
const RECEIVE_ALL_IMAGE_PREDICTIONS = 'RECEIVE_ALL_IMAGE_PREDICTIONS';
const REQUEST_TRAINING = 'REQUEST_TRAINING';
const DELETE_TRAINING_IMAGE = 'DELETE_TRAINING_IMAGE';
const RECEIVE_TRAINING = 'RECEIVE_TRAINING';
const STOP_TRAINING = 'STOP_TRAINING';

// Action creators


export const setUserID = (userID) => {
  console.log(`setUserID`);
  return {
    type: SET_USER_ID,
    userID
  };
};

const receiveNewDataset = (new_dataset_object) => {
  return {
    type: RECEIVE_NEW_DATASET_OBJECT,
    new_dataset_object
  };
};


const receiveListOfDatasets = (list_of_datasets) => {
  return {
    type: RECEIVE_LIST_OF_DATASETS,
    list_of_datasets,
  };
};

const receiveListOfNetworks = (list_of_networks) => {
  return {
    type: RECEIVE_LIST_OF_NETWORKS,
    list_of_networks,
  };
};

const handleError = (error) => {
  console.log('dispatch error', error);
  return {
    type: HANDLE_ERROR,
  };
};

const startLoading = () => {
  return {
    type: START_LOADING,
  };
};

const receiveImages = (images, datasetId) => {
  print_time('receiveImages()', datasetId);

  return {
    type: RECEIVE_IMAGES,
    datasetId,
    images,
  };
};

const receiveAllImagePredictions = (response) => {
  return {
    type: RECEIVE_ALL_IMAGE_PREDICTIONS,
    response,
  };
};

const receiveImageLabel = (data) => {
  return {
    type: RECEIVE_IMAGE_LABEL,
    imageId: data.imageId,
    labelClass: data.labelClass,
    labelPolygons: data.labelPolygons,
  };
};

const requestTraining = () => {
  return {
    type: REQUEST_TRAINING,
  };
};

const deleteTrainingImage = (imageId) => {
  console.log(`deleteTrainingImage imageId=${imageId}`);
  return {
    type: DELETE_TRAINING_IMAGE,
    imageId,
  };
};

const receiveTraining = (data) => {
  return {
    type: RECEIVE_TRAINING,
    networkId: data.neural_net_ID,
    networkType: data.neural_net_type,
    nEpochs: data.N_epochs,
  };
};

const receiveClearActiveDatasetID = () => {
    return {
      type: CLEAR_ACTIVE_DATASET_ID,
    };
}


export const stopTraining = () => {
  return {
    type: STOP_TRAINING,
  };
};

// ========================================================================== //
// ========================================================================== //


export const setActiveUserID = (userID) => {

    return function (dispatch) {
        print_in_red(`setActiveUserID user_ID=${userID}`)

        //console.log("YOOO")
        dispatch(setUserID(userID));
  };

}

// ========================================================================== //
// ========================================================================== //

export const createNewDataset = (userID, name, boolMultiClass=false, boolDetection=false, boolPassDetection=false) => {

  return function (dispatch) {
    dispatch(startLoading());

    const new_dataset_URL = `${CREATE_NEW_DATASET_URL}/${userID}/${name}/${boolMultiClass}/${boolDetection}/${boolPassDetection}`;
    //console.log(`new_dataset_URL=${new_dataset_URL}`)

    return axios
      .get(new_dataset_URL)
      .then((response) => {

        //console.log('=================================================');
        //console.log(response);
        //console.log('=================================================');
        const new_dataset_object = response.data

        dispatch(receiveNewDataset(new_dataset_object));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

// ========================================================================== //
// ========================================================================== //

export const createNewDatasetWithImages = (userID, name, job_slot_str, month_str, images_date, imageset_name, boolMultiClass=false, boolDetection=false, boolPassDetection=false) => {

  //const new_dataset_URLx = `${CREATE_NEW_DATASET_WITH_IMAGES_URL}/${userID}/${name}/${job_slot_str}/${month_str}/${images_date}/${boolMultiClass}`;
  //console.log(`new_dataset_URL=${new_dataset_URLx}`)

  return function (dispatch) {
    dispatch(startLoading());

    // add timestamp to backend calls to prevent caching URL calls (as imagesets may update between backend URL calls)
    const date_timestamp_str = new Date().toISOString()

    const new_dataset_URL = `${CREATE_NEW_DATASET_WITH_IMAGES_URL}/${userID}/${name}/${job_slot_str}/${month_str}/${images_date}/${imageset_name}/${boolMultiClass}/${boolDetection}/${boolPassDetection}/${date_timestamp_str}`;
    //console.log(`new_dataset_URL=${new_dataset_URL}`)

    return axios
      .get(new_dataset_URL)
      .then((response) => {

        //console.log('=================================================');
        //console.log(response);
        //console.log('=================================================');
        const new_dataset_object = response.data

        dispatch(receiveNewDataset(new_dataset_object));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

// ========================================================================== //
// ========================================================================== //


export const clearActiveDatasetID = () => {

    return function (dispatch) {
      dispatch(startLoading());
      dispatch(receiveClearActiveDatasetID());
  };

}

// ========================================================================== //
// ========================================================================== //


// ========================================================================== //
// ========================================================================== //

/*
const receiveImage = (data) => {
    const image = {
        blob: data.blob,
        imageId: data.img_id,
        imageFilename: data.img_filename,
        labelClass: data.class_label,
        labelPolygons: data.list_of_label_polygons,
        isValidation: data.isValidation,
    };
    console.log(`label=${image.labelClass}`);
    return {
        type: RECEIVE_IMAGE,
        image,
    };
};
*/

/*
const receiveImages = (images_to_receive) => {
    print_time('receiveImages()');
    var list_of_images = new Array();
    //console.log(`images_to_receive=${images_to_receive}`);
    for (var i in images_to_receive) {
        var raw_image = images_to_receive[i];
        //console.log(`raw_image=${raw_image}`);
        const image = {
            blob: raw_image.blob,
            imageId: raw_image.img_id,
            imageFilename: raw_image.img_filename,
            labelClass: raw_image.class_label,
            labelPolygons: raw_image.list_of_label_polygons,
            isValidation: raw_image.sValidation,
        };
        //console.log(`label=${image.labelClass}`)
        list_of_images.push(image);
    }
    return {
        type: RECEIVE_IMAGES,
        list_of_images,
    };
};
*/

//console.log(`____________________________`)
//console.log(` `)
//console.log(`USER_ID=${userId}`)
//console.log(`DATASET_ID=${datasetId}`)
//console.log(` `)
//console.log(`____________________________`)

export const uploadImage = (file, userId, datasetId, boolMultiClass=false, class_label="NONE") => {
  return function (dispatch) {
    dispatch(startLoading());

    const url = `${UPLOAD_IMAGE_URL}/${userId}/${datasetId}/${boolMultiClass}/${class_label}`;
    let data = new FormData();
    data.append('file', file, file.fileName);
    return axios
      .post(url, data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then((response) => {
          /*
          const image = {
            id: imageMetadata.img_ID,
            path: imageMetadata.img_path,
            filename: imageFilename,
            labelClass: imageMetadata.class_label,
            labelPolygons: [],
            blob: URL.createObjectURL(blob),
            isValidation: null,
          };
          */
        const tempImages = []
        const image = {
          id: response.data.img_ID,
          path: file.fileName,
          img_filename: file.fileName,
          labelClass: class_label,
          labelPolygons: [],
          blob: URL.createObjectURL(file),
          isValidation: null,
        };
        tempImages.push(image)
        dispatch(receiveImages(tempImages, datasetId));

      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};




// ========================================================================== //
// ========================================================================== //

// Functions

var x_reader = null;
var bool_x_break = {};

const getImageFromZip = (entry, callback) => {
  entry.getData(new window.zip.BlobWriter('image/jpeg'), (data) => {
    //console.log(entry.filename)
    callback(data, entry.filename);
  });
};

const unzipBlob = (blob, startIndex, endIndex, callback) => {
  // use a zip.BlobReader object to read zipped data stored in blob
  return window.zip.createReader(
    new window.zip.BlobReader(blob),
    (zipReader) => {
      // get entries from the zip file
      zipReader.getEntries((entries) => {
        // save the zipReader object in global so we can close it later
        // (need to close zipReader to release its memory)
        x_reader = zipReader;

        for (let i = startIndex; i <= endIndex; i++) {
          getImageFromZip(entries[i], callback);
        }
        console.log('unzipBlob success');
      });
    },
    console.log('unzipBlob error')
  );
};

// recursive calls to get 100 images
// --> each recursive call will add 100 images to the redux store
// --> last recursive call will add final N<=100 images to the redux store

let tempImages = [];
const BATCH_SIZE = 100;

const oldUnzip = (
  dispatch,
  datasetId,
  zipBlob,
  batchStartIndex,
  batchEndIndex,
  imageData,
  imageCount,
) => {
  const datasetEndIndex = imageCount - 1;

  if (batchEndIndex > datasetEndIndex) {
    console.log('---------------------------------------------------------');
    console.log(`OLD batchEndIndex=${batchEndIndex}`);
    batchEndIndex = datasetEndIndex;
    console.log(`NEW batchEndIndex=${batchEndIndex}`);
    console.log('---------------------------------------------------------');
  }

  unzipBlob(
    zipBlob,
    batchStartIndex,
    batchEndIndex,
    (blob, thumbnailFilename) => {
        // (jpg used for thumbnails as jpg compresses ~4X)
        // (png used for images as does not compress)

        //console.log('_______________________________________')
        //console.log(' ')
        //console.log(`thumbnailFilename=${thumbnailFilename}`)
        //console.log(' ')
        //for (var key in imageData) {
        //  console.log(`${key}: ${imageData[key]}`);
        //}
        //console.log(`N_images=${imageData}`)
        //console.log('_______________________________________')




        const imageMetadata = imageData[thumbnailFilename];

        //console.log(`imageData=${imageData}`)
        //console.log(`imageData.length=${imageData.length}`)
        //console.log(`thumbnailFilename=${thumbnailFilename}`)
        //console.log(`imageMetadata=${JSON.stringify(imageMetadata)}`)

        const image = {
            id: imageMetadata.img_ID,
            path: imageMetadata.img_path,
            filename: imageMetadata.filename,
            labelClass: imageMetadata.class_label,
            labelPolygons: imageMetadata.list_of_label_polygons,
            blob: URL.createObjectURL(blob),
            isValidation: null,
        };
        tempImages.push(image);

        const N_images_to_add = batchEndIndex - batchStartIndex + 1;

        if (tempImages.length === N_images_to_add) {
            //console.log('-----------------------------------------------------');
            //console.log(`batchStartIndex=${batchStartIndex}`);
            //console.log(`batchEndIndex=${batchEndIndex}`);
            //console.log(`datasetEndIndex=${datasetEndIndex}`);
            //console.log('----------------------------------------------------');
            x_reader.close();
            x_reader = null;
            
            console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
            //console.log(`x=${this.state.datasets.active_dataset_ID}`);
            //console.log(`x=${datasets.active_dataset_ID}`);
            console.log(`bool_x_break=${JSON.stringify(bool_x_break)}`);
            console.log(`bool_x_break[${datasetId}]=${JSON.stringify(bool_x_break[datasetId])}`);
            if (bool_x_break[datasetId] && bool_x_break[datasetId] === true) {
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 
                console.log("BREAK BREAK BREAK"); 

                tempImages = null;
                tempImages = [];

                return;
                //return () => {};
            }

            //// bool_x_break = true;


            //console.log(`x=${selectors.getDatasetId(this.state)}`);
            //getDatasetId: (state) => state.datasets.active_dataset_ID,

            console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');

            
            dispatch(receiveImages(tempImages, datasetId));
            tempImages = null;
            tempImages = [];

            if (batchEndIndex !== datasetEndIndex) {
                const new_batchStartIndex = batchEndIndex + 1;
                const new_batchEndIndex = new_batchStartIndex + BATCH_SIZE - 1;

                return oldUnzip(
                    dispatch,
                    datasetId,
                    zipBlob,
                    new_batchStartIndex,
                    new_batchEndIndex,
                    imageData,
                    imageCount,
                );
            } else {
                // reset globals
                tempImages = null;
                tempImages = [];
            }
            return;
        }
    }
  );
};

// API calls

// first call: get list_of_filenames + image_metadata
// second call: get list_of_img_thumbnails
// then, unzip thumbnails in batches of 100
export const getZipFromServer = (userId, datasetId) => {
  print_time('getZipFromServer()');

  //bool_x_break = false;
  bool_x_break[datasetId]=false;

  print_in_red(`[GET_ZIP_FROM_SERVER] userId=${userId} datasetId=${datasetId}`)
  print_in_red(`bool_x_break=${JSON.stringify(bool_x_break)}`)

  const getMetadataUrl = `${GET_DATASET_IMAGE_METADATA_DICT_URL}/${userId}/${datasetId}`;
  const getZipUrl = `${GET_DATASET_THUMBNAILS_ZIP_URL}/${userId}/${datasetId}`;

  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)
  //console.log(`getZipUrl=${getZipUrl}`)

  return function (dispatch) {
    // first call: get list_of_filenames + image_metadata
    axios
      .get(getMetadataUrl)
      .then((response) => {
        print_time('get_dataset_metadata_dict response received');

        // global dataset_metadata_dict so that when images are unzipped,
        // unzip() can access dataset_metadata_dict[filename]
        const imageCount = response.data['N_images'];
        const imageData = response.data['dataset_image_metadata_dict'];

        //console.log("_________________________________")
        //console.log("_________________________________")
        //console.log(`imageData=${JSON.stringify(imageData)}`);
        //console.log("_________________________________")
        //console.log("_________________________________")

        if (imageCount===0) {
            print_in_red("[GET_ZIP_FROM_SERVER] No images found")
            return;
        }
        dispatch(startLoading());

        // second call: get list_of_img_thumbnails
        axios
          .get(getZipUrl, { responseType: 'arraybuffer',
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            },
                          })
          .then((response) => {
            print_time('get_thumbnails_zip response received');
            //console.log(`response.data=${response.data}`);
            //console.log(
            //  `response.headers['content-type']=${response.headers['content-type']}`
            //);

            const zipBlob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            //console.log(`zipBlob=${zipBlob}`);

            dispatch(
              oldUnzip(
                dispatch,
                datasetId,
                zipBlob,
                0,
                BATCH_SIZE-1,
                imageData,
                imageCount,
              )
            );

            // dispatch(
            //   unzipImages(
            //     dispatch,
            //     datasetId,
            //     zipBlob,
            //     imageData,
            //   )
            // );
          })
          .catch((error) => {
            dispatch(handleError(error));
          });
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

/*
export const getImagesFromServer = () => {
  print_time('getImagesFromServer()');

  const userId = 1;
  const datasetId = 1;

  const get_dataset_info_url = `${GET_DATASET_INFO_URL}/${userId}/${datasetId}`;

  return function (dispatch) {
    dispatch(startLoading());

    // first call get list_of_filenames + image_metadata
    axios
      .get(get_dataset_info_url)
      .then((response) => {
        print_time('get_dataset_info response received');

        const filenames = response.data['list_of_filenames'];
        const list_of_image_metadata = response.data['list_of_image_metadata'];

        // create an array of the axios GET IMAGE requests
        let image_requests = [];

        for (let i = 0; i < 100; i++) {
          const filename = filenames[i];
          const image_url = `${LOAD_BINARY_IMAGE_THUMBNAIL_FROM_SERVER_URL}/${userId}/${datasetId}/${filename}`;
          const image_request = axios.get(image_url, {
            responseType: 'arraybuffer',
          });
          image_requests.push(image_request);
        }

        // second call, get binary images
        return axios
          .all(image_requests)
          .then(
            axios.spread((...responses) => {
              print_time('image_requests responses received');

              let images = [];

              for (let i = 0; i < responses.length; i++) {
                const response = responses[i];
                const blob = new Blob([response.data], {
                  type: response.headers['content-type'],
                });
                let image_metadata = list_of_image_metadata[i];

                const image_data = {
                  id: image_metadata.img_ID,
                  path: image_metadata.img_path,
                  labelClass: image_metadata.class_label,
                  labelPolygons: null,
                  blob: URL.createObjectURL(blob),
                  isValidation: null,
                };
                images.push(image_data);
              }

              dispatch(receiveImages(images, datasetId));
            })
          )
          .catch((error) => {
            dispatch(handleError(error));
          });
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};
*/

export const getAllImagePredictions = (userId, networkId) => {
  return function (dispatch) {
    dispatch(startLoading());

    const predictions_url = `${IMAGE_PREDICTION_URL}/${userId}/${networkId}`;

    return axios
      .get(predictions_url)
      .then((response) => {
        //console.log('=================================================');
        //console.log(response);
        //console.log('=================================================');
        dispatch(receiveAllImagePredictions(response));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

export const getListOfDatasets = (userID) => {

  return function (dispatch) {
    dispatch(startLoading());


    const list_of_datasets_url = `${GET_LIST_OF_DATASETS_URL}/${userID}`;
    //console.log(`list_of_datasets_url=${list_of_datasets_url}`)

    return axios
      .get(list_of_datasets_url)
      .then((response) => {

        //console.log('=================================================');
        //console.log(response);
        //console.log('=================================================');
        const list_of_datasets = response.data
        /*
        {
          byId: {
            1: {
              id: 1,
              name: 'Alpha1 Dataset',
              imageCount: 0,
              size: '0 MB',
              lastModified: 1592287421,
              labellingPercentage: 0,
              status: 'Upload images',
              labellingSplit: {
                unlabeled: 0,
                pass: 0,
                fail: 0,
              },
            },
            2: {
              id: 2,
              name: 'Alpha2 Dataset',
              imageCount: 645,
              size: '715 MB',
              lastModified: 1582387421,
              labellingPercentage: 100,
              status: 'Ready to be trained',
              labellingSplit: {
                unlabeled: 0,
                pass: 51,
                fail: 49,
              },
            },
          },
          allIds: [1, 2],
        };
        */
        dispatch(receiveListOfDatasets(list_of_datasets));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};


export const getListOfNetworks = (userID, datasetID) => {
    console.log("AK")


    return function (dispatch) {
        dispatch(startLoading());

        console.log('x10');

        const list_of_networks_url = `${GET_LIST_OF_NETWORKS_URL}/${userID}/${datasetID}`;
        console.log(`list_of_networks_url=${list_of_networks_url}`)

        console.log('x100')

        return axios
            .get(list_of_networks_url)
            .then((response) => {
                console.log('x1000')

                console.log('=================================================');
                console.log(response);
                console.log('=================================================');
                const list_of_networks = response.data
                /*
                    byId: {
                        1: {
                            id: 1,
                            name: 'neural_net_1',
                            trainedWith: 715,
                            testedOn: 1655,
                            accuracy: 98,
                            falsePositives: 2,
                        },
                        2: {
                            id: 2,
                            name: 'neural_net_2',
                            trainedWith: 3440,
                            testedOn: 32132,
                            accuracy: 99,
                            falsePositives: 0,
                        },
                        3: {
                            id: 3,
                            name: 'neural_net_3',
                            trainedWith: 3440,
                            testedOn: 32132,
                            accuracy: 99,
                            falsePositives: 0,
                        },
                    },
                    allIds: [1, 2, 3],
                };
                */
                dispatch(receiveListOfNetworks(list_of_networks));
        })
        .catch((error) => {
            dispatch(handleError(error));
        });
    };
};



export const deleteTrainingImageRedux = (imageId) => {
  console.log(`deleteTrainingImageRedux1 imageId=${imageId}`);

    return function (dispatch) {

        print_in_red(`deleteTrainingImageRedux2 imageId=${imageId}`);

        dispatch(deleteTrainingImage(imageId));
  };

}

export const startTraining = (
  split,
  rate,
  epochs,
  userId,
  datasetId,
  n_classes,
) => {
  return function (dispatch) {
    dispatch(requestTraining());

    const url = `${START_TRAINING_URL}/${userId}/${datasetId}`;

    const params = {
      dataset_split: split,
      learning_rate: rate,
      N_epochs: epochs,
      N_classes: n_classes,
    };

    return axios
      .post(url, params, REQUEST_CONFIG)
      .then((response) => {
        dispatch(receiveTraining(response.data));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

export const postImageLabel = (
  imageId,
  label,
  list_of_labels,
  userId,
  datasetId,
) => {
  return function (dispatch) {
    dispatch(startLoading());

    /*
    // Only save closed polygons
    const validPolygons = {};
    Object.keys(entities).forEach((entityId) => {
      const entity = entities[entityId];
      // A valid polygon:
      // - must be closed
      // - must have at least 3 vertices (to form an area)
      if (entity.isClosed && Object.keys(entity.vertices).length > 2) {
        validPolygons[entityId] = entity;
      }
    });
    */
    const url = `${NEW_IMAGE_LABEL_URL}/${userId}/${datasetId}/${imageId}`;

    var binary_class_label = "NONE";
    var multi_class_label = -1;
    console.log(`LABEL = ${label}`)
    if (label === "PASS" || label === "FAIL" || label === "NONE")
    {
        binary_class_label = label
        multi_class_label = label
        console.log(`binary_class_label = ${label}`)

    }
    else {
        multi_class_label = label
        console.log(`multi_class_label = ${label}`)
    }
    //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    //console.log(`datasets.js: list_of_labels=${JSON.stringify(list_of_labels)}`);
    //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")


    return axios
      .post(
        url,
        {
          binary_class_label: binary_class_label,
          multi_class_label: multi_class_label,
          list_of_label_polygons: list_of_labels,
        },
        REQUEST_CONFIG
      )
      .then((response) => {
        const labelData = {
          imageId,
          labelClass: label,
          labelPolygons: list_of_labels,
        };
        dispatch(receiveImageLabel(labelData));
      })
      .catch((error) => {
        dispatch(handleError(error));
      });
  };
};

// _________________________________________________________________ //
// _________________________________________________________________ //


// State

const initialState = {
  user: {
    id: 1,
  },
  active_dataset_ID: null,
  datasets: {
    byId: {},
    allIds: [],
  },
  images: {
    byId: {},
    allIds: [],
  },
  active_network_ID: null,
  networks: {
    byId: {},
    allIds: [],
  },
  isLoading: false,
};

const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const datasets = produce((draft, action) => {
    //print_in_blue(`REDUX STORE action.type=${action.type}`)

    switch (action.type) {
        case SET_USER_ID:
            print_in_blue(`SET_USER_ID userID=${action.userID}`)
            draft.user.id = action.userID;
            return;
        case START_LOADING:
            draft.isLoading = true;
            return;
        case RECEIVE_NEW_DATASET_OBJECT:
            draft.datasets.allIds.push(action.new_dataset_object.id)
            draft.datasets.byId[action.new_dataset_object.id] = action.new_dataset_object
            draft.active_dataset_ID = action.new_dataset_object.id

            /*
            console.log(`%c  draft.datasets.allIds=${draft.datasets.allIds}:`, 'background: orange; color: white; display: block;');
            console.log(`%c  draft.datasets.byId=${draft.datasets.byId}:`, 'background: orange; color: white; display: block;');
            for (var dataset_ID_index in draft.datasets.byId) {
                console.log(`%c  ${dataset_ID_index}: ${draft.datasets.byId[dataset_ID_index]}`, 'background: blue; color: white; display: block;');
                var dataset_object =  draft.datasets.byId[dataset_ID_index]
                for (var key in dataset_object) {
                    console.log(`%c  ${key}: ${dataset_object[key]}`, 'background: red; color: white; display: block;');
                }
            }
            */
            draft.images.byId = {};
            draft.images.allIds = [];
            draft.isLoading = false;
            return;
        case CLEAR_ACTIVE_DATASET_ID:
            print_in_blue("[CLEAR_ACTIVE_DATASET_ID]")
            print_in_blue(`draft.active_dataset_ID=${draft.active_dataset_ID}`)
            bool_x_break[draft.active_dataset_ID]=true;
            print_in_blue(`bool_x_break=${JSON.stringify(bool_x_break)}`)

            draft.active_dataset_ID = null;
            draft.images.byId = {};
            draft.images.allIds = [];
            draft.isLoading = false;
            return;
        case RECEIVE_LIST_OF_DATASETS:
            print_in_blue("[RECEIVE_LIST_OF_DATASETS]")
            //console.log(action.list_of_datasets)
            draft.datasets = action.list_of_datasets;
            draft.isLoading = false;
            return;
        case RECEIVE_LIST_OF_NETWORKS:
            print_in_blue("[RECEIVE_LIST_OF_NETWORKS]")
            draft.networks = action.list_of_networks;
            draft.isLoading = false;
            return;
        case RECEIVE_IMAGES:
            //print_in_blue('[RECEIVE_IMAGES]')

            draft.active_dataset_ID = action.datasetId

            action.images.forEach((image) => {
                if (draft.images.byId.hasOwnProperty(image.id)) {
                    print_in_red('[RECEIVE_IMAGES] IMAGE_EXISTS')
                    return;
                }
                draft.images.byId[image.id] = image;
                draft.images.allIds.push(image.id);
                //const dataset = draft.datasets.byId[action.datasetId];
                //if (dataset.imageIds.indexOf(image.id) === -1) {
                //  dataset.imageIds.push(image.id);
                //}
            });
            /*
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            console.log(`SORT SORT SORT SORT SORT`);
            

            draft.images.allIds.sort(function(id_a, id_b) {
                var a = draft.images.byId[id_a]
                var b = draft.images.byId[id_b]
                //console.log(`_____________________`);
                //console.log(`a=${JSON.stringify(a)}`);
                //console.log(`b=${JSON.stringify(b)}`);
                if (a.labelClass===b.labelClass) return 0;
                if (a.labelClass==='NONE') return -1;
                if (b.labelClass==='NONE') return 1;
                if (a.labelClass==='PASS') return -1;
                if (b.labelClass==='PASS') return 1;

                if (a.labelClass < b.labelClass) return -1;
                if (b.labelClass > a.labelClass) return 1;

                console.log("unknown labelClass sorting images in redux/reducers/datasets getDatasetImagesSorted")
                return 0;
            });
            */
            draft.isLoading = false;
            return;

        case RECEIVE_IMAGE_LABEL:
          draft.isLoading = false;
          const labelImage = draft.images.byId[action.imageId]
          labelImage.labelClass = action.labelClass;
          labelImage.labelPolygons = action.labelPolygons;

          //console.log(`labelClass=${labelImage.labelClass}`);
          //console.log(`labelPolygons=${JSON.stringify(labelImage.labelPolygons)}`);

          return;

        case REQUEST_TRAINING:
            draft.isLoading = true;
            draft.isValidTraining = false;
            return;

        case DELETE_TRAINING_IMAGE:
            //console.log(`imageId to delete =${action.imageId}`);
            //console.log(`draft.images.allIds.length1=${draft.images.allIds.length}`);
            draft.images.allIds = draft.images.allIds.filter((item)=>{
                return item !== action.imageId
            })
            //console.log(`draft.images.allIds.length2=${draft.images.allIds.length}`);
            //console.log(`draft.images.byId1=${JSON.stringify(draft.images.byId)}`);
            delete draft.images.byId[action.imageId]
            //console.log(`draft.images.byId1=${JSON.stringify(draft.images.byId)}`);
            return;

        case RECEIVE_TRAINING:
            draft.isLoading = false;
            draft.isValidTraining = true;
            draft.active_network_ID = action.networkId
            return;
        case STOP_TRAINING:
            draft.isLoading = false;
            draft.isValidTraining = false;
            return;
        case RECEIVE_ALL_IMAGE_PREDICTIONS:
            draft.isLoading = false;
            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
            console.log(action.response.data);

            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
            //console.log(draft.imagesList)
            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
            //console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');

            print_in_red(`draft.active_dataset_ID=${draft.active_dataset_ID}`)
            const x = typeof (draft.active_dataset_ID)
            print_in_red(`typeof draft.active_dataset_ID=${x}`)
            print_in_red(`draft.active_dataset_ID=${draft.datasets.byId[draft.active_dataset_ID]}`)
            //print_in_blue(JSON.stringify(draft.datasets.byId[draft.active_dataset_ID], null, 4));

            var bool_multi_class = draft.datasets.byId[draft.active_dataset_ID].bool_multi_class
            var bool_detection = draft.datasets.byId[draft.active_dataset_ID].bool_detection
            print_in_red(`bool_multi_class=${bool_multi_class}`)

            const y = typeof (bool_multi_class)
            print_in_blue(`typeof bool_multi_class=${y}`)

            const validation_predictions = action.response.data;
            //print_in_blue(`action.response.data=${action.response.data}`);

            ////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////
            draft.images.allIds.forEach((image_id) => {
                //let image = draft.images.byId[image_id];
                //image.isValidation = false;
                //draft.images.byId[image_id] = image;
                draft.images.byId[image_id].isValidation = false;

                if (bool_detection === true) {
                    // this line is probably not needed, as only validation images should reference predictionPolygons
                    draft.images.byId[image_id].predictionPolygons=[]
                }
            });

            ////////////////////////////////////////////////////////////////
            ////////////////////////////////////////////////////////////////


            for (var i = 0; i < validation_predictions.length; i++) {

                //console.log(`validation image i=${i}`);
                const validation_prediction = validation_predictions[i];
                const validation_img_ID = validation_prediction.img_ID;
                const class_prediction  = validation_prediction.class_prediction;
                const validation_prediction_confidence = validation_prediction.validation_prediction_confidence;

                //const validationImage = draft.images.byId.find(
                //    (image) => image.imageId === validation_img_ID
                //);
                //console.log(`draft.images=${draft.images}`)
                //print_in_blue(JSON.stringify(draft.images, null, 4));
                //console.log(`validation_img_ID=${validation_img_ID}`)

                const validationImage = draft.images.byId[validation_img_ID]

                validationImage.isValidation = true;

                if (bool_detection === true) {
                    validationImage.predictionPolygons = validation_prediction.list_of_prediction_polygons;
                }

                if (bool_multi_class===true && bool_detection===false)
                {
                    validationImage.predictionClass = class_prediction;
                }
                else
                {
                    if (class_prediction === 1) {
                        //console.log('PASS');
                        validationImage.predictionClass = 'PASS';
                    } else {
                        //console.log('FAIL');
                        validationImage.predictionClass = 'FAIL';
                    }
                }




            }


            return;
        case HANDLE_ERROR:
            draft.isLoading = false;
            draft.isValidTraining = false;
            return draft;
        default:
            draft.isLoading = false;
            draft.isValidTraining = false;
            return draft;
    }
}, initialState);

// Selectors

export const selectors = {
  getUserId: (state) => state.datasets.user.id,
  getDatasetId: (state) => state.datasets.active_dataset_ID,
  getNetworkId: (state) => state.datasets.active_network_ID,

  getDatasetsList: (state) =>
    state.datasets.datasets.allIds.map(
      (datasetId) => state.datasets.datasets.byId[datasetId]
    ),
    getDatasetsObject: (state) => state.datasets.datasets,

  /*
  getFirstTenImageIDs: (state, dataset_ID) => {
    dataset_object = state.datasets.datasets.byId[dataset_ID]
    return dataset_object.firstTenImageIDs
  }
  */

  getDataset: (state, datasetId) => state.datasets.datasets.byId[datasetId],
  getDatasetImages: (state) => {

    print_time(`getDatasetImages1`);
    const list_of_images = state.datasets.images.allIds.map(
      (imageId) => state.datasets.images.byId[imageId]
    );
    print_time(`getDatasetImages2`);

    //for (var key in list_of_images[0]) {
    //    console.log(`%c  ${key}: ${list_of_images[0][key]}`, 'background: orange; color: white; display: block;');
    //}

    return list_of_images;

  },
  /*
  getDatasetImagesSorted: (state) => {
    const list_of_images = state.datasets.images.allIds.map(
      (imageId) => state.datasets.images.byId[imageId]
    );

    //for (var key in list_of_images[0]) {
    //    console.log(`%c  ${key}: ${list_of_images[0][key]}`, 'background: orange; color: white; display: block;');
    //}
    //console.log(`list_of_images=${JSON.stringify(list_of_images)}`);
    console.log(`getDatsetImages SORTED`);

            list_of_images.sort(function(id_a, id_b) {
                var a = state.datasets.images.byId[id_a.id]
                var b = state.datasets.images.byId[id_b.id]
                //console.log(`_____________________`);
                //console.log(`a=${JSON.stringify(a)}`);
                //console.log(`b=${JSON.stringify(b)}`);
                if (a.labelClass===b.labelClass) return 0;
                if (a.labelClass==='NONE') return -1;
                if (b.labelClass==='NONE') return 1;
                if (a.labelClass==='PASS') return -1;
                if (b.labelClass==='PASS') return 1;

                if (a.labelClass < b.labelClass) return -1;
                if (b.labelClass > a.labelClass) return 1;

                console.log("unknown labelClass sorting images in redux/reducers/datasets getDatasetImagesSorted")
                return 0;
            });


    return list_of_images;

  },
  */
  getNetworksList: (state, datasetId) => {
    return state.datasets.networks.allIds.map(
      (network_ID) => state.datasets.networks.byId[network_ID]
    );
  },
    //if (!datasetId) {
    //  return [];
    //}
    //return state.datasets.datasets.byId[datasetId].networkIds.map(
    //  (networkId) => state.datasets.networks.byId[networkId]
    //);
  getNetwork: (state, networkId) => state.datasets.networks.byId[networkId],
  isLoading: (state) => state.datasets.isLoading,
  isValidTraining: (state) => state.datasets.isValidTraining,
};

export default datasets;

