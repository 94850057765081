// Utilities

export const alphaColor = (color, alpha) => {
  return color.replace(/1\)/, `.${alpha})`);
};

export const getUniqueKey = () => {
  let uniqueKey = new Date().getTime().toString(36);
  return uniqueKey;
};

export const Vertex = (state) => {
  return Object.assign(state);
};

export const PolygonData = ({ id, name, isClosed = false, vertices = [] }) => {
  const state = { id, name, isClosed, vertices };
  return Object.assign(state);
};

export const PolygonIsClosed = (annotation) => {
  return annotation.isClosed;
};

// Classes

export class UndoRedo {
  constructor(state) {
    this.previous = [];
    this.next = [];
  }

  save = (state) => {
    const clonedState = JSON.parse(JSON.stringify(state));
    this.previous.push(clonedState);
    this.next = [];
  };

  undo = (state) => {
    this.next.push(state);
    return this.previous.pop();
  };

  redo = (state) => {
    this.previous.push(state);
    return this.next.pop();
  };
}

// Constants

export const COLORS = {
  red: 'rgba(255, 36, 80, 1)',
  orange: 'rgba(255, 109, 36, 1)',
  purple: 'rgba(146, 128, 255, 1)',
};

/*
const KEYS = {
  ArrowLeft: { key: 'ArrowLeft', code: 37 },
  ArrowRight: { key: 'ArrowRight', code: 39 },
  ArrowUp: { key: 'ArrowUp', code: 38 },
  ArrowDown: { key: 'ArrowDown', code: 40 },
  Enter: { key: 'Enter', code: 13 },
  z: { key: 'z', code: 90 },
  Backspace: { key: 'Backspace', code: 8 },
  y: { key: 'y', code: 89 },
  x: { key: 'x', code: 88 },
  Delete: { key: 'Delete', code: 46 },
};

export const SHORTCUTS = {
  BUTTON: {
    PREVIOUS: KEYS['ArrowLeft'],
    NEXT: KEYS['ArrowRight'],
    PASS: KEYS['ArrowUp'],
    FAIL: KEYS['ArrowDown'],
    SUBMIT: KEYS['Enter'],
  },
  HISTORY: {
    UNDO: KEYS['z'],
    UNDO_BACKSPACE: KEYS['Backspace'],
    REDO: KEYS['y'],
    REDO_X: KEYS['x'],
    DELETE_LABEL: KEYS['Delete'],
  },
  REVIEW: {
    TOGGLE_LABEL: KEYS['ArrowUp'],
    TOGGLE_PREDICTION: KEYS['ArrowDown'],
  },
};
*/

const KEYS = {
  ArrowLeft: { key: 'ArrowLeft', code: 37 },
  ArrowRight: { key: 'ArrowRight', code: 39 },
  ArrowUp: { key: 'ArrowUp', code: 38 },
  ArrowDown: { key: 'ArrowDown', code: 40 },
  //Enter: { key: 'Enter', code: 13 },
  //z: { key: 'z', code: 90 },
  Backspace: { key: 'Backspace', code: 8 },
  //y: { key: 'y', code: 89 },
  //x: { key: 'x', code: 88 },
  Delete: { key: 'Delete', code: 46 },
};


export const SHORTCUTS = {
  BUTTON: {
    PREVIOUS: KEYS['ArrowLeft'],
    NEXT: KEYS['ArrowRight'],
    PASS: KEYS['ArrowUp'],
    FAIL: KEYS['ArrowDown'],
    //SUBMIT: KEYS['Enter'],
  },
  HISTORY: {
    //UNDO: KEYS['z'],
    BACKSPACE_LABEL: KEYS['Backspace'],
    //REDO: KEYS['y'],
    //REDO_X: KEYS['x'],
    DELETE_LABEL: KEYS['Delete'],
  },
  REVIEW: {
    TOGGLE_LABEL: KEYS['ArrowUp'],
    TOGGLE_PREDICTION: KEYS['ArrowDown'],
  },
};



export const IMAGE_RATIO = 1.33;
