import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';

import BrowseHistory from 'pages/camera/BrowseHistory';

import { BROWSE_URL,
         GET_IMAGES_ZIP_URL,
         GET_IMAGE_HISTORY_JOBS_URL,
         GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL } from 'pages/urls';

import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './User.module.sass';

import axios from 'axios';


import UserPage from 'components/camera/UserPage';


class History extends Component {
  constructor(props) {
    super(props);

    this.state = {
        tmp: 0,
    };


  }

  ////////////////////////////////////////////////////////////////////////////////


  render() {


      return (
        <UserPage />
      );
    }

}

export default History;

