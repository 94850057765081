import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';

import { Redirect } from 'react-router-dom';

import { useParams } from 'react-router-dom';


//import BrowseHistory from 'pages/camera/BrowseHistory';

import { //BROWSE_URL,
         //GET_IMAGES_ZIP_URL,
         //GET_IMAGE_HISTORY_JOBS_URL,
         //GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL,
         GET_JOB_NAME_URL,
         getHistoryMonthsURL,
         //getHistoryBrowseURL,
         getHistoryImageSetsURL,
} from 'pages/urls';

//import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './History.module.sass';

import axios from 'axios';

import Line from 'pages/camera/Line';

import HistoryBreadcrumb from 'components/navigation/HistoryBreadcrumb';


//import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';



// searchType one of: "JOB", "MONTH", "DATE"
//
// History displays:
//   Job [0-8]
//     Month <Month>_<yyyy>
//       Date <Month>_<dd>_<yyyy>
//


const HistoryDates = (props) => {
  const { job_slot, month_str } = useParams();

    console.log(`ZLOG useParams=${JSON.stringify(useParams())}`);
    return ( <HistoryDatesX job_slot={job_slot} month_str={month_str} / >);

};



class HistoryDatesX extends Component {
  constructor(props) {
    super(props);


   const { job_slot, month_str } = this.props;


    this.state = {
        response_dict: null,
        date_lines: null,
        searchType: "JOB",
        jobSlotSelected: job_slot,
        monthSelected: month_str,
    };

    this.getJobName(job_slot);
    this.getDates(month_str);

  }

  ////////////////////////////////////////////////////////////////////////////////

  getJobName = (jobSlotSelected) => {
    axios
    .get(GET_JOB_NAME_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');

        this.setState({
            job_name: response.data.job_name,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get job_name")
    });
  }


  ////////////////////////////////////////////////////////////////////////////////

  getDates = (month_str) => {
    const job_slot_str = this.state.jobSlotSelected.toString();

    axios
    .get(GET_IMAGE_HISTORY_DATES_URL + '/' + job_slot_str + '/' + month_str)

    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');

        const lines = [];

        let date_objects = response.data;

        //console.log(`${JSON.stringify(date_strings)}`, 'background: red; color: white; display: block;')
        //date_strings = date_strings.sort().reverse();
        //console.log(`${JSON.stringify(date_strings)}`, 'background: red; color: white; display: block;')


        for (let i = 0; i < date_objects.length; i++) {
          let current_date = date_objects[i]

          //console.log(`current_month=${current_month}`)

          lines.push(
            <Line
              key={i}
              jobSlot={job_slot_str}
              month={month_str}
              date={current_date.date_str}
              lineType={"DATE"}
              onClickRow={this.onClickDate}
              isActiveX={current_date.bool_is_active}
            />
          );
        }

        this.setState({
            searchType: "DATE",
            response_dict: response.data,
            date_lines: lines,
            monthSelected: month_str,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get list_of_dates")
    });
  }

  ////////////////////////////////////////////////////////////////////////////////


  onClickDate = (date) => {
    console.log("onClickDate");

    const date_str = date.split(' ').join('_');
    console.log(`date_str=${date_str}`);

    this.setState({
        searchType: "DATE_SELECTED",
        //lines: null,
        dateSelected: date_str,
    });
  }


  render() {



    if (this.state.searchType === "DATE_SELECTED") {

      const ImageSetsURL = getHistoryImageSetsURL(this.state.jobSlotSelected, this.state.monthSelected, this.state.dateSelected);

      console.log(`AA ImageSetsURL=${ImageSetsURL}`);

      return (
                 <Redirect to={ImageSetsURL}/>

             );
    }



    if (this.state.searchType === "MONTH") {
        return (
                 <Redirect to={getHistoryMonthsURL(this.state.jobSlotSelected)} />
	       );
    }


    if (this.state.response_dict === null)
    {    return (<div / >);    }


    if (this.state.searchType === "DATE") {
      return [
        <div className={s.component} key="component">
          <header className={s.header}>
            <div className={s.backlink}>

              <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                console.log("BACK2!");
                this.setState({
                               searchType: "MONTH",
                              });
                }}>

                Select Month
              </BackLink>
            </div>
            <p className={s.title}> <span className={s.liteweight}>Browse History: Select Date</span></p>
          </header>
          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.month)}>Date</p>
              {/* <p className={cx(s.cell, s.action)}>Action</p> */}
            </div>
          </div>
          {this.state.date_lines}
        </div>,
       <HistoryBreadcrumb key="breadcrumb" job_slot={this.state.jobSlotSelected} job_name={this.state.job_name} month_str={this.state.monthSelected}/>,
      ];
    }

    else {
      return (<div / >);
    }

  }
}

export default HistoryDates;


