// IOSettings.js

import React, { useState, useEffect } from 'react';

import Title from 'components/elements/Title';
import Toggles from 'components/form/Toggles';
import IPAddress from 'components/form/IPAddress';
import Input from 'components/form/Input';

import Radios from 'components/form/Radios';
import RadioTrigger from 'components/form/RadioTrigger';

import Request from 'axios-request-handler';

import Slider from 'components/form/Slider';
import Content from 'components/elements/Content';

import Button from 'components/elements/Button';
import Tag from 'components/elements/Tag';

import cx from 'classnames';

import Modal from 'components/elements/Modal';

import axios from 'axios';

//import Switch from "react-switch";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input2 from 'components/form/Input2';


import s from './IOSettings.module.sass';

import {
  GET_CONNECTED_DEVICES_URL,
  GET_IP_ADDRESS_AND_NETMASK_URL,
  SET_IP_ADDRESS_AND_NETMASK_URL,

  GET_IO_DEVICE_TYPE_URL,
  SET_IO_DEVICE_TYPE_URL,

  GET_PLC_IP_ADDRESS_URL,
  SET_PLC_IP_ADDRESS_URL,

  GET_INPUT_TAG_OF_TRIGGER_URL,
  SET_INPUT_TAG_OF_TRIGGER_URL,

  GET_OUTPUT_TAG_OF_PASS_SIGNAL_URL,
  SET_OUTPUT_TAG_OF_PASS_SIGNAL_URL,

  GET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL,
  SET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL,

  SUBSCRIBE_IO_NOTIFICATIONS_URL,
  UNSUBSCRIBE_IO_NOTIFICATIONS_URL,

  GET_BOOL_STREAM_IO_NOTIFICATIONS_URL,
  SET_BOOL_STREAM_IO_NOTIFICATIONS_URL,

  GET_AUTO_TRIGGER_INTERVAL_URL,
  SET_AUTO_TRIGGER_INTERVAL_URL,

  GET_IO_DEVICE_IP_ADDRESS_URL,
  SET_IO_DEVICE_IP_ADDRESS_URL,
  INIT_IO_DEVICE_CONNECTION_URL,
  GET_IO_DEVICE_CONNECTON_STATUS_URL,

  GET_BOOL_PRODUCTION_MODE_URL,

  GET_GATEWAY_URL,
  SET_GATEWAY_URL,

  GET_TAG_NAMES_URL,
  SET_TAG_NAME_URL,

  GET_BOOL_USE_PLC_TIME_URL,
  SET_BOOL_USE_PLC_TIME_URL,

} from 'pages/urls';


const BOOL_USE_PLC_TIME_OPTIONS = [
  { value: false, label: 'Camera Time' },
  { value: true, label: 'PLC Time' },
];




const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}


const AUTO_TRIGGER_INTERVAL_MARKS = {
  0: '0ms',
  5000: '5000ms',
};



const UPDATE_IP_ADDRESS_OPTIONS = [
  { value: 'current', label: 'current' },
  { value: 'update', label: 'update' },
];


const TRIGGER_OPTIONS = [
  { value: 'manual', label: 'Manual' },
  { value: 'plc', label: 'PLC' },
];

const DEVICE_TYPE_OPTIONS = [
 /* { value: 'io_block', label: 'IO Block' }*/,
  { value: 'ethernet_ip', label: 'ControlLogix / CompactLogix' },
  { value: 'odva_ethernet_ip', label: 'ODVA EtherNet/IP' },
 /* { value: 'profinet', label: 'PROFINET (Siemens PLC)' }, */
  { value: '24v_discrete', label: '24V I/O' },
  { value: 'offline', label: 'OFFLINE (Manual Trigger)' },


];

const DEVICE_TRIGGER_OPTIONS = [
  { value: true, label: 'Trigger' },
  { value: false, label: 'Trigger Not Set' },
  { value: null, label: 'N/A' },
];

const DEVICE_READY_OPTIONS = [
  { value: 'ready', label: 'Camera Ready' },
  { value: 'busy', label: 'Camera Not Ready (Busy)' },
];

const DEVICE_PASS_OUTPUT_OPTIONS = [
  { value: 'pass', label: 'Pass' },
  { value: 'reset_pass', label: 'Pass Not Set' },
];

const DEVICE_FAIL_OUTPUT_OPTIONS = [
  { value: 'fail', label: 'Fail' },
  { value: 'reset_fail', label: 'Fail Not Set' },
];


const SET_PASS_OPTIONS = [
  { value: 'pass', label: 'SET Pass ON' },
  { value: 'reset_pass', label: 'SET Pass OFF' },
  { value: 'na_pass', label: 'N/A' },
];

const SET_FAIL_OPTIONS = [
  { value: 'fail', label: 'SET Fail ON' },
  { value: 'reset_fail', label: 'SET Fail OFF' },
  { value: 'na_fail', label: 'N/A' },

];



const DEVICE_INDETERMINATE_OUTPUT_OPTIONS = [
  { value: 'indeterminate', label: 'Indeterminate' },
  { value: 'reset_indeterminate', label: 'Indeterminate Not Set' },
];

const LIGHT_TRIGGER_OPTIONS = [
  { value: 'trigger', label: 'Trigger' },
];


var DEVICES = [];



const IOSettings = (props) => {
  const [triggerMode, setTriggerMode] = useState();
  const [staticIP, setStaticIP] = useState();
  const [subnetMask, setSubnetMask] = useState();
  const [gateway, setGateway] = useState();
  const [device_type, setDeviceType] = useState();
  const [onstart, setOnstart] = useState(true);
  const [bool_show_update_ip_address_modal, showUpdateIPaddressModal] = useState(false);
  const [bool_show_update_PLC_ip_address_modal, show_update_PLC_ip_address_modal] = useState(false);
  //const [bool_show_monitor_io_signals_modal, show_monitor_io_signals_modal] = useState(false);


  const [bool_update_ip_address_submit, updateIPaddressSubmit] = useState(false);
  const [bool_update_ip_address_error, errorUpdateIPaddress] = useState(false);

  const [PLC_ip_address, set_PLC_ip_address] = useState("...");
  const [bool_use_PLC_time, set_bool_use_PLC_time] = useState(true);

  const [trigger_input_tag_name, set_trigger_input_tag_name] = useState("");
  const [pass_signal_output_tag_name, set_pass_signal_output_tag_name] = useState("");
  const [fail_signal_output_tag_name, set_fail_signal_output_tag_name] = useState("");

  // TODO: this should be set to false by default
  const [bool_IO_device_connected, set_bool_IO_device_connected] = useState(true);

  const [bool_trigger_set, set_bool_trigger_set] = useState(null);
  const [bool_busy_set, set_bool_busy_set] = useState(null);
  const [bool_pass_set, set_bool_pass_set] = useState(null);
  const [bool_fail_set, set_bool_fail_set] = useState(null);
  const [bool_heartbeat_set, set_bool_heartbeat_set] = useState(null);

  const [autoTriggerInterval, setAutoTriggerInterval] = useState(0);

  const [bool_production_mode, set_bool_production_mode] = useState(null);

  const [bool_show_PLC_tags_modal, set_bool_show_PLC_tags_modal] = useState(false);

  /*
  const [bool_trigger_tag_enabled, set_bool_trigger_tag_enabled] = useState(true);
  const [bool_results_ready_tag_enabled, set_bool_results_ready_tag_enabled] = useState(true);
  const [bool_pass_tag_enabled, set_bool_pass_tag_enabled] = useState(true);
  const [bool_fail_tag_enabled, set_bool_fail_tag_enabled] = useState(true);

  const [bool_class_prediction_tag_enabled, set_bool_class_prediction_tag_enabled] = useState(false);
  const [bool_active_job_tag_enabled, set_bool_active_job_tag_enabled] = useState(false);
  const [bool_job_change_tag_enabled, set_bool_job_change_tag_enabled] = useState(false);

  const [bool_trigger_ack_tag_enabled, set_bool_trigger_ack_tag_enabled] = useState(false);
  const [bool_clear_results_tag_enabled, set_bool_clear_results_tag_enabled] = useState(false);
  */

  const [bool_editing_tag_name, set_bool_editing_tag_name] = useState(false);
  const [tag_name_editing, set_tag_name_editing] = useState(-1);

  //const [trigger_tag_name, set_trigger_tag_name] = useState("TRIGGER");
  //const [results_ready_tag_name, set_results_ready_tag_name] = useState("RESULTS_READY");
  //const [pass_tag_name, set_pass_tag_name] = useState("PASS");
  //const [fail_tag_name, set_fail_tag_name] = useState("FAIL");
  //const [class_prediction_tag_name, set_class_prediction_tag_name] = useState("CLASS_PREDICTION");
  //const [clear_results_tag_name, set_clear_results_tag_name] = useState("CLEAR_RESULTS");
  //const [active_job_tag_name, set_active_job_tag_name] = useState("ACTIVE_JOB");
  //const [job_change_tag_name, set_job_change_tag_name] = useState("JOB_CHANGE");
  //const [trigger_ack_tag_name, set_trigger_ack_tag_name] = useState("TRIGGER_ACK");

  const [trigger_tag_name, set_trigger_tag_name] = useState("");
  const [results_ready_tag_name, set_results_ready_tag_name] = useState("");
  const [pass_tag_name, set_pass_tag_name] = useState("");
  const [fail_tag_name, set_fail_tag_name] = useState("");

  const [class_prediction_tag_name, set_class_prediction_tag_name] = useState("");
  const [class_prediction_string_tag_name, set_class_prediction_string_tag_name] = useState("");
  const [clear_results_tag_name, set_clear_results_tag_name] = useState("");
  const [active_job_tag_name, set_active_job_tag_name] = useState("");
  const [job_change_tag_name, set_job_change_tag_name]  = useState("");
  const [trigger_ack_tag_name, set_trigger_ack_tag_name] = useState("");

  const [focus_tag_name, set_focus_tag_name] = useState("");
  const [active_focus_tag_name, set_active_focus_tag_name] = useState("");

  const [exposure_tag_name, set_exposure_tag_name] = useState("");
  const [active_exposure_tag_name, set_active_exposure_tag_name] = useState("");

  const [heartbeat_tag_name, set_heartbeat_tag_name] = useState("");



  if (onstart === true)
  {
      console.log(`process.env=${JSON.stringify(process.env)}`);
      console.log(`window.$$env=${JSON.stringify(window.$$env)}`);
      console.log(`GET_CONNECTED_DEVICES_URL=${GET_CONNECTED_DEVICES_URL}`);
      console.log(GET_CONNECTED_DEVICES_URL);
      console.log(typeof GET_CONNECTED_DEVICES_URL);

      console.log("onstart");

      ///////////////////////////////////////////////////////


  // Get bool_production_mode

    axios
    .get(GET_BOOL_PRODUCTION_MODE_URL)
    .then((response) => {
      console.log('RECV BOOL_PRODUCTION_MODE');
      console.log(response.data);
      console.log(response.data.bool_production_mode);
      console.log('RECV BOOL_PRODUCTION_MODE');

      set_bool_production_mode(response.data.bool_production_mode);

   })
   .catch((error) => {
       console.log("[ERROR] get bool_production_mode")
   });


      ///////////////////////////////////////////////////////////

      // Get auto trigger interval

          axios
          .get(`${GET_AUTO_TRIGGER_INTERVAL_URL}`)
          .then((response) => {
              console.log('_________________');
              console.log(response.data);
              console.log(response.data.auto_trigger_interval_milliseconds);
              console.log('_________________');
              setAutoTriggerInterval(response.data.auto_trigger_interval_milliseconds);
          })
          .catch((error) => {
              console.log("[ERROR] get auto trigger interval")
          });

      ///////////////////////////////////////////////////////////

      // Get connected devices

      axios.get(GET_CONNECTED_DEVICES_URL)
      .then((response) => {
          console.log("RECVD CONNECTED DEVICES");
          console.log(response.data)
          console.log("RECVD CONNECTED DEVICES");

          DEVICES = response.data;

            DEVICES.map((device) => {
               console.log(device)
               console.log(device.IP)
               console.log(device.MAC)
            });
          //const [devicesX, setDevicesX] = useState(DEVICES);
          setOnstart(false);

      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      // Get bool_PLC_trigger
      /*
      axios.get(GET_BOOL_PLC_TRIGGER_URL)
      .then((response) => {
          console.log("RECVD BOOL PLC TRIGGER");
          console.log(response.data)
          console.log(response.data['bool_PLC_trigger'])
          console.log(response.data.bool_PLC_trigger)
          console.log("RECVD BOOL PLC TRIGGER");

          if (response.data.bool_PLC_trigger === true) {
              setTriggerMode('plc');
          }
          if (response.data.bool_PLC_trigger === false) {
              setTriggerMode('manual');
          }

      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });
      */
      ///////////////////////////////////////////////////////////

      // Get device_type

      axios.get(GET_IO_DEVICE_TYPE_URL)
      .then((response) => {
          console.log("RECVD IO DEVICE TYPE");
          console.log(response.data)
          console.log("RECVD IO DEVICE TYPE");

          if (response.data.device_type === 0) {
              setDeviceType('offline');
          }
          if (response.data.device_type === 4) {
              setDeviceType('24v_discrete');
          }
          if (response.data.device_type === 2) {
              setDeviceType('ethernet_ip');
          }
          if (response.data.device_type === 1) {
              setDeviceType('odva_ethernet_ip');
          }
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      // Get ip_address and netmask

      axios.get(GET_IP_ADDRESS_AND_NETMASK_URL)
      .then((response) => {
          console.log("RECVD IP ADDRESS");
          console.log(response.data)
          console.log(response.data.ip_address)
          console.log(response.data.netmask)
          console.log("RECVD IP ADDRESS");

          setStaticIP(response.data.ip_address)
          setSubnetMask(response.data.netmask)
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      // Get gateway

      axios.get(GET_GATEWAY_URL)
      .then((response) => {
          console.log("RECVD GATEWAY");
          console.log(response.data)
          console.log(response.data.gateway)
          console.log("RECVD GATEWAY");

          setGateway(response.data.gateway)
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      // Get tag names

      axios.get(GET_TAG_NAMES_URL)
      .then((response) => {
          console.log("RECVD TAG NAMES");

          set_trigger_tag_name(response.data.trigger_tag_name)
          set_results_ready_tag_name(response.data.results_ready_tag_name)
          set_pass_tag_name(response.data.pass_tag_name)
          set_fail_tag_name(response.data.fail_tag_name)

          set_class_prediction_tag_name(response.data.class_prediction_tag_name)
          set_class_prediction_string_tag_name(response.data.class_prediction_string_tag_name)
          set_clear_results_tag_name(response.data.clear_results_tag_name)
          set_active_job_tag_name(response.data.active_job_tag_name)
          set_job_change_tag_name(response.data.job_change_tag_name)
          set_trigger_ack_tag_name(response.data.trigger_ack_tag_name)

          set_focus_tag_name(response.data.focus_tag_name)
          set_active_focus_tag_name(response.data.active_focus_tag_name)

          set_exposure_tag_name(response.data.exposure_tag_name)
          set_active_exposure_tag_name(response.data.active_exposure_tag_name)

          set_heartbeat_tag_name(response.data.heartbeat_tag_name)

      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      /*

      // set bool_stream_io_notifications true

      axios
      .get(`${SET_BOOL_STREAM_IO_NOTIFICATIONS_URL}/true`)
      .then((response) => {
          console.log('_________________');
          console.log(response);
          console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool_stream_io_notifications")
      });

     */

    ///////////////////////////////////////////////////////////

    // Get PLC ip address

                axios.get(GET_PLC_IP_ADDRESS_URL)
                .then((response) => {
                    console.log("RECVD PLC IP ADDRESS");
                    console.log(response.data)
                    console.log(response.data.device_ip_address)
                    console.log("RECVD PLC IP ADDRESS");

                    set_PLC_ip_address(response.data.device_ip_address)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

    ///////////////////////////////////////////////////////////

    // Get bool use PLC time

                axios.get(GET_BOOL_USE_PLC_TIME_URL)
                .then((response) => {
                    console.log("RECVD BOOL USE PLC TIME");
                    console.log(response.data)
                    console.log(response.data.bool_use_PLC_time)
                    console.log("RECVD BOOL USE PLC TIME");

                    set_bool_use_PLC_time(response.data.bool_use_PLC_time)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

    ///////////////////////////////////////////////////////////



  }

// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!



  /////////////////////////////////////////////////////////////


  useEffect(() => {

    // returned function will be called on component unmount
    return () => {

      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");

      const unsubscribe_url = (UNSUBSCRIBE_IO_NOTIFICATIONS_URL);

      ////////////////////////////////////////////////////////////////////////////

      axios.get(unsubscribe_url)
      .then((response) => {
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      });


      ////////////////////////////////////////////////////////////////////////////

      /*
      // set bool_stream_io_notifications false

      axios
      .get(`${SET_BOOL_STREAM_IO_NOTIFICATIONS_URL}/false`)
      .then((response) => {
          console.log('_________________');
          console.log(response);
          console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool_stream_io_notifications")
      });
      */

      ////////////////////////////////////////////////////////////////////////////


    }
  }, [])

  /////////////////////////////////////////////////////////////



// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  if (bool_production_mode===true) {
    return (
                 <div className={s.production_mode_error_message}>
                   IO Settings not available in production mode
                 </div>

    );
  }




  if (bool_show_update_ip_address_modal === true) {

    return (
      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          // ________________________________________________________ //
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          showUpdateIPaddressModal(false);
          // ________________________________________________________ //
        }}
      >

       {/* //////////////////////////////////////////////////////////////////////// */}

          <div className={s.modal_component}>
            <form className={s.form}>
              <Title>Edit Camera IP address</Title>

              { (bool_update_ip_address_submit && bool_update_ip_address_error) &&
                 <div className={s.offline_error_message}>
                   Error: please enter valid IP address
                 </div>
              }

              <br />


        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Static IP
          </Title>

          <IPAddress
            defaultValue={staticIP}
            onChange={(value) => {
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              setStaticIP(value)
            }}
          />
        </div>

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Subnet
          </Title>

          <IPAddress
            defaultValue={subnetMask}
            onChange={(value) => setSubnetMask(value)}
          />
        </div>

       {/* //////////////////////////////////////////////////////////////////////// */}

        <br />

        <Button isFullWidth={true} onClick={(e) => {
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");

          //updateIPaddressSubmit(true);

          // Set IP address and netmask
          axios.get(`${SET_IP_ADDRESS_AND_NETMASK_URL}/${staticIP}/${subnetMask}`)

          .then((response) => {
              console.log("RECVD RESPONSE SET_IP_ADDRESS_AND_NETMASK");
              console.log(response)
              console.log("RECVD RESPONSE SET_IP_ADDRESS_AND_NETMASK");

          })
          .catch((error) => {
              console.log(`[ERROR] ${error.message}`)
          });

          e.preventDefault();

      }}
      >
        Update IP address
      </Button>

       {/* //////////////////////////////////////////////////////////////////////// */}

        <br/>
        <br/>

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Gateway
          </Title>

          <IPAddress
            defaultValue={gateway}
            onChange={(value) => setGateway(value)}
          />
        </div>

        <br/>

        <Button isFullWidth={true} onClick={(e) => {
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");

          //updateIPaddressSubmit(true);

          // Set IP address and netmask
          axios.get(`${SET_GATEWAY_URL}/${gateway}`)

          .then((response) => {
              console.log("RECVD RESPONSE SET_GATEWAY");
              console.log(response)
              console.log("RECVD RESPONSE SET_GATEWAY");

          })
          .catch((error) => {
              console.log(`[ERROR] ${error.message}`)
          });

          e.preventDefault();

      }}
      >
        Update Gateway
      </Button>

       {/* //////////////////////////////////////////////////////////////////////// */}


        </form>
      </div>


                </Modal>
            );

  }

// #################################################################################
// #################################################################################
// #################################################################################

  if (bool_show_PLC_tags_modal === true) {

    return (
      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          // ________________________________________________________ //
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          set_bool_show_PLC_tags_modal(false);
          // ________________________________________________________ //
        }}
      >

       {/* //////////////////////////////////////////////////////////////////////// */}

          <div className={s.modal_component}>

            <form className={s.form}>

              <br />


      { (device_type==='ethernet_ip') &&

         <div>
         <br/>

         <Title>Set PLC tag names</Title>

         <table className={s.table} onClick={()=>{

            if (bool_editing_tag_name==true) {

              if (tag_name_editing==1) {
                  axios.get(`${SET_TAG_NAME_URL}/${trigger_tag_name}/trigger_tag_name`);

              } else if (tag_name_editing==2) {
                  axios.get(`${SET_TAG_NAME_URL}/${results_ready_tag_name}/results_ready_tag_name`);

              } else if (tag_name_editing==3) {
                  axios.get(`${SET_TAG_NAME_URL}/${pass_tag_name}/pass_tag_name`);

              } else if (tag_name_editing==4) {
                  axios.get(`${SET_TAG_NAME_URL}/${fail_tag_name}/fail_tag_name`);

              } else if (tag_name_editing==5) {
                  axios.get(`${SET_TAG_NAME_URL}/${class_prediction_tag_name}/class_prediction_tag_name`);

              } else if (tag_name_editing==6) {
                  axios.get(`${SET_TAG_NAME_URL}/${active_job_tag_name}/active_job_tag_name`);

              } else if (tag_name_editing==7) {
                  axios.get(`${SET_TAG_NAME_URL}/${job_change_tag_name}/job_change_tag_name`);

              } else if (tag_name_editing==8) {
                  axios.get(`${SET_TAG_NAME_URL}/${clear_results_tag_name}/clear_results_tag_name`);

              } else if (tag_name_editing==9) {
                  axios.get(`${SET_TAG_NAME_URL}/${trigger_ack_tag_name}/trigger_ack_tag_name`);

              } else if (tag_name_editing==10) {
                  axios.get(`${SET_TAG_NAME_URL}/${class_prediction_string_tag_name}/class_prediction_string_tag_name`);

              } else if (tag_name_editing==11) {
                  axios.get(`${SET_TAG_NAME_URL}/${focus_tag_name}/focus_tag_name`);

              } else if (tag_name_editing==12) {
                  axios.get(`${SET_TAG_NAME_URL}/${active_focus_tag_name}/active_focus_tag_name`);

              } else if (tag_name_editing==13) {
                  axios.get(`${SET_TAG_NAME_URL}/${exposure_tag_name}/exposure_tag_name`);

              } else if (tag_name_editing==14) {
                  axios.get(`${SET_TAG_NAME_URL}/${active_exposure_tag_name}/active_exposure_tag_name`);

              } else if (tag_name_editing==15) {
                  axios.get(`${SET_TAG_NAME_URL}/${heartbeat_tag_name}/heartbeat_tag_name`);

              } else {
                  print_in_red(`invalid tag name editing=${tag_name_editing}`);
              }

            }
            set_bool_editing_tag_name(false);
            set_tag_name_editing(-1);
         }}>
           <thead>

             <tr>
               {/*
               <th className={s.slot}>
                 ON/OFF
               </th>
               */}
               <th className={s.tag_description}>
                 Tag Description
               </th>
               <th className={s.tag_name}>
                 Tag Name
               </th>
               <th className={s.input_output}>
                 Input/Output
               </th>
               <th className={s.data_type}>
                 Type
               </th>
             </tr>
           </thead>
           <tbody>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_trigger_tag_enabled}
                   onChange={(value)=>{set_bool_trigger_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}

               <td>Trigger</td>

               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(1);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==1) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={trigger_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_trigger_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {trigger_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Input</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_results_ready_tag_enabled}
                   onChange={(value)=>{set_bool_results_ready_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Results Ready</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(2);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==2) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={results_ready_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_results_ready_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {results_ready_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_pass_tag_enabled}
                   onChange={(value)=>{set_bool_pass_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />

               </td>
               */}
               <td>Pass</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(3);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==3) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={pass_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_pass_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {pass_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_fail_tag_enabled}
                   onChange={(value)=>{set_bool_fail_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Fail</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(4);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==4) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={fail_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_fail_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {fail_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_class_prediction_tag_enabled}
                   onChange={(value)=>{set_bool_class_prediction_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Class Prediction [1-N]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(5);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==5) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={class_prediction_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_class_prediction_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {class_prediction_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}

             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_active_job_tag_enabled}
                   onChange={(value)=>{set_bool_active_job_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Active Job [0-8]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(6);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==6) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={active_job_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_active_job_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {active_job_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_job_change_tag_enabled}
                   onChange={(value)=>{set_bool_job_change_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Job Change[0-8]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(7);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==7) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={job_change_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_job_change_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {job_change_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Input</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_clear_results_tag_enabled}
                   onChange={(value)=>{set_bool_clear_results_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Clear Results</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(8);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==8) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={clear_results_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_clear_results_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {clear_results_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Input</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               {/*
               <td>
                 <Switch
                   checked={bool_trigger_ack_tag_enabled}
                   onChange={(value)=>{set_bool_trigger_ack_tag_enabled(value)} }
                   onColor="#86d3ff"
                   onHandleColor="#2693e6"
                   handleDiameter={30}
                   uncheckedIcon={false}
                   checkedIcon={false}
                   boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                   activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                   height={20}
                   width={48}
                   className="react-switch"
                   id="switch1"
                 />
               </td>
               */}
               <td>Trigger Ack</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(9);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==9) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={trigger_ack_tag_name}
                            onChange={(e) => {
                                                console.log("change trigger_tag_name");
                                                set_trigger_ack_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {trigger_ack_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Output</td>
               <td>BOOL</td>
             </tr>
             {/***************************************************************/}
             <tr>
               <td>Class Prediction [NAME]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(10);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==10) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={class_prediction_string_tag_name}
                            onChange={(e) => {
                                                console.log("change class_name_string_tag_name");
                                                set_class_prediction_string_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {class_prediction_string_tag_name}
                        </div>
                      )}
                  </div>
               </td>

               <td>Output</td>
               <td>STRING</td>
             </tr>

             {/***************************************************************/}

             <tr>
               <td>Set Focus [1-100]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        //console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(11);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==11) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={focus_tag_name}
                            onChange={(e) => {
                                                //console.log("change focus_tag_name");
                                                set_focus_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             //console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {focus_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Input</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               <td>Get Focus [1-100]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(12);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==12) ? (
                        <div className={s.field}>
                          <Input2
                            id="trigger_tag_name"
                            name="trigger_name"
                            type="text"
                            value={active_focus_tag_name}
                            onChange={(e) => {
                                                //console.log("change focus_tag_name");
                                                set_active_focus_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {active_focus_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Output</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               <td>Set Exposure Microseconds [1-20000]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(13);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==13) ? (
                        <div className={s.field}>
                          <Input2
                            id="exposure_tag_name"
                            name="focusname"
                            type="text"
                            value={exposure_tag_name}
                            onChange={(e) => {
                                                //console.log("change focus_tag_name");
                                                set_exposure_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {exposure_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Input</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               <td>Get Exposure Microseconds [1-20000]</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(14);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==14) ? (
                        <div className={s.field}>
                          <Input2
                            id="active_exposure_tag_name"
                            name="focusname"
                            type="text"
                            value={active_exposure_tag_name}
                            onChange={(e) => {
                                                console.log("change focus_tag_name");
                                                set_active_exposure_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {active_exposure_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Output</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}
             <tr>
               <td>Camera Heartbeat</td>
               <td>
                 <div className={s.title2}>
                    <span className={s.iconEdit} onClick={(e)=>{
                        console.log(`EDIT CLICK`);
                        e.stopPropagation();
                        if (bool_editing_tag_name === false) {
                            set_bool_editing_tag_name(true);
                            set_tag_name_editing(15);
                        }

                      }}
                    >
                      <FontAwesomeIcon icon="pen" />
                    </span>
                    { (bool_editing_tag_name && tag_name_editing==15) ? (
                        <div className={s.field}>
                          <Input2
                            id="heartbeat_tag_name"
                            name="focusname"
                            type="text"
                            value={heartbeat_tag_name}
                            onChange={(e) => {
                                                console.log("change heartbeat_tag_name");
                                                set_heartbeat_tag_name(e.target.value)
                                             }
                                      }
                            placeholder=""
                            onClick={(e)=>{
                                             console.log("Input2 CLICK");
                                             e.stopPropagation();
                                          }
                                    }
                          />
                        </div>
                      ) : (
                        <div>
                            {heartbeat_tag_name}
                        </div>
                      )}
                  </div>
               </td>
               <td>Output</td>
               <td>DINT</td>
             </tr>
             {/***************************************************************/}

           </tbody>
         </table>


         <br/>

         </div>
      }

      </form>
     </div>
   </Modal>
   );

  }


     {/* /////////////////////////////////////////////////////////////////// */}




// #################################################################################
// #################################################################################
// #################################################################################


  if (bool_show_update_PLC_ip_address_modal === true) {

    return (
      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          // ________________________________________________________ //
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          show_update_PLC_ip_address_modal(false);
          // ________________________________________________________ //
        }}
      >

       {/* //////////////////////////////////////////////////////////////////////// */}

          <div className={s.modal_component}>

            <Title>Set PLC IP address</Title>

            <form className={s.form}>


              {/* (bool_update_ip_address_submit && bool_update_ip_address_error) &&
                 <div className={s.offline_error_message}>
                   Error: please enter valid IP address
                 </div>
              */}

              <br />

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            PLC/IO IP address
          </Title>

          <IPAddress
            defaultValue={PLC_ip_address}
            onChange={(value) => {
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);
              console.log(`value=${value}`);

              set_PLC_ip_address(value);

              /////////////////////////////////////////////////////////////

              // Set PLC IP address
              axios.get(`${SET_PLC_IP_ADDRESS_URL}/${value}`)
              .then((response) => {
                  console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");
                  console.log(response)
                  console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");
              })
              .catch((error) => {
                  console.log(`[ERROR] ${error.message}`)
              });

              /////////////////////////////////////////////////////////////

            }}
          />

        </div>


       {/* //////////////////////////////////////////////////////////////////////// */}


        {/*
        <br />

        <Button isFullWidth={true} onClick={(e) => {
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");
          console.log("CLICK CLICK CLICK");


          // Set PLC IP address
          axios.get(`${SET_PLC_IP_ADDRESS_URL}/${PLC_ip_address}`)

          .then((response) => {
              console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");
              console.log(response)
              console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");

          })
          .catch((error) => {
              console.log(`[ERROR] ${error.message}`)
          });
          e.preventDefault();

      }}
      >
        Update PLC IP address
      </Button>
      */}

      { (device_type==='ethernet_ip') &&
         <div>

          <br/>
          <Title parentClass={s.title} size="1">
            Use PLC Time on/off
          </Title>

            <Toggles

              currentValue={bool_use_PLC_time}
              options={BOOL_USE_PLC_TIME_OPTIONS}
              onChange={(new_toggle_value)=> {
                  //print_in_orange(`new_toggle_value=${new_toggle_value}`);
                  set_bool_use_PLC_time(new_toggle_value);
                    axios
                    .get(`${SET_BOOL_USE_PLC_TIME_URL}/${new_toggle_value}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');
                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_use_PLC_time")
                    });

              }}
            />
        </div>
      }




      { (device_type==='offline') &&
         <div className={s.offline_error_message}>
           Please set I/O device type (currently set to 'offline')
          </div>
      }

      { (device_type==='odva_ethernet_ip') &&
         <div>
           See programming guide for more details. Phone support 586.854.7475
          </div>
      }


     {/* /////////////////////////////////////////////////////////////////// */}
     {/* /////////////////////////////////////////////////////////////////// */}
     {/* /////////////////////////////////////////////////////////////////// */}
     {/* /////////////////////////////////////////////////////////////////// */}
     {/* /////////////////////////////////////////////////////////////////// */}
     {/* /////////////////////////////////////////////////////////////////// */}

      { (device_type==='io_block') &&

         <div className={s.field}>

         {/*
              <div className={s.label_component}>
                <label htmlFor="input_tag_name_of_trigger">Job Name</label>
              </div>
         */ }

          {/* /////////////////////////////////////////////////////////////////// */}

          {/* Trigger tag name */ }

          <Title parentClass={s.title} size="1" noMargin>
            Input # of Trigger
          </Title>

          <Input
            id="input_tag_name_of_trigger"
            name="input_tag_name_of_trigger"
            type="text"
            value={trigger_input_tag_name}
            onChange={(event, key) => {
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                set_trigger_input_tag_name(event.target.value);
                /* TODO: send to server */
            }}
          />

         <br />
         <br />

         {/* /////////////////////////////////////////////////////////////////// */}

         {/* Pass Signal tag name */ }

          <Title parentClass={s.title} size="1" noMargin>
            Output # of Pass Signal
          </Title>

          <Input
            id="output_tag_name_of_pass_signal"
            name="output_tag_name_of_pass_signal"
            type="text"
            value={pass_signal_output_tag_name}
            onChange={(event, key) => {
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                set_pass_signal_output_tag_name(event.target.value);
                /* TODO: send to server */
            }}
          />

         <br />
         <br />

         {/* /////////////////////////////////////////////////////////////////// */}

         {/* Fail Signal tag name */ }

          <Title parentClass={s.title} size="1" noMargin>
            Output # of Fail Signal
          </Title>

          <Input
            id="output_tag_name_of_fail_signal"
            name="output_tag_name_of_fail_signal"
            type="text"
            value={fail_signal_output_tag_name}
            onChange={(event, key) => {
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                console.log(`event.target.value=${event.target.value}`)
                set_fail_signal_output_tag_name(event.target.value);
                /* TODO: send to server */
            }}
          />

         <br />
         <br />

         {/* /////////////////////////////////////////////////////////////////// */}

         </div>
      }

      {/* /////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////// */}


      </form>
     </div>
   </Modal>
   );

  }


// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################


  {/* idea here was to set and/or monitor IO signals (e.g. tags) for initial setup ("traffic light")*/
  /* instead, monitor these signals from PLC */

  /*
  if (bool_show_monitor_io_signals_modal === true) {

    return (

      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          // ________________________________________________________ //
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");


      ////////////////////////////////////////////////////////////////////////////

      const unsubscribe_url = (UNSUBSCRIBE_IO_NOTIFICATIONS_URL);

      axios.get(unsubscribe_url)
      .then((response) => {
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      });


      ////////////////////////////////////////////////////////////////////////////


          show_monitor_io_signals_modal(false);
          // ________________________________________________________ //
        }}
      >


          <div className={s.modal_component}>
            <form className={s.form}>



              { (bool_IO_device_connected === false) &&
                 <div>
                   <Title>Monitor I/O Signals</Title>
                   <div className={s.offline_error_message}>
                     Error: No PLC/IO device connected
                   </div>
                 </div>
              }


      { (bool_IO_device_connected === true) &&


     <div>
       <div className={s.titleX}>
         <Title>Monitor I/O Signals</Title>
       </div>

       <br />
       <br />

       <div className={s.rowX}>

         <div className={s.columnX}>

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Monitor Device I/O
          </Title>
          <RadioTrigger
            currentValue={bool_trigger_set}
            options={DEVICE_TRIGGER_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />

          <br />

          <RadioTrigger
            currentValue={"ready"}
            options={DEVICE_READY_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />

          <br />

          <RadioTrigger
            currentValue={"pass"}
            options={DEVICE_PASS_OUTPUT_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />

          <br />

          <RadioTrigger
            currentValue={null}
            options={DEVICE_FAIL_OUTPUT_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />


      </div>
      </div>

        <div className={s.columnX}>

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Test/Activate Device I/O
          </Title>
          <Radios
            currentValue={"na_pass"}
            options={SET_PASS_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />

          <br />

          <Radios
            currentValue={"na_fail"}
            options={SET_FAIL_OPTIONS}
            onChange={(value) => { //setDeviceType(value)
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
              console.log("onChange Trigger")
            }}
          />


          </div>
          </div>
        </div>
      </div>
      }


        </form>
      </div>

    </Modal>

    );
  }
  */}






// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
// #################################################################################
















  return (
    <div className={s.component}>
      <aside className={s.side}>
        <div className={s.setting}>

         {/*
        <br />

          <Title parentClass={s.title} size="1" noMargin>
            Trigger Mode
          </Title>

          <Toggles
            currentValue={triggerMode}
            options={TRIGGER_OPTIONS}
            onChange={(value) => { //setTriggerMode(value)


      /////////////////////////////////////////////////////////

      // Set bool_PLC_trigger
      console.log(`TRIGGER_MODE=${value}`);
      var set_bool_PLC_trigger_url = "";
      if (value === 'manual') {
          set_bool_PLC_trigger_url = `${SET_BOOL_PLC_TRIGGER_URL}/${false}`;
      }
      if (value === 'plc') {
          set_bool_PLC_trigger_url = `${SET_BOOL_PLC_TRIGGER_URL}/${true}`;
      }

      axios.get(set_bool_PLC_trigger_url)
      .then((response) => {
          console.log("RECVD SET PLC TRIGGER");
          console.log(response.data)
          console.log("RECVD BOOL PLC TRIGGER");

          /////////////////////////////////////////////////////////
          // Get bool_PLC_trigger
          axios.get(GET_BOOL_PLC_TRIGGER_URL)
          .then((response) => {
              console.log("RECVD BOOL PLC TRIGGER");
              console.log(response.data)
              console.log("RECVD BOOL PLC TRIGGER");

              if (response.data.bool_PLC_trigger === true) {
                  setTriggerMode('plc');
              }
              if (response.data.bool_PLC_trigger === false) {
                  setTriggerMode('manual');
              }
          })
          .catch((error) => {
              console.log(`[ERROR] ${error.message}`)
          });
          /////////////////////////////////////////////////////////
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      /////////////////////////////////////////////////////////



            }}
          />

        */}
        {/*
        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Static IP
          </Title>

          <IPAddress
            defaultValue={staticIP}
            onChange={(value) => setStaticIP(value)}
          />
        </div>

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Subnet
          </Title>

          <IPAddress
            defaultValue={subnetMask}
            onChange={(value) => setSubnetMask(value)}
          />
        </div>

       <div>
          <Toggles
            currentValue={triggerMode}
            options={UPDATE_IP_ADDRESS_OPTIONS}
            onChange={(value) => { //setTriggerMode(value)

            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");

            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");
            console.log("TODO TODO TODO");



      /////////////////////////////////////////////////////////

      // TODO: this is boiler plate.. edit for set IP address

      // Set bool_PLC_trigger
      console.log(`TRIGGER_MODE=${value}`);
      var set_bool_PLC_trigger_url = "";
      if (value === 'manual') {
          set_bool_PLC_trigger_url = `${SET_BOOL_PLC_TRIGGER_URL}/${false}`;
      }
      if (value === 'plc') {
          set_bool_PLC_trigger_url = `${SET_BOOL_PLC_TRIGGER_URL}/${true}`;
      }

      axios.get(set_bool_PLC_trigger_url)
      .then((response) => {
          console.log("RECVD SET PLC TRIGGER");
          console.log(response.data)
          console.log("RECVD BOOL PLC TRIGGER");

          /////////////////////////////////////////////////////////
          // Get bool_PLC_trigger
          axios.get(GET_BOOL_PLC_TRIGGER_URL)
          .then((response) => {
              console.log("RECVD BOOL PLC TRIGGER");
              console.log(response.data)
              console.log("RECVD BOOL PLC TRIGGER");

              if (response.data.bool_PLC_trigger === true) {
                  setTriggerMode('plc');
              }
              if (response.data.bool_PLC_trigger === false) {
                  setTriggerMode('manual');
              }
          })
          .catch((error) => {
              console.log(`[ERROR] ${error.message}`)
          });
          /////////////////////////////////////////////////////////
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      /////////////////////////////////////////////////////////



            }}
          />
        </div>

        */}

{/* /////////////////////////////////////////////////////////////////////////////////// */ }


        <br />
        <br />


        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            I/O Device communications
          </Title>
          <Radios
            currentValue={device_type}
            options={DEVICE_TYPE_OPTIONS}
            onChange={(value) => { //setDeviceType(value)

                /////////////////////////////////////////////////////////

                // Set IO device type
                console.log(`DEVICE_TYPE=${value}`);
                var set_device_type_url = "";

                if (value === 'offline') {
                    set_device_type_url = `${SET_IO_DEVICE_TYPE_URL}/0`;
                }
                if (value === '24v_discrete') {
                    set_device_type_url = `${SET_IO_DEVICE_TYPE_URL}/4`;
                }
                if (value === 'ethernet_ip') {
                    set_device_type_url = `${SET_IO_DEVICE_TYPE_URL}/2`;
                }
                if (value === 'odva_ethernet_ip') {
                    set_device_type_url = `${SET_IO_DEVICE_TYPE_URL}/1`;
                }

                console.log(`device_type_url=${set_device_type_url}`)

      axios.get(set_device_type_url)
      .then((response) => {
          console.log("RECVD SET IO DEVICE TYPE");
          console.log(response.data)
          console.log("RECVD SET IO DEVICE TYPE");

          /////////////////////////////////////////////////////////
          // Get device_type
          axios.get(GET_IO_DEVICE_TYPE_URL)
          .then((response) => {
              console.log("RECVD IO DEVICE TYPE");
              console.log(response.data)
              console.log("RECVD IO DEVICE TYPE");

              if (response.data.device_type === 0) {
                  setDeviceType('offline');
              }
              if (response.data.device_type === 4) {
                  setDeviceType('24v_discrete');
              }
              if (response.data.device_type === 2) {
                  setDeviceType('ethernet_ip');
              }
              if (response.data.device_type === 1) {
                  setDeviceType('odva_ethernet_ip');
              }
          })
          .catch((error) => {
             console.log(`[ERROR] ${error.message}`)
          });
          /////////////////////////////////////////////////////////
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      /////////////////////////////////////////////////////////


            }}
          />

        <br />

      { ((device_type=='offline') || (device_type=='24v_discrete')) &&

        <div>
        <br />

        <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Auto-Trigger Interval
          </Title>
          <Content parentClass={s.content}>
            During continuous trigger, set trigger frequency
          </Content>

           <Slider
            min={0}
            max={5000}
            step_interval={1}
            marks={AUTO_TRIGGER_INTERVAL_MARKS}
            value={autoTriggerInterval}
            defaultValue={autoTriggerInterval}
            onChange={(sliderValue) => { //setCameraExposure(sliderValue)

      axios
      .get(`${SET_AUTO_TRIGGER_INTERVAL_URL}/${sliderValue}`)
      .then((response) => {
          console.log('_________________');
          console.log(response);
          console.log('_________________');
          ////////////////////////////////////////////////////////
          axios
          .get(`${GET_AUTO_TRIGGER_INTERVAL_URL}`)
          .then((response) => {
              console.log('_________________');
              console.log(response.data);
              console.log(response.data.auto_trigger_interval_milliseconds);
              console.log('_________________');
              setAutoTriggerInterval(response.data.auto_trigger_interval_milliseconds);
          })
          .catch((error) => {
              console.log("[ERROR] get auto trigger interval")
          });
          ////////////////////////////////////////////////////////
      })
      .catch((error) => {
          console.log("[ERROR] get auto trigger interval")
      });


            }}
          />
        <br />
        </div>
        </div> }


       <br />

                  <Button parentClass={cx(s.button, s.showIP)}
                   onClick={() => {

                     showUpdateIPaddressModal(true);

                   }}
                  >
                    Edit Camera IP address
                  </Button>


        <br />
        <br />

          <Button parentClass={cx(s.button, s.showIP)}
            onClick={() => {
                show_update_PLC_ip_address_modal(true);

                ///////////////////////////////////////////////////////////

                // Get PLC ip address

                axios.get(GET_PLC_IP_ADDRESS_URL)
                .then((response) => {
                    console.log("RECVD PLC IP ADDRESS");
                    console.log(response.data)
                    console.log(response.data.device_ip_address)
                    console.log("RECVD PLC IP ADDRESS");

                    set_PLC_ip_address(response.data.device_ip_address)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

                ///////////////////////////////////////////////////////////

                // Get Trigger input tag name

                axios.get(GET_INPUT_TAG_OF_TRIGGER_URL)
                .then((response) => {
                    console.log("RECVD TRIGGER TAG NAME");
                    console.log(response.data)
                    console.log(response.data.tag_name)
                    console.log("RECVD TRIGGER TAG NAME");

                    set_trigger_input_tag_name(response.data.tag_name)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

                ///////////////////////////////////////////////////////////

                // Get Pass Signal output tag name

                axios.get(GET_OUTPUT_TAG_OF_PASS_SIGNAL_URL)
                .then((response) => {
                    console.log("RECVD PASS SIGNAL TAG NAME");
                    console.log(response.data)
                    console.log(response.data.tag_name)
                    console.log("RECVD PASS SIGNAL TAG NAME");

                    set_pass_signal_output_tag_name(response.data.tag_name)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

                ///////////////////////////////////////////////////////////

                // Get Fail Signal output tag name

                axios.get(GET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL)
                .then((response) => {
                    console.log("RECVD FAIL SIGNAL TAG NAME");
                    console.log(response.data)
                    console.log(response.data.tag_name)
                    console.log("RECVD FAIL SIGNAL TAG NAME");

                    set_fail_signal_output_tag_name(response.data.tag_name)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

                ///////////////////////////////////////////////////////////

{/*
  const [PLC_ip_address, set_PLC_ip_address] = useState();
  const [trigger_input_tag_name, set_trigger_input_tag_name] = useState("TRIGGER");
  const [pass_signal_output_tag_name, set_pass_signal_output_tag_name] = useState("PASS");
  const [fail_signal_output_tag_name, set_fail_signal_output_tag_name] = useState("FAIL");

  GET_PLC_IP_ADDRESS_URL,
  GET_INPUT_TAG_OF_TRIGGER_URL,
  GET_OUTPUT_TAG_OF_PASS_SIGNAL_URL,
  GET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL,
*/}


                   }}
                  >
                    Edit PLC/IO Settings
                  </Button>

        <br />
        <br />

        {/* Show PLC tags button under Rockwell ethernet_ip device type*/}

        { (device_type==='ethernet_ip') && (

                  <Button parentClass={cx(s.button, s.showIP)}
                   onClick={() => {

                     set_bool_show_PLC_tags_modal(true);

                   }}
                  >
                    Edit PLC tags
                  </Button>
         )}


         {/*
         <Button parentClass={cx(s.button, s.showIP)}
           onClick={() => {

    ///////////////////////////////////////////////////////////


      const reviews = new Request(SUBSCRIBE_IO_NOTIFICATIONS_URL);

      // 'long poll'
      // async continuous request sent from browser to backend
      // --> backend sleeps until an image is ready
      // --> when image is captured, backend responds to poll
      // --> ... image_capture_callback
      const interval_ms = 1
      reviews.poll(interval_ms).get((response) => {
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")

          print_in_red(`bool_trigger_set=${response.data.bool_trigger_set}`);
          print_in_red(`bool_busy_set=${response.data.bool_busy_set}`);
          print_in_red(`bool_pass_set=${response.data.bool_pass_set}`);
          print_in_red(`bool_fail_set=${response.data.bool_fail_set}`);
          print_in_red(`bool_heartbet_set=${response.data.bool_heartbeat_set}`);

          set_bool_trigger_set(response.data.bool_trigger_set);
          set_bool_busy_set(response.data.bool_busy_set);
          set_bool_pass_set(response.data.bool_pass_set);
          set_bool_fail_set(response.data.bool_fail_set);
          set_bool_heartbeat_set(response.data.bool_heartbeat_set);
          // you can cancel polling by returning false
      });

    ///////////////////////////////////////////////////////////




               show_monitor_io_signals_modal(true);;

           }}
         >
             Monitor I/O signals
         </Button>
         */}

       </div>
       </div>

      </aside>

      <div className={s.main}>
        <Title parentClass={s.title}>Available devices</Title>

        <table className={s.table}>
          <thead>
            <tr>
              <th>IPv4</th>
              <th>MAC Address</th>
              <th>Connection Status</th>

            </tr>
          </thead>
          <tbody>
            {DEVICES.map((device) => {
              const key = device.MAC + device.IP;
              const isConnected = (device.IP === PLC_ip_address);
              return (
                <tr key={key}>
                  <td>{device.IP}</td>
                  <td>{device.MAC}</td>
                  <td>


                    {isConnected ? (

                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Connected
                        </div>
                      </Tag>
                    ) :
                       (
                        <div className={s.activateText}>

                        {(isConnected===false) && (
                          <Button parentClass={cx(s.buttonx, s.loadJob)} onClick={()=>{
                              /////////////////////////////////////////////////////////
                              console.log("activate_click");
                              console.log("activate_click");


              // Set PLC IP address
              axios.get(`${SET_PLC_IP_ADDRESS_URL}/${device.IP}`)
              .then((response) => {
                  console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");
                  console.log(response)
                  console.log("RECVD RESPONSE SET_PLC_IP_ADDRESS");

              })
              .catch((error) => {
                  console.log(`[ERROR] ${error.message}`)
              });

                    set_PLC_ip_address(device.IP)


                              /////////////////////////////////////////////////////////

               // Get PLC ip address
{/*
                axios.get(GET_PLC_IP_ADDRESS_URL)
                .then((response) => {
                    console.log("RECVD PLC IP ADDRESS");
                    console.log(response.data)
                    console.log(response.data.device_ip_address)
                    console.log("RECVD PLC IP ADDRESS");

                    set_PLC_ip_address(response.data.device_ip_address)
                })
                .catch((error) => {
                    console.log(`[ERROR] ${error.message}`)
                });

*/}
                /////////////////////////////////////////////////////////


                              }}
                          >
                            Connect
                          </Button>
                        )}
                      </div>
                     )}

                  </td>

                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IOSettings;
