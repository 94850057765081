import React from 'react';

import { useParams } from 'react-router-dom';

import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';
import NewNetwork from 'components/networks/NewNetwork';

const TrainSettingsPage = (props) => {
  const { datasetId } = useParams();

  return [
    <NewNetwork key="new-network" dataset_ID={datasetId}/>,
    <DatasetBreadcrumb key="breadcrumb" datasetId={datasetId} showNewNetwork />,
  ];
};

export default TrainSettingsPage;
