import React from 'react';

import s from './Label.module.sass';

const Label = (props) => {
  const { children, htmlFor } = props;

  return (
    <div className={s.component}>
      <label for={htmlFor}>{children}</label>
    </div>
  );
};

export default Label;
