import React from 'react';

import cx from 'classnames';
import s from './KeyValue.module.sass';

const KeyValue = (props) => {
  const { children, value, type, info } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${type}`]]: type,
  });

  return (
    <div className={componentClass}>
      <div className={s.body}>
        <div className={s.key}>{children}</div>
        <div className={s.value}>{value}</div>
      </div>

      {info && <p className={s.info}>{info}</p>}
    </div>
  );
};

export default KeyValue;
