import React from 'react';

import FilterLabel from 'components/history/FilterLabel';
import Toggles from 'components/form/Toggles';

import cx from 'classnames';
import s from './ToggleFilter.module.sass';

const ToggleFilter = (props) => {
  const { label, currentValue, options, onChange, parentClass } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return (
    <div className={componentClass}>
      <FilterLabel>{label}</FilterLabel>
      <div className={s.value}>
        <Toggles
          parentClass={s.radios}
          currentValue={currentValue}
          options={options}
          onChange={(value) => onChange(value)}
        />
      </div>
    </div>
  );
};

export default ToggleFilter;
