import React from 'react';

import Title from './Title';

import cx from 'classnames';
import s from './Heading.module.sass';

const Heading = (props) => {
  const { surtitle, children, parentClass } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return (
    <div className={componentClass}>
      <Title size="1" CustomTag="p" parentClass={s.surtitle}>
        {surtitle}
      </Title>
      <Title size="3">{children}</Title>
    </div>
  );
};

export default Heading;
