import React from 'react';

import Button from 'components/elements/Button';
import Content from 'components/elements/Content';
import Title from 'components/elements/Title';

import { PolygonIsClosed } from 'components/annotation/utils';

import cx from 'classnames';
import s from './Instructions.module.sass';

const Instructions = (props) => {

  const {
    bool_pass_detection=false,
    bool_fail_detection=false,
    bool_multi_class_detection=false,
    bool_crop_instructions=false,
  } = props;

  /*
  const canUndo = history.previous.length > 0;
  const canRedo = history.next.length > 0;
  const canDelete = focusedName;
  const canFinish = entitiesList.some(PolygonIsClosed);
  */

  return (

   <div>

   { (bool_pass_detection || bool_fail_detection || bool_multi_class_detection) ? (

      <div className={s.annotater}>

        <Title parentClass={s.annotateTitle} size="2">
          Label / Crop
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Click within image to add label</li>
            <li>Click within image to add crop</li>
            <li>Click box / backspace to delete</li>
          </ul>
        </Content>

      </div>


     ) : (

      <div className={s.annotater}>

        <Title parentClass={s.annotateTitle} size="2">
          Label / Crop
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Toggle Label Image to Crop Image</li>
            <li>Click within image to add crop</li>
          </ul>
        </Content>

      </div>

     )}

   {/*(bool_fail_detection===true) && (

      <div className={s.annotater}>
        <Title parentClass={s.annotateTitle} size="2">
          Identify defects in image
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Click within image to identify defect</li>
            <li>1+ complete label is needed</li>
          </ul>
        </Content>

      </div>
    )*/}

    {/*(bool_pass_detection===true) && (


      <div className={s.annotater}>

        <Title parentClass={s.annotateTitle} size="2">
          Identify objects in image
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Click within image to identify object</li>
            <li>1+ complete label is needed</li>
          </ul>
        </Content>

      </div>
    )*/}

    {/*(bool_multi_class_detection===true) && (

      <div className={s.annotater}>

        <Title parentClass={s.annotateTitle} size="2">
          Identify objects in image
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Click 'Add Class' to select label #</li>
            <li>Click within image to identify object</li>
            <li>1+ complete label is needed</li>
          </ul>
        </Content>

      </div>
    )*/}

    {/*(bool_crop_instructions===true) && (

      <div className={s.annotater}>

        <Title parentClass={s.annotateTitle} size="2">
          Add optional crop
        </Title>

        <Content className={s.annotateContent}>
          <ul>
            <li>Toggle "Label Image" to "Crop Image"</li>
            <li>Click within image to add crop</li>
          </ul>
        </Content>

      </div>
    )*/}

    </div>

  );
};

export default Instructions;
