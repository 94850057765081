// UserPage.js

// TODO: software_update compat check

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { selectors, startTraining } from 'redux/reducers/datasets';
import {
  getImagesURL,
  getNetworkProgressURL,
  getNetworksIndexURL,
  GET_CAMERA_INFO_URL,
  GET_CAMERA_TIME_URL,
  SET_CAMERA_TIME_URL,
  GET_AVAILABLE_STORAGE_URL,
  GET_MANUAL_URL,
  UPLOAD_SOFTWARE_UPDATE_URL,
} from 'pages/urls';

import Empty from 'components/elements/Empty';
import Heading from 'components/elements/Heading';
import Setting from 'components/train/Setting';
import Label from 'components/form/Label';
import Toggles from 'components/form/Toggles';
import Slider from 'components/form/Slider';
import Button from 'components/elements/Button';
import BackLink from 'components/navigation/BackLink';

import ListHeader3 from 'components/list/ListHeader3';

import s from './UserPage.module.sass';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import axios from 'axios';

import Modal from 'components/elements/Modal';

import Title from 'components/elements/Title';

import DateTimePicker from 'react-datetime-picker';

import { useDropzone } from 'react-dropzone';

import cx from 'classnames';

import ProgressBar from 'components/graphics/ProgressBar';


const JobDropzone = (props) => {

  const { saveJobFile } = props;

  const dropzoneConfig = {
    accept: '.build',
    onDrop: (acceptedFiles) => {

      console.log(`acceptedFiles.length=${acceptedFiles.length}`);
      if (acceptedFiles.length === 1) {
          const fileX=acceptedFiles[0];
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log(`fileX=${fileX}`);
          console.log(JSON.stringify(fileX));
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          console.log("~~~~~~~~~~~~~~~~~~~~~~~~~");
          acceptedFiles.map((file) => saveJobFile(file));
      } else {
          // TODO: error message (?)
          console.log("1 job File only")
      }


    },
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone(
    dropzoneConfig
  );

  const componentClass = cx({
    [s.dropzone_component]: true,
    [s.isDragActive]: isDragActive,
  });


  return (

    <div {...getRootProps({ className: componentClass })}>
      <div className={s.dropzone_border}>
        <div className={s.dropzone_content}>
          <input {...getInputProps()} />

          {isDragActive ? (
            <Title parentClass={s.title}>
              Drop Build file <strong>here</strong>
            </Title>
          ) : (
            <Title parentClass={s.dropzone_title}>
              <strong>Drop Build file here</strong>
              <br/>
              <br/>
              OR
            </Title>
          )}


          <div className={s.cta}>
            <Button
              iconRight="mouse-pointer"
              size="small"
              color={isDragActive ? 'success' : null}
            onClick={(e)=>{
              console.log("DropZone click");
              e.preventDefault();
            } }>
              Select Build file
            </Button>

          </div>
        </div>
      </div>
    </div>



  );
};


























const UserPage = (props) => {
  const {
  } = props;

  const [split, setSplit] = useState(80);
  const [rate, setRate] = useState(1.0);
  const [epochs, setEpochs] = useState(50);
  const [bool_new_network_button_click, set_bool_new_network_button_click] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [serial_number, set_serial_number] = useState("");
  const [MAC_address, set_MAC_address] = useState("");
  const [hardware_version, set_hardware_version] = useState("R6");
  const [software_version, set_software_version] = useState("1.4");
  const [resolution, set_resolution] = useState("1.2 MegaPixels");
  const [part_number, set_part_number] = useState("");
  const [ip_address, set_ip_address] = useState("");
  const [bool_color_camera, set_bool_color_camera] = useState(null);

  const [camera_time_str, set_camera_time_str] = useState("");
  const [total_GB, set_total_GB] = useState(984);
  const [GB_available, set_GB_available] = useState(942);

  const [bool_first_time_setup, set_bool_first_time_setup] = useState(true);

  const [bool_show_software_update_modal, set_bool_show_software_update_modal] = useState(false);
  const [bool_show_set_time_modal, set_bool_show_set_time_modal] = useState(false);

  const [load_job_progress, set_load_job_progress] = useState(0.0);
  const [load_job_filename, set_load_job_filename] = useState("");

  const [current_Date_object, set_current_Date_object] = useState(new Date());


  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

  const saveJobFile = (file) => {

    //console.log(`filename=${file.path}`);

    let form_data = new FormData();
    form_data.append('file', file, file.fileName);

    set_load_job_filename(file.path);


    const url = `${UPLOAD_SOFTWARE_UPDATE_URL}`;

    // TODO: clear interval when training complete
    var interval = setInterval(() => {
            set_load_job_progress(prev_load_job_progress => {

                if (prev_load_job_progress < 0.8) {
                    return (prev_load_job_progress + 0.01);
                } else {
                    return prev_load_job_progress;
                }
            });

    }, 1111);

    axios
    .post(url, form_data, {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`,
        },
    })
    .then((response) => {
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")
         console.log(response.data);
         console.log("!!!!!!!!!!!!!!!!!!!!!!!!!")

         clearInterval(interval);

         set_load_job_progress(1);
    })
    .catch((error) => {
        console.log("[ERROR] uploadJob");
    });
  };

  // ______________________________________________________________________ //
  // ______________________________________________________________________ //

      //const unsubscribe_url = (UNSUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL);

  if (bool_first_time_setup === true) {
      axios.get(GET_CAMERA_INFO_URL)
      .then((response) => {
           console.log("CAMERA_INFO CAMERA_INFO CAMERA_INFO");
           console.log("CAMERA_INFO CAMERA_INFO CAMERA_INFO");
           console.log(JSON.stringify(response));

           set_serial_number(response.data.serial_number)
           set_MAC_address(response.data.MAC_address)
           set_hardware_version(response.data.hardware_version)
           set_software_version(response.data.software_version)
           set_resolution(response.data.resolution)
           set_part_number(response.data.part_number)
           set_ip_address(response.data.ip_address)
           set_bool_color_camera(response.data.bool_color_camera)
      });

      //setBoolStreamVideo(false);
      axios.get(GET_CAMERA_TIME_URL)
      .then((response) => {
           set_camera_time_str(response.data.camera_time_str);
      });


      axios.get(GET_AVAILABLE_STORAGE_URL)
      .then((response) => {
           set_total_GB(response.data.total_GB)
           set_GB_available(response.data.GB_available)
      });


      set_bool_first_time_setup(false);
  }

  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //

  if (bool_show_software_update_modal===true) {
    return (
      <Modal
        key="modal"
        isOpen={true}
        onRequestClose={ () => {
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");

          set_bool_show_software_update_modal(false);
        }}
      >

        <div className={s.modal_container}>
            <form className={s.form}>
              <Title>Software Update</Title>


              {(load_job_progress==1.0) &&
                 <div className={s.load_job_message}>
                   Update Complete: Please reboot camera
                 </div>
              }
              {(load_job_filename=="") &&

                <div className={s.label_component}>
                  <label htmlFor="upload build file">Upload Build File:</label>
                </div>

              }
              {(load_job_filename!="" && load_job_progress < 1.0) &&

                <div className={s.label_component}>
                  <label htmlFor="loading software update">Loading software update...</label>
                </div>

              }

              {/* (this.state.load_job_submit && this.state.load_job_name!=='' && this.state.load_job_filename==='') &&
                 <div className={s.load_job_error_message}>
                   Please upload neural network
                 </div>

              <div className={s.fieldX}>


                <Input
                  id="load_job_name"
                  name="load_job_name"
                  type="text"
                  value={this.state.load_job_name}
                  onChange={(e) => this.handleChange(e, 'load_job_name')}
                  placeholder="Job 1"
                />

              </div>

                */}

              { (load_job_filename!=='') &&

                 <div className={s.load_job_progress_bar}>
                      <ProgressBar value={load_job_progress}/>
                 </div>
              }


              <br />
              <JobDropzone saveJobFile={(file) => { saveJobFile(file)  } } />

              <br />

                  </form>
                </div>


      </Modal>
    );
  }


  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //

 if (bool_show_set_time_modal === true) {

    return (
      <Modal
        key="modal2"
        isOpen={true}
        onRequestClose={ () => {
          // ________________________________________________________ //
          console.log("REQUEST_CLOSE_MODAL");
          console.log("REQUEST_CLOSE_MODAL");
          set_bool_show_set_time_modal(false);
          // ________________________________________________________ //
        }}
      >
    {/* //////////////////////////////////////////////////////////////////////// */}
    <div className={s.modal_container}>

        <Title>Set Camera Time</Title>

        <br/>

        <div className={s.date_select}>
            <DateTimePicker
                onChange={set_current_Date_object}
                value={current_Date_object}
                disableCalendar={true}
                disableClock={true}
            />
        </div>

        <br/>
        <br/>

        <Button isFullWidth={true} onClick={(e) => {
            console.log("CLICK_UPDATE_TIME");
            console.log("CLICK_UPDATE_TIME");

            //console.log(`current_Date_object=${current_Date_object}`);

            // getMonth [0,11]
            var month = 1 + current_Date_object.getMonth()
            var day   = current_Date_object.getDate()
            var year  = current_Date_object.getFullYear()
            var hour   = current_Date_object.getHours()
            var minute = current_Date_object.getMinutes()

            var user_set_camera_time_url = `${SET_CAMERA_TIME_URL}/${month}/${day}/${year}/${hour}/${minute}`;

            console.log(`user_set_camera_time_url=${user_set_camera_time_url}`);

            axios.get(user_set_camera_time_url)
            .then((response) => {
                // ___________________________________________________ //
                axios.get(GET_CAMERA_TIME_URL)
                .then((response) => {
                    set_camera_time_str(response.data.camera_time_str);
                    set_bool_show_set_time_modal(false);
                });
                // ___________________________________________________ //

            });

            e.preventDefault();
        }}>
            Update Time
        </Button>

    </div>
    {/* //////////////////////////////////////////////////////////////////////// */}

      </Modal>
    );

  }


  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //
  // _______________________________________________________ //






  return [
    <div key="component" className={s.component}>
      <main className={s.main}>

        <ListHeader3 title="DeepView AI Camera">
                <Button
                  color="success"
                  iconLeft="arrow-down"
                  isLoading={isLoading}
                  onClick={() => {
                      //console.log("DOWNLOAD BUTTON!");
                      const getManualUrl = GET_MANUAL_URL;
                      window.location.href = getManualUrl;
                  }}
                >
                  Download Manual
            </Button>
        </ListHeader3>

        <br/>

        <div className={s.settings_row_container_top}>
        <br/>

          <div className={s.settings_row_item}>
              <div className={s.settings_column_item}>

                  <div className={s.icon_title_container}>
                    <figure className={s.iconx}>
                      <FontAwesomeIcon icon="hdd" />
                    </figure>

                    <h3 className={s.titlex}> <span className={s.titlex_span}> Available Storage: </span> {GB_available} GB / {total_GB} GB</h3>
                    
                    <span className={s.optionx} onClick={()=>{
                        console.log("CLICK manage-storage");

                        set_bool_show_software_update_modal(true);

                    }}>
                        Software Update
                    </span>
                    
                  </div>


              </div>
          </div>

          <div className={s.settings_row_item}>
              <div className={s.settings_column_item}>

                  <div className={s.icon_title_container}>
                    <figure className={s.iconx}>
                      <FontAwesomeIcon icon="tachometer-alt" />
                    </figure>

                    <h3 className={s.titlex}> <span className={s.titlex_span}> System Time: </span> {camera_time_str} </h3>

                    <span className={s.optionx} onClick={()=>{

                        console.log("CLICK update-time");

                        set_bool_show_set_time_modal(true);

                    }}>
                      Update Time
                    </span>


                  </div>

              </div>
          </div>

        <br/>

        </div>


        <div className={s.settings_row_container_middle}>

        <br/>


          <div className={s.settings_row_item}>
              <div className={s.settings_column_item}>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> S/N: </span> {serial_number}
                  </div>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> P/N: </span> {part_number}
                  </div>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> Version: </span> {hardware_version}
                  </div>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> MAC: </span> {MAC_address}
                  </div>


              </div>
              <div className={s.settings_column_item}>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> Software version: </span> {software_version}
                  </div>
                  { (bool_color_camera===true) ? ( <div className={s.settings_detail_row_item}>
                                                   <span className={s.itemx}> Color version: </span> Color
                                                 </div> )
                                             : ( <div className={s.settings_detail_row_item}>
                                                   <span className={s.itemx}> Color version: </span> Monochrome
                                                 </div> )
                  }
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> Resolution: </span> {resolution}
                  </div>
                  <div className={s.settings_detail_row_item}>
                      <span className={s.itemx}> IP Address: </span> {ip_address}
                  </div>

                  </div>
              </div>

              <br/>

        <br/>
        </div>

        <div className={s.settings_row_container_bottom}>

        <br/>

          <div className={s.settings_row_item}>

              <div className={s.settings_column_item}>
                  <span className={s.support}>
                    <u> Support Contact 1: </u> <br/>
                    Eli Davis, Camera Engineer <br/>
                    edavis@deepviewai.com <br/>
                    Cell: (586) 854-7475 <br/>
                  </span>

              </div>
              <div className={s.settings_column_item}>
                  <span className={s.support}>
                    <u> Support Contact 2: </u> <br/>
                    Nick Zimmick, Project Engineer <br/>
                    nick@deepviewai.com <br/>
                    Cell: (586) 291-1120 <br/>
                 </span>
              </div>
          </div>

        <br/>
        <br/>

        </div>

      </main>

    </div>,
  ];
};

export default UserPage;
