import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'classnames';
import s from './Label.module.sass';

const Label2 = (props) => {
  const { type, parentClass, iconLeft, isDisabled, children } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${type}`]]: type,
    [s.isDisabled]: isDisabled,
    [parentClass]: parentClass,
  });

  return (
    <span className={componentClass}>
      {iconLeft && (
        <span className={cx(s.icon, s.iconLeft)}>
          <FontAwesomeIcon icon={iconLeft} />
        </span>
      )}
      {children}
    </span>
  );
};

export default Label2;
