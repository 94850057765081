import React from 'react';
import { useParams } from 'react-router-dom';

import DatasetTabs from 'components/navigation/DatasetTabs';
//import DatasetTabs2 from 'components/navigation/DatasetTabs2';
import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';
import Images from 'components/images/Images';

import s from './Images.module.sass';

import { connect } from 'react-redux';

import { selectors, } from 'redux/reducers/datasets';


const ImagesPage = (props) => {
  const { datasetId } = useParams();

  //const { imagesList } = props;
  //const imagesListEmpty = [];
  return [
    <div key="component" className={s.component}>
      <DatasetTabs datasetId={datasetId} />
      <Images datasetId={datasetId} />
    </div>,
    <DatasetBreadcrumb
      key="breadcrumb"
      datasetId={datasetId}
      showImages={true}
    />,
  ];
};


const mapStateToProps = (state, ownProps) => {
  return {
    //imagesList: selectors.getDatasetImages(state),
    //imagesListSorted: selectors.getDatasetImagesSorted(state),
  };
};

export default connect(mapStateToProps)(ImagesPage);


