// check_labels_present_within_region.js




const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}
const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}
const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}


const return_bool_crop_within_or_overlaps_prediction_box = (crop_object_i, box_object_i) => {
    // Crop box coordinates
    let crop_x1 = crop_object_i.x;
    let crop_y1 = crop_object_i.y;
    let crop_x2 = crop_object_i.x + crop_object_i.width;
    let crop_y2 = crop_object_i.y + crop_object_i.height;

    // Box coordinates
    let box_x1 = box_object_i.x;
    let box_y1 = box_object_i.y;
    let box_x2 = box_object_i.x + box_object_i.width;
    let box_y2 = box_object_i.y + box_object_i.height;

    // Check if the boxes overlap
    if (box_x1 < crop_x2 && box_x2 > crop_x1 && box_y1 < crop_y2 && box_y2 > crop_y1) {
        return true;
    }

    return false;
};

const return_bool_point_within_crop = (crop_object_i, point_x, point_y) => {

    //print_in_orange(` (6) crop_object_i=${JSON.stringify(crop_object_i)}`);
    //print_in_orange(` (6) point_x=${point_x} point_y=${point_y}`);

    let crop_x1 = crop_object_i.x;
    let crop_y1 = crop_object_i.y;
    let crop_x2 = crop_object_i.x + crop_object_i.width;
    let crop_y2 = crop_object_i.y + crop_object_i.height;

    if ( ((crop_x1 <= point_x) && (point_x <= crop_x2)) &&
         ((crop_y1 <= point_y) && (point_y <= crop_y2)) ) {
        return true;
    }
    return false;
}

const return_bool_list_of_labels_contains_label_within_crop = (crop_object_i,  list_of_class_i_labels) => {

    for (let label_i in list_of_class_i_labels) {
        let label_object_i = list_of_class_i_labels[label_i];

        // corner case: the whole prediction is larger than the crop
        let bool_prediction_contains_crop = return_bool_crop_within_or_overlaps_prediction_box(crop_object_i, label_object_i);

        // top left
        let point1_x = label_object_i.x
        let point1_y = label_object_i.y

        // top right
        let point2_x = label_object_i.x + label_object_i.width;
        let point2_y = label_object_i.y

        // bottom right
        let point3_x = label_object_i.x + label_object_i.width;
        let point3_y = label_object_i.y + label_object_i.height;

        // bottom left
        let point4_x = label_object_i.x
        let point4_y = label_object_i.y + label_object_i.height;

        // top_left within crop
        let bool_point1_within_crop = return_bool_point_within_crop(crop_object_i, point1_x, point1_y);

        // top_right within crop
        let bool_point2_within_crop = return_bool_point_within_crop(crop_object_i, point2_x, point2_y);

        // bottom right within crop
        let bool_point3_within_crop = return_bool_point_within_crop(crop_object_i, point3_x, point3_y);

        // bottom left within crop
        let bool_point4_within_crop = return_bool_point_within_crop(crop_object_i, point4_x, point4_y);

        if ((bool_prediction_contains_crop===true) ||
            (bool_point1_within_crop===true) ||
            (bool_point2_within_crop===true) ||
            (bool_point3_within_crop===true) ||
            (bool_point4_within_crop===true)) {
            print_in_green("(5) BOOL_TRUE");
            return true;
        }

    }

    print_in_green("(5) BOOL_FALSE");

    return false;
}

// __________________________________________________________________________ //
// __________________________________________________________________________ //

// PASS_CHECK
// true=PASS
const return_bool_image_contains_ALL_class_i_labels_within_region = (class_object_i, image_labels, list_of_crop_objects) => {

    //print_in_blue(`    (3)class_object_i=${JSON.stringify(class_object_i)}`);

    // ____
    let list_of_class_i_labels = [];
    for (let label_i in image_labels) {
        let label_object_i = image_labels[label_i];
        //print_in_green(`    (3)label_object_i=${JSON.stringify(label_object_i)}`);
        if (label_object_i.detection_label_class===class_object_i.slot) {
            list_of_class_i_labels.push(label_object_i);
        }
    }
    // ____
    //print_in_green(`        (4)list_of_class_i_labels=${JSON.stringify(list_of_class_i_labels)}`);
    // ################################################################# //
    // ################################################################# //

    // no locations required -- dont fail on identified part
    if (class_object_i.roi_select_value.length===0) {
        return true;

    } else {

        for (let i = 0; i < class_object_i.roi_select_value.length; i++) {
            let crop_i = parseInt(class_object_i.roi_select_value[i].slice(4));

            if (crop_i >= list_of_crop_objects.length) {
                print_in_red(`[VIEWER] LIST_OF_CROPS[${crop_i}] not present`);
                continue;
            }

            let crop_object_i = list_of_crop_objects[crop_i];
            //print_in_orange(`        (4)crop_object_i=${JSON.stringify(crop_object_i)}`);
            let bool_list_of_labels_contains_label_within_crop = return_bool_list_of_labels_contains_label_within_crop(crop_object_i, list_of_class_i_labels);

            if (bool_list_of_labels_contains_label_within_crop===false) {
                return false;
            }
        }

        return true;

        ///////////////////////////////////////////////////

    }
    // ################################################################# //
    // ################################################################# //

}


// PASS_CHECK
// true=PASS
export const return_bool_EACH_CLASS_has_label_present_within_required_region = (list_of_class_objects, image_labels, list_of_crops) => {
    // for each class
    // -> bool_image_contains_class_i_label_within_region
    for (let class_i in list_of_class_objects) {
        let class_object_i = list_of_class_objects[class_i];
        let bool_image_contains_class_i_label_within_region = return_bool_image_contains_ALL_class_i_labels_within_region(class_object_i, image_labels, list_of_crops);
        if (bool_image_contains_class_i_label_within_region===false) {
            return false;
        }
    }
    return true;
}

// __________________________________________________________________________ //
// __________________________________________________________________________ //

// FAIL_CHECK
// false=PASS
const return_bool_image_contains_ANY_class_i_labels_within_region = (class_object_i, image_labels, list_of_crop_objects) => {

    let list_of_class_i_labels = [];
    for (let label_i in image_labels) {
        let label_object_i = image_labels[label_i];
        //print_in_green(`    (3)label_object_i=${JSON.stringify(label_object_i)}`);
        if (label_object_i.detection_label_class===class_object_i.slot) {
            list_of_class_i_labels.push(label_object_i);
        }
    }

    console.log(`class_object_i.roi_select_value=${class_object_i.roi_select_value}`);
    //console.log(`Array.isArray(class_object_i.roi_select_value)=${Array.isArray()(class_object_i.roi_select_value)}`);


    // no classes present
    if (list_of_class_i_labels.length === 0) {
        return false;
    }

    // no locations required -- dont fail on identified part
    else if (class_object_i.roi_select_value.length === 0) {
        return false;
    }
    // check locations
    else {
        for (let i = 0; i < class_object_i.roi_select_value.length; i++) {
            let crop_i = parseInt(class_object_i.roi_select_value[i].slice(4));

            if (crop_i >= list_of_crop_objects.length) {
                print_in_red(`[VIEWER] LIST_OF_CROPS[${crop_i}] not present`);
                continue;
            }

            let crop_object_i = list_of_crop_objects[crop_i];
            //print_in_orange(`        (4)crop_object_i=${JSON.stringify(crop_object_i)}`);
            let bool_list_of_labels_contains_label_within_crop = return_bool_list_of_labels_contains_label_within_crop(crop_object_i, list_of_class_i_labels);

            if (bool_list_of_labels_contains_label_within_crop===true) {
                return true;
            }
        }

        return false;


    }

}

// FAIL_CHECK
// false=PASS
export const return_bool_ANY_CLASS_has_label_present_within_required_region = (list_of_class_objects, image_labels, list_of_crops) => {
    // for each class
    // -> bool_image_contains_class_i_label_within_region
    for (let class_i in list_of_class_objects) {
        let class_object_i = list_of_class_objects[class_i];
        let bool_image_contains_class_i_label_within_region = return_bool_image_contains_ANY_class_i_labels_within_region(class_object_i, image_labels, list_of_crops);
        if (bool_image_contains_class_i_label_within_region===true) {
            return true;
        }
    }
    return false;
}
// __________________________________________________________________________ //
// __________________________________________________________________________ //


