import React, { Component } from 'react';
import { connect } from 'react-redux';

import { selectors, getZipFromServer } from 'redux/reducers/datasets';

//import { getZipFromServer } from 'redux/reducers/datasets';


class LoadDataset extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getZipFromServer, imagesList, user_ID, dataset_ID } = this.props;

        if (imagesList.length !== 0)
            return;

        getZipFromServer(user_ID, dataset_ID)
    }

    render() {
        return <div/>
    }
};

//const LoadDataset = (props) => {
//  const { getZipFromServer, imagesList } = props;
//  console.log("X-------------------------------------X")
//  console.log(imagesList.length)
//  console.log("X-------------------------------------X")
//  getZipFromServer()
// //const loadImages = () => getZipFromServer();

//return <button onClick={loadImages}>Load dataset</button>;


const mapStateToProps = (state, ownProps) => ({
    imagesList: selectors.getDatasetImages(state),
    //isLoading: selectors.isLoading(state),
    //isValidTraining: selectors.isValidTraining(state),
});


//export default connect(mapStateToProps, {
//  startTraining,
//})(NewNetwork);

export default connect(mapStateToProps, {
    getZipFromServer,
})(LoadDataset);
