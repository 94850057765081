import React from 'react';

import cx from 'classnames';
import s from './Tag.module.sass';

const Tag = (props) => {
  const { color, isActive, isDisabled, parentClass, children } = props;

  const componentClass = cx({
    [s.tag]: true,
    [s[`color-${color}`]]: color,
    [s.isActive]: isActive,
    [s.isDisabled]: isDisabled,
    [parentClass]: parentClass,
  });

  return <div className={componentClass}>{children}</div>;
};

export default Tag;
