// Login.js

import React, { Component } from 'react';

import Label from 'components/form/Label';
import Input from 'components/form/Input';
import Button from 'components/elements/Button';
import Title from 'components/elements/Title';

import cx from 'classnames';
import s from './Login.module.sass';

import axios from 'axios';

import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';

import { selectors, setActiveUserID } from 'redux/reducers/datasets';

import {
  LOGIN_URL,
} from 'pages/urls';



//import { setActiveUserID } from 'redux/reducers/datasets';


console.log(`process.env=${JSON.stringify(process.env)}`);
console.log(`window.$$env=${JSON.stringify(window.$$env)}`);

console.log('____');
console.log(' href => ' + window.location.href);
console.log(' host => ' + window.location.host);
console.log(' hostname => ' + window.location.hostname);
console.log(' port => ' + window.location.port);
console.log(' protocol => ' + window.location.protocol);
console.log(' pathname => ' + window.location.pathname);
console.log(' hashpathname => ' + window.location.hash);
console.log(' search=> ' + window.location.search);
console.log('____');

console.log(`LOGIN_URL=${LOGIN_URL}`)

class Login extends Component {
  constructor(props) {
    super(props);
	const { boolPreviousLoginFailed, previousLoginErrorMessage } = props;
    this.state = {
      email: '',
      password: '',
	  boolPreviousLoginFailed: boolPreviousLoginFailed,
	  previousLoginErrorMessage: previousLoginErrorMessage,
    };
  }

  handleChange(event, key) {
    this.setState({
      [key]: event.target.value,
    });
  }

  render() {
    const { email, password } = this.state;
	const { boolPreviousLoginFailed, previousLoginErrorMessage } = this.state;

	const { setActiveUserID } = this.props;

	console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
	console.log(`boolPreviousLoginFailed=${boolPreviousLoginFailed}`);
	console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');
	console.log(`previousLoginErrorMessage=${previousLoginErrorMessage}`);
	console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@');

	if (previousLoginErrorMessage==="LOGIN_SUCCESS") {
		return (
			<Redirect to={"/choose-app"} />
		)
	}

    return (
      <div className={s.component}>
        <form className={s.form}>
          <Title>Login to DeepView</Title>
          { boolPreviousLoginFailed &&
			  <div className={s.login_error_message}>
			     {previousLoginErrorMessage}
			  </div>
		  }

	  <div className={s.login_info_message}>
              { "EMAIL: sysadmin@deepviewai.com \/\/ PASSWORD: x" }
	  </div>

          <div className={s.field}>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={(e) => this.handleChange(e, 'email')}
              placeholder="e.g. sysadmin@deepviewai.com"
            />
          </div>

          <div className={s.field}>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              name="password"
              type="password"
              value={password}
              onChange={(e) => this.handleChange(e, 'password')}
              placeholder="********"
            />
          </div>

          <div className={s.field}>
            <Button onClick={(e) => {
				console.log(`email=${this.state.email}`);
				console.log(`password=${this.state.password}`);


				const login_url = `${LOGIN_URL}/${this.state.email}/${this.state.password}`;


				axios
			      .get(login_url)
			      .then((response) => {
			        //console.log('=================================================');
			        //console.log(response.data);
					console.log('=================================================');
					console.log(`user_ID=${response.data.user_ID}`);
					console.log('=================================================');
					console.log(`login_status=${response.data.login_status}`);
					console.log('=================================================');
					if (response.data.login_status === "LOGIN_SUCCESS") {
						console.log("SUCCESS");
						setActiveUserID(response.data.user_ID);
						this.setState({
							boolPreviousLoginFailed: false,
							previousLoginErrorMessage: response.data.login_status,
						})
					} else {
						console.log("FAIL");
						this.setState({
							boolPreviousLoginFailed: true,
							previousLoginErrorMessage: response.data.login_status,
							password: '',
						})
					}

			      })
			      .catch((error) => {
			          console.log(`[LOGIN] error=${error}`);
			      });

				e.preventDefault();

			}}
			>
			  Submit
			</Button>
          </div>
        </form>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
});

export default connect(mapStateToProps, {
  setActiveUserID,
})(Login);
