import React from 'react';

//import LoadDataset from 'components/buttons/LoadDataset';
import DeepViewLogo from 'components/graphics/DeepViewLogo';

import s from './TrainingHeader.module.sass';

const TrainingHeader = () => {
  return (
    <div className={s.component}>
      <div className={s.logo}>
        <DeepViewLogo />
      </div>

      {/*<LoadDataset />*/}
    </div>
  );
};

export default TrainingHeader;
