import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';

import { useParams } from 'react-router-dom';

import { Redirect } from 'react-router-dom';


import BrowseHistory from 'pages/camera/BrowseHistory';

import { GET_IMAGES_ZIP_URL,
         GET_IMAGE_HISTORY_JOBS_URL,
         GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL,
         GET_JOB_NAME_URL,
         HISTORY_URL,
         getHistoryDatesURL,
} from 'pages/urls';

import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './History.module.sass';

import axios from 'axios';

import Line from 'pages/camera/Line';

import HistoryBreadcrumb from 'components/navigation/HistoryBreadcrumb';




const HistoryMonths = (props) => {
  const { job_slot } = useParams();

    return ( <HistoryMonthsX job_slot={job_slot}/ >);

};


// searchType one of: "JOB", "MONTH", "DATE"
//
// History displays:
//   Job [0-8]
//     Month <Month>_<yyyy>
//       Date <Month>_<dd>_<yyyy>
//


class HistoryMonthsX extends Component {
  constructor(props) {
    super(props);

    console.log("HistoryMonths");

    const { job_slot } = this.props;

    this.state = {
        response_dict: null,
        job_name: 'JOB',
        tmp: 0,
        job_lines: null,
        month_lines: null,
        date_lines: null,
        searchType: "MONTH",
        jobSlotSelected: job_slot,
        monthSelected: null,
    };

    console.log(`job_slot=${job_slot}`);

    this.getJobName(job_slot);
    this.getMonths(job_slot);


  }

  ////////////////////////////////////////////////////////////////////////////////

  getJobName = (jobSlotSelected) => {
    axios
    .get(GET_JOB_NAME_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');

        this.setState({
            job_name: response.data.job_name,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get job_name")
    });
  }


  getMonths = (jobSlotSelected) => {
    axios
    .get(GET_IMAGE_HISTORY_MONTHS_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');


        let month_dicts = response.data;

        // reverse sort months completed in backend
        // console.log(`${JSON.stringify(month_strings)}`, 'background: red; color: white; display: block;')

        const lines = [];

        for (let i = 0; i < month_dicts.length; i++) {
          let current_month = month_dicts[i]

          //console.log(`current_month=${JSON.stringify(current_month)}`)
          //console.log(`current_month.month_str=${(current_month['month_str'])}`)
          //console.log(`current_month.bool_is_active=${(current_month.bool_is_active)}`)

          lines.push(
            <Line
              key={i}
              month={current_month.month_str}
              lineType={"MONTH"}
              onClickRow={this.onClickMonth}
              isActiveX={current_month.bool_is_active}
            />
          );
        }

        this.setState({
            searchType: "MONTH",
            response_dict: response.data,
            tmp: 1,
            month_lines: lines,
            jobSlotSelected: jobSlotSelected,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get list_of_months")
    });
  }


  onClickMonth = (month) => {
    const month_str = month.split(' ').join('_');
    //this.getDates(month_str);
    console.log("CLICK MONTH");

    this.setState({
        searchType: "DATE",
        monthSelected: month_str,
    });




  }

  render() {




    if (this.state.response_dict === null)
    {    return (<div / >);    }




    if (this.state.searchType == "JOB") {
        return (
                 <Redirect to={HISTORY_URL} />
               );

    }


    if (this.state.searchType == "DATE") {
        return (
                 <Redirect to={getHistoryDatesURL(this.state.jobSlotSelected, this.state.monthSelected)} />
               );
    }


    if (this.state.searchType == "MONTH") {
      return [
        <div className={s.component} key="component">
          <header className={s.header}>
            <div className={s.backlink}>
              <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                console.log("BACK1!");
                this.setState({
                               searchType: "JOB",
                              });
              }}>
                Select Job
              </BackLink>
            </div>
            <p className={s.title}> <span className={s.liteweight}>Browse History: Select Month</span></p>
          </header>
          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.month)}>Month</p>
            </div>
          </div>
          {this.state.month_lines}
        </div>,
       <HistoryBreadcrumb key="breadcrumb" job_slot={this.state.jobSlotSelected} job_name={this.state.job_name} />,
     ];
    }
  }
}


export default HistoryMonths;


