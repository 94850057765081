import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import ListHeader from 'components/list/ListHeader';
import DatasetsList from 'components/list/DatasetsList';
import Button from 'components/elements/Button';

import { getDatasetURL } from 'pages/urls';

import { selectors, createNewDataset, clearActiveDatasetID } from 'redux/reducers/datasets';


import s from './DatasetsIndex.module.sass';


import Modal from 'components/elements/Modal';

import Label from 'components/form/Label';
import Input from 'components/form/Input';
import Title from 'components/elements/Title';


class DatasetsIndex extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initial_render: true,
            show_create_dataset_modal: false,
            dataset_name: '',
            create_dataset_submit: false,
            create_dataset_type: '',
            outside_click_count: 0,
        }


    }


    componentDidMount() {
        const { clearActiveDatasetID } = this.props;
        clearActiveDatasetID()
    }

    componentDidUpdate() {
        if (this.state.initial_render===true) {
            this.setState({
                initial_render: false
            });
        }
    }

    handleChange(event, key) {
      this.setState({
        [key]: event.target.value,
      });
    }


    render() {

        const { user_ID, dataset_ID, createNewDataset } = this.props;

        const { dataset_name, create_dataset_submit, create_dataset_type, show_create_dataset_modal } = this.state;


        console.log(`user_ID=${user_ID}`)
        console.log(`dataset_ID=${dataset_ID}`)

        // this.state.initial_render===false is checked,
        // because otherwise when dataset_ID is cleared on mounting, it doesnt
        // take effect until the second render
        if (dataset_ID !==null && this.state.initial_render===false) {
          const new_dataset_overview_url = getDatasetURL(dataset_ID)
          console.log(`new_dataset_overview_url=${new_dataset_overview_url}`)
          return (
              <Redirect to={new_dataset_overview_url} />
          )
        }

        if (show_create_dataset_modal===true && dataset_name !=='' && create_dataset_submit===true) {
            console.log("*****************************************************")
            console.log("SUBMIT!")
            console.log(`create_dataset_type=${create_dataset_type}`)

            if (create_dataset_type==="PASS_FAIL") {
                createNewDataset(user_ID, dataset_name)
            }
            if (create_dataset_type==="CLASSIFICATION") {
                createNewDataset(user_ID, dataset_name, true)
            }
            if (create_dataset_type==="PASS_DETECTION") {
                createNewDataset(user_ID, dataset_name, false, true, true)
            }
            if (create_dataset_type==="FAIL_DETECTION") {
                createNewDataset(user_ID, dataset_name, false, true, false)
            }
            if (create_dataset_type==="MULTI_CLASS_DETECTION") {
                createNewDataset(user_ID, dataset_name, true, true)
            }


            console.log("*****************************************************")
            //const new_dataset_overview_url = getDatasetURL(dataset_ID)
            //console.log(`new_dataset_overview_url=${new_dataset_overview_url}`)
            //return (
            //    <Redirect to={new_dataset_overview_url} />
            //)
          }






        if (this.state.show_create_dataset_modal===true) {
            return (
                <Modal
                  key="modal"
                  isOpen={true}
                  onRequestClose={ () => {
                      console.log("REQUEST_CLOSE");
                      this.setState({
                          show_create_dataset_modal: false
                      });
                  }}
                >

                <div className={s.modal_component}>
                  <form className={s.form}>
                    <Title>Create Dataset</Title>

                    { (create_dataset_submit && dataset_name==='') &&
          			   <div className={s.create_dataset_error_message}>
          			      Please enter Dataset Name
          			   </div>
          		    }

                    <div className={s.field}>

                    <div className={s.label_component}>
                        <label htmlFor="dataset_name">Dataset Name</label>
                    </div>

                      <Input
                        id="dataset_name"
                        name="dataset_name"
                        type="text"
                        value={dataset_name}
                        onChange={(e) => this.handleChange(e, 'dataset_name')}
                        placeholder="Dataset Alpha"
                      />
                    </div>

                    <br />


                    <Button isFullWidth={true} onClick={(e) => {
                      //console.log(`dataset_name=${this.state.dataset_name}`);
                      //console.log(`password=${this.state.password}`);

                      this.setState({
                          create_dataset_submit: true,
                          create_dataset_type: "PASS_FAIL"
                      });
                      e.preventDefault();

                  }}
                  >
                    Create Pass / Fail Dataset
                  </Button>

                  <br />

                  <Button isFullWidth={true} onClick={(e) => {
                    //console.log(`dataset_name=${this.state.dataset_name}`);
                    //console.log(`password=${this.state.password}`);
                    this.setState({
                        create_dataset_submit: true,
                        create_dataset_type: "CLASSIFICATION"
                    });
                    e.preventDefault();

                }}
                >
                  Create Classification Dataset
                </Button>

                  <br />

                  <Button isFullWidth={true} onClick={(e) => {
                    //console.log(`dataset_name=${this.state.dataset_name}`);
                    //console.log(`password=${this.state.password}`);
                    this.setState({
                        create_dataset_submit: true,
                        create_dataset_type: "PASS_DETECTION"
                    });
                    e.preventDefault();

                }}
                >
                  Create Pass Detection Dataset
                </Button>

                  <br />

                  <Button isFullWidth={true} onClick={(e) => {
                    //console.log(`dataset_name=${this.state.dataset_name}`);
                    //console.log(`password=${this.state.password}`);
                    this.setState({
                        create_dataset_submit: true,
                        create_dataset_type: "FAIL_DETECTION"
                    });
                    e.preventDefault();

                }}
                >
                  Create Fail Detection Dataset
                </Button>

                  <br />

                  <Button isFullWidth={true} onClick={(e) => {
                    //console.log(`dataset_name=${this.state.dataset_name}`);
                    //console.log(`password=${this.state.password}`);
                    this.setState({
                        create_dataset_submit: true,
                        create_dataset_type: "MULTI_CLASS_DETECTION"
                    });
                    e.preventDefault();

                }}
                >
                  Create Multi-Class Detection Dataset
                </Button>


                  </form>
                </div>



                </Modal>
            );
        }

        return (
        <div className={s.component} onClick={()=>{
            console.log("OUTSIDE CLICK1");

            this.setState((prevState, props) => ({
                outside_click_count: prevState.outside_click_count + 1
            }));

        }}>
          <ListHeader title="Applications">
            <Button
              color="success"
              iconLeft="plus-square"
              onClick={() => {
                this.setState({
                    show_create_dataset_modal: true
                });
              }}
            >
            Create new Application
            </Button>

          </ListHeader>

          <br/>

          <DatasetsList outside_click_count={this.state.outside_click_count}/>
        </div>
        );
    };
};


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
  dataset_ID: selectors.getDatasetId(state),
});

export default connect(mapStateToProps, {
    createNewDataset,
    clearActiveDatasetID,
})(DatasetsIndex);
