import React from 'react';

import cx from 'classnames';
import s from './PredictionType.module.sass';

const PredictionType = (props) => {
  const { type, parentClass } = props;

  if (type === "PASS")
      var predictionType = 'pass'
  if (type === "FAIL")
      var predictionType = 'fail'

  const predictionClass = cx({
    [s.component]: true,
    [s[`type-${predictionType}`]]: predictionType,
    [parentClass]: parentClass,
  });

  return <span className={predictionClass}>{type}</span>;
};

export default PredictionType;
