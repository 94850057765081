import React from 'react';

import cx from 'classnames';
import s from './Keyboard.module.sass';

const Keyboard = (props) => {
  const { children, parentClass } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return <span className={componentClass}>{children}</span>;
};

export default Keyboard;
