import React from 'react';

import cx from 'classnames';
import s from './Message3.module.sass';

const Message3 = (props) => {
  const { title, children, type, color, hue, value } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${type}`]]: type,
    [s[`color-${color}`]]: color,
  });

  const componentStyle = hue
    ? {
        '--h': hue,
      }
    : null;

  return (
    <div className={componentClass} style={componentStyle}>
      {title && (
        <header className={s.header}>
          <p className={s.title}>{title}</p>
          {value !== null && <p className={s.value}>{value}</p>}
        </header>
      )}
      <div className={s.body}>{children}</div>
    </div>
  );
};

export default Message3;
