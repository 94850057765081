import React from 'react';
import { useParams } from 'react-router-dom';

import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';
import TrainProgress from 'components/networks/TrainProgress';

const TrainProgressPage = (props) => {
  const { datasetId, networkId } = useParams();

  return [
    <TrainProgress
      key="train-progress"
      datasetId={datasetId}
      networkId={networkId}
      nEpochs={50}
    />,
    <DatasetBreadcrumb key="breadcrumb" datasetId={datasetId} showNewNetwork />,
  ];
};

export default TrainProgressPage;
