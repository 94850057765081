// NetworkReview.js

// TODO: LOAD_VALIDATION_IMAGE_FROM_SERVER IN <Viewer img_src={} >

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import { selectors, getAllImagePredictions } from 'redux/reducers/datasets';
import {
    DOWNLOAD_JOB_FILE_URL,
    getNetworksIndexURL,
    getImagesURL,
    LOAD_TRAINING_IMAGE_FROM_SERVER_URL,
}
from 'pages/urls';

import BackLink from 'components/navigation/BackLink';
import Loader from 'components/elements/Loader';
import Empty from 'components/elements/Empty';
import Title from 'components/elements/Title';
import Heading from 'components/elements/Heading';
import Content from 'components/elements/Content';
import KeyValue from 'components/elements/KeyValue';
import ProgressBar from 'components/graphics/ProgressBar';
import Predictions from 'components/review/Predictions';
import Button from 'components/elements/Button';
import Modal from 'components/elements/Modal';
import Viewer from 'components/label/Viewer';
import Label from 'components/elements/Label';

import Message from 'components/elements/Message';
import Message3 from 'components/elements/Message3';

import LoadJobModal from 'components/camera/LoadJobModal';


import s from './NetworkReview.module.sass';

const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}


class NetworkReview extends Component {
  constructor(props) {
    super(props);

    const { dataset } = this.props;

    //print_in_green("_______________________")
    //print_in_green("_______________________")
    //print_in_green("_______________________")
    //print_in_green("_______________________")
    //print_in_green(`dataset.bool_pass_detection=${dataset.bool_pass_detection}`);
    //print_in_green("_______________________")
    //print_in_green("_______________________")
    //print_in_green("_______________________")
    //print_in_green("_______________________")

    this.state = {
      currentImageId: '',
      currentImageIndex: -1,
      isViewerOpen: false,
      isLoadJobModalOpen: false,
      viewerListType: '',
      correctList: [],
      correctFilteredList: [],
      wrongList: [],
      wrongFilteredList: [],
      correctFilterType: 'all',
      wrongFilterType: 'all',
      bool_multi_class: dataset.bool_multi_class,
      boolSegmentationEnabled: dataset.bool_detection,

      bool_pass_detection: dataset.bool_pass_detection,

      bool_multi_class_detection: (dataset.bool_multi_class === true && dataset.bool_detection===true)
    };
  }

  componentDidMount() {
      print_in_orange("MOUNT MOUNT MOUNT")
      print_in_orange("MOUNT MOUNT MOUNT")
      print_in_orange("MOUNT MOUNT MOUNT")

      this.getImagePredictions();
      this.createLists();
  }

  componentDidUpdate() {
      print_in_green("UPDATE UPDATE UPDATE")
      print_in_green("UPDATE UPDATE UPDATE")
      print_in_green("UPDATE UPDATE UPDATE")

      //this.getImagePredictions();
      //this.createLists();
  }



  componentDidUpdate(prevProps, prevState) {
    const { imagesList } = this.props;

    //console.log(`1111+++++++++++++++++++++++++++++++++++++`);
    //const obj1 = imagesList[0]
    //console.log(`imagesList${imagesList}`);

    ///for (var key in imagesList[0]) {
    //    console.log(`${key}: ${imagesList[0][key]}`);
    //}
    //console.log(`2222+++++++++++++++++++++++++++++++++++++`);


    if (imagesList !== prevProps.imagesList) {
        console.log('[NetworkReview.js] Updating imagesList');
        this.createLists();
    }
  }


    createLists = () => {

        console.log(`createLists`)
        const { imagesList, dataset } = this.props;

        //console.log(`1111xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`);
        //const obj1 = imagesList[0]
        //console.log(`%c imagesList${imagesList}`, 'background: green; color: white; display: block;');

        //for (var key in imagesList[0]) {
        //    console.log(`%c  ${key}: ${imagesList[0][key]}`, 'background: red; color: white; display: block;');
        //}
        //console.log(`2222xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx`);


        console.log(`imagesList.length=${imagesList.length}`)

        //console.log("_________________________")
        //console.log(JSON.stringify(dataset));
        //console.log("_________________________")

        const validationImagesList = imagesList.filter(
            (item) => item.isValidation === true
        );

        //for (var key in validationImagesList) {
        //    console.log(`%c  ${key}: ${imagesList[key]}`, 'background: blue; color: white; display: block;');
        //}

        console.log(`validationImagesList.length=${validationImagesList.length}`)

        /////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////

        /*
        if (dataset.bool_detection===true && dataset.bool_multi_class===true) {

            const correctList = validationImagesList;

            const wrongList = [];

            //const correctList = validationImagesList.filter(
            //    (item) => item.labelClass === item.predictionClass
            //);

            console.log(`correctList.length=${correctList.length}`)

            //const wrongList = validationImagesList.filter(
            //    (item) => item.labelClass !== item.predictionClass
            //);

            console.log(`wrongList.length=${wrongList.length}`)

            this.setState({
                correctList,
                correctFilteredList: correctList,
                wrongList,
                wrongFilteredList: wrongList,
            });

            return;
        }
        */
        /////////////////////////////////////////////////////////////////////////////
        /////////////////////////////////////////////////////////////////////////////

        const correctList = validationImagesList.filter(
            (item) => item.labelClass === item.predictionClass
        );

        console.log(`correctList.length=${correctList.length}`)

        const wrongList = validationImagesList.filter(
            (item) => item.labelClass !== item.predictionClass
        );

        console.log(`wrongList.length=${wrongList.length}`)


        this.setState({
            correctList,
            correctFilteredList: correctList,
            wrongList,
            wrongFilteredList: wrongList,
        });
    };



  filterPredictions = (filterType, listType) => {
    const { correctList, wrongList } = this.state;

    if (listType === 'correct') {
      const correctFilteredList =
        filterType === 'all'
          ? correctList
          : correctList.filter((item) => item.labelClass === filterType);

      return this.setState({
        correctFilterType: filterType,
        correctFilteredList,
      });
    } else if (listType === 'wrong') {
      const wrongFilteredList =
        filterType === 'all'
          ? wrongList
          : wrongList.filter((item) => item.labelClass === filterType);

      return this.setState({
        wrongFilterType: filterType,
        wrongFilteredList,
      });
    }
  };

  getImagePredictions = () => {
    const {
      imagesList,
      getAllImagePredictions,
      userId,
      networkId,
    } = this.props;

    //const imageIds = imagesList.map((image) => image.imageId);
    getAllImagePredictions(userId, networkId);
  };

  openViewer = (imageId, listType) => {
    const viewerList =
      listType === 'correct'
        ? this.state.correctFilteredList
        : this.state.wrongFilteredList;

    const currentImageIndex = imageId
      ? viewerList.findIndex((image) => image.id === imageId)
      : false;

    this.setState({
      currentImageId: imageId,
      currentImageIndex,
      isViewerOpen: true,
      viewerListType: listType,
    });
  };

  closeViewer = () => {
    this.setState({
      isViewerOpen: false,
      viewerListType: '',
    });
  };


  openLoadJobModal = () => {
    this.setState({
      isLoadJobModalOpen: true,
    });
  };

  closeLoadJobModal = () => {
    this.setState({
      isLoadJobModalOpen: false,
    });
  };









  imageTransition = (direction) => {


    console.log(`%c direction=${direction}`, 'background: blue; color: white; display: block;');

    const { imagesList } = this.props;

    const { currentImageIndex, viewerListType } = this.state;


    const validationImagesList = imagesList.filter(
        (item) => item.isValidation === true
    );
    console.log(`%c validationImagesList=${validationImagesList}`, 'background: green; color: white; display: block;');


    console.log(`%c currentImageIndex=${currentImageIndex}`, 'background: blue; color: white; display: block;');
    console.log(`%c viewerListType=${viewerListType}`, 'background: blue; color: white; display: block;');

    const viewerList =
        viewerListType === 'correct'
        ? this.state.correctFilteredList
        : this.state.wrongFilteredList;

    if (!direction) {
      return;
    }

    const lastIndex = viewerList.length - 1;

    const nextIndex =
      direction === 'next'
        ? currentImageIndex + 1
        : direction === 'previous'
        ? currentImageIndex - 1
        : currentImageIndex;

    // Bail if out of bounds
    if (nextIndex > lastIndex) {
      this.closeViewer();
      return;
    } else if (nextIndex < 0) {
      return;
    }

    const nextId = viewerList[nextIndex].id;

    console.log(`%c nextId=${nextId}`, 'background: blue; color: white; display: block;');
    console.log(`%c nextIndex=${nextIndex}`, 'background: blue; color: white; display: block;');


    this.setState({
      currentImageId: nextId,
      currentImageIndex: nextIndex,
    });
  };

  render() {
    const { imagesList = [], userId, networkId, isLoading, datasetId, network } = this.props;
    const {
      currentImageId,
      currentImageIndex,
      isViewerOpen,
      isLoadJobModalOpen,
      viewerListType,
      correctList,
      correctFilteredList,
      correctFilterType,
      wrongList,
      wrongFilteredList,
      wrongFilterType,
    } = this.state;

    console.log(`network=${JSON.stringify(network)}`);

    const viewerList =
      viewerListType === 'correct' ? correctFilteredList : wrongFilteredList;

    if (imagesList.length === 0) {
      return [
        <Empty key="empty">
          <Heading surtitle="Error">You have no images</Heading>
          <Button to={getImagesURL(datasetId)}>Upload images</Button>
        </Empty>,
      ];
    }

    if (isLoading) {
      return [
        <Empty key="empty">
          <Heading>Fetching data…</Heading>
          <Loader parentClass={s.loader} />
        </Empty>,
      ];
    }

    const validationImagesList = imagesList.filter(
      (item) => item.isValidation === true
    );


//////

    // Counts
    const allCount = validationImagesList.length;

    // ____________________________________________________ //

    const failCount = validationImagesList.filter(
        (item) => item.labelClass === 'FAIL'
    ).length;

    const correctFailCount = correctList.filter(
        (item) => item.labelClass === 'FAIL'
    ).length;

    // ____________________________________________________ //

    //const correctFailPercentage = Math.round(
    //  (correctFailCount / failCount) * 100
    //);

    //const missedCount = wrongList.filter((item) => item.labelClass === 'FAIL')
    //  .length;

    //const missedPercentage = Math.round((missedCount / failCount) * 100);

    // ____________________________________________________ //

    const accuracyPercentage = Math.round(
      (correctList.length / allCount) * 100
    );

    // ____________________________________________________ //

    const falseNegativesCount = wrongList.filter(
      (item) => item.labelClass === 'PASS'
    ).length;

    const falseNegativesPercentage = Math.round(
      (falseNegativesCount / allCount) * 100
    );

    // ____________________________________________________ //

    const falsePositivesCount = wrongList.filter(
      (item) => item.labelClass === 'FAIL'
    ).length;

    const falsePositivesPercentage = Math.round(
      (falsePositivesCount / allCount) * 100
    );

    // ____________________________________________________ //

    // Images for Viewer navigation
    console.log(`currentImageId=${currentImageId}`)

    //if (currentImageId === '') {
    //}

    const currentImage =
      imagesList.find((image) => image.id === currentImageId) || correctList[0];


    //const firstImage = correctList[0];

    //const buttonOnClick = firstImage
    //  ? () => this.openViewer(firstImage.id, 'correct')
    //  : null;

    const networkURL = getNetworksIndexURL(datasetId);

    console.log(`this.state.bool_multi_class=${this.state.bool_multi_class}`);

    return [
      <div key="component" className={s.component}>
        <aside className={s.side}>
          <div className={s.back}>
            <BackLink parentClass={s.backLink} to={networkURL}>
              Back to list
            </BackLink>
          </div>

          <div className={s.sideBody}>
            <Title>{network.name}: TRAINING RESULTS</Title>

            <Content parentClass={s.content}>
                <ul>
                  <li>
                    Trained on {imagesList.length - allCount} images
                  </li>
                  <li>
                    Tested on {allCount} images
                    <ul>

                      { (this.state.bool_multi_class !== true) &&
                        ( <li>
                            Found {correctFailCount} / {failCount} defects
                          </li> )
                      }
                      { (this.state.bool_multi_class === true) &&
                        ( <li>
                            Correctly identified {correctList.length} / {allCount} images
                          </li> )
                      }



                      {/*
                      <li>
                        <strong className={s.correct}>{falsePositivesCount} False Positives </strong>
                      </li>
                      <li>
                        <strong className={s.correct}>{missedCount} False Negatives </strong>
                      </li> */}

                    </ul>
                  </li>
                </ul>

            </Content>


                      <Message3
                        value={`${accuracyPercentage}%`}
                        title="Accuracy"
                        hue="200"
                      >
                        <p> % of defects found </p>
                      </Message3>

                      { (this.state.bool_multi_class !== true) &&
                        (
                          <Message3
                            value={`${falseNegativesPercentage}%`}
                            title="False Negatives"
                            hue="200"
                          >
                            <p>% of GOOD PARTS failed by the network</p>
                          </Message3>
                        )
                      }
                      { (this.state.bool_multi_class !== true) &&
                        (
                         <Message3
                           value={`${falsePositivesPercentage}%`}
                           title="False Positives"
                           hue="200"
                         >
                           <p> % of BAD PARTS passed by the network</p>
                         </Message3>
                        )
                      }
                      { (this.state.bool_multi_class === true) &&
                        (
                          <Message3
                            value={`N/A`}
                            title="False Negatives"
                            hue="200"
                          >
                            <p>% of GOOD PARTS failed by the network</p>
                          </Message3>
                        )
                      }
                      { (this.state.bool_multi_class === true) &&
                        (

                         <Message3
                           value={`N/A`}
                           title="False Positives"
                           hue="200"
                         >
                           <p> % of BAD PARTS passed by the network</p>
                         </Message3>

                        )
                      }

           <br />

           {/*

            <Content parentClass={s.content}>
              <p>
                Tested trained network on <strong>{allCount}</strong> images.
                <br />
                Found <strong className={s.correct}>
                  {correctFailCount}
                </strong>{' '}
                of <strong className={s.fail}>{failCount}</strong> defects, with{' '}
                <strong className={s.wrong}>{missedCount}</strong> missed
                defects and{' '}
                <strong className={s.wrong}>{falsePositivesCount}</strong> false
                positives.
              </p>
            </Content>

            <div className={s.data}>
              <KeyValue value={correctFailCount} type="correct" info="% of defects found">
                Accuracy
              </KeyValue>

              <ProgressBar
                value={correctFailPercentage / 100}
                type="correct"
                label="accuracy"
              />
            </div>

            <div className={s.data}>
              <KeyValue
                value={missedCount}
                info="Defects that were not detected"
                type="wrong"
              >
                False positives
              </KeyValue>

              <ProgressBar
                value={missedPercentage / 100}
                type="wrong"
                label="inaccuracy"
              />
            </div>

            <div className={s.data}>
              <KeyValue
                value={falsePositivesCount}
                info="GOOD PARTS that were failed by the network"
                type="wrong"
              >
                False Negatives
              </KeyValue>

              <ProgressBar
                value={falsePositivesPercentage / 100}
                type="wrong"
                label="missed"
              />
            </div>

            */}

            <div className={s.network_buttons}>

              <Button iconRight="arrow-right" onClick={()=>{
                  this.openViewer(correctList[0].id, 'correct')
              }}
              >
                Review Neural Network predictions
              </Button>

              {/*
              <Button
                  parentClass={s.button}
                  iconLeft="arrow-down"
                  onClick={()=>{
                      console.log("DOWNLOAD BUTTON!");
                      const downloadJobFileUrl = `${DOWNLOAD_JOB_FILE_URL}/${networkId}`;
                      window.location.href = downloadJobFileUrl;
                  }}
              >
                Download Neural Network Job File
              </Button> */}

              <Button
                  parentClass={s.button}
                  color="success"
                  iconRight="arrow-right"
                  onClick={()=>{
                      console.log("LOAD JOB BUTTON!");
                      this.openLoadJobModal()
                  }}
              >
                Load Neural Network to Job Slot
              </Button>

            </div>



          </div>
        </aside>

        <main className={s.main}>
          <Predictions
            type="correct"
            parentClass={s.predictions}
            imagesList={correctList}
            filteredList={correctFilteredList}
            filterType={correctFilterType}
            onFilterClick={(filterType) =>
              this.filterPredictions(filterType, 'correct')
            }
            onGalleryClick={(imageId) => this.openViewer(imageId, 'correct')}
            bool_multi_class={this.state.bool_multi_class}
            bool_multi_class_detection={this.state.bool_multi_class_detection}
          />

          <Predictions
            type="wrong"
            parentClass={s.predictions}
            imagesList={wrongList}
            filteredList={wrongFilteredList}
            filterType={wrongFilterType}
            onFilterClick={(filterType) =>
              this.filterPredictions(filterType, 'wrong')
            }
            onGalleryClick={(imageId) => this.openViewer(imageId, 'wrong')}
            bool_multi_class={this.state.bool_multi_class}
            bool_multi_class_detection={this.state.bool_multi_class_detection}
          />
        </main>
      </div>,
      <Modal
        key="modal"
        isOpen={isViewerOpen}
        onRequestClose={this.closeViewer}
      >
        <Viewer
          datasetId={datasetId}
          image={currentImage}
          img_src={LOAD_TRAINING_IMAGE_FROM_SERVER_URL + `/${userId}/${datasetId}/${currentImageId}`}
          boolSegmentationEnabled={this.state.boolSegmentationEnabled}
          imageIndex={currentImageIndex}
          imageCount={viewerList.length}
          onNextImageCallback={() => this.imageTransition('next')}
          onPreviousImageCallback={() => this.imageTransition('previous')}
          isReadOnly={true}

          bool_pass_detection={this.state.bool_pass_detection}
          bool_multi_class_detection={this.state.bool_multi_class_detection}

          bool_history_image={false}

        />
      </Modal>,
      <LoadJobModal
        key="modal2"
        isOpen={isLoadJobModalOpen}
        onClose={this.closeLoadJobModal}
        user_ID={ userId}
        dataset_ID={ datasetId}
        network_ID={ networkId }
        network_name={ network.name }
        network_type={ network.type }
      />
    ];
  }
}

const mapStateToProps = (state, ownProps) => ({
  userId: selectors.getUserId(state),
  imagesList: selectors.getDatasetImages(state),
  network: selectors.getNetwork(state, ownProps.networkId),
  isLoading: selectors.isLoading(state),
  dataset: selectors.getDataset(state, ownProps.datasetId),
});

export default connect(mapStateToProps, {
  getAllImagePredictions,
})(NetworkReview);
