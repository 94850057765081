import React, { Component } from 'react';
//import { NavLink } from 'react-router-dom';

import { Redirect } from 'react-router-dom';


import BrowseHistory from 'pages/camera/BrowseHistory';

import { BROWSE_URL,
         GET_IMAGES_ZIP_URL,
         GET_IMAGE_HISTORY_JOBS_URL,
         GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL,
         getHistoryMonthsURL, } from 'pages/urls';

import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './History.module.sass';

import axios from 'axios';

import Line from 'pages/camera/Line';

import HistoryDates from 'pages/camera/HistoryDates';


import HistoryBreadcrumb from 'components/navigation/HistoryBreadcrumb';


//import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}


// searchType one of: "JOB", "MONTH", "DATE"
//
// History displays:
//   Job [0-8]
//     Month <Month>_<yyyy>
//       Date <Month>_<dd>_<yyyy>
//


class HistoryJobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
        response_dict: null,
        tmp: 0,
        job_lines: null,
        month_lines: null,
        date_lines: null,
        searchType: "JOB",
        jobSlotSelected: null,
        monthSelected: null,
        active_job_slot: -1,
    };

      this.getJobs();
      //this.getMonths();


  }

  ////////////////////////////////////////////////////////////////////////////////


  componentDidMount() {
    // TODO: clear interval when training complete
    this.interval = setInterval(() => this.getJobs(), 1500);
    print_in_blue("MOUNT MOUNT MOUNT")
    print_in_blue("MOUNT MOUNT MOUNT")
    print_in_blue("MOUNT MOUNT MOUNT")
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    print_in_blue("UNMOUNT UNMOUNT UNMOUNT")
    clearInterval(this.interval);
  }



  ////////////////////////////////////////////////////////////////////////////////


  getJobs = () => {

    console.log("getJobs")

    axios
    .get(GET_IMAGE_HISTORY_JOBS_URL)
    .then((response) => {
        //console.log('_________________');
        //console.log(JSON.stringify(response.data));
        //console.log('_________________');

        const lines = [];

        var new_active_job_slot = null;

        /////////////////////////////////////////

        for (let i = 0; i < response.data.length; i++) {
          let current_job = response.data[i]
          //console.log("@@@@@@@");
          //console.log(JSON.stringify(current_job));

          if (current_job.bool_is_active) {
              new_active_job_slot = current_job.slot;
          }


        /////////////////////////////////////////

        if (new_active_job_slot !== this.state.active_job_slot) {

            for (let i = 0; i < response.data.length; i++) {
               let current_job = response.data[i]

               if (current_job.name !== null) {
                   //console.log(`current_job.slot=${current_job.slot}`);
                   //console.log(`this.state.jobSlotSelected=${this.state.jobSlotSelected}`);
                   lines.push(
                       <Line
                           key={i}
                           lineType={"JOB"}
                           jobName={current_job.name}
                           jobSlot={current_job.slot}
                           onClickRow={this.onClickJob}
                           isActiveX={current_job.bool_is_active}
                       />
                    );
                 }
           }

           this.setState({
              response_dict: response.data,
              tmp: 2,
              job_lines: lines,
           });
        }
     }

    })
    .catch((error) => {
        console.log("[ERROR] get list_of_jobs")
    });
  }

  ////////////////////////////////////////////////////////////////////////////////

/*
  getMonths = (jobSlotSelected) => {
    axios
    .get(GET_IMAGE_HISTORY_MONTHS_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');
        const lines = [];
        for (let i = 0; i < response.data.months.length; i++) {
          let current_month = response.data.months[i]
          //console.log(`current_month=${current_month}`)
          lines.push(
            <Line
              key={i}
              month={current_month}
              lineType={"MONTH"}
              onClickRow={this.onClickMonth}
            />
          );
        }
        this.setState({
            searchType: "MONTH",
            response_dict: response.data,
            tmp: 1,
            month_lines: lines,
            jobSlotSelected: jobSlotSelected,
        });
    })
    .catch((error) => {
        console.log("[ERROR] get list_of_months")
    });
  }
  ////////////////////////////////////////////////////////////////////////////////
  getDates = (month_str) => {
    const job_slot_str = this.state.jobSlotSelected.toString();
    axios
    .get(GET_IMAGE_HISTORY_DATES_URL + '/' + job_slot_str + '/' + month_str)
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');
        const lines = [];
        let date_strings = response.data.dates;
        console.log(`${JSON.stringify(date_strings)}`, 'background: red; color: white; display: block;')
        date_strings = date_strings.sort().reverse();
        console.log(`${JSON.stringify(date_strings)}`, 'background: red; color: white; display: block;')
        for (let i = 0; i < date_strings.length; i++) {
          let current_date = date_strings[i]
          //console.log(`current_month=${current_month}`)
          lines.push(
            <Line
              key={i}
              jobSlot={job_slot_str}
              month={month_str}
              date={current_date}
              lineType={"DATE"}
              onClickRow={this.onClickDate}
            />
          );
        }
        this.setState({
            searchType: "DATE",
            response_dict: response.data,
            tmp: 1,
            date_lines: lines,
            monthSelected: month_str,
        });
    })
    .catch((error) => {
        console.log("[ERROR] get list_of_dates")
    });
  }
*/
  ////////////////////////////////////////////////////////////////////////////////


  onClickJob = (jobSlotSelected) => {
    //this.getMonths(jobSlotSelected);
    console.log("CLICK Job SLOT");

    this.setState({
      searchType: "MONTH",

      jobSlotSelected: jobSlotSelected,


    });




  }

/*
  onClickMonth = (month) => {
    const month_str = month.split(' ').join('_');
    this.getDates(month_str);
  }
  onClickDate = (date) => {
    const date_str = date.split(' ').join('_');
    this.setState({
        searchType: "DATE_SELECTED",
        //lines: null,
        dateSelected: date_str,
    });
  }
*/

  render() {

    //console.log("RENDER RENDER RENDER RENDER");
    //console.log("RENDER RENDER RENDER RENDER");


    if (this.state.searchType === "MONTH") {
        return (
                 <Redirect to={getHistoryMonthsURL(this.state.jobSlotSelected)} />
	       );
    }





    if (this.state.response_dict === null)
    {    return (<div / >);    }


    if (this.state.searchType === "JOB") {


      return [
        <div className={s.component} key="component">

          <header className={s.header}>
            <p className={s.title}> <span className={s.liteweight}>Browse History: Select Job</span></p>
          </header>
          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.job_name)}>Job Name</p>
              <p className={cx(s.cell, s.job_slot)}>Job Slot</p>

            </div>
          </div>
          {this.state.job_lines}
        </div>,
       <HistoryBreadcrumb key="breadcrumb" />,
     ];

    }

    /*
    else if (this.state.searchType == "MONTH") {
      return (
        <div className={s.component}>
          <header className={s.header}>
            <div className={s.backlink}>
              <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                console.log("BACK1!");
                this.setState({
                               searchType: "JOB",
                              });
              }}>
                Select Job
              </BackLink>
            </div>
            <p className={s.title}> <span className={s.liteweight}>Browse History: Select Month</span></p>
          </header>
          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.month)}>Month</p>
            </div>
          </div>
          {this.state.month_lines}
        </div>
      );
    }
    else if (this.state.searchType == "DATE") {
      return (
        <div className={s.component}>
          <header className={s.header}>
            <div className={s.backlink}>
              <BackLink parentClass={s.backLink} to='#' onClick={()=>{
                console.log("BACK2!");
                this.setState({
                               searchType: "MONTH",
                              });
              }}>
                Select Month
              </BackLink>
            </div>
            <p className={s.title}> <span className={s.liteweight}>Browse History: Select Date</span></p>
          </header>
          <br />
          <div className={s.head}>
            <div className={s.content}>
              <p className={cx(s.cell, s.month)}>Date</p>
              <p className={cx(s.cell, s.action)}>Action</p>
            </div>
          </div>
          {this.state.date_lines}
        </div>
      );
    }
    else if (this.state.searchType == "DATE_SELECTED") {
      return (
        <BrowseHistory job_slot_str={this.state.jobSlotSelected.toString()} month_str={this.state.monthSelected} images_date={this.state.dateSelected}/>
      );
    }
    */

    else {
      return (<div / >);
    }

  }
}

export default HistoryJobs;
