// ImageSetLine.js

import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';

import BrowseHistory from 'pages/camera/BrowseHistory';

import { BROWSE_URL,
         GET_IMAGES_ZIP_URL,
         GET_IMAGE_HISTORY_JOBS_URL,
         GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL,
         getHistoryJobURL,
} from 'pages/urls';

import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './History.module.sass';

import axios from 'axios';

import Tag from 'components/elements/Tag';


import Title from 'components/elements/Title';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input2 from 'components/form/Input2';


// lineType one of: "JOB", "MONTH", "DATE"
//
// History displays:
//   Job [0-8]
//     Month <Month>_<yyyy>
//       Date <Month>_<dd>_<yyyy>
//

const ImageSetLine = (props) => {
  const { jobName, jobSlot, month, date, imageset_object, jobCount, imageCount, failPercentage, lineType, onClickRow, isTodayX, isActiveX, isFullX, onClickActivate, onClickDelete, onClickEdit, bool_stop_editing, onClickUpdateImageSetName, imageset_display_nameX, bool_production_mode=false } = props;

  //console.log(`ZLOG params() = ${JSON.stringify(props)}`);
  //console.log(`update() = ${onClickUpdateImageSetName}`);

  //const [bool_ignore_stop_editing, set_bool_ignore_stop_editing] = useState(false);

  const [bool_editing_imageset_name, set_bool_editing_imageset_name] = useState(false);
  const [imageset_display_name, set_imageset_display_name] = useState(imageset_display_nameX);

  // this is needed to correct weird react issue that causes datasets to show the wrong name
  if (imageset_display_nameX && bool_editing_imageset_name===false && imageset_display_nameX != imageset_display_name) {
    set_imageset_display_name(imageset_display_nameX);
  }

  //if (bool_ignore_stop_editing===false && bool_editing_imageset_name===true && bool_stop_editing===true) {
  if (bool_editing_imageset_name && bool_stop_editing === true) {
      set_bool_editing_imageset_name(false);
      //console.log(`000ooooooo0000000ooooooo000`);
      //console.log(`onClickUpdateImageSetName=${onClickUpdateImageSetName}`);
      onClickUpdateImageSetName(imageset_display_name);
      //set_bool_ignore_stop_editing(true);
  }

    return (
      <div className={s.row} onClick={()=>{

        if (bool_production_mode && isActiveX) {
          console.log("CANT ACTIVATE IN PRODUCTION MODE");

        } else {
          onClickRow(imageset_object)

        }
      }}>
        <div className={s.content}>

          {/* _____________________________________________________________________ */}
                    <Title parentClass={s.title2} noMargin>

                       { (bool_production_mode===false) && (

                          <span className={s.iconEdit} onClick={(e)=>{
                             console.log(`EDIT CLICK`);
                             e.stopPropagation();


                             const { imageset_object, onClickEdit } = props;

                             //console.log(`EDIT: imageset_object.name=${imageset_object.name}`);

                             var bool_already_editing = onClickEdit(imageset_object.name);

                             //console.log(`EDIT: bool_already_editing=${bool_already_editing}`);

                             if (bool_already_editing===false) {
                                 set_bool_editing_imageset_name(true)
                                 //set_bool_ignore_stop_editing(false);
                             }

                             //this.setState({
                             //    bool_editing_network_name: true,
                             //    active_network_name_object: network_name_object ? network_name_object : { name: network.name, id: network.id },
                             //});



                             /*
                                 prev_outside_click_count: outside_click_count,
                                 bool_editing_display_name: true,
                                 active_dataset_ID: item.id,
                                 active_dataset_display_name: NEW_item ? NEW_item.display_name : item.name,
                             });
                             */
                         }}>
                             <FontAwesomeIcon icon="pen" />
                         </span>
                      )}

                             { (bool_editing_imageset_name) ? (
                                    <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={imageset_display_name}
                                            onChange={(e) => {
                                                console.log("change imageset_display+name");
                                                set_imageset_display_name(e.target.value)
                                                //this.setState({
                                                //    active_network_name_object: { name: e.target.value, id: this.state.active_network_name_object.id }
                                                //});

                                            }}
                                            placeholder="ImageSet Alpha"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {imageset_display_name}
                                     </div>
                              )}



                  </Title>

          {/* _____________________________________________________________________ */}
          {/*
          <p className={cx(s.cell, s.month)}>{`${imageset_object.display_name}`} </p>
          */}

          {(bool_production_mode===false) && (
            <div className={s.imageset_info3}>
            { (isActiveX) ?
              (
                <Tag parentClass={s.tag} isActive>
                  <div className={s.tagText}>
                    Active
                  </div>
                </Tag>
              )
              :
              ( (isFullX===false) ?
                (
                  <Button parentClass={cx(s.button2, s.loadJob2)} onClick={(e)=>{
                      e.stopPropagation();
                      console.log("ACTIVATE BUTTON!");
                      const { imageset_object, onClickActivate } = props;
                      onClickActivate(imageset_object.name);


                  }}>
                  &nbsp; Activate &nbsp;
                  </Button>
                )
                :
                (
                  <Button isDisabled={true} parentClass={cx(s.button3, s.loadJob2)}>
                   &nbsp; &nbsp; &nbsp; Full &nbsp; &nbsp; &nbsp;
                  </Button>
                )
              )
            }
            </div>

          )}


          {(bool_production_mode===true) && (
            <div className={s.imageset_info3}>
            { (isActiveX) ?
              (
                <Tag parentClass={s.tag} isActive>
                  <div className={s.tagText}>
                    Active
                  </div>
                </Tag>
              )
              :
              (
                 <div/>
              )
            }
            </div>

          )}



             <div className={s.imageset_info2}>
                 <ul>
                     <li>
                         {imageset_object.N_images} images
                     </li>
                     <li>
                         {imageset_object.start_time} - {imageset_object.end_time}
                     </li>
                 </ul>
             </div>

         {/* (bool_production_mode===true) && (
             <div className={s.imageset_info}>
                        {imageset_object.start_time} - {imageset_object.end_time}
             </div>
         )*/}
         {/* (bool_production_mode===true) && (
             <div className={s.imageset_info}>
                        {imageset_object.N_images} images
             </div>
          )*/}





          <p className={s.imageset_buttons}>

            <Button
              parentClass={s.button}
              iconLeft="chart-pie"
              color="success"
              isDisabled={isActiveX && bool_production_mode}
            >
            View All
            </Button>

            <Button
              parentClass={s.button}
              iconLeft="arrow-down"
              isDisabled={isActiveX && bool_production_mode}
              //onClick={onButtonClick}
              onClick={(e)=>{
                  //var e = window.event;
                  //e.cancelBubble = true;
                  //if (e.stopPropagation)
                  e.stopPropagation();

                  console.log("DOWNLOAD BUTTON!");

                  console.log(`jobSlot=${jobSlot}`);
                  console.log(`MONTH=${month}`);

                  const date_str = date.split(' ').join('_');
                  const date_timestamp_str = new Date().toISOString()
                  //jobSlotSelected.toString() this.state.monthSelected} this.state.dateSelected
                  const getZipUrl = GET_IMAGES_ZIP_URL + `/${jobSlot}/${month}/${date_str}/${imageset_object.name}/${date_timestamp_str}`;
                  window.location.href = getZipUrl;
                  //const downloadJobFileUrl = `${DOWNLOAD_JOB_FILE_URL}/${network.id}`;
                  //window.location.href = downloadJobFileUrl;

             }}
            >
              Download
            </Button>

            <Button
                      parentClass={s.button}
                      color="danger"
                      iconLeft="times-circle"
                      isDisabled={isActiveX && bool_production_mode}
                      onClick={(e)=>{
                          //var e = window.event;
                          //e.cancelBubble = true;
                          //if (e.stopPropagation)
                          e.stopPropagation();
                          console.log("DELETE BUTTON!");

                          const { imageset_object, onClickDelete } = props;

                          /*
                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")

                          console.log(`imageset_object=${JSON.stringify(imageset_object)})`)

                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")
                          console.log("++++++++++++++++++++++++")

                          //console.log(`onClickDelete=${onClickDelete})`)

                          //console.log("++++++++++++++++++++++++")
                          //console.log("++++++++++++++++++++++++")
                          //console.log("++++++++++++++++++++++++")
                          //console.log("++++++++++++++++++++++++")
                          */

                          onClickDelete(imageset_object.name);

                      }}
            >
                Delete
            </Button>

          </p>
        </div>
      </div>

    );

};

export default ImageSetLine;

