import React from 'react';

import s from './Input.module.sass';

const Input = (props) => {
  const { id, name, value, type = 'text', placeholder, onChange } = props;

  return (
    <input
      className={s.component}
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default Input;
