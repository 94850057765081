import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { NavLink, Redirect } from 'react-router-dom';

import { selectors, getListOfDatasets } from 'redux/reducers/datasets';

import Title from 'components/elements/Title';

import cx from 'classnames';
import s from './DatasetsList.module.sass';

import ImagePreviewCarousel from 'components/list/ImagePreviewCarousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input2 from 'components/form/Input2';

import axios from 'axios';

import Modal from 'components/elements/Modal';
import ConfirmationModal from 'components/elements/ConfirmationModal';


import {
  getDatasetURL,
  SET_DATASET_NAME_URL,
  DELETE_DATASET_URL,
} from 'pages/urls';


const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}




class DatasetsList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bool_editing_display_name: false,
            prev_outside_click_count: -1,
            active_dataset_ID: null,
            active_dataset_display_name: null,
            updated_display_name_object: [],
            bool_delete_dataset_confirmation_modal_open: false,
            delete_dataset_ID: null,
            delete_dataset_name: null,
            list_of_deleted_dataset_IDs: [],
            bool_redirect_to_datasets_list: false,


        };

    }

    componentDidMount() {
        const { datasets, getListOfDatasets, user_ID } = this.props;

        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        console.log(`DatasetsList user_ID=${user_ID}`)
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
        getListOfDatasets(user_ID)
    }


    onOutsideClick(outside_click_count) {
        this.setState({
            prev_outside_click_count: outside_click_count,
            bool_editing_display_name: false,
        });

        const { datasets, user_ID } = this.props;

        const active_dataset = datasets.find(x => x.id === this.state.active_dataset_ID)


        const NEW_item = this.state.updated_display_name_object.find(x => x.id === active_dataset.id)


        const NEW_active_dataset_display_name = NEW_item ? NEW_item.display_name : active_dataset.name;


        //console.log(`datasets=${JSON.stringify(datasets)}`);
        console.log(`active_dataset=${JSON.stringify(active_dataset)}`);


        if (this.state.active_dataset_display_name != NEW_active_dataset_display_name) {
            console.log(`dataset_display_name changed`);

            ///////////////////////////////////////////////////////

            // change dataset name
            axios
                .get(`${SET_DATASET_NAME_URL}/${user_ID}/${active_dataset.id}/${this.state.active_dataset_display_name}`)
                .then((response) => {
                    console.log('RECV SET_DATASET_NAME');
                    console.log(JSON.stringify(response.data));
                    console.log('RECV SET_DATASET_NAME');

                    //this.setState({ myArray: [...this.state.myArray, 'new value'] })

                    const active_dataset_display_name_object = this.state.updated_display_name_object.find(x => x.id === this.state.active_dataset_ID)

                    if (active_dataset_display_name_object) {
                        active_dataset_display_name_object.display_name = this.state.active_dataset_display_name;

                        const NEW_updated_display_name_object = this.state.updated_display_name_object.map((obj, index) => {
                            return (obj.id === this.state.active_dataset_ID) ? active_dataset_display_name_object : obj;
                        });

                        console.log(`yy1=${JSON.stringify(NEW_updated_display_name_object)}`);

                        this.setState( (prevState) => ({
                            active_dataset_ID: null,
                            active_dataset_display_name: null,
                            updated_display_name_object: NEW_updated_display_name_object,
                        }));


                    }
                    else {
                        const new_display_name_object = {
                            id: this.state.active_dataset_ID,
                            display_name: this.state.active_dataset_display_name,
                        }
                        console.log(`xx1=${JSON.stringify(new_display_name_object)}`);

                        console.log(`xx2=${JSON.stringify([...this.state.updated_display_name_object, new_display_name_object])}`);
                        this.setState( (prevState) => ({
                            active_dataset_ID: null,
                            active_dataset_display_name: null,
                            updated_display_name_object: [...prevState.updated_display_name_object, new_display_name_object],

                        }));
                    }

                    getListOfDatasets(user_ID)


                })
                .catch((error) => {
                    console.log("[ERROR] set dataset_name")
                });

                  ///////////////////////////////////////////////////////
        }





    }




    render() {
    //const DatasetsList = (props) => {
        const { datasets, outside_click_count, user_ID } = this.props;

        //if (this.state.bool_redirect_to_datasets_list === true) {
        //    return ( <Redirect to={"/training"} /> )
        //}



        //getListOfDatasets(1);
        console.log(`outside_click_count=${outside_click_count}`);
        if (outside_click_count != this.state.prev_outside_click_count && this.state.bool_editing_display_name===true) {
            this.onOutsideClick(outside_click_count);
        }


        const listItems = datasets.filter((item)=>{
        // FILTER
            if (this.state.list_of_deleted_dataset_IDs.includes(item.id)) {
                return false; // skip
            }
            return true;
        // MAP
        }).map((item) => {
            //const statusType = item.labellingPercentage === 100 ? 'success' : 'warning';

            const NEW_item = this.state.updated_display_name_object.find(x => x.id === item.id)

            const statusType = 'warning';

            const statusClass = cx({
              [s.status]: true,
              [`is-${statusType}`]: statusType,
            });

            //print_in_red(`item.id=${item.id}`)

            //print_in_red(`item=${JSON.stringify(item)}`)

            //console.log(`this.state=${JSON.stringify(this.state)}`);

            return (
              <div key={item.id} className={s.item} onClick={(e)=>{
                    //console.log("dataset CLICK!");
                    //console.log(`dataset CLICK! bool_editing_display_name=${this.state.bool_editing_display_name}`);
                    if (this.state.bool_editing_display_name===true) {
                        //e.stopPropogation();
                        //e.stopPropogation();
                        //e.preventDefault();
                        this.onOutsideClick(outside_click_count);

                    }
                }}>

                { (this.state.bool_editing_display_name===true)
                  ? (<NavLink to='#' className={s.link}/>)
                  : (<NavLink to={getDatasetURL(item.id)} className={s.link}/>) }


                <div className={s.container}>
                  <figure className={s.figure}>
                      <ImagePreviewCarousel dataset_ID={item.id} list_of_image_IDs={item.firstTenImageIDs}/>
                  </figure>

                  <div className={s.text}>
                    <header className={s.header}>
                      <Title noMargin parentClass={s.title}>

                          {/*******************************************************/}

                              { (this.state.bool_editing_display_name && (this.state.active_dataset_ID===item.id) )? (
                                    <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={this.state.active_dataset_display_name}
                                            onChange={(e) => {
                                                //console.log("change dataset display name");
                                                //set_dataset_display_name(e.target.value)
                                                this.setState({
                                                    active_dataset_display_name: e.target.value,
                                                });

                                            }}
                                            placeholder="Dataset Alpha"
                                            onClick={(e)=>{
                                                //console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                   (NEW_item ? (<span className={s.titleText}> {NEW_item.display_name} </span>) : (<span className={s.titleText}> {item.name} </span>))
                              )}

                          {/*******************************************************/}


                         <span className={s.iconEdit} onClick={(e)=>{
                             //console.log(`EDIT CLICK`);
                             this.setState({
                                 prev_outside_click_count: outside_click_count,
                                 bool_editing_display_name: true,
                                 active_dataset_ID: item.id,
                                 active_dataset_display_name: NEW_item ? NEW_item.display_name : item.name,
                             });
                             e.stopPropagation();
                         }}>
                             <FontAwesomeIcon icon="pen" />
                         </span>



                         <span className={s.iconDelete} onClick={()=>{
                             //console.log(`DELETE CLICK!`);
                             if (this.state.bool_editing_display_name === false) {

                             this.setState({
                                 bool_delete_dataset_confirmation_modal_open: true,
                                 delete_dataset_ID: item.id,
                                 delete_dataset_name: item.name,
                             });


                             }
                         }}>
                             <FontAwesomeIcon icon="times-circle" />
                         </span>

                      </Title>

                      <ul className={s.info}>
                        <li>
                          {item.imageCount} images
                        </li>
                        <li>
                          {/*<em>Total size:</em> {item.size}*/}
                          <em>Total size:</em> {item.imageCount} MB
                        </li>
                        <li>
                          <em>Last modified:</em>{' '}
                          {moment.unix(item.lastModified).format('MMMM D, YYYY h:mm a')}
                        </li>
                      </ul>
                    </header>

                    <footer className={s.footer}>
                      <p className={statusClass}>
                        Labelling <strong>{item.labellingPercentage}%</strong> complete
                        <span>/</span>

                        { (item.imageCount===0) ?
                          ( <strong>Next Step: Upload Images</strong> )
                          : ( (item.labellingPercentage===100) ?
                              (<strong>Next Step: Train Neural Network</strong>)
                              : (<strong>Next Step: Label Images</strong> ) ) }

                      </p>

                      <ul className={s.tags}>
                        <li className="is-pass">
                          <strong>{item.labellingSplit.pass}%</strong> labeled{' '}
                          <strong>PASS</strong>
                        </li>
                        <li className="is-fail">
                          <strong>{item.labellingSplit.fail}%</strong> labeled{' '}
                          <strong>FAIL</strong>
                        </li>
                        <li className="is-unlabeled">
                          <strong>{item.labellingSplit.unlabeled}%</strong> unlabeled
                        </li>

                      </ul>
                    </footer>
                  </div>
                </div>
              </div>
            );
        });

        return <div>

        <div className={s.component}>{listItems}</div>

        <Modal key="modal" isOpen={this.state.bool_delete_dataset_confirmation_modal_open} onRequestClose={()=>{

            this.setState({
                             bool_delete_dataset_confirmation_modal_open: false,
                             delete_dataset_ID: null,
                             delete_dataset_name: null,
                          });

        }}>
            <ConfirmationModal datasetName={this.state.delete_dataset_name} onSubmit={(e, datasetName)=>{
                e.preventDefault();
                //console.log("DELETE CONFIRMATION onSubmit() called");

                ///////////////////////////////////////////////////////

                // delete dataset

                axios
                .get(`${DELETE_DATASET_URL}/${user_ID}/${this.state.delete_dataset_ID}`)
                .then((response) => {
                    //console.log('RECV DELETE_DATASET ACK');
                    //console.log(JSON.stringify(response.data));
                    //console.log('RECV DELETE_DATASET ACK');

                    getListOfDatasets(user_ID)

                    this.setState({
                                    list_of_deleted_dataset_IDs: [...this.state.list_of_deleted_dataset_IDs, this.state.delete_dataset_ID],
                                    bool_delete_dataset_confirmation_modal_open: false,
                                    //bool_redirect_to_datasets_list: true,
                                  });


                    //return ( <NavLink to={"/training"} /> )


                })
                .catch((error) => {
                    console.log("[ERROR] delete_dataset")
                });

                ///////////////////////////////////////////////////////



            }} />
        </Modal>
      </div>;
    };
};


const mapStateToProps = (state) => ({
  datasets: selectors.getDatasetsList(state),
  user_ID: selectors.getUserId(state),
});

export default connect(mapStateToProps, {
    getListOfDatasets,
})(DatasetsList);
