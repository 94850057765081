// RangeSlider.js

import React from 'react';
//import RCSlider from 'rc-slider/lib/Slider';
import RCSlider from 'rc-slider/lib/Range';

import s from './Slider.module.sass';

const RangeSlider = (props) => {
  const { min, max, defaultValue, onChange, disabled, step_interval=0.001 } = props;

  /*
  if (disabled === true) {
    return (
    <div className={s.component}>
      <RCSlider
        min={min}
        max={max}
        marks={marks}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={true}
      />
    </div>
    );
  }
  */
  const marks = { };
  marks[min] = min;
  marks[max] = max;

  return (
    <div className={s.component}>
      <RCSlider
        step={step_interval}
        min={min}
        max={max}
        range={true}
        marks={marks}
        defaultValue={defaultValue}
        onAfterChange={onChange}
        //tooltipFormatter={(value) => value === min ? 'any' : value}
      />
    </div>
  );
};

export default RangeSlider;
