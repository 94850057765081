import React from 'react';
import { useParams } from 'react-router-dom';

import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';
import NetworkReview from 'components/networks/NetworkReview';

import s from './NetworkReview.module.sass';

const NetworkReviewPage = (props) => {
  const { datasetId, networkId } = useParams();
  console.log('ZLOG useParams()', useParams());

  return [
    <div key="component" className={s.component}>
      <NetworkReview datasetId={datasetId} networkId={networkId} />
    </div>,
    <DatasetBreadcrumb
      key="breadcrumb"
      datasetId={datasetId}
      networkId={networkId}
    />,
  ];
};

export default NetworkReviewPage;
