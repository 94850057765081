import React from 'react';

import FilterLabel from 'components/history/FilterLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import s from './DropdownFilter.module.sass';

const DropdownFilter = (props) => {
  const { label, onChange, options, selectedValue } = props;

  return (
    <div className={s.component}>
      <FilterLabel>{label}</FilterLabel>

      <div className={s.dropdown}>
        <select className={s.select} onChange={onChange} value={selectedValue}>
          {options.map((option) => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            );
          })}
        </select>
        <FontAwesomeIcon icon="angle-double-down" className={s.icon} />
      </div>
    </div>
  );
};

export default DropdownFilter;
