import React from 'react';

import FilterLabel from 'components/history/FilterLabel';

import s from './DateRangeFilter.module.sass';

const DateRangeFilter = (props) => {
  const {images_date} = props;

  //var {month, day, year } = images_date.split('_')
  var date_array = images_date.split('_')

  var month = date_array[0];
  var day   = date_array[1];
  var year  = date_array[2];

  return (
    <div className={s.component}>
      <div className={s.filter}>
        <FilterLabel>Date Selected:</FilterLabel>
        <p className={s.value}>{`${month} ${day} ${year}`}</p>
      </div>

      {/*
      <div className={s.sep}>
        <FilterLabel></FilterLabel>
        <p className={s.value}>→</p>
      </div>

      <div className={s.filter}>
        <FilterLabel>To</FilterLabel>
        <p className={s.value}>{`${date_array[0]}`}</p>
      </div> */}
    </div>
  );
};

export default DateRangeFilter;
