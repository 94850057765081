import React from 'react';
import { Switch, Route } from 'react-router-dom';

import {
  DATASETS_INDEX_URL,
  DATASET_VIEW_URL,
  IMAGES_URL,
  TRAIN_SETTINGS_URL,
  TRAIN_PROGRESS_URL,
  NETWORKS_INDEX_URL,
  NETWORK_VIEW_URL,
} from 'pages/urls';

import TrainingHeader from 'components/global/TrainingHeader';
import DatasetsIndex from 'pages/training/DatasetsIndex';
import DatasetOverview from 'pages/training/DatasetOverview';
import Images from 'pages/training/Images';
import NetworksIndex from 'pages/training/NetworksIndex';
import TrainSettings from 'pages/training/TrainSettings';
import TrainProgress from 'pages/training/TrainProgress';
import NetworkReview from 'pages/training/NetworkReview';

import s from './Training.module.sass';

const Training = () => {
  return [
    <div key="header" className={s.header}>
      <TrainingHeader />
    </div>,
    <main key="main" className={s.main}>
      <Switch>
        <Route exact path={DATASETS_INDEX_URL}>
          <DatasetsIndex />
        </Route>
        <Route exact path={DATASET_VIEW_URL}>
          <DatasetOverview />
        </Route>
        <Route path={IMAGES_URL}>
          <Images />
        </Route>
        <Route exact path={NETWORKS_INDEX_URL}>
          <NetworksIndex />
        </Route>
        <Route path={TRAIN_SETTINGS_URL}>
          <TrainSettings />
        </Route>
        <Route path={TRAIN_PROGRESS_URL}>
          <TrainProgress />
        </Route>
        <Route path={NETWORK_VIEW_URL}>
          <NetworkReview />
        </Route>
      </Switch>
    </main>,
  ];
};

export default Training;
