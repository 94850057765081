import React from 'react';
import { useParams } from 'react-router-dom';

import { getNewNetworkURL } from 'pages/urls';

import DatasetBreadcrumb from 'components/navigation/DatasetBreadcrumb';
import ListHeader from 'components/list/ListHeader';
import Button from 'components/elements/Button';
import NetworksList from 'components/list/NetworksList';
import DatasetTabs from 'components/navigation/DatasetTabs';

import s from './NetworksIndex.module.sass';

const NetworksIndex = (props) => {
  const { datasetId } = useParams();

  return [
    <div key="component" className={s.component}>
      <DatasetTabs datasetId={datasetId} />

      <div className={s.body}>
        <ListHeader title="Neural Networks">
          <Button
            color="success"
            iconLeft="plus-square"
            to={getNewNetworkURL(datasetId)}
          >
            Train new Neural Network
          </Button>
        </ListHeader>

        <NetworksList dataset_ID={datasetId}/>
      </div>
    </div>,
    <DatasetBreadcrumb
      key="breadcrumb"
      datasetId={datasetId}
      showNetworks={true}
    />,
  ];
};

export default NetworksIndex;
