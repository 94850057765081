// B"H

import React, { useState } from 'react';

import Modal from 'components/elements/Modal';
import CameraSettings from 'components/camera/CameraSettings';
import IOSettings from 'components/camera/IOSettings';
import ProgramSelect2 from 'components/camera/ProgramSelect2';

import cx from 'classnames';
import s from './LoadJobModal.module.sass';

const TABS = [
  { id: 'program', label: 'Load Neural Network to Job Slot' },
];

const LoadJobModal = (props) => {
  const { isOpen, onClose, user_ID, dataset_ID, network_ID, network_name, network_type } = props;
  const [activeTab, setTab] = useState('program');

  const tabs = TABS.map((item, index) => {
    const tabClass = cx({
      [s.tab]: true,
      [s[item.id]]: true,
      [s.activeTab]: activeTab === item.id,
    });

    return (
      <div key={item.id} className={tabClass} onClick={() => setTab(item.id)}>
        {item.label}
      </div>
    );
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={s.component}>
        <nav className={s.tabs}>{tabs}</nav>
        <div className={s.body}>
          {activeTab === 'program' &&
             <ProgramSelect2
                user_ID={user_ID}
                dataset_ID={dataset_ID}
                network_ID={network_ID}
                network_name={network_name}
                network_type={network_type}
             />
          }
        </div>
      </div>
    </Modal>
  );
};

export default LoadJobModal;
