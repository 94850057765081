// Chart.js

import React from 'react';
import { ResponsiveLine } from '@nivo/line';

import s from './Chart.module.sass';
import theme from './theme';

const Chart = (props) => {
  const { chartData = [], nEpochs } = props;

  return (
    <div className={s.component}>
      <ResponsiveLine
        data={chartData}
        theme={theme}
        margin={{ top: 40, right: 40, bottom: 100, left: 100 }}
        colors={[
          'hsl(250, 100%, 75%)',
          'hsl(300, 100%, 75%)',
          'hsl(350, 100%, 75%)',
        ]}
        xScale={{
          type: 'linear',
          min: 1,
          max: nEpochs,
        }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 100,
        }}
        axisRight={null}
        axisBottom={{
          orient: 'bottom',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Number of training iterations',
          legendOffset: 60,
          legendPosition: 'middle',
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Percentage',
          legendOffset: -60,
          legendPosition: 'middle',
        }}
        pointSize={10}
        pointBorderWidth={2}
        pointLabel="y"
        useMesh={true}
      />
    </div>
  );
};

export default Chart;
