import React from 'react';

import s from './Title.module.sass';
import cx from 'classnames';

const Title = (props) => {
  const {
    CustomTag = 'h2',
    size = '3',
    children,
    noMargin = false,
    parentClass,
  } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`size-${size}`]]: size,
    [s.withMargin]: !noMargin,
    [parentClass]: parentClass,
  });

  return <CustomTag className={componentClass}>{children}</CustomTag>;
};

export default Title;
