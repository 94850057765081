// BrowseHistory.js

import React, { Component } from 'react';

import { useParams } from 'react-router-dom';

import axios from 'axios';
import { connect } from 'react-redux';

import { selectors } from 'redux/reducers/datasets';

import { Redirect } from 'react-router-dom';

import { GET_THUMBNAILS_METADATA_URL,
         GET_IMAGES_ZIP_URL,
         GET_THUMBNAILS_ZIP_URL,
         GET_JOB_NAME_URL,
         LOAD_BINARY_IMAGE_FROM_SERVER_URL,
         DELETE_CAMERA_IMAGE_URL,
         getHistoryImageSetsURL,
} from 'pages/urls';

//import LoadDataset from 'components/buttons/LoadDataset';
import AdvancedSearch from 'components/buttons/AdvancedSearch';
import DateRangeFilter from 'components/history/DateRangeFilter';
import GalleryGrid from 'components/photos/GalleryGrid';
import Button from 'components/elements/Button';
import RangeFilter from 'components/filters/RangeFilter';
import DropdownFilter from 'components/filters/DropdownFilter';
import ToggleFilter from 'components/filters/ToggleFilter';

import Modal from 'components/elements/Modal';

// deprecated
//import CameraViewer from 'components/label/CameraViewer';

import Viewer from 'components/label/Viewer';

import BackLink from 'components/navigation/BackLink';

import History from 'pages/camera/History';

import SaveImagesToTraining from 'components/camera/SaveImagesToTraining';


import HistoryBreadcrumb from 'components/navigation/HistoryBreadcrumb';


import s from './BrowseHistory.module.sass';

const PREDICTION_OPTIONS = [
  { value: 'all', label: 'All' },
  { value: 'pass', label: 'Pass', type: 'pass' },
  { value: 'fail', label: 'Fail', type: 'fail' },
];

const JOB_OPTIONS = [
  { value: 'caliper-paint-detect', label: 'Caliper Paint Detect' },
  { value: 'brake-defects', label: 'Braked Defects' },
];

const SORTING_OPTIONS = [
  { value: 'date', label: 'Date' },
  { value: 'confidence', label: 'Confidence' },
];


const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}

const print_time = (message, v = '') => {
  const date_object = new Date();
  const t = date_object.getTime() / 1000;
  console.log(`${message}: ${t} ${v}`);
};







// ========================================================================== //
// ========================================================================== //
// ========================================================================== //
// ========================================================================== //

// Functions

var x_reader = null;

const getImageFromZip = (entry, callback) => {
  entry.getData(new window.zip.BlobWriter('image/jpeg'), (data) => {
    //console.log(entry.filename)
    callback(data, entry.filename);
  });
};

const unzipBlob = (blob, startIndex, endIndex, callback) => {
  // use a zip.BlobReader object to read zipped data stored in blob
  return window.zip.createReader(
    new window.zip.BlobReader(blob),
    (zipReader) => {
      // get entries from the zip file
      zipReader.getEntries((entries) => {
        // save the zipReader object in global so we can close it later
        // (need to close zipReader to release its memory)
        x_reader = zipReader;

        for (let i = startIndex; i <= endIndex; i++) {
          getImageFromZip(entries[i], callback);
        }
        console.log('unzipBlob success');


        print_time('unzipped');

        return;
      });
    },
    console.log('unzipBlob error')
  );
};

// recursive calls to get 100 images
// --> each recursive call will add 100 images to the redux store
// --> last recursive call will add final N<=100 images to the redux store

let bool_unmount = false;
let tempImages = [];
let tempImagesBAD = [];
let prevCounted = 0;
const BATCH_SIZE = 60;


const oldUnzip = (
  updateImagesCallback,
  zipBlob,
  batchStartIndex,
  batchEndIndex,
  imageData,
  imageCount,
) => {
  const datasetEndIndex = imageCount - 1;

  if (batchEndIndex > datasetEndIndex) {
    console.log('---------------------------------------------------------');
    console.log(`OLD batchEndIndex=${batchEndIndex}`);
    batchEndIndex = datasetEndIndex;
    console.log(`NEW batchEndIndex=${batchEndIndex}`);
    console.log('---------------------------------------------------------');
  }

  unzipBlob(
    zipBlob,
    batchStartIndex,
    batchEndIndex,
    (blob, thumbnailFilename) => {
        // (jpg used for thumbnails as jpg compresses ~4X)
        // (png used for images as does not compress)

        //console.log('_______________________________________')
        //console.log(' ')
        //console.log(`thumbnailFilename=${thumbnailFilename}`)
        //console.log(' ')
        //for (var key in imageData) {
        //console.log(`${key}: ${imageData[key]}`);
        //console.log(`N_images=${imageData}`)
        //console.log('_______________________________________')
        const imageMetadata = imageData[thumbnailFilename];

        if (bool_unmount === true) {
            return;
        }


        // print_in_red(`predictionClassNamedClass=${imageMetadata.class_prediction_named_class}`);

        if (imageMetadata) {
            const image = {
                id: imageMetadata.img_ID,
                path: imageMetadata.img_path,
                filename: imageMetadata.filename,
                predictionClass: imageMetadata.class_prediction,
                predictionClassNamedClass: imageMetadata.class_prediction_named_class,
                predictionClassConfidence: imageMetadata.class_prediction_confidence,
                predictionPolygons: imageMetadata.list_of_prediction_polygons,
                labelPolygons: [],
                blob: URL.createObjectURL(blob),
                isValidation: null,
            };

            tempImages.push(image);

        } else {
            // when image not in db, fail gravefully (i.e. ignore image)
            // keep track of errors to not disturb unzip count
            tempImagesBAD.push({ id: -1 });
        }

        const N_images_to_add = batchEndIndex - batchStartIndex + 1;

        /*
        if ((prevCounted + tempImages.length+tempImagesBAD.length) !== N_images_to_add) {
           if ((prevCounted + tempImages.length+tempImagesBAD.length) % 100 === 0 ) {


                print_time('PRE(x) updateImagesCALLBACK()');

                updateImagesCallback();

                print_time('POST(x) updateImagesCALLBACK()');

                prevCounted = tempImages.length+tempImagesBAD.length;

                tempImages = null;
                tempImages = [];
                tempImagesBAD = null;
                tempImagesBAD = [];

           }
           return;
        }
        */
        if ((tempImages.length+tempImagesBAD.length) === N_images_to_add) {
            console.log('-----------------------------------------------------');
            console.log(`batchStartIndex=${batchStartIndex}`);
            console.log(`batchEndIndex=${batchEndIndex}`);
            console.log(`datasetEndIndex=${datasetEndIndex}`);
            console.log('-----------------------------------------------------');
            x_reader.close();
            x_reader = null;

            print_time('PRE updateImagesCALLBACK()');

            updateImagesCallback();

            print_time('POST updateImagesCALLBACK()');

            if (batchEndIndex !== datasetEndIndex) {
                const new_batchStartIndex = batchEndIndex + 1;
                const new_batchEndIndex = new_batchStartIndex + BATCH_SIZE - 1;

                tempImages = null;
                tempImages = [];
                tempImagesBAD = null;
                tempImagesBAD = [];
                prevCounted = 0;

                return oldUnzip(
                    updateImagesCallback,
                    zipBlob,
                    new_batchStartIndex,
                    new_batchEndIndex,
                    imageData,
                    imageCount,
                );
            } else {
                // reset globals
                tempImages = null;
                tempImages = [];
                tempImagesBAD = null;
                tempImagesBAD = [];
                prevCounted = 0;

            }
            return;
        }

    }
  );
};

// API calls

// first call: get list_of_filenames + image_metadata
// second call: get list_of_img_thumbnails
// then, unzip thumbnails in batches of 100
const getZipFromServer = (job_slot_str, month_str, images_date, imageset_name, updateImagesCallback, setImagesMetadataCallback) => {
  print_time('getZipFromServer()');

  print_in_blue(`[GET_ZIP_FROM_SERVER] date=${images_date}`)

  // add timestamp to backend calls to prevent caching URL calls (as imagesets may update between backend URL calls)
  const date_timestamp_str = new Date().toISOString()

  const getMetadataUrl = GET_THUMBNAILS_METADATA_URL + `/${job_slot_str}/${month_str}/${images_date}/${imageset_name}/${date_timestamp_str}`;
  const getZipUrl = GET_THUMBNAILS_ZIP_URL + `/${job_slot_str}/${month_str}/${images_date}/${imageset_name}/${date_timestamp_str}`;

  print_in_blue(`[GET_ZIP_FROM_SERVER] getMetadataUrl=${getMetadataUrl}`)
  print_in_blue(`[GET_ZIP_FROM_SERVER] getZipUrl=${getZipUrl}`)

    // first call: get list_of_filenames + image_metadata
    axios
      .get(getMetadataUrl)
      .then((response) => {
        print_time('get_images_metadata_dict response received');

        // global dataset_metadata_dict so that when images are unzipped,
        // unzip() can access dataset_metadata_dict[filename]
        const imageCount = response.data['N_images'];

        const bool_classification = response.data['bool_classification'];
        const bool_detection      = response.data['bool_detection'];
        const bool_pass_detection = response.data['bool_pass_detection'];
        const bool_multi_class_detection = response.data['bool_multi_class_detection'];

        print_in_red(`PRE bool_classification=${bool_classification}`)
        print_in_red(`PRE bool_detection=${bool_detection}`)
        print_in_red(`PRE bool_pass_detection=${bool_pass_detection}`)
        print_in_red(`PRE bool_multi_class_detection=${bool_multi_class_detection}`)

        const imageData = response.data['dataset_image_metadata_dict'];

        //this.setState({
        //  n_results: imageCount,
        //});
        setImagesMetadataCallback(imageData, bool_classification, bool_detection, bool_pass_detection, bool_multi_class_detection);


        if (imageCount===0) {
            print_in_red("[GET_ZIP_FROM_SERVER] No images found")
            return;
        }

        // second call: get list_of_img_thumbnails
        axios
          .get(getZipUrl, { responseType: 'arraybuffer',
                            headers: {
                                'Cache-Control': 'no-cache',
                                'Pragma': 'no-cache',
                                'Expires': '0',
                            },
                          })
          .then((response) => {
            print_time('get_thumbnails_zip response received');
            console.log(`response.data=${response.data}`);
            console.log(
              `response.headers['content-type']=${response.headers['content-type']}`
            );

            const zipBlob = new Blob([response.data], {
              type: response.headers['content-type'],
            });
            console.log(`zipBlob=${zipBlob}`);

            oldUnzip(
              updateImagesCallback,
              zipBlob,
              0,
              BATCH_SIZE-1,
              imageData,
              imageCount,
            );

          })
          .catch((error) => {
            print_in_red("[GET_ZIP_FROM_SERVER] get_metadata failed")
          });
      })
      .catch((error) => {
        print_in_red("[GET_ZIP_FROM_SERVER] get_thumbnails_zip failed")
      });

};

// ========================================================================== //
// ========================================================================== //
// ========================================================================== //
// ========================================================================== //




const BrowseHistory = (props) => {
  const { job_slot, month_str, date_str, imageset_name } = useParams();

    console.log(`ZLOG useParams()=${JSON.stringify(useParams())}`);

    return ( <BrowseHistoryX job_slot_str={job_slot} month_str={month_str} images_date={date_str} imageset_name={imageset_name} / >);

};





class BrowseHistoryX extends Component {
  constructor(props) {
    super(props);

    const {job_slot_str, month_str, images_date, imageset_name} = props;

    print_time("CONSTRUCTOR!!!!!!!!!!!!!!!");

    bool_unmount = false;
    console.log(`bool_unmount=${bool_unmount}`);
    console.log(`bool_unmount=${bool_unmount}`);
    console.log(`bool_unmount=${bool_unmount}`);
    console.log(`bool_unmount=${bool_unmount}`);
    console.log(`bool_unmount=${bool_unmount}`);
    console.log(`bool_unmount=${bool_unmount}`);

    this.state = {
      showAdvanced: false,
      imagesList: null,
      filteredList: null,
      filterType: "all",
      job_slot_str: job_slot_str,
      job_name: "JOB",
      month_str: month_str,
      images_date: images_date,
      imageset_name: imageset_name,

      n_results: null,
      percent_fail_str: "",
      imagesMetadata: null,
      bool_redirect_to_history_list: false,
      currentImageId: '',
      currentImageIndex: -1,
      isViewerOpen: false,
      isModalOpen: false,

      bool_classification: false,
      bool_detection: false,
      bool_pass_detection: false,
    };

    this.getJobName(job_slot_str);

    getZipFromServer(job_slot_str, month_str, images_date, imageset_name, this.updateImagesList, this.setImagesMetadata);
    /*
    axios
    .get(GET_LIST_OF_JOBS_URL)
    .then((response) => {
      console.log('_________________');
      console.log(response.data);
      console.log('_________________');

      this.setState({
        programsList: response.data,
      });

   })
   .catch((error) => {
       console.log("[ERROR] return list_of_jobs")
   });
   */
  }


  ////////////////////////////////////////////////////////////////////////////////

  getJobName = (jobSlotSelected) => {
    axios
    .get(GET_JOB_NAME_URL + '/' + jobSlotSelected.toString())
    .then((response) => {
        console.log('_________________');
        console.log(JSON.stringify(response.data));
        console.log('_________________');

        this.setState({
            job_name: response.data.job_name,
        });

    })
    .catch((error) => {
        console.log("[ERROR] get job_name")
    });
  }


  ////////////////////////////////////////////////////////////////////////////////

  componentWillUnmount() {
    console.log('WILL UNMOUNT');
    console.log('WILL UNMOUNT');
    console.log('WILL UNMOUNT');
    console.log('WILL UNMOUNT');
    console.log('WILL UNMOUNT');
    console.log('WILL UNMOUNT');
    bool_unmount = true;

  }

  ////////////////////////////////////////////////////////////////////////////////



  handlePredictionChange = (filterType) => {
    console.log('ZLOG value', filterType);

      const filteredList =
        filterType === 'all'
          ? this.state.imagesList
          : (filterType === 'pass' ? this.state.imagesList.filter((item) => item.predictionClass === 'PASS')
                                   : this.state.imagesList.filter((item) => item.predictionClass === 'FAIL'));

      this.setState({
        filteredList,
        filterType
      });


  };

  handleJobChange = (value) => {
    console.log('ZLOG value', value);
  };

  handleAdvancedToggle = () => {
    this.setState({
      showAdvanced: !this.state.showAdvanced,
    });
  };

  updateImagesList = () => {
    print_in_green("[UPDATE_IMAGES_LIST]");

     if (this.state.imagesList === null) {
         this.setState({
             imagesList: tempImages,
             filteredList: tempImages,
             //n_results: tempImages.length
         });
     } else {
         this.setState((prevState)=>({
             imagesList: this.state.imagesList.concat([...tempImages]),
             filteredList: this.state.filteredList.concat([...tempImages]),
             //n_results: prevState.n_results + tempImages.length
         }));
     }

  };

  setImagesMetadata = (imageData, bool_classification, bool_detection, bool_pass_detection, bool_multi_class_detection) => {
    print_in_green("[UPDATE_IMAGE_METADATA]");

    //console.log(`imageData=${JSON.stringify(imageData)}`);

    var image_objects = Object.entries(imageData);
    var N_total = image_objects.length;
    var N_fail = 0;


    for (let [key, image_object] of image_objects) {
        //console.log(`${key}: ${JSON.stringify(image_object)}`);
        if (image_object.class_prediction === "FAIL") {
            N_fail += 1;
        }
        //for (var keyx in image_object) {
        //    console.log(`    ${keyx}: ${image_object[keyx]}`);
        //}


    }

    console.log(`N_fail=${N_fail}`);
    console.log(`N_total=${N_total}`);

    var percent_fail = ((N_fail/N_total) * 100).toFixed(1) + '%'

    console.log(`percent_fail=${percent_fail}`);
    console.log(typeof percent_fail);

    this.setState({
        imagesMetadata: imageData,
        percent_fail_str: percent_fail,
        n_results: N_total,
        bool_classification: bool_classification,
        bool_detection: bool_detection,
        bool_pass_detection: bool_pass_detection,
        bool_multi_class_detection: bool_multi_class_detection,
    });

  };

 openViewer = (imageId) => {
    const viewerList =this.state.filteredList;


    //console.log(`%c viewerList=${JSON.stringify(viewerList)}`, 'background: red; color: white; display: block;');

    const currentImageIndex = viewerList.findIndex((image) => image.id === imageId)

    //const currentImageIndex = (imageId
    //  ? viewerList.findIndex((image) => image.imageId === imageId)
    //  : false);

    //console.log(`%c currentImageIndex=${currentImageIndex}`, 'background: red; color: white; display: block;');


    this.setState({
      currentImageId: imageId,
      currentImageIndex,
      isViewerOpen: true,
    });
  };

  closeViewer = () => {
    this.setState({
      isViewerOpen: false,
    });
  };

  deleteImageCallback = (filename_to_delete) => {

      axios
      .get(`${DELETE_CAMERA_IMAGE_URL}/${this.state.job_slot_str}/${this.state.month_str}/${this.state.images_date}/${this.state.imageset_name}/${filename_to_delete}`)
      .then((response) => {
                              console.log('RECV DELETE_CAMERA_IMAGE');
                              console.log(response.data);
                              console.log('RECV DELETE_CAMERA_IMAGE');


                              ///////////////////////////////////////////////////////////

                              console.log(`filename_to_delete=${filename_to_delete}`);

                              //console.log(`imagesList=${JSON.stringify(this.state.imagesList)}`);

                              console.log(`imagesList.length=${this.state.imagesList.length}`)
                              const updatedImagesList = this.state.imagesList.filter((image_object) => image_object.filename !== filename_to_delete);

                              const failImagesList = updatedImagesList.filter((item) => item.predictionClass === 'FAIL');

                              const N_total = updatedImagesList.length;
                              const N_fail = failImagesList.length;
                              console.log(`N_fail=${N_fail}`);
                              console.log(`N_total=${N_total}`);
                              var percent_fail = ((N_fail/N_total) * 100).toFixed(1) + '%'

                              //console.log(`updatedImagesList.length=${updatedImagesList.length}`)

                              this.setState({
                                  imagesList: updatedImagesList,
                                  filteredList: updatedImagesList,
                                  n_results: N_total,
                                  percent_fail_str: percent_fail,
                              });


                              /*
                              const updatedImagesList =
                                  filterType === 'all'
                                       ? this.state.imagesList
                                       : (filterType === 'pass' ? this.state.imagesList.filter((item) => item.predictionClass === 'PASS')
                                                                : this.state.imagesList.filter((item) => item.predictionClass === 'FAIL'));

                               this.setState({
                                   filteredList,
                                   filterType
                               });
                               */
                              ///////////////////////////////////////////////////////////

                              this.closeViewer();

                          })
                          .catch((error) => {
                              console.log("[ERROR] delete camera image")
                          });

  };


  imageTransition = (direction) => {

    console.log(`%c direction=${direction}`, 'background: blue; color: white; display: block;');

    //const { imagesList } = this.props;

    //const { showAdvanced, imagesList, currentImageId } = this.state;
    const { filteredList } = this.state;

    const { currentImageIndex } = this.state;


    console.log(`%c currentImageIndex=${currentImageIndex}`, 'background: blue; color: white; display: block;');

    const viewerList = filteredList;

    if (!direction) {
      return;
    }

    if (!viewerList) {
      return;
    }

    const lastIndex = viewerList.length - 1;

    const nextIndex =
      direction === 'next'
        ? currentImageIndex + 1
        : direction === 'previous'
        ? currentImageIndex - 1
        : currentImageIndex;

    // Bail if out of bounds
    if (nextIndex > lastIndex) {
      this.closeViewer();
      return;
    } else if (nextIndex < 0) {
      return;
    }

    const nextId = viewerList[nextIndex].id;

    //console.log(`%c nextId=${nextId}`, 'background: blue; color: white; display: block;');
    //console.log(`%c nextIndex=${nextIndex}`, 'background: blue; color: white; display: block;');

    this.setState({
      currentImageId: nextId,
      currentImageIndex: nextIndex,
    });
  };




  render() {
    const { showAdvanced, filteredList, currentImageId } = this.state;

    var currentImage;
    var currentImageFilename;

    print_time('NEW render');


    if (filteredList !== null) {
      /*
      for (var key in imagesList) {
          console.log(`    ${key}: ${imagesList[key]}`);
          var image_object=imagesList[key];
          for (var keyx in image_object) {
              console.log(`    ${keyx}: ${image_object[keyx]}`);
          }
      }
      */

      //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      //console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
      currentImage = filteredList.find((image) => image.id === currentImageId);
    }

    // prevents error in Viewer->src->image.filename when image is undefined
    if (currentImage) {
        currentImageFilename = currentImage.filename;
    }

    //console.log(`currentImage=${currentImage}`);
    //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    //console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    //console.log(`this.state.filteredList=${JSON.stringify(this.state.filteredList)}`);

    //const networkURL = getNetworksIndexURL(datasetId);

    if (this.state.bool_redirect_to_history_list === true) {

        return (
                 <Redirect to={getHistoryImageSetsURL(this.state.job_slot_str, this.state.month_str, this.state.images_date)} />
               );
    }

    return [
      <div className={s.component} key="component">
        <header className={s.header}>
          <div className={s.scroller}>
            <div className={s.body}>

                <div className={s.back}>
                  <BackLink parentClass={s.backLink} to={'#'} onClick={()=>{
                     console.log("BACKCLICK!");

                     this.setState({
                         bool_redirect_to_history_list: true,
                     });

                  }}>
                    Back
                  </BackLink>
                </div>

              {/* <div className={s.filters}> */}
               <div className={s.dateFilter}>
                  <DateRangeFilter
                    images_date={this.state.images_date}
                  />
                </div>
                <div className={s.predictionFilter}>

                  <ToggleFilter
                    label="Prediction"
                    currentValue={this.state.filterType}
                    onChange={this.handlePredictionChange}
                    options={PREDICTION_OPTIONS}
                  />
                </div>

              {/*
              <div className={s.circle}>
                <Button iconCircle="arrow-right" isCircle />
              </div>
              */}

                <div className={s.numbers}>
                  <p className={s.count}>
                    <strong>{this.state.n_results}</strong> results
                  </p>

                  <p className={s.fail}>{this.state.percent_fail_str} fail</p>
                </div>


                <div className={s.download}>
                  <Button parentClass={s.button} iconLeft="arrow-down"
                   onClick = {() => {
                      print_in_green("DOWNLOAD BUTTON!");
                      // TODO: correct date, from user (dropdown? calendar?)

                      // add Date.Now() to make url unique which will disable cache
                      const date_timestamp_str = new Date().toISOString()
                      const getZipUrl = GET_IMAGES_ZIP_URL + `/${this.state.job_slot_str}/${this.state.month_str}/${this.state.images_date}/${this.state.imageset_name}/${date_timestamp_str}`;
                      window.location.href = getZipUrl;

                      /*
                      axios
                      .get(getZipUrl, { responseType: 'arraybuffer' })
                      .then((response) => {
                          print_time('get_images_zip response received');
                          window.open(response.file);
                          // console.log(`response.data=${response.data}`);
                          // console.log(
                          //    `response.headers['content-type']=${response.headers['content-type']}`
                          //);

                          //const zipBlob = new Blob([response.data], {
                          //type: response.headers['content-type'],
                          //console.log(`zipBlob=${zipBlob}`);
                     })
                     .catch((error) => {
                         print_in_red("[GET_IMAGES_ZIP_FROM_SERVER] get_metadata failed")
                     });
                     */

                   }}
                  >
                    Download All
                  </Button>
                </div>



                <div className={s.copyall}>
                  <Button parentClass={s.button} iconRight="arrow-right" color="success"
                   onClick = {() => {
                      print_in_green("COPYALL BUTTON!");

                      this.setState({
                          isModalOpen: true,
                      });

                   }}
                  >
                    Copy All to Training Application
                  </Button>
                {/* </div> */}
              </div>

            </div>
          </div>

         {/* <AdvancedSearch
            isActive={showAdvanced}
            onClick={this.handleAdvancedToggle}
          /> */}
        </header>

        {showAdvanced && (
          <div className={s.advanced}>
            <div className={s.scroller}>
              <div className={s.body}>
                <DropdownFilter
                  label="Job"
                  options={JOB_OPTIONS}
                  selectedValue="caliper-paint-detect"
                  onChange={this.handleJobChange}
                />
                <RangeFilter label="Confidence" start="48%" end="87%" />
                <ToggleFilter
                  label="Sort by"
                  options={SORTING_OPTIONS}
                  onChange={this.handleSortingChange}
                  currentValue="date"
                />
              </div>
            </div>
          </div>
        )}

       { (this.state.filteredList!==null) && (
        <div className={s.gallery}>
          <GalleryGrid
            step="review"
            items={filteredList}
            onClick={(imageID)=>{
                console.log(`gallery click ID=${imageID}`);
                this.openViewer(imageID);
            }}

           />

      <Modal
        key="modal"
        isOpen={this.state.isViewerOpen}
        onRequestClose={this.closeViewer}
      >
       {/*

        <CameraViewer
           image={currentImage}
           imageIndex={this.state.currentImageIndex}
           imageCount={this.state.filteredList.length}
           isReadOnly={true}
           job_slot_str={this.state.job_slot_str}
           month_str={this.state.month_str}
           images_date={this.state.images_date}
           imageset_name={this.state.imageset_name}
           onNextImageCallback={() => this.imageTransition('next')}
           onPreviousImageCallback={() => this.imageTransition('previous')}
           onDelete={this.deleteImageCallback}
       />
        */}

       <Viewer
          image={currentImage}
          imageIndex={this.state.currentImageIndex}
          imageCount={this.state.filteredList.length}
          isReadOnly={true}

          img_src={LOAD_BINARY_IMAGE_FROM_SERVER_URL + `/${this.state.job_slot_str}/${this.state.month_str}/${this.state.images_date}/${this.state.imageset_name}/${currentImageFilename}`}

          onNextImageCallback={() => this.imageTransition('next')}
          onPreviousImageCallback={() => this.imageTransition('previous')}
          onDelete={this.deleteImageCallback}

          boolSegmentationEnabled={this.state.bool_detection}
          userId={-1}
          datasetId={-1}
          boolMultiClass={this.state.bool_classification}

          max_label={-1}
          bool_all_classes_named={false}
          list_of_class_objects={[]}
          bool_pass_detection={this.state.bool_pass_detection}
          bool_multi_class_detection={this.state.bool_multi_class_detection}

          bool_history_image={true}
        />

      </Modal>

    <SaveImagesToTraining
      key="save"
      job_slot_str={this.state.job_slot_str}
      month_str={this.state.month_str}
      images_date={this.state.images_date}
      imageset_name={this.state.imageset_name}

      isOpen={this.state.isModalOpen}
      onClose={() => {
          this.setState({
                          isModalOpen: false,
                        });
      }}
    />

          {/*datasetId={datasetId}*/}



          {/*step="review"*/}
          {/*items={filteredList}*/}

        </div>
       )}

      </div>,
       <HistoryBreadcrumb key="breadcrumb" job_slot={this.state.job_slot_str} job_name={this.state.job_name} month_str={this.state.month_str} date_str={this.state.images_date} />,
    ];
  }
}

export default BrowseHistory;
