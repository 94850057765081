// B"H

import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Loader from 'components/elements/Loader';
import Keyboard from 'components/elements/Keyboard';

import cx from 'classnames';
import s from './Button.module.sass';

const Button = (props) => {
  const {
    href,
    to,
    to_x,
    type,
    size,
    color,
    children,
    external,
    iconCircle,
    iconLeft,
    iconRight,
    keyboard,
    parentClass,
    isCircle,
    isTall,
    isFullWidth,
    isDisabled,
    isLoading,
    isStatic,
    onClick,
    onSubmit,
    isActive
  } = props;

  const isHoverable = !isDisabled && !isStatic && !isLoading;

  const componentClass = cx({
    button: true,
    [s.button]: true,
    [s[`size-${size}`]]: size,
    [s[`color-${color}`]]: color,
    [s.isFullWidth]: isFullWidth,
    [s.isTall]: isTall,
    [s.isCircle]: isCircle,
    [s.isHoverable]: isHoverable,
    [s.isDisabled]: isDisabled,
    [s.isStatic]: isStatic,
    [s.isActive]: isActive,
    [s.isLoading]: isLoading,
    [parentClass]: parentClass,
  });

  let attr = {
    className: componentClass,
    onClick,
    onSubmit,
    type,
  };

  let body = [
    <span key="body" className={s.body}>
      {children}
    </span>,
  ];

  if (iconLeft) {
    body.unshift(
      <span key="iconLeft" className={cx(s.icon, s.iconLeft)}>
        <FontAwesomeIcon icon={iconLeft} />
      </span>
    );
  }

  if (iconRight) {
    body.push(
      <span key="iconRight" className={cx(s.icon, s.iconRight)}>
        <FontAwesomeIcon icon={iconRight} />
      </span>
    );
  }

  if (iconCircle) {
    body = (
      <span key="iconCircle" className={cx(s.icon, s.iconCircle)}>
        <FontAwesomeIcon icon={iconCircle} />
      </span>
    );
  }

  if (keyboard) {
    body.push(
      <Keyboard key="keyboard" parentClass={s.keyboard}>
        {keyboard}
      </Keyboard>
    );
  }

  const content = isHoverable ? (
    [
      <div key="normal" className={s.normal}>
        {body}
      </div>,
      <div key="background" className={s.background} />,
      <div key="hover" className={s.hover}>
        {body}
      </div>,
    ]
  ) : (
    <div className={s.normal}>{body}</div>
  );

  // Internal link
  if (to && to !== '') {
    return (
      <NavLink to={to} {...attr}>
        {content}
      </NavLink>
    );
  }

  // Internal link 2
  if (to_x && to_x !== '') {
    return (
    <button {...attr}>
      <NavLink to={to_x}>
        {content}
      </NavLink>
    </button>

    );
  }

  // External link
  if (href && href !== '') {
    if (external) {
      attr.target = '_blank';
      attr.ref = 'noopener noreferrer';
    }

    return (
      <a href={href} {...attr}>
        {content}
      </a>
    );
  }

  // Static button
  if (isDisabled || isStatic || isLoading) {
    return (
      <span {...attr}>
        {content}
        {isLoading && (
          <div className={s.loading}>
            <Loader parentClass={s.loader} />
          </div>
        )}
      </span>
    );
  }

  // Basic button
  return <button {...attr}>{content}</button>;
};

export default Button;
