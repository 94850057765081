import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';

import BrowseHistory from 'pages/camera/BrowseHistory';

import { BROWSE_URL,
         GET_IMAGES_ZIP_URL,
         GET_IMAGE_HISTORY_JOBS_URL,
         GET_IMAGE_HISTORY_MONTHS_URL,
         GET_IMAGE_HISTORY_DATES_URL,
         getHistoryJobURL,
} from 'pages/urls';

import Button from 'components/elements/Button';

import BackLink from 'components/navigation/BackLink';

import cx from 'classnames';
import s from './History.module.sass';

import axios from 'axios';

import Tag from 'components/elements/Tag';


import Title from 'components/elements/Title';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input2 from 'components/form/Input2';


// lineType one of: "JOB", "MONTH", "DATE"
//
// History displays:
//   Job [0-8]
//     Month <Month>_<yyyy>
//       Date <Month>_<dd>_<yyyy>
//

const Line = (props) => {
  const { jobName, jobSlot, month, date, imageset_object, jobCount, imageCount, failPercentage, lineType, onClickRow, isActiveX, onClickDelete, onClickEdit, bool_editing_imageset_name } = props;

  //const [bool_editing_imageset_name, set_bool_editing_imageset_name] = useState(false);
  //const [imageset_display_name, set_imageset_display_name] = useState("");


  if (lineType === "JOB") {
    console.log(`jobName=${jobName}`);
    console.log(`jobSlot=${jobSlot}`);
  }
  else if (lineType === "MONTH") {
    console.log(`MONTH=${month}`);
  }
  else if (lineType === "DATE") {
    console.log(`DATE=${date}`);
  }
  else if (lineType === "IMAGESET") {
    console.log(`IMAGESET=${JSON.stringify(imageset_object)}`);
    //if (imageset_display_name === "") {
    //  set_imageset_display_name(imageset_object.display_name);
    //}
  }
  else {
    console.log("[ERROR] History.js lineType not recognized");
  }


  //const onButtonClick = (e) => {
  //  e.preventDefault();
  //  return false;
  //};
  //const isActiveX = false;

  if (lineType === "JOB") {
    return (
      <div className={s.row} onMouseUp={()=>{onClickRow(jobSlot); }}>


        <div className={s.content}>

          <p className={cx(s.cell, s.job_name)}>{jobName} </p>


            {isActiveX &&
             <ul className={cx(s.cell, s.info)}>
                <li>
                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Active
                        </div>
                      </Tag>
                </li>
              </ul>
            }

          <p className={cx(s.cell, s.job_slot)}>{jobSlot}</p>

        </div>
      </div>
    );
  }
  else if (lineType === "MONTH") {
    return (
      <div className={s.row} onMouseUp={()=>{onClickRow(month)} }>
        <div className={s.content}>

          <p className={cx(s.cell, s.month)}>{month}</p>

            {isActiveX &&
             <ul className={s.info2}>
                <li>
                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Active
                        </div>
                      </Tag>
                </li>
              </ul>
            }

          <p className={cx(s.cell, s.job_slot)}></p>


        </div>
      </div>
    );
  }
  else if (lineType === "DATE") {

    return (
      <div className={s.row} onClick={()=>{onClickRow(date)}}>
        <div className={s.content}>
          <p className={cx(s.cell, s.month)}>{date}</p>

            {isActiveX &&
             <ul className={cx(s.cell, s.info)}>
                <li>
                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          Active
                        </div>
                      </Tag>
                </li>
              </ul>
            }

          <p className={cx(s.cell, s.job_slot)}></p>



            {/* TODO: download days worth of images, irrespective of imageset?
            <Button
              parentClass={s.button}
              iconLeft="arrow-down"
              //onClick={onButtonClick}
              onClick={(e)=>{
                  //var e = window.event;
                  //e.cancelBubble = true;
                  //if (e.stopPropagation)
                  e.stopPropagation();
                  console.log("DOWNLOAD BUTTON!");
                  console.log(`jobSlot=${jobSlot}`);
                  console.log(`MONTH=${month}`);
                  const date_str = date.split(' ').join('_');
                  const date_timestamp_str = new Date().toISOString()
                  //jobSlotSelected.toString() this.state.monthSelected} this.state.dateSelected
                  const getZipUrl = GET_IMAGES_ZIP_URL + `/${jobSlot}/${month}/${date_str}/${date_timestamp_str}`;
                  window.location.href = getZipUrl;
                  //const downloadJobFileUrl = `${DOWNLOAD_JOB_FILE_URL}/${network.id}`;
                  //window.location.href = downloadJobFileUrl;
              }}
            >
              Download All
            </Button>
            */}
        </div>
      </div>

    );
  }
  /*
  else if (lineType === "IMAGESET") {


    return (
      <div className={s.row} onClick={()=>{

        //if (bool_editing_imageset_name) {
        //    set_bool_editing_imageset_name(false);
        //} else {
        onClickRow(imageset_object)
        //}
      }}>
        <div className={s.content}>

                    <Title parentClass={s.title2} noMargin>

                          <span className={s.iconEdit} onClick={(e)=>{
                             console.log(`EDIT CLICK`);
                             e.stopPropagation();


                             const { imageset_object, onClickEdit } = props;
                             onClickEdit(imageset_object.name);

                             //this.setState({
                             //    bool_editing_network_name: true,
                             //    active_network_name_object: network_name_object ? network_name_object : { name: network.name, id: network.id },
                             //});

                             //set_bool_editing_imageset_name(true)

                         }}>
                             <FontAwesomeIcon icon="pen" />
                         </span>


                             { (bool_editing_imageset_name) ? (
                                    <div className={s.field}>
                                        <Input2
                                            id="dataset_name"
                                            name="dataset_name"
                                            type="text"
                                            value={imageset_object.display_name}
                                            onChange={(e) => {
                                                console.log("change imageset_display+name");
                                                //set_imageset_display_name(e.target.value)
                                                //this.setState({
                                                //    active_network_name_object: { name: e.target.value, id: this.state.active_network_name_object.id }
                                                //});

                                            }}
                                            placeholder="ImageSet Alpha"
                                            onClick={(e)=>{
                                                console.log("Input2 CLICK");
                                                //this.setState({
                                                //    bool_editing_display_name: true,
                                                //});
                                                e.stopPropagation();
                                            }}
                                      />
                                    </div>
                               ) : (
                                     <div>
                                      {imageset_object.display_name}
                                     </div>
                              )}



                  </Title>

             <div className={s.imageset_info}>
                        {imageset_object.start_time} - {imageset_object.end_time}
             </div>

             <div className={s.imageset_info}>
                        {imageset_object.N_images} images
             </div>


          <p className={s.imageset_buttons}>
            <Button
              parentClass={s.button}
              iconLeft="chart-pie"
              color="success"
            >
            View Results
            </Button>
            <Button
              parentClass={s.button}
              iconLeft="arrow-down"
              //onClick={onButtonClick}
              onClick={(e)=>{
                  //var e = window.event;
                  //e.cancelBubble = true;
                  //if (e.stopPropagation)
                  e.stopPropagation();

                  console.log("DOWNLOAD BUTTON!");

                  console.log(`jobSlot=${jobSlot}`);
                  console.log(`MONTH=${month}`);

                  const date_str = date.split(' ').join('_');
                  const date_timestamp_str = new Date().toISOString()
                  //jobSlotSelected.toString() this.state.monthSelected} this.state.dateSelected
                  const getZipUrl = GET_IMAGES_ZIP_URL + `/${jobSlot}/${month}/${date_str}/${imageset_object.name}/${date_timestamp_str}`;
                  window.location.href = getZipUrl;
                  //const downloadJobFileUrl = `${DOWNLOAD_JOB_FILE_URL}/${network.id}`;
                  //window.location.href = downloadJobFileUrl;

             }}
            >
              Download All
            </Button>

            <Button
                      parentClass={s.button}
                      color="danger"
                      iconLeft="times-circle"

                      onClick={(e)=>{
                          //var e = window.event;
                          //e.cancelBubble = true;
                          //if (e.stopPropagation)
                          e.stopPropagation();
                          console.log("DELETE BUTTON!");

                          const { imageset_object, onClickDelete } = props;


                          onClickDelete(imageset_object.name);

                      }}
            >
                Delete
            </Button>


          </p>
        </div>
      </div>

    );
  }*/
  else {
    console.log("[ERROR] History.js lineType not recognized");
  }

};

export default Line;

