// Comparison.js

import React from 'react';

import Title from 'components/elements/Title';
import Label from 'components/elements/Label';
import Polygon from 'components/graphics/Polygon';
import Toggles from 'components/form/Toggles';
import Keyboard from 'components/elements/Keyboard';

import cx from 'classnames';
import s from './Comparison.module.sass';

const Comparison = (props) => {
  const {
    image,
    showLabelDefects,
    showPredictionDefects,
    setDefectsVisibility,
    bool_multi_class_detection=false,
  } = props;
  const {
    labelClass,
    predictionClass,
    labelPolygons,
    predictionPolygons,
  } = image;

  const predictionType = labelClass === predictionClass ? 'correct' : 'wrong';

  const predictionClassname = cx({
    [s.prediction]: true,
    [s[`prediction-${predictionType}`]]: predictionType,
  });


  var hasPassLabelDetections = false;
  var hasPassPredictionDetections = false;

  var hasFailLabelDetections = false;
  var hasFailPredictionDetections = false;

  if (bool_multi_class_detection === true) {
      if (labelPolygons && labelPolygons.length > 0) {
          hasPassLabelDetections = 'detection_label_class' in labelPolygons[0];
      }

      if (predictionPolygons && predictionPolygons.length > 0) {
          hasPassPredictionDetections = 'detection_predicted_class' in predictionPolygons[0];
      }

  } else {

      if (labelPolygons) {
          hasPassLabelDetections = labelClass === 'PASS' && Object.keys(labelPolygons).length > 0;
          hasFailLabelDetections = labelClass === 'FAIL' && Object.keys(labelPolygons).length > 0;
      }

      if (predictionPolygons) {
          hasPassPredictionDetections = predictionClass === 'PASS' && Object.keys(predictionPolygons).length > 0;
          hasFailPredictionDetections = predictionClass === 'FAIL' && Object.keys(predictionPolygons).length > 0;
      }
  }



  const showOptions = [
    { value: true, label: 'Show' },
    { value: false, label: 'Hide' },
  ];

  return (
    <div className={s.component}>
      <div className={predictionClassname}>
        <p>
          The network prediction was <Label type={predictionType} />
        </p>
      </div>

      <div className={s.columns}>
        <div className={s.column}>
          <Title parentClass={s.columnTitle} size="1">
            Label
          </Title>
          <p className={s.columnSubtitle}>By you</p>
          <p>
            <Label type={labelClass} />
          </p>


          {hasPassLabelDetections && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon detection_type="pass_label" />
                <span>Labels (person)</span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showLabelDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('label', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↑</Keyboard>
              </div>
            </div>
          )}
          {hasFailLabelDetections && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon detection_type="fail_label" />
                <span>Labels (person)</span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showLabelDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('label', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↑</Keyboard>
              </div>
            </div>
          )}

        </div>

        <div className={s.column}>
          <Title parentClass={s.columnTitle} size="1">
            Prediction
          </Title>
          <p className={s.columnSubtitle}>By the neural network</p>
          <p>
            <Label type={predictionClass} />
          </p>

          {hasPassPredictionDetections && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon detection_type="pass_prediction" />
                <span>Predictions (network) </span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showPredictionDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('prediction', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↓</Keyboard>
              </div>
            </div>
          )}
          {hasFailPredictionDetections && (
            <div className={s.defects}>
              <div className={s.polygon}>
                <Polygon detection_type="fail_prediction" />
                <span>Predictions (network) </span>
              </div>
              <Toggles
                parentClass={s.radios}
                currentValue={showPredictionDefects}
                options={showOptions}
                onChange={(value) => setDefectsVisibility('prediction', value)}
              />
              <div className={s.toggle}>
                <span>Toggle with</span> <Keyboard>↓</Keyboard>
              </div>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default Comparison;
