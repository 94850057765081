import React, { useState } from 'react';

import Title from 'components/elements/Title';
import Button from 'components/elements/Button';
import Input from 'components/form/Input';

import s from './ConfirmationModal.module.sass';

const ConfirmationModal = (props) => {
  const { datasetName, onSubmit } = props;

  const [repeatName, setRepeatName] = useState('');

  return (
    <div className={s.box}>
      <header className={s.header}>
        <Title>Are you sure you want to delete this dataset?</Title>
      </header>

      <div className={s.content}>
        <p>
          This action cannot be undone. This will permanently delete the{' '}
          <strong>{datasetName}</strong> dataset.
        </p>
        <p>
          Please type <strong>{datasetName}</strong> to confirm.
        </p>
      </div>

      <form className={s.form} onSubmit={(e) => {

          if (repeatName===datasetName) {
              console.log('SUBMITTING CONFIRMATION MODAL FORM');
              onSubmit(e, datasetName)
          } else {
              e.preventDefault();
              console.log('NOT SUBMITTING CONFIRMATION MODAL FORM');
          }


      }}>
        <Input
          id="repeatName"
          name="repeatName"
          type="repeatName"
          value={repeatName}
          onChange={(e) => setRepeatName(e.target.value)}
        />
        <Button
          parentClass={s.button}
          isDisabled={repeatName !== datasetName}
          color="danger"
        >
          Delete <strong>{datasetName}</strong> dataset
        </Button>
      </form>
    </div>
  );
};

export default ConfirmationModal;
