// B"H

// CameraSettings.js

import React, { useState, useEffect, useRef } from 'react';

import Title from 'components/elements/Title';
import Slider from 'components/form/Slider';
import Content from 'components/elements/Content';
import Live from 'components/svgs/Live';
import Toggles from 'components/form/Toggles';


import Radios from 'components/form/Radios';
import Button from 'components/elements/Button';

import SelectImagesModal from 'components/camera/SelectImagesModal';

//Button
//        //width: 30em
//        //max-width: 40em
//        width: 100%
//
//        height: 5em
//        margin-bottom: 1.5em


import Request from 'axios-request-handler';

import axios from 'axios';



import s from './CameraSettings.module.sass';


import { Stage, Layer, Rect, Image } from 'react-konva';

import { COLORS, alphaColor } from 'components/label/utils';


import {
  SUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL,
  UNSUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL,
  SET_BOOL_STREAM_VIDEO_URL,
  SET_BOOL_STREAM_EXPOSURE_URL,
  GET_EXPOSURE_URL,
  SET_EXPOSURE_URL,
  GET_FOCUS_URL,
  SET_FOCUS_URL,
  GET_CAMERA_ID,
  GET_BOOL_PRODUCTION_MODE_URL,
  GET_CAMERA_SELECT_URL,
  SET_CAMERA_SELECT_URL,

} from 'pages/urls';

import {
  SHORTCUTS,
} from 'components/annotation/utils';

//TODO: variable?
const IMAGE_RATIO = 1.33


const STREAM_VIDEO_OPTIONS = [
  { value: false, label: 'Video OFF' },
  { value: true, label: 'Video On' },
];

const EXPOSURE_MARKS = {
  0.1: '0.1ms',
  20: '20ms',
};

const FOCUS_MARKS = {
  0: '0',
  100: '100',
};


const CAMERA_SELECT_OPTIONS = [
  { value: "SIMULATED_CAMERA", label: 'SIMULATED CAMERA' },
  { value: "ALLIED_VISION_CSI2", label: 'CAMERA' },
];

const CAMERA_SELECT_OPTIONS_SERVER = [
  { value: "IDS_USB", label: 'IDS U3-3041LE-C-HQ' },
  { value: "V4L2_USB", label: 'OPENCV USB CAMERA' },
  { value: "SIMULATED_CAMERA", label: 'SIMULATED CAMERA' },
  //{ value: "ALLIED_VISION_CSI2", label: 'CAMERA' },
];


/*
const GAIN_MARKS = {
  0: '0',
  255: '255',
};
*/


const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}


const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

const CameraSettings = (props) => {


  const [boolOnStart, setBoolOnStart] = useState(true);


  const [boolStreamVideo, setBoolStreamVideo] = useState(false);
  const [frameSrc, setFrameSrc] = useState(null);

  const [cameraExposure, setCameraExposure] = useState(null);
  //const [cameraId, setCameraId] = useState(null);

  const [cameraFocus, setCameraFocus] = useState(null);

  const [boolCameraBuiltin, setBoolCameraBuiltin] = useState(true);
  const [cameraSelect, setCameraSelect] = useState(null);

  const [boolSelectImagesModal, setBoolSelectImagesModal] = useState(false);

  const [gainValue, setGainValue] = useState(0);

  const [bool_production_mode, set_bool_production_mode] = useState(null);

  const [bool_crop_img_loaded, set_bool_crop_img_loaded] = useState(false);

  const [mainWidth, set_mainWidth] = useState(0);
  const [mainHeight, set_mainHeight] = useState(0);

  const [bool_image_cropped, set_bool_image_cropped] = useState(false);
  const [bool_crop_in_progress, set_bool_crop_in_progress] = useState(false);

  const [img_crop_x, set_img_crop_x] = useState(0);
  const [img_crop_y, set_img_crop_y] = useState(0);
  const [img_crop_width, set_img_crop_width]   = useState(0);
  const [img_crop_height, set_img_crop_height] = useState(0);

  // TODO: display active job next to cropped image
  const [active_job_slot, set_active_job_slot] = useState(-1);



  //var AA = useRef(null);

  var mainRef = useRef(null);
  var imgRef  = useRef(null);

  const [reviews, set_reviews] = useState(null);


  if (boolOnStart) {

    setBoolOnStart(false);

    print_in_blue('CONSTRUCTOR CONSTRUCTOR CONSTRUCTOR')
    print_in_blue('CONSTRUCTOR CONSTRUCTOR CONSTRUCTOR')

    // 'long poll'
    // async continuous request sent from browser to backend
    // --> backend sleeps until an image is ready
    // --> when image is captured, backend responds to poll
    // --> ... image_capture_callback
    set_reviews(new Request(SUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL));


  ///////////////////////////////////////////////////////

  // Get bool_production_mode

    axios
    .get(GET_BOOL_PRODUCTION_MODE_URL)
    .then((response) => {
      //console.log('RECV BOOL_PRODUCTION_MODE');
      //console.log(response.data);
      //console.log(response.data.bool_production_mode);
      //console.log('RECV BOOL_PRODUCTION_MODE');

      set_bool_production_mode(response.data.bool_production_mode);

   })
   .catch((error) => {
       console.log("[ERROR] get bool_production_mode")
   });

  ///////////////////////////////////////////////////////


  ///////////////////////////////////////////////////////
      /*
      axios
      .get(`${GET_CAMERA_ID}`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response.data);
          //console.log(response.data.exposure_microseconds);
          //console.log(typeof response.data.exposure_microseconds);
          //console.log('_________________');

          setCameraId(response.data.camera_id);
      })
      .catch((error) => {
          console.log("[ERROR] get camera_id")
      });
      */
      axios
      .get(`${GET_EXPOSURE_URL}`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response.data);
          //console.log(response.data.exposure_microseconds);
          //console.log(typeof response.data.exposure_microseconds);
          //console.log('_________________');

          if (response.data.exposure_microseconds!==null) {
              console.log('_________________');
              console.log('exposure != null');
              console.log('_________________');
              setCameraExposure(response.data.exposure_microseconds * 0.001);

          }
      })
      .catch((error) => {
          console.log("[ERROR] get exposure")
      });


      axios
      .get(`${GET_FOCUS_URL}`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response.data);
          //console.log(response.data.exposure_microseconds);
          //console.log(typeof response.data.exposure_microseconds);
          //console.log('_________________');

          if (response.data.focus_value!==null) {
              console.log('_________________');
              console.log('focus != null');
              console.log('_________________');
              setCameraFocus(response.data.focus_value);

          }
      })
      .catch((error) => {
          console.log("[ERROR] get focus")
      });
      /////////////////////////////////////////////////////////////////////////////
      axios
      .get(`${GET_CAMERA_SELECT_URL}`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response.data);
          //console.log('_________________');

          if (response.data.camera_select_value!==null) {
              console.log('_________________');
              console.log('camera_select_str != null');
              console.log('_________________');
              setCameraSelect(response.data.camera_select_value);
              setBoolCameraBuiltin(response.data.bool_camera_builtin);
          }
      })
      .catch((error) => {
          console.log("[ERROR] get select_value")
      });
      /////////////////////////////////////////////////////////////////////////////

      axios
      .get(`${SET_BOOL_STREAM_EXPOSURE_URL}/true`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response.data);
          //console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool stream exposure")
      });

      /////////////////////////////////////////////////////////////////////////////

      //const unsubscribe_url = (UNSUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL);

      //axios.get(unsubscribe_url)
      //.then((response) => {
      //    console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      //    console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      //});

      //setBoolStreamVideo(false);

      /////////////////////////////////////////////////////////////////////////////

      axios
      .get(`${SET_BOOL_STREAM_VIDEO_URL}/false`)
      .then((response) => {
          //console.log('_________________');
          //console.log(response);
          //console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool_stream_video")
      });

      /////////////////////////////////////////////////////////////////////////////


  }

////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////


  // CALLED ON MOUNT (PASS [] AS SECOND ARGUMENT TO USE_EFFECT)
  useEffect(() => {

      setMainDimensions();

  }, [])

////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////


  // CALLED ON MOUNT (PASS [] AS SECOND ARGUMENT TO USE_EFFECT)
  // CALLED ON UNMOUNT (RETURN FUNCTION CALLED ON UNMOUNT)
  useEffect(() => {

      // weird workaround to be access state from key handle (shrug)
      // https://github.com/facebook/react/issues/15815
      const handleKeydown = (e) => {

          if (e.key === 'Delete') {
              //set_bool_delete_key_pressed(true);
              handleRectangleDelete();
          }
      };

      window.addEventListener('keydown', handleKeydown);
      window.addEventListener('resize', handleResize);

      return () => {

          window.removeEventListener('keydown', handleKeydown);
          window.removeEventListener('resize', handleResize);
      }


  }, [])


////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////
////////////////////////////////////////

useEffect(() => {

  //print_in_blue(`AA boolStreamVideo=${boolStreamVideo}`);

  if (boolStreamVideo === false) {
      reviews.cancel();
  }

  if (boolStreamVideo === true) {

    const interval_ms = 1
    reviews.poll(interval_ms).get((response) => {
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")

        const b64Data = response.data['img']
        const blob = b64toBlob(b64Data, "image/jpeg");
        const blobUrl = URL.createObjectURL(blob);

        //print_in_red(`boolStreamVideo=${boolStreamVideo}`);

        //if (boolStreamVideo === true) {
        setFrameSrc(blobUrl);
        //}
        // you can cancel polling by returning false
        //if (boolStreamVideo === false) {
        //    return false;
        //}
    });

  }

  //print_in_blue("ELSE");

  }, [boolStreamVideo])


//////////////////////////////////////////



  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////

  // Window events

  let handleResize = () => {
    setMainDimensions();
  };

  let handleRectangleDelete = () => {

    print_in_blue("HANDLE RECTANGLE DELETE");

    set_bool_image_cropped(false);
    set_bool_crop_in_progress(false);

    set_img_crop_x(0);
    set_img_crop_y(0);
    set_img_crop_width(0);
    set_img_crop_height(0);

    /*
    axios
    .get(SET_IMAGE_CROP_URL+'/false/0/0/0/0')
    .then((response) => {
        console.log("SET IMAGE CROP RESPONSE");
    })
    .catch((error) => {
        console.log("[ERROR] SET IMAGE CROP RESPONSE");
    });
    */

  }


  // TODO: handle differently
  let setMainDimensions = () => {
    print_in_orange(`SET MAIN DIMENSIONS A:`);

    if (!mainRef || !mainRef.current) {
      return;
    }

    const new_mainWidth = mainRef.current.clientWidth;
    const new_mainHeight = Math.round(new_mainWidth / IMAGE_RATIO);

    print_in_orange(`SET MAIN DIMENSIONS B: new_mainWidth=${new_mainWidth}`);
    print_in_orange(`SET MAIN DIMENSIONS C: new_mainHeight=${new_mainHeight}`);

    set_mainWidth(new_mainWidth);
    set_mainHeight(new_mainHeight);

    /*
    // Get image_crop
    axios
    .get(GET_IMAGE_CROP_URL)
    .then((response) => {
      print_in_blue('RECV BOOL_IMAGE_CROP');
      //console.log(response.data);
      print_in_blue(response.data.bool_image_cropped);
      print_in_blue(response.data.active_job_slot);
      print_in_blue(response.data.img_crop_x);
      print_in_blue(response.data.img_crop_y);
      print_in_blue(response.data.img_crop_width);
      print_in_blue(response.data.img_crop_height);
      //console.log('RECV BOOL_IMAGE_CROP');

      if (response.data.bool_image_cropped === true) {

          print_in_blue("  SETTING CROP STATE FROM BACKEND");

          set_bool_image_cropped(response.data.bool_image_cropped);

          // convert crop to pixel_coordinates
          const x_pixels = response.data.img_crop_x * new_mainWidth;
          const y_pixels = response.data.img_crop_y * new_mainHeight;
          const width_pixels  = response.data.img_crop_width * new_mainWidth;
          const height_pixels = response.data.img_crop_height * new_mainHeight;

          print_in_blue(`new_mainWidth=${new_mainWidth}`);
          print_in_blue(`new_mainHeight=${new_mainHeight}`);

          print_in_blue(`mainWidth=${mainWidth}`);
          print_in_blue(`mainHeight=${mainHeight}`);

          print_in_blue(`x_pixels=${x_pixels}`);
          print_in_blue(`y_pixels=${y_pixels}`);
          print_in_blue(`width_pixels=${width_pixels}`);
          print_in_blue(`height_pixels=${height_pixels}`);

          set_img_crop_x(x_pixels);
          set_img_crop_y(y_pixels);
          set_img_crop_width(width_pixels);
          set_img_crop_height(height_pixels);
          set_active_job_slot(response.data.active_job_slot);
      }

   })
   .catch((error) => {
       console.log("[ERROR] get bool_image_cropped")
   });
   */


  };

  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////

  // CALLED ON change of bool_stream_video
  useEffect(() => {
    if (boolStreamVideo === false) {
        //set_bool_crop_img_loaded(false);
        print_in_red("SET boolStreamVideo");

    }
  }, [boolStreamVideo])

  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////


  // CALLED ON change of bool_stream_video
  useEffect(() => {
        set_bool_crop_img_loaded(false);
        print_in_red("SET bool_crop_img_loaded FALSE");
  }, [frameSrc])


  /////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////

  // CALLED ON UNMOUNT (FUNCTION RETURNED BY USE EFFECT)
  useEffect(() => {

    // returned function will be called on component unmount 
    return () => {

      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");
      print_in_red("UNMOUNT UNMOUNT UNMOUNT");

      const unsubscribe_url = (UNSUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL);

      ////////////////////////////////////////////////////////////////////////////

      axios.get(unsubscribe_url)
      .then((response) => {
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      });

      //setBoolStreamVideo(false);

      ////////////////////////////////////////////////////////////////////////////

      axios
      .get(`${SET_BOOL_STREAM_VIDEO_URL}/false`)
      .then((response) => {
          console.log('_________________');
          console.log(response);
          console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool_stream_video")
      });

      ////////////////////////////////////////////////////////////////////////////

      axios
      .get(`${SET_BOOL_STREAM_EXPOSURE_URL}/false`)
      .then((response) => {
          console.log('_________________');
          console.log(response.data);
          console.log('_________________');
      })
      .catch((error) => {
          console.log("[ERROR] set bool stream exposure")
      });

      ////////////////////////////////////////////////////////////////////////////


    }
  }, [])

  /////////////////////////////////////////////////////////////



  let handleMouseDown = (event) => {

    return;

    /*
    const { x, y } = event.target.getStage().getPointerPosition();

        print_in_blue(`mouse_down_x=${x}`);
        print_in_blue(`mouse_down_y=${y}`);


    // OK to start new crop
    if (bool_image_cropped == false && bool_crop_in_progress === false) {

        set_img_crop_x(x);
        set_img_crop_y(y);
        set_bool_crop_in_progress(true);

    }

    else if (bool_image_cropped === false && bool_crop_in_progress === true) {

        var w = x - img_crop_x
        var h = y - img_crop_y

        set_img_crop_width(w)
        set_img_crop_height(h)

        set_bool_image_cropped(true);
        set_bool_crop_in_progress(false);

        print_in_blue(`x=${x}`);
        print_in_blue(`y=${y}`);
        print_in_blue(`w=${w}`);
        print_in_blue(`h=${h}`);

        // convert_list_of_labels_to_image_coordinates
        var xp = Math.round((img_crop_x / mainWidth) * 10000) / 10000;
        var yp = Math.round((img_crop_y / mainHeight) * 10000) / 10000;
        var wp = Math.round((w / mainWidth) * 10000) / 10000;
        var hp = Math.round((h / mainHeight) * 10000) / 10000;

        print_in_blue(`x=${x}`);
        print_in_blue(`y=${y}`);
        print_in_blue(`w=${w}`);
        print_in_blue(`h=${h}`);

        axios
        .get(SET_IMAGE_CROP_URL+'/true/'+xp+'/'+yp+'/'+wp+'/'+hp)
        .then((response) => {
            console.log("SET IMAGE CROP RESPONSE");
        })
        .catch((error) => {
            console.log("[ERROR] SET IMAGE CROP RESPONSE");
        });
     }
     */

  };






  let handleMouseUp = (event) => {

  };



  let handleMouseMove = (event) => {

    return;

    /*
    //console.log("mouseMove");

    if (bool_image_cropped === false && bool_crop_in_progress === true) {

        const { x, y } = event.target.getStage().getPointerPosition();

        set_img_crop_width(x - img_crop_x)
        set_img_crop_height(y - img_crop_y)
    }
    */

  }







  if (bool_production_mode===true) {
    return (
                 <div className={s.production_mode_error_message}>
                   Camera Settings not available in production mode
                 </div>

    );
  }

  var crop_selected_opacity = 30;

  return (
    <div className={s.component}>
      <aside className={s.side}>

          <Title parentClass={s.title} size="1">
            Live Video on/off
          </Title>

            <Toggles

              currentValue={boolStreamVideo}
              options={STREAM_VIDEO_OPTIONS}
              onChange={(new_toggle_value)=> {
                  print_in_orange(`new_toggle_value=${new_toggle_value}`);
                  setBoolStreamVideo(new_toggle_value);
                    axios
                    .get(`${SET_BOOL_STREAM_VIDEO_URL}/${new_toggle_value}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');
                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_stream_video")
                    });

              }}
            />

           <br />
           <br />


    {( (cameraExposure!==null) &&(
       <div className={s.setting}>
          <Title parentClass={s.title} size="1" noMargin>
            Camera Exposure time
          </Title>
          <Content parentClass={s.content}>
          </Content>


          <Slider
            min={0.100}
            max={20.000}
            marks={EXPOSURE_MARKS}
            value={cameraExposure}
            defaultValue={cameraExposure}
            onChange={(sliderValue) => { //setCameraExposure(sliderValue)

                axios
               .get(`${SET_EXPOSURE_URL}/${sliderValue}`)
               .then((response) => {
                   console.log('_________________');
                   console.log(response);
                   console.log('_________________');
                   ////////////////////////////////////////////////////////
                   axios
                   .get(`${GET_EXPOSURE_URL}`)
                   .then((response) => {
                       console.log('_________________');
                       console.log(response.data);
                       console.log(response.data.exposure_microseconds);
                       console.log('_________________');
                       setCameraExposure(response.data.exposure_microseconds );
                   })
                   .catch((error) => {
                       console.log("[ERROR] get exposure")
                   });
                   ////////////////////////////////////////////////////////
               })
               .catch((error) => {
                   console.log("[ERROR] get exposure")
               });
            }}
          />

        <br/>

        </div>))
        }



        {( (cameraFocus !== null) && (<div className={s.setting}>

          <Title parentClass={s.title} size="1" noMargin>
            Camera Focus
          </Title>
          <Content parentClass={s.content}>
          </Content>


           <Slider
            min={1}
            max={100}
            marks={FOCUS_MARKS}
            value={cameraFocus}
            defaultValue={cameraFocus}
            onChange={(sliderValue) => { //setCameraExposure(sliderValue)

                axios
                .get(`${SET_FOCUS_URL}/${sliderValue}`)
                .then((response) => {
                    console.log('_________________');
                    console.log(response);
                    console.log('_________________');
                    ////////////////////////////////////////////////////////
                    axios
                    .get(`${GET_FOCUS_URL}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response.data);
                        console.log(response.data.camera_focus);
                        console.log('_________________');
                        setCameraFocus(response.data.camera_focus);
                    })
                    .catch((error) => {
                        console.log("[ERROR] get exposure")
                    });
                    ////////////////////////////////////////////////////////
                })
               .catch((error) => {
                   console.log("[ERROR] get exposure")
               });
            }}
            step_interval={1}
          />
        <br/>

        </div>))}

        {( (cameraSelect !== null) && (<div className={s.setting}>

          <Title parentClass={s.title} size="1" noMargin>
            Camera Select
          </Title>
          <Content parentClass={s.content}>
          </Content>


          <Radios
            currentValue={cameraSelect}
            options={boolCameraBuiltin ? CAMERA_SELECT_OPTIONS : CAMERA_SELECT_OPTIONS_SERVER}
            onChange={(value) => {

                /////////////////////////////////////////////////////////

                // Set IO device type
                console.log(`CAMERA_SELECTED=${value}`);
                var set_camera_select_url = `${SET_CAMERA_SELECT_URL}/${value}`;

                console.log(`set_camera_select_url=${set_camera_select_url}`)

                /////////////////////////////////////////////////////////
                axios.get(set_camera_select_url)
                .then((response) => {
                    console.log("RECVD SET IO DEVICE TYPE");
                    console.log(response.data)
                    console.log("RECVD SET IO DEVICE TYPE");

                    /////////////////////////////////////////////////////////
                    // Get device_type
                    axios.get(GET_CAMERA_SELECT_URL)
                    .then((response) => {
                        console.log("RECVD CAMERA SELECT");
                        console.log(response.data)
                        console.log("RECVD CAMERA SELECT");
                        setCameraSelect(response.data.camera_select_value)
                    })
                    .catch((error) => {
                        console.log(`[ERROR] ${error.message}`)
                    });
                    /////////////////////////////////////////////////////////
                })
               .catch((error) => {
                   console.log(`[ERROR] ${error.message}`)
               });
               /////////////////////////////////////////////////////////

               /////////////////////////////////////////////////////////

            }}
          />

          {( (cameraSelect === "SIMULATED_CAMERA") && (

              <div>
                <br />
                <Button
                   parentClass={s.button}
                    //color="success"
                    iconRight="arrow-right"
                    onClick={()=>{
                        console.log("SELECT DATSAET BUTTON!");
                        setBoolSelectImagesModal(true);
                    }}
                >
                  SELECT IMAGES FOR SIMULATED CAMERA
                </Button>

               <SelectImagesModal
                 key="modal2"
                 isOpen={boolSelectImagesModal}
                 onClose={() => { setBoolSelectImagesModal(false); } }
               />


              </div>


           ))}


        </div>))}

      {/* //////////////////////////////////////////////////////// */ }
      {/* //////////////////////////////////////////////////////// */ }
      {/* //////////////////////////////////////////////////////// */ }
      {/* //////////////////////////////////////////////////////// */ }

      <br />
      {/* { (true) &&

        <div>
          <div className={s.setting}>
            <Title parentClass={s.title} size="1" noMargin>
              Camera Gain (disabled)
            </Title>
            <Content parentClass={s.content}>
              Controls amplification of signal from camera sensor and
              apparent sensitivity to light
            </Content>
          </div>
          <Slider
            min={0}
            max={255}
            marks={GAIN_MARKS}
            value={0}
            defaultValue={0}
            disabled={true}
            onChange={(sliderValue) => {
                //setGainValue(sliderValue)
            }}
          />
        </div>
      } */}

      {/* (boolStreamVideo === false) &&

          <div className={s.setting}>
            <Title parentClass={s.title} size="1" noMargin>
              Crop Image
            </Title>
            <Content parentClass={s.content}>
              Crop image to focus neural network. Click within image to crop.
              Crops are saved by job (each job may have a different crop).
              <ul>
                <li> Click within image to crop. </li>
                <li> Use delete key to remove crop.</li>
              </ul>
            </Content>
          </div>

      */}
      </aside>

      <figure className={s.live} ref={mainRef}>

            { (frameSrc !== null) &&
                <div>
                  { (boolStreamVideo === true) &&
                    <div>
                      <Live />
                      <img alt="Live camera view" src={frameSrc} />
                    </div>

                  }

                  { (boolStreamVideo === false) &&
                    <div>

                        <img
                                className={s.imgx}
                                ref={ imgRef }
                                width={ mainWidth }
                                height={ mainHeight }
                                src={ frameSrc }
                                alt='Live camera view'
                                onLoad={ (e) => {
                                  console.log('CROP IMG LOADED');
                                  console.log('CROP IMG LOADED');
                                  //console.log(`mainWidth=${mainWidth}`);
                                  //console.log(`mainHeight=${mainHeight}`);
                                  set_bool_crop_img_loaded(true);
                                }}
                        />

                       { bool_crop_img_loaded &&

                        <Stage
                            className={s.stage}
                            width={mainWidth}
                            height={mainHeight}


                            onMouseDown={(event)=>handleMouseDown(event)}
                            onMouseUp={(event)=>handleMouseUp(event)}
                            onMouseMove={(event)=>handleMouseMove(event)}
                        >

                            <Layer>
                            {   bool_crop_img_loaded && (
                                    <Image image={ imgRef.current } width={ mainWidth } height={ mainHeight }/>
                                )
                            }


                            { (bool_image_cropped || bool_crop_in_progress) && (
                                            <Rect
                                                x={img_crop_x}
                                                y={img_crop_y}
                                                width={img_crop_width}
                                                height={img_crop_height}
                                                fill={alphaColor(COLORS.orange, crop_selected_opacity)}
                                                stroke={COLORS.orange}

                                                onMouseOver={ () => {

                                                        mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />
                            )}


                            </Layer>
                        </Stage>
                       }

                </div>
              }
              </div>
            }
            { (frameSrc === null) &&
              <div className={s.live_image}>
                  CAMERA LIVE FEED OFF
              </div>
            }

      </figure>
    </div>
  );
};

export default CameraSettings;
