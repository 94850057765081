// Settings.js

import React, { useState } from 'react';

import Modal from 'components/elements/Modal';
import CameraSettings from 'components/camera/CameraSettings';
import IOSettings from 'components/camera/IOSettings';
import ProgramSelect from 'components/camera/ProgramSelect';

import cx from 'classnames';
import s from './Settings.module.sass';

const TABS = [
  { id: 'camera', label: 'Camera settings' },
  { id: 'io', label: 'I/O settings' },
  { id: 'program', label: 'Program Select' },
];

const Settings = (props) => {
  const { isOpen, onClose } = props;
  const [activeTab, setTab] = useState('camera');

  const tabs = TABS.map((item, index) => {
    const tabClass = cx({
      [s.tab]: true,
      [s[item.id]]: true,
      [s.activeTab]: activeTab === item.id,
    });

    return (
      <div key={item.id} className={tabClass} onClick={() => setTab(item.id)}>
        {item.label}
      </div>
    );
  });

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={s.component}>
        <nav className={s.tabs}>{tabs}</nav>
        <div className={s.body}>
          {activeTab === 'camera' && <CameraSettings />}
          {activeTab === 'io' && <IOSettings />}
          {activeTab === 'program' && <ProgramSelect />}
        </div>
      </div>
    </Modal>
  );
};

export default Settings;
