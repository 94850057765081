import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cx from 'classnames';
import s from './BackLink.module.sass';

const BackLink = (props) => {
  const { to, children, parentClass, onClick } = props;

  const componentClass = cx({
    [s.component]: true,
    [parentClass]: parentClass,
  });

  return (
    <NavLink className={componentClass} to={to} onClick={onClick}>
      <FontAwesomeIcon icon="arrow-left" className={s.icon} />
      {children}
    </NavLink>
  );
};

export default BackLink;
