// B"H

//const ip_address = '192.168.1.12';
//const port = '6002';
//export const GET_IMAGE_HISTORY_JOBS_URL = `http://${ip_address}:${port}/get_image_history_jobs`
//export const GET_IMAGE_HISTORY_MONTHS_URL = `http://${ip_address}:${port}/get_image_history_months`
//export const GET_IMAGE_HISTORY_DATES_URL = `http://${ip_address}:${port}/get_image_history_dates`
//export const GET_THUMBNAILS_METADATA_URL = `http://${ip_address}:${port}/get_images_metadata`;
//export const GET_IMAGES_ZIP_URL = `http://${ip_address}:${port}/get_images_zip`;
//export const GET_THUMBNAILS_ZIP_URL = `http://${ip_address}:${port}/get_thumbnails_zip`;

//const camera_ip_address = window.$$env.CAMERA_IP_ADDRESS;
const camera_ip_address = "http://"+window.location.hostname;
const training_api_url = camera_ip_address+":6001"


export const LOGIN_URL = `${training_api_url}/login`;

export const SET_DATASET_NAME_URL = `${training_api_url}/set_dataset_name`
export const SET_NETWORK_NAME_URL = `${training_api_url}/set_network_name`;

export const GET_DATASET_IMAGES_ZIP_URL = `${training_api_url}/get_images_zip`;

export const DELETE_DATASET_URL = `${training_api_url}/delete_dataset`;

export const DELETE_TRAINING_IMAGE_URL = `${training_api_url}/delete_training_image`;
export const DELETE_NETWORK_URL = `${training_api_url}/delete_network`;



export const CREATE_NEW_DATASET_URL = `${training_api_url}/create-new-dataset`;
export const CREATE_NEW_DATASET_WITH_IMAGES_URL = `${training_api_url}/create-new-dataset-with-images`;
export const ADD_IMAGES_TO_TRAINING_DATASET_URL = `${training_api_url}/add-images-to-dataset`;

export const UPDATE_NAMED_CLASSES_URL = `${training_api_url}/UPDATE_NAMED_CLASSES`;
export const REQUEST_NAMED_CLASSES_URL = `${training_api_url}/REQUEST_NAMED_CLASSES`;

export const UPLOAD_IMAGE_URL = `${training_api_url}/new-image-upload`;
export const GET_LIST_OF_DATASETS_URL = `${training_api_url}/get-list-of-datasets`;
export const GET_LIST_OF_NETWORKS_URL = `${training_api_url}/get-list-of-networks`;
export const GET_DATASET_INFO_URL = `${training_api_url}/load-filenames-in-dataset`;
export const LOAD_BINARY_IMAGE_THUMBNAIL_FROM_SERVER_URL = `${training_api_url}/load-image-thumbnail`;
export const IMAGE_PREDICTION_URL = `${training_api_url}/get-img-predictions`;
export const START_TRAINING_URL = `${training_api_url}/start-training`;
export const GET_DATASET_IMAGE_METADATA_DICT_URL = `${training_api_url}/load-dataset-image-metadata`;
export const GET_DATASET_THUMBNAILS_ZIP_URL = `${training_api_url}/load-dataset-thumbnails`;
export const NEW_IMAGE_LABEL_URL = `${training_api_url}/new-image-label`;

export const SAVE_CROP_TO_DATASET_URL = `${training_api_url}/new-dataset-crop`;
export const RETURN_DATASET_CROP_URL = `${training_api_url}/return-dataset-crop`;

export const LOAD_TRAINING_IMAGE_FROM_SERVER_URL = `${training_api_url}/load-image`;

export const DOWNLOAD_JOB_FILE_URL = `${training_api_url}/download_network`;

export const API_TRAINING_PROGRESS_URL = `${training_api_url}/GET-PROGRESS`;


const backend_api_url = camera_ip_address+":6002";


export const GET_CAMERA_INFO_URL = `${backend_api_url}/camera_info`
export const GET_MANUAL_URL = `${backend_api_url}/manual`
export const GET_CAMERA_TIME_URL = `${backend_api_url}/camera_time`
export const SET_CAMERA_TIME_URL = `${backend_api_url}/set_camera_time`
export const GET_AVAILABLE_STORAGE_URL = `${backend_api_url}/get_available_storage`
export const GET_PRODUCTION_STATS_URL = `${backend_api_url}/production_stats`
export const REFRESH_PRODUCTION_STATS_URL = `${backend_api_url}/refresh_production_stats`
export const DELETE_CAMERA_IMAGE_URL = `${backend_api_url}/delete_camera_image`

export const GET_IMAGE_HISTORY_JOBS_URL = `${backend_api_url}/get_image_history_jobs`
export const GET_IMAGE_HISTORY_MONTHS_URL = `${backend_api_url}/get_image_history_months`
export const GET_IMAGE_HISTORY_DATES_URL = `${backend_api_url}/get_image_history_dates`
export const GET_IMAGE_HISTORY_IMAGESETS_URL = `${backend_api_url}/get_image_history_imagesets`
export const GET_THUMBNAILS_METADATA_URL = `${backend_api_url}/get_images_metadata`;
export const GET_IMAGES_ZIP_URL = `${backend_api_url}/get_images_zip`;
export const GET_THUMBNAILS_ZIP_URL = `${backend_api_url}/get_thumbnails_zip`;

export const GET_LIST_OF_JOBS_URL = `${backend_api_url}/return_list_of_jobs`;
export const GET_JOB_NAME_URL = `${backend_api_url}/return_job_name`;
export const UPLOAD_JOB_URL = `${backend_api_url}/upload_job`;
export const UPLOAD_SOFTWARE_UPDATE_URL = `${backend_api_url}/upload_software_update`;

export const LOAD_NETWORK_TO_JOB_SLOT_URL = `${backend_api_url}/load_network_to_job_slot`;

export const ACTIVATE_JOB_URL = `${backend_api_url}/activate_job`;
export const EDIT_JOB_NAME_URL = `${backend_api_url}/edit_job_name`;
export const DELETE_JOB_URL = `${backend_api_url}/delete_job`;
export const NEW_IMAGESET_URL = `${backend_api_url}/new_imageset`
export const DELETE_IMAGESET_URL = `${backend_api_url}/delete_imageset`
export const ACTIVATE_IMAGESET_URL = `${backend_api_url}/activate_imageset`
export const EDIT_IMAGESET_NAME_URL = `${backend_api_url}/edit_imageset_name`
export const BOOL_TODAY_URL = `${backend_api_url}/bool_today`


export const GET_CONNECTED_DEVICES_URL = `${backend_api_url}/get_connected_devices`;

export const GET_EXPOSURE_URL = `${backend_api_url}/get_exposure`;
export const SET_EXPOSURE_URL = `${backend_api_url}/set_exposure`;

export const GET_FOCUS_URL = `${backend_api_url}/get_focus`;
export const SET_FOCUS_URL = `${backend_api_url}/set_focus`;

export const GET_CAMERA_SELECT_URL = `${backend_api_url}/get_camera_select`;
export const SET_CAMERA_SELECT_URL = `${backend_api_url}/set_camera_select`;

export const GET_CAMERA_ID = `${backend_api_url}/get_camera_id`;

export const GET_SIMULATED_CAMERA_DATASET_ID_URL = `${backend_api_url}/get_simulated_camera_dataset_ID`
export const SET_SIMULATED_CAMERA_DATASET_ID_URL = `${backend_api_url}/set_simulated_camera_dataset_ID`

export const GET_AUTO_TRIGGER_INTERVAL_URL = `${backend_api_url}/get_auto_trigger_interval`;
export const SET_AUTO_TRIGGER_INTERVAL_URL = `${backend_api_url}/set_auto_trigger_interval`;

export const SUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL = `${backend_api_url}/subscribe_image_capture_notifications`;
export const UNSUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL = `${backend_api_url}/unsubscribe_image_capture_notifications`;

export const SUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL = `${backend_api_url}/subscribe_exposure_image_capture_notifications`;
export const UNSUBSCRIBE_EXPOSURE_IMAGE_CAPTURE_NOTIFICATIONS_URL = `${backend_api_url}/unsubscribe_exposure_image_capture_notifications`;

export const SUBSCRIBE_IO_NOTIFICATIONS_URL = `${backend_api_url}/subscribe_io_notifications`;
export const UNSUBSCRIBE_IO_NOTIFICATIONS_URL = `${backend_api_url}/unsubscribe_io_notifications`;

export const GET_BOOL_STREAM_IO_NOTIFICATIONS_URL = `${backend_api_url}/get_bool_stream_io_notifications`;
export const SET_BOOL_STREAM_IO_NOTIFICATIONS_URL = `${backend_api_url}/set_bool_stream_io_notifications`;

export const SET_BOOL_STREAM_VIDEO_URL = `${backend_api_url}/set_bool_stream_video`;
export const SET_BOOL_STREAM_EXPOSURE_URL = `${backend_api_url}/set_bool_exposure_video`;
export const CAPTURE_IMAGE_NO_INFERENCE_URL = `${backend_api_url}/capture_image_no_inference`;

export const GET_BOOL_PLC_TRIGGER_URL = `${backend_api_url}/get_bool_PLC_trigger`;
export const SET_BOOL_PLC_TRIGGER_URL = `${backend_api_url}/set_bool_PLC_trigger`;

export const GET_BOOL_USE_PLC_TIME_URL = `${backend_api_url}/get_bool_use_PLC_time`;
export const SET_BOOL_USE_PLC_TIME_URL = `${backend_api_url}/set_bool_use_PLC_time`;


export const GET_BOOL_PRODUCTION_MODE_URL = `${backend_api_url}/get_bool_production_mode`;
export const SET_BOOL_PRODUCTION_MODE_URL = `${backend_api_url}/set_bool_production_mode`;

export const GET_IP_ADDRESS_AND_NETMASK_URL = `${backend_api_url}/get_ip_address_and_netmask`;
export const SET_IP_ADDRESS_AND_NETMASK_URL = `${backend_api_url}/set_ip_address_and_netmask`;

export const GET_GATEWAY_URL = `${backend_api_url}/get_gateway`;
export const SET_GATEWAY_URL = `${backend_api_url}/set_gateway`;

// Read all tag names
export const GET_TAG_NAMES_URL = `${backend_api_url}/get_tag_names`;
// Set individual tag name
export const SET_TAG_NAME_URL = `${backend_api_url}/set_tag_name`;

export const GET_PLC_IP_ADDRESS_URL = `${backend_api_url}/get_PLC_ip_address`;
export const SET_PLC_IP_ADDRESS_URL = `${backend_api_url}/set_PLC_ip_address`;

export const GET_INPUT_TAG_OF_TRIGGER_URL = `${backend_api_url}/get_input_tag_of_trigger`;
export const SET_INPUT_TAG_OF_TRIGGER_URL =`${backend_api_url}/set_input_tag_of_trigger`;
export const GET_OUTPUT_TAG_OF_PASS_SIGNAL_URL = `${backend_api_url}/get_output_tag_of_pass_signal`;
export const SET_OUTPUT_TAG_OF_PASS_SIGNAL_URL = `${backend_api_url}/set_output_tag_of_pass_signal`;
export const GET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL = `${backend_api_url}/get_output_tag_of_fail_signal`;
export const SET_OUTPUT_TAG_OF_FAIL_SIGNAL_URL = `${backend_api_url}/set_output_tag_of_fail_signal`;


export const GET_IO_DEVICE_TYPE_URL = `${backend_api_url}/get_IO_device_type`;
export const SET_IO_DEVICE_TYPE_URL = `${backend_api_url}/set_IO_device_type`;
export const GET_IO_DEVICE_IP_ADDRESS_URL = `${backend_api_url}/get_IO_device_ip_address`;
export const SET_IO_DEVICE_IP_ADDRESS_URL = `${backend_api_url}/set_IO_device_ip_address`;
export const INIT_IO_DEVICE_CONNECTION_URL = `${backend_api_url}/init_IO_device_connection`;
export const GET_IO_DEVICE_CONNECTON_STATUS_URL = `${backend_api_url}/get_IO_device_connection_status`;

export const LOAD_BINARY_IMAGE_FROM_SERVER_URL = `${backend_api_url}/load_image`;


export const TRAINING_BASE_PATH = '/training';
export const DATASETS_INDEX_URL = TRAINING_BASE_PATH;
export const DATASET_VIEW_URL = `${DATASETS_INDEX_URL}/:datasetId`;
  export const IMAGES_URL = `${DATASET_VIEW_URL}/images`;
  export const NETWORKS_INDEX_URL = `${DATASET_VIEW_URL}/networks`;
    export const TRAIN_SETTINGS_URL = `${NETWORKS_INDEX_URL}/new-network`;
    export const NETWORK_VIEW_URL = `${NETWORKS_INDEX_URL}/:networkId`;
      export const TRAIN_PROGRESS_URL = `${NETWORK_VIEW_URL}/train-progress`;

// export const UPLOAD_URL = TRAINING_BASE_PATH;
// export const LABEL_URL = `${TRAINING_BASE_PATH}/label`;
// export const REVIEW_URL = `${TRAINING_BASE_PATH}/review`;

export const CAMERA_BASE_PATH = '/camera';
export const PRODUCTION_VIEW_URL = CAMERA_BASE_PATH;
export const CAPTURE_ONLY_URL = `${CAMERA_BASE_PATH}/capture-only`;
export const HISTORY_URL = `${CAMERA_BASE_PATH}/history`;

export const HISTORY_MONTHS_URL = `${HISTORY_URL}/:job_slot`;
export const HISTORY_DATES_URL = `${HISTORY_MONTHS_URL}/:month_str`;

export const HISTORY_IMAGESETS_URL = `${HISTORY_DATES_URL}/:date_str`;

export const HISTORY_BROWSE_URL = `${HISTORY_IMAGESETS_URL}/:imageset_name`;

export const USER_URL = `${CAMERA_BASE_PATH}/user`;

const replaceDatasetId = (str, datasetId) => str.replace(':datasetId', datasetId);
const replaceNetworkId = (str, networkId) => str.replace(':networkId', networkId);

export const getDatasetURL = datasetId => replaceDatasetId(DATASET_VIEW_URL, datasetId);
export const getImagesURL = datasetId => replaceDatasetId(IMAGES_URL, datasetId);
export const getNetworksIndexURL = datasetId => replaceDatasetId(NETWORKS_INDEX_URL, datasetId);
export const getNewNetworkURL = datasetId => replaceDatasetId(TRAIN_SETTINGS_URL, datasetId);
export const getNetworkURL = (datasetId, networkId) => {
  const a = replaceDatasetId(NETWORK_VIEW_URL, datasetId);
  return replaceNetworkId(a, networkId);
}
export const getNetworkProgressURL = (datasetId, networkId) => {
  const a = replaceDatasetId(TRAIN_PROGRESS_URL, datasetId);
  return replaceNetworkId(a, networkId);
}


const replaceJobSlot = (str, job_slot) => str.replace(':job_slot', job_slot);
export const getHistoryMonthsURL = job_slot => replaceJobSlot(HISTORY_MONTHS_URL, job_slot);

const replaceMonthStr = (str, month_str) => str.replace(':month_str', month_str);
const replaceDateStr = (str, date_str) => str.replace(':date_str', date_str);
const replaceImageSetName = (str, imageset_name) => str.replace(':imageset_name', imageset_name);


//export const getHistoryDatesURL = month_str => replaceMonthStr(HISTORY_DATES_URL, month_str);


export const getHistoryDatesURL = (job_slot, month_str) => {
  const a = replaceJobSlot(HISTORY_DATES_URL, job_slot);
  return replaceMonthStr(a, month_str);
}


export const getHistoryImageSetsURL = (job_slot, month_str, date_str) => {
  const a = replaceJobSlot(HISTORY_IMAGESETS_URL, job_slot);
  const b = replaceMonthStr(a, month_str);
  const c = replaceDateStr(b, date_str);
  console.log(`getHistoryImageSetsURL=${c}`);
  return c;
}

export const getHistoryBrowseURL = (job_slot, month_str, date_str, imageset_name) => {
  const a = replaceJobSlot(HISTORY_BROWSE_URL, job_slot);
  const b = replaceMonthStr(a, month_str);
  const c = replaceDateStr(b, date_str);
  return replaceImageSetName(c, imageset_name);

}

