import React from 'react';

import { getDatasetURL, getImagesURL, getNetworksIndexURL } from 'pages/urls';
import Tabs from 'components/navigation/Tabs';

const DatasetTabs = (props) => {
  const { datasetId } = props;

  const items = [
    {
      id: 'overview',
      label: 'Overview',
      to: getDatasetURL(datasetId),
      exact: true,
    },
    {
      id: 'images',
      label: 'Images',
      to: getImagesURL(datasetId),
    },
    {
      id: 'networks',
      label: 'Networks',
      to: getNetworksIndexURL(datasetId),
    },
  ];

  return <Tabs items={items} />;
};

export default DatasetTabs;
