// polygon.js

import React from 'react';

import cx from 'classnames';
import s from './Polygon.module.sass';

const Polygon = (props) => {
  const { detection_type } = props;

  const componentClass = cx({
    [s.component]: true,
    [s[`type-${detection_type}`]]: detection_type,
  });

  console.log(`detection_type=${detection_type}`);

  return (
    <svg
      className={componentClass}
      width="26px"
      height="22px"
      viewBox="0 0 26 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.502595061"
      >
      { (detection_type==="fail_label") &&
            <rect x="0" y="0" width="100%" height="100%" stroke="#FF2450" fill="#FF2450"/>
      }
      { (detection_type==="fail_prediction") &&
            <rect x="0" y="0" width="100%" height="100%" stroke="#FF6D24" fill="#FF6D24"/>
      }
      { (detection_type==="pass_label") &&
            <rect x="0" y="0" width="100%" height="100%" stroke="#1A8421" fill="#1A8421"/>
      }

      { (detection_type==="pass_prediction") &&
            <rect x="0" y="0" width="100%" height="100%" stroke="#24FF50" fill="#24FF50"/>
      }

      { /*(detection_type==="pass_prediction") &&
            <rect x="0" y="0" width="100%" height="100%" stroke="#36FF68" fill="#36FF68"/>
      */ }

        {/*
        <path
          d="M1,12.859503 C8.33333333,18.286501 12,21 12,21 C20.6666667,16.2500759 25,13.8751138 25,13.8751138 C22.3333333,5.75022755 21,1.68778444 21,1.68778444 L11.3495579,1 L1,12.859503 Z"
          stroke="#FF2450"
          fill="#FF2450"
        ></path>
          */}
      </g>
    </svg>
  );
};

export default Polygon;
