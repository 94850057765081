// B"H

// SaveImagesToTraining.js

// TODO: if add back save images to new dataset,
//       calls to create dataset form list_of_datasets and here
//       should call one common file

import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';

import Modal from 'components/elements/Modal';
//import CameraSettings from 'components/camera/CameraSettings';
//import IOSettings from 'components/camera/IOSettings';

import cx from 'classnames';
import s from './SelectImagesModal.module.sass';


import Title from 'components/elements/Title';
import Input from 'components/form/Input';
import Button from 'components/elements/Button';

import { ADD_IMAGES_TO_TRAINING_DATASET_URL,
         getDatasetURL,
         GET_SIMULATED_CAMERA_DATASET_ID_URL,
         SET_SIMULATED_CAMERA_DATASET_ID_URL,
       }
from 'pages/urls';

import { selectors, createNewDatasetWithImages, clearActiveDatasetID, getListOfDatasets } from 'redux/reducers/datasets';

import Tag from 'components/elements/Tag';

import axios from 'axios';

import ToggleFilter from 'components/filters/ToggleFilter';

import Slider2 from 'components/form/Slider2';


/*
const TABS = [
  { id: 'save_new', label: 'Save Images to New Dataset' },
  { id: 'save_existing', label: 'Save Images to Existing Dataset' },
];
*/
const TABS = [
  { id: 'save_existing', label: 'Select Images for Simulated Camera' },
];


const PASS_FAIL_LABEL_OPTIONS = [
  { value: 'NONE', label: 'None' },
  { value: 'PASS', label: 'Pass', type: 'pass' },
  { value: 'FAIL', label: 'Fail', type: 'fail' },
];





const SelectImagesModal = (props) => {
  const { isOpen, onClose } = props;

  const [activeTab, setTab] = useState('save_existing');
  const [dataset_name, set_dataset_name] = useState('');

  const [create_dataset_submit, set_create_dataset_submit] = useState(false);
  const [create_dataset_type, set_create_dataset_type] = useState('');

  const [bool_dataset_created, set_bool_dataset_created] = useState(false);
  const [bool_go_to_new_dataset, set_bool_go_to_new_dataset] = useState(false);

  const [bool_images_added, set_bool_images_added] = useState(false);
  const [bool_go_to_existing_dataset, set_bool_go_to_existing_dataset] = useState(false);

  const [bool_first_render, set_bool_first_render] = useState(true);

  const [active_dataset_ID, set_active_dataset_ID] = useState(-1);




  // const [SAVED_DATASET_bool_multi_class, set_SAVED_DATASET_bool_multi_class] = useState(null);

  if (bool_first_render === true) {

      const { datasets, getListOfDatasets, user_ID } = props;

      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      //console.log(`DatasetsList user_ID=${user_ID}`)
      //console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!")
      getListOfDatasets(user_ID)

      set_bool_first_render(false);

      const { clearActiveDatasetID } = props;
      clearActiveDatasetID()

      ///////////////////////////////////////////////////////

     // Get SIMULATED_CAMERA_DATASET_ID

    axios
    .get(GET_SIMULATED_CAMERA_DATASET_ID_URL)
    .then((response) => {
      console.log('RECV SIMULATED_CAMERA_DATASET_ID');
      console.log(response.data);
      console.log('RECV SIMULATED_CAMERA_DATASET_ID');

      set_active_dataset_ID(parseInt(response.data.SIMULATED_CAMERA_DATASET_ID));

   })
   .catch((error) => {
       console.log("[ERROR] get bool_production_mode")
   });


  }


  const { datasets } = props;

  //console.log(`datasets=${JSON.stringify(datasets)}`)

    //const handleChange = (event, key) => {
    //  this.setState({
    //    [key]: event.target.value,
    //  });
    //}

  //console.log(`user_ID=${user_ID}`)
  //console.log(`dataset_ID=${dataset_ID}`)
  const tabs = TABS.map((item, index) => {
    const tabClass = cx({
      [s.tab]: true,
      [s[item.id]]: true,
      [s.activeTab]: activeTab === item.id,
    });

    return (
      <div key={item.id} className={tabClass} onClick={() => setTab(item.id)}>
        {item.label}
      </div>
    );
  });


        /*
        // this.state.initial_render===false is checked,
        // because otherwise when dataset_ID is cleared on mounting, it doesnt
        // take effect until the second render
        if (dataset_ID !==null && this.state.initial_render===false) {
          const new_dataset_overview_url = getDatasetURL(dataset_ID)
          console.log(`new_dataset_overview_url=${new_dataset_overview_url}`)
          return (
              <Redirect to={new_dataset_overview_url} />
          )
        }
        */



/*
const defaultProgramsList = [
  { slot: 0, name: 'x', bool_is_active: true, },
  { slot: 1, name: 'x', },
  { slot: 2, name: 'x', },
  { slot: 3, name: 'x', },
  { slot: 4, name: 'x', },
  { slot: 5, name: 'x', },
  { slot: 6, name: 'x', },
  { slot: 7, name: 'x', },
  { slot: 8, name: 'x', },
];
*/

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose}>
      <div className={s.component}>
        <nav className={s.tabs}>{tabs}</nav>
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}


          {activeTab === 'save_existing' &&



   <div className={s.componentX}>
      <table className={s.table}>
        <thead>

            <tr>
              <th className={s.dataset_nameX}>Application Name</th>
              <th className={s.dataset_buttonX}>Select images for simulated camera</th>
            </tr>

        </thead>
        <tbody>

         { datasets.map((dataset) => {

            const key = dataset.id;
            const isActive = (active_dataset_ID===dataset.id)

            //console.log(`dataset=${dataset}`);
            console.log(`dataset=${JSON.stringify(dataset)}`);


              if (isActive) {


              return (
                <tr className={s.active_row} key={key}>

                  <td className={s.dataset_nameXY}>
                    <span key="name" className={s.name}>
                      {dataset.name} &nbsp; &nbsp;
                    </span>
                  </td>

                  <td className={s.dataset_buttonXY}>

                   <div className={s.activateText}>

                      <Tag parentClass={s.tag} isActive>
                        <div className={s.tagText}>
                          SIMULATED CAMERA ACTIVE DATASET
                        </div>
                      </Tag>

                    </div>

                  </td>


                </tr>
              );


              }

              else  {

              return (
                <tr className={s.row} key={key}>
                  <td className={s.dataset_nameX}>
                    <span key="name" className={s.name}>
                      {dataset.name} &nbsp; &nbsp;
                    </span>
                  </td>

                  <td className={s.dataset_buttonX}>
                    <div className={s.activateText}>

                        <div className={s.button}>
                            <Button isFullWidth={true} onClick={()=>{
                                console.log(`DATASET_SELECTED=${dataset.id}`);

                                /////////////////////////////////////////////////////////

                                var set_simulated_camera_dataset_id_url = `${SET_SIMULATED_CAMERA_DATASET_ID_URL}/${dataset.id}`;

                                console.log(`set_simulated_camera_dataset_id_url=${set_simulated_camera_dataset_id_url}`)

                                /////////////////////////////////////////////////////////
                                axios.get(set_simulated_camera_dataset_id_url)
                                .then((response) => {

                                    /////////////////////////////////////////////////////////
                                    axios.get(GET_SIMULATED_CAMERA_DATASET_ID_URL)
                                    .then((response) => {
                                        //console.log("RECVD SIMULATED_CAMERA_DATASET_ID ");
                                        //console.log(response.data)
                                        //console.log("RECVD SIMULATED_CAMERA_DATASET_ID ");
                                        set_active_dataset_ID(parseInt(response.data.SIMULATED_CAMERA_DATASET_ID))
                                    })
                                    .catch((error) => {
                                        console.log(`[ERROR] ${error.message}`)
                                    });
                                    /////////////////////////////////////////////////////////
                                })
                                .catch((error) => {
                                    console.log(`[ERROR] ${error.message}`)
                                });
                                /////////////////////////////////////////////////////////

                                } }
                            >

                              Select images for simulated camera
                            </Button>
                       </div>

                     </div>

                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    </div>

          }




        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}
        {/********************************************/}

      </div>
    </Modal>
  );
};


const mapStateToProps = (state) => ({
  user_ID: selectors.getUserId(state),
  dataset_ID: selectors.getDatasetId(state),
  datasets: selectors.getDatasetsList(state),
});

export default connect(mapStateToProps, {
    createNewDatasetWithImages,
    clearActiveDatasetID,
    getListOfDatasets,
})(SelectImagesModal);


