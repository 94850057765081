export default {
  background: 'transparent',
  fontFamily: 'inherit',
  fontSize: 12,
  textColor: 'var(--text-light)',
  axis: {
    domain: {
      line: {
        stroke: 'transparent',
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: 'var(--border)',
        strokeWidth: 1,
      },
      text: {},
    },
    legend: {
      text: {
        fill: 'var(--text)',
        fontSize: 20,
      },
    },
  },
  grid: {
    line: {
      stroke: 'var(--border)',
      strokeWidth: 1,
    },
  },
  legends: {
    text: {
      fill: 'var(--text)',
    },
  },
  labels: {
    text: {},
  },
  markers: {
    lineColor: 'var(--text)',
    lineStrokeWidth: 1,
    text: {},
  },
  dots: {
    text: {},
  },
  tooltip: {
    container: {
      background: 'var(--border)',
      color: 'inherit',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '0.25em 0.5em',
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      marginRight: 8,
    },
    table: {},
    tableCell: {
      padding: '0.25em 0.5em',
    },
  },
  crosshair: {
    line: {
      stroke: 'var(--text)',
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: '8 8',
    },
  },
  annotations: {
    text: {
      fontSize: 20,
      outlineWidth: 2,
      outlineColor: 'var(--text-invert)',
    },
    link: {
      stroke: 'var(--text)',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: 'var(--text-invert)',
    },
    outline: {
      fill: 'none',
      stroke: 'var(--text)',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: 'var(--text-invert)',
    },
    symbol: {
      fill: 'var(--text)',
      outlineWidth: 2,
      outlineColor: 'var(--text-invert)',
    },
  },
};
