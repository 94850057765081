// B"H

// Viewer.js

import React, { Component } from 'react';
import { connect } from 'react-redux';

// TODO: img src now passed to Viewer - OK for components/images/Image.js -- also needed for NetworkReview.js (& BrosweHistory.js?)

//
//import { useRef, useState } from 'react';
import { COLORS, DETECTION_LABEL_COLORS, alphaColor } from './utils';

import { Stage, Layer, Rect, Image, Group, Line, Text } from 'react-konva';

import axios from 'axios';

import { selectors, postImageLabel } from 'redux/reducers/datasets';

import Keyboard from 'components/elements/Keyboard';
import Title from 'components/elements/Title';
//import AnnotationTool from 'components/annotation/AnnotationTool';
import ReviewTool from 'components/annotation/ReviewTool';
import Voting from 'components/label/Voting';
import Instructions from 'components/label/Instructions';
import Comparison from 'components/label/Comparison';
import PredictionAside from 'components/label/PredictionAside';

import {
  getUniqueKey,
  Vertex,
  PolygonData,
  PolygonIsClosed,
  SHORTCUTS,
  //IMAGE_RATIO,
  UndoRedo,
} from 'components/annotation/utils';

import s from './Viewer.module.sass';

import Button from 'components/elements/Button';
import Content from 'components/elements/Content';
import Toggles from 'components/form/Toggles';

import { return_bool_EACH_CLASS_has_label_present_within_required_region } from 'components/images/check_labels_present_within_region'
import { return_bool_ANY_CLASS_has_label_present_within_required_region } from 'components/images/check_labels_present_within_region'


import {
  LOAD_TRAINING_IMAGE_FROM_SERVER_URL,
  DELETE_TRAINING_IMAGE_URL,
  SAVE_CROP_TO_DATASET_URL,
  RETURN_DATASET_CROP_URL,

} from 'pages/urls';


const REQUEST_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
};


const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}

const print_in_green = (msg) => {
    console.log(`%c ${msg}`, 'background: green; color: white; display: block;');
}


//TODO: variable?
const IMAGE_RATIO = 1.33




const CROP_IMAGE_OPTIONS = [
  { value: false, label: 'LABEL IMAGE' },
  { value: true, label: 'CROP IMAGE' },
];










const convert_list_of_labels_to_image_coordinates = (list_of_labels, mainWidth, mainHeight) => {
    //console.log(`${JSON.stringify(list_of_labels)}`)

    return list_of_labels.map(value => {

        //console.log("________________________________");
        //console.log(`____focused_x=${this.state.focused_x}`);
        //console.log(`____value.x=${value.x}`);
        //console.log(`____focused_y=${this.state.focused_y}`);
        //console.log(`____value.y=${value.y}`);

        // prevent x, y exceeding boundary
        //x = Math.max(0, Math.min(x, mainWidth));
        //y = Math.max(0, Math.min(y, mainHeight));

        // convert coordinates into percentages
        const xp = Math.round((value.x / mainWidth) * 10000) / 10000;
        const yp = Math.round((value.y / mainHeight) * 10000) / 10000;

        const widthp = Math.round((value.width / mainWidth) * 10000) / 10000;
        const heightp = Math.round((value.height / mainHeight) * 10000) / 10000;

        if (value.detection_label_class) {
            return {  x: xp,
                      y: yp,
                      width: widthp,
                      height: heightp,
                      detection_label_class: value.detection_label_class  }
        } else {
            return {  x: xp,
                      y: yp,
                      width: widthp,
                      height: heightp,
                      detection_label_class: -1  }
        }

    });

}








// Component

class Viewer extends Component {
  notificationTimer;
  resizeTimer;

  constructor(props) {
    super(props);

    //console.log(`ZLOG props=${JSON.stringify(this.props)}`)
    const { image, max_label, bool_all_classes_named=false, list_of_class_objects=[] } = this.props;

    /*
    console.log("_____________________________________")
    console.log("_____________________________________")
    //console.log(`image=${JSON.stringify(image)}`);
    console.log(`max_label=${max_label}`);
    console.log(`image.labelClass=${image.labelClass}`);
    console.log("_____________________________________")
    console.log("_____________________________________")
    */

    //print_in_blue(`bool_all_classes_named=${bool_all_classes_named}`);
    //print_in_red(`list_of_class_objects=${list_of_class_objects}`);
    //print_in_red(`list_of_class_objects.length=${list_of_class_objects.length}`);

    this.state = {
        mainWidth: 0,
        mainHeight: 0,
        focusedName: '',
        toggleShowLabels: true,
        toggleShowPredictions: true,
        n_classes: max_label,
        label_selected: image.labelClass,

        is_label_selected: false,

        list_of_labels: [],
        bool_start: true,
        bool_img_loaded: false,
        //bool_img2_loaded: false,
        bool_annotation_in_progress: false,
        label_in_progress: null,

        last_move_x: -2,
        last_move_y: -2,

        focused_x: -1,
        focused_y: -1,

        bool_close_modal: false,

        list_of_predictions: [],

        bool_all_classes_named: bool_all_classes_named,
        list_of_class_objects: list_of_class_objects,

        multi_class_detection_label: -1,

        // multi class detection (pass / fail values)
        __pass_fail_check_toggle_value: "NOT_SET",
        __bool_roi_select_enabled: false,
        __N_classes: 0,
        __list_of_class_objects: [],


        // crop state
        bool_in_crop_mode: false,
        bool_crop_img_loaded: false,
        //don't reset list_of_crops between images
        list_of_crops: [],
        focused_crop_x: -1,
        focused_crop_y: -1,
        is_crop_selected: false,
        last_crop_move_x: -2,
        last_crop_move_y: -2,
        bool_crop_annotation_in_progress: false,
        crop_in_progress: null,

        // ADD any additional crop state properties to resetState

    };

    this.mainRef = React.createRef();
    this.imgRef = React.createRef();
  }


  // Lifecycle methods

  componentDidMount = () => {
      const { image, mainWidth, mainHeight, isReadOnly, bool_history_image=false, } = this.props;
      window.addEventListener('keydown', this.handleKeydown, false);
      window.addEventListener('resize', this.handleResize);
      this.setMainDimensions();

      if (bool_history_image===false) {
          this.restore_crops_from_backend();
          this.restore_labels_from_backend(image);
      }

      if (isReadOnly) {
          this.restore_predictions_from_backend(image);
      }

  };

  componentDidUpdate(prevProps, prevState) {
      const { image, isReadOnly } = this.props;


      //print_in_blue("componentDidUpdate");

      if (this.state.bool_close_modal) {
          //console.log("Viewer.js: boolCloseModal True");
          //console.log("______________")
          return;
      }

      if (image !== prevProps.image) {

          print_in_blue("componentDidUpdate--different_image");


          this.restore_labels_from_backend(image);

          if (isReadOnly) {
              this.restore_predictions_from_backend(image);
          }

          //this.setState({bool_img_loaded: false})
      }
  }

  componentWillUnmount = () => {

      ////////////////////////////////////////////////////////////////////////////////////

      print_in_red(`componentWillUnmount`);

      const { isReadOnly, image, userId, datasetId, boolSegmentationEnabled, list_of_labels, bool_pass_detection=false, bool_multi_class_detection=false } = this.props;

      if (image===null) {
          return;
      }

      // POST IMAGE LABEL
      if (!isReadOnly && boolSegmentationEnabled && (
           (bool_pass_detection===false && image.labelClass === "FAIL") ||
           (bool_pass_detection===true && image.labelClass === "PASS" ) ||
           (bool_multi_class_detection===true)
         )) {

          //console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
          let image_space_list_of_labels = convert_list_of_labels_to_image_coordinates(this.state.list_of_labels, this.state.mainWidth, this.state.mainHeight)
          //console.log(`image_space_list_of_labels=${JSON.stringify(image_space_list_of_labels)}`);
          this.viewer_postImageLabel(image.id, image.labelClass, image_space_list_of_labels, userId, datasetId);
      }

      ////////////////////////////////////////////////////////////////////////////////////

      // POST DATASET CROP

      let image_space_list_of_crops = convert_list_of_labels_to_image_coordinates(this.state.list_of_crops, this.state.mainWidth, this.state.mainHeight)
      //console.log(`image_space_list_of_crops=${JSON.stringify(image_space_list_of_crops)}`);
      this.postDatasetCrop(userId, datasetId, image_space_list_of_crops);

      ////////////////////////////////////////////////////////////////////////////////////

      window.removeEventListener('keydown', this.handleKeydown, false);
      window.removeEventListener('resize', this.handleResize);
  };

  return_multi_class_detection_image_label_class(image_label_class, image_space_list_of_labels) {

      // ______________________________ //
      if (this.state.__pass_fail_check_toggle_value==="MEASUREMENT_CHECK") {
          return image_label_class;
      }
      // ______________________________ //
      if (this.state.__pass_fail_check_toggle_value=="FAIL_CHECK") {

          // classes found
          if (image_space_list_of_labels && image_space_list_of_labels.length > 0) {

              // ROI SELECT OFF
              if (this.state.__bool_roi_select_enabled===false) {
                  return "FAIL"
              }
              // ROI SELECT ON
              else {

                  let image_space_list_of_crops = convert_list_of_labels_to_image_coordinates(this.state.list_of_crops, this.state.mainWidth, this.state.mainHeight)
                  let bool_any_class_has_label_present_within_required_region = return_bool_ANY_CLASS_has_label_present_within_required_region(
                      this.state.__list_of_class_objects,
                      image_space_list_of_labels,
                      image_space_list_of_crops);

                   if (bool_any_class_has_label_present_within_required_region===true) {
                       return "FAIL";
                   } else {
                       return "PASS";
                   }
              }
          }
          // no classes found
          else {
              return "PASS"
          }
      }
      // ______________________________ //
      if (this.state.__pass_fail_check_toggle_value=="PASS_CHECK") {

          // ________________ //
          if (image_space_list_of_labels && image_space_list_of_labels.length > 0) {

              const class_labels_in_image = [...new Set(image_space_list_of_labels.map(item => item.detection_label_class))]
              const expected_class_labels_in_image = Array.from({length: this.state.__list_of_class_objects.length}, (_, i) => i + 1)

              print_in_blue(`class_labels_in_image${JSON.stringify(class_labels_in_image)}`);
              print_in_blue(`expected_class_labels_in_image${JSON.stringify(expected_class_labels_in_image)}`);

              const bool_all_classes_present_in_image = (class_labels_in_image.sort().join(',') === expected_class_labels_in_image.sort().join(','));

              // _____________________________________________________ //
              // _____________________________________________________ //
              // ROI SELECT OFF
              if (this.state.__bool_roi_select_enabled===false) {
                  if (bool_all_classes_present_in_image) {
                      return "PASS";
                  } else {
                      return "FAIL";
                  }
              }
              // _____________________________________________________ //
              // _____________________________________________________ //
              // ROI SELECT ON

              //print_in_orange(`(1) list_of_crops=${JSON.stringify(this.state.list_of_crops)}`);
              //print_in_green(`(1) image.labelPolygons=${JSON.stringify(image.labelPolygons)}`);

              if (this.state.__bool_roi_select_enabled===true) {

                  let image_space_list_of_crops = convert_list_of_labels_to_image_coordinates(this.state.list_of_crops, this.state.mainWidth, this.state.mainHeight)

                  //print_in_orange(`(viewer 2)image_space_list_of_crops=${JSON.stringify(image_space_list_of_crops)}`);
                  //print_in_green(`(viewer 2)image_space_list_of_labels=${JSON.stringify(image_space_list_of_labels)}`);
                  //print_in_blue(`(viewer 2)this.state.__list_of_class_objects=${JSON.stringify(this.state.__list_of_class_objects)}`);

                  let bool_each_class_has_label_present_within_required_region = return_bool_EACH_CLASS_has_label_present_within_required_region(this.state.__list_of_class_objects, image_space_list_of_labels, image_space_list_of_crops);

                  if (bool_each_class_has_label_present_within_required_region===true) {
                      return "PASS"
                  } else {
                      return "FAIL";
                  }
              }

              // _____________________________________________________ //


          // ________________ //
          } else {
              return "FAIL";
          }
          // ________________ //
      }
      // ______________________________ //
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      print_in_red(`[VIEWER] SET MULTI CLASS DETECTION LABEL: SHOULDNT GET HERE!!!`);
      // ______________________________ //
      return "NONE";

  }


  viewer_postImageLabel(image_id, image_label_class, image_space_list_of_labels, userId, datasetId) {

    const { postImageLabel, bool_multi_class_detection=false } = this.props;

    if (bool_multi_class_detection) {

        let new_label_class = this.return_multi_class_detection_image_label_class(image_label_class, image_space_list_of_labels);
        print_in_blue(`[viewer: multi class detection] return_multi_class_detection_image_label_class=${new_label_class}`);
        postImageLabel(image_id, new_label_class, image_space_list_of_labels, userId, datasetId);

    } else {
        postImageLabel(image_id, image_label_class, image_space_list_of_labels, userId, datasetId);
   }
  }

  // _________________________________________________________________________________________ //
  // _________________________________________________________________________________________ //

  postDatasetCrop = (
      userId,
      datasetId,
      list_of_crops,
  ) => {

    const url = `${SAVE_CROP_TO_DATASET_URL}/${userId}/${datasetId}`;

    //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")
    //console.log(`datasets.js: list_of_crops=${JSON.stringify(list_of_crops)}`);
    //console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%")

    return axios
      .post(
        url,
        {
          list_of_crop_polygons: list_of_crops,
        },
        REQUEST_CONFIG
      )
      .then((response) => {
        console.log("SAVED CROP POLYGONS");
      })
      .catch((error) => {
        console.log(`[ERROR] postDatasetCrop error=${error}`);
      });
  };

  // _________________________________________________________________________________________ //
  // _________________________________________________________________________________________ //


  restore_crops_from_backend = () => {

      const { userId, datasetId, bool_multi_class_detection=false } = this.props;

      const mainWidth = this.mainRef.current.clientWidth;
      const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

      print_in_red("restore_crop4");

      axios
        .get(`${RETURN_DATASET_CROP_URL}/${userId}/${datasetId}`)
        .then((response) => {
          //console.log('_________________');
          //console.log(JSON.stringify(response.data));
          //console.log('_________________');

          print_in_red("restore_crop2");

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////

          if (response.data && bool_multi_class_detection===true && response.data.named_classes != undefined) {

              console.log('_________________');
              print_in_red(`response.data=${JSON.stringify(response.data)}`);
              console.log('_________________');


              var pass_fail_check_toggle_value = "NOT_SET"

              if (response.data.bool_pass_check===true) {
                  pass_fail_check_toggle_value = "PASS_CHECK";
              } else if (response.data.bool_fail_check===true) {
                  pass_fail_check_toggle_value = "FAIL_CHECK";
              }

              var bool_roi_select_enabled = false;
              if (response.data.bool_roi_select_enabled===true) {
                  bool_roi_select_enabled = true;
              }

              this.setState({
                  __list_of_class_objects: JSON.parse(response.data.named_classes),
                  __N_classes: JSON.parse(response.data.named_classes).length,
                  __pass_fail_check_toggle_value: pass_fail_check_toggle_value,
                  __bool_roi_select_enabled: bool_roi_select_enabled,
              });

          }


          if (response.data && response.data.list_of_crop_polygons) {

              print_in_red("restore_crop1");

              let list_of_crops_from_backend = (response.data.list_of_crop_polygons).map(value => {

                  //const xp = Math.round((value.x / mainWidth) * 10000) / 10000;
                  //const yp = Math.round((value.y / mainHeight) * 10000) / 10000;
                  //const widthp = Math.round((value.width / mainWidth) * 10000) / 10000;
                  //const heightp = Math.round((value.height / mainHeight) * 10000) / 10000;
                  const x_pixels = value.x * mainWidth;
                  const y_pixels = value.y * mainHeight;
                  const width_pixels = value.width * mainWidth;
                  const height_pixels = value.height * mainHeight;

                  // double check no crop smaller than 10x10
                  if (Math.abs(width_pixels) > 10 && Math.abs(height_pixels) > 10) {

                      return {  x: x_pixels,
                                y: y_pixels,
                                width: width_pixels,
                                height: height_pixels,
                             }
                  } else {

                      return {  x: x_pixels,
                                y: y_pixels,
                                width: 10,
                                height: 10,
                             }


                  }
              });

              //console.log(`list_of_crops_from_backend=${JSON.stringify(list_of_crops_from_backend)}`);

              this.setState({
                  list_of_crops: list_of_crops_from_backend,
              });
          }

          /////////////////////////////////////////////////////////
          /////////////////////////////////////////////////////////

        })
        .catch((error) => {
          console.log("[ERROR] get list_of_crops from backend")
        });


  };



  // _________________________________________________________________________________________ //
  // _________________________________________________________________________________________ //


  restore_labels_from_backend = (image) => {

      const mainWidth = this.mainRef.current.clientWidth;
      const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

      //console.log("RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");
      //console.log(`image.labelPolygons=${JSON.stringify(image.labelPolygons)}`);
      //console.log(`mainWidth=${mainWidth}`);
      //console.log(`mainHeight=${mainHeight}`);

      //console.log(`image.labelPolygons=${image.labelPolygons}`);

      if (image && image.labelPolygons) {

          let list_of_labels_from_backend = (image.labelPolygons).map(value => {

              //const xp = Math.round((value.x / mainWidth) * 10000) / 10000;
              //const yp = Math.round((value.y / mainHeight) * 10000) / 10000;
              //const widthp = Math.round((value.width / mainWidth) * 10000) / 10000;
              //const heightp = Math.round((value.height / mainHeight) * 10000) / 10000;
              const x_pixels = value.x * mainWidth;
              const y_pixels = value.y * mainHeight;
              const width_pixels = value.width * mainWidth;
              const height_pixels = value.height * mainHeight;



              print_in_red(`restore_labels: value.detection_label_class=${value.detection_label_class}`);
              if (value.detection_label_class) {
                  return {  x: x_pixels,
                            y: y_pixels,
                            width: width_pixels,
                            height: height_pixels,
                            detection_label_class: value.detection_label_class,
                         }

              }
              else {
                  return {  x: x_pixels,
                            y: y_pixels,
                            width: width_pixels,
                            height: height_pixels,
                            detection_label_class: -1,
                         }

             }
          });

          //console.log(`list_of_labels_from_backend=${JSON.stringify(list_of_labels_from_backend)}`);

          this.setState({
              list_of_labels: list_of_labels_from_backend,
          });
      }
  };


  restore_predictions_from_backend = (image) => {

      const mainWidth = this.mainRef.current.clientWidth;
      const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

      print_in_blue("LOAD IMAGE PREDICTIONS:");
      //print_in_blue(`image.predictionPolygons=${JSON.stringify(image.predictionPolygons)}`);
      //print_in_blue(`image=${JSON.stringify(image)}`);

      //console.log(`mainWidth=${mainWidth}`);
      //console.log(`mainHeight=${mainHeight}`);

      //console.log(`image.labelPolygons=${image.labelPolygons}`);

      if (image && image.predictionPolygons) {

          let list_of_predictions_from_backend = (image.predictionPolygons).map(value => {

              //const xp = Math.round((value.x / mainWidth) * 10000) / 10000;
              //const yp = Math.round((value.y / mainHeight) * 10000) / 10000;
              //const widthp = Math.round((value.width / mainWidth) * 10000) / 10000;
              //const heightp = Math.round((value.height / mainHeight) * 10000) / 10000;
              const x_pixels = value.x * mainWidth;
              const y_pixels = value.y * mainHeight;
              const width_pixels = value.width * mainWidth;
              const height_pixels = value.height * mainHeight;

              return {  x: x_pixels,
                        y: y_pixels,
                        width: width_pixels,
                        height: height_pixels,
                        detection_predicted_class: value.detection_predicted_class  }

          });

          //console.log(`list_of_predictions_from_backend=${JSON.stringify(list_of_predictions_from_backend)}`);

          this.setState({
              list_of_predictions: list_of_predictions_from_backend,
          });
      }
  };



  ////////////////////////////////////////////////////////////////////////////


  resetState = () => {
    this.setState({
      is_label_selected: false,
      focusedName: '',
      label_selected: -2,
      list_of_labels: [],
      list_of_predictions: [],

      // ________
      bool_start: true,
      bool_img_loaded: false,
      bool_annotation_in_progress: false,
      label_in_progress: null,

      last_move_x: -2,
      last_move_y: -2,

      focused_x: -1,
      focused_y: -1,
      // ________


      // ________
      bool_in_crop_mode: false,
      bool_crop_img_loaded: false,
      //don't reset list_of_crops between images
      //list_of_crops: [],
      focused_crop_x: -1,
      focused_crop_y: -1,
      is_crop_selected: false,
      last_crop_move_x: -2,
      last_crop_move_y: -2,
      bool_crop_annotation_in_progress: false,
      crop_in_progress: null,
      // ________

    });

  };

  setMainDimensions = () => {
    if (!this.mainRef || !this.mainRef.current) {
      return;
    }

    const mainWidth = this.mainRef.current.clientWidth;
    const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

    this.setState({
        mainWidth,
        mainHeight,
    });
  };


  handleDetectionVisibility = (detection_type, value = null) => {
    if (detection_type === 'label') {
      const newValue = value || !this.state.toggleShowLabels;
      this.setState({
        toggleShowLabels: newValue,
      });
    } else if (detection_type === 'prediction') {
      const newValue = value || !this.state.toggleShowPredictions;
      this.setState({
        toggleShowPredictions: newValue,
      });
    }
  };


  // Window events

  handleResize = () => {
    this.setMainDimensions();
  };

  handleKeydown = (e) => {
    const { isReadOnly, boolSegmentationEnabled, bool_multi_class=false, } = this.props;

    switch (e.keyCode) {
      case SHORTCUTS.BUTTON.PREVIOUS.code:
        this.handlePreviousImage();
        break;
      case SHORTCUTS.BUTTON.NEXT.code:
        this.handleNextImage();
        break;
      default:
    }

    if (this.state.bool_in_crop_mode) {
      switch (e.keyCode) {
        case SHORTCUTS.HISTORY.DELETE_LABEL.code:
          this.handleCropRectangleDelete();
          break;
        case SHORTCUTS.HISTORY.BACKSPACE_LABEL.code:
          this.handleCropRectangleDelete();
          break;

        default:
      }
      return;
    }

    //print_in_blue(`bool_multi_class=${bool_multi_class}`);

    // classification
    if (boolSegmentationEnabled===false && bool_multi_class===true) {
      return;
    }

    if (isReadOnly) {
      switch (e.keyCode) {
        case SHORTCUTS.REVIEW.TOGGLE_LABEL.code:
          this.handleDetectionVisibility('label');
          break;
        case SHORTCUTS.REVIEW.TOGGLE_PREDICTION.code:
          this.handleDetectionVisibility('prediction');
          break;
        default:
      }
      return;
    }

    // pass/fail classification
    if (boolSegmentationEnabled===false && bool_multi_class===false) {
      switch (e.keyCode) {
        case SHORTCUTS.BUTTON.PASS.code:
          this.handlePassClick();
          break;
        case SHORTCUTS.BUTTON.FAIL.code:
          this.handleFailClick();
          break;
        case SHORTCUTS.HISTORY.DELETE_LABEL.code:
          this.handleRectangleDelete();
          break;
        case SHORTCUTS.HISTORY.BACKSPACE_LABEL.code:
          this.handleRectangleDelete();
          break;

        default:
      }
      return;
    }


    switch (e.keyCode) {
      case SHORTCUTS.HISTORY.BACKSPACE_LABEL.code:
        this.handleRectangleDelete();
        break;
      case SHORTCUTS.HISTORY.DELETE_LABEL.code:
        this.handleRectangleDelete();
        break;
      case SHORTCUTS.BUTTON.PASS.code:
        this.handlePassClick();
        break;
      case SHORTCUTS.BUTTON.FAIL.code:
        this.handleFailClick();
        break;
      default:
    }
  };






  // Form events

  // POST IMAGE LABEL
  handleVoting = (label) => {
    const { isReadOnly, image, userId, datasetId, bool_pass_detection=false, bool_multi_class_detection=false } = this.props;

    if (isReadOnly) {
      return;
    }


    print_in_blue(`[HANDLE_VOTING] bool_pass_detection=${bool_pass_detection}`);
    if (bool_multi_class_detection) {
       print_in_blue(`[HANDLE_VOTING] bool_multi_class`);
       if (this.state.list_of_labels && this.state.list_of_labels.length !== 0) {

           let image_space_list_of_labels = convert_list_of_labels_to_image_coordinates(this.state.list_of_labels, this.state.mainWidth, this.state.mainHeight)
           this.viewer_postImageLabel(image.id, "NONE", image_space_list_of_labels, userId, datasetId);

       } else {

           this.viewer_postImageLabel(image.id, "NONE", [], userId, datasetId);
       }


    }
    else if (((bool_pass_detection===false && label === "FAIL") || (bool_pass_detection===true && label === "PASS" )) && this.state.list_of_labels && this.state.list_of_labels.length !== 0) {

        print_in_blue(`[HANDLE_VOTING] detections`);

        let image_space_list_of_labels = convert_list_of_labels_to_image_coordinates(this.state.list_of_labels, this.state.mainWidth, this.state.mainHeight)
        this.viewer_postImageLabel(image.id, label, image_space_list_of_labels, userId, datasetId);

    } else {
       print_in_blue(`[HANDLE_VOTING] no detections`);
       this.viewer_postImageLabel(image.id, label, [], userId, datasetId);
    }

  };

  handlePassClick = (prevState) => {
    //this.resetState();
    this.handleVoting('PASS');

    this.setState({
      list_of_labels: [],
    });

  };

  handleFailClick = () => {
    //this.resetState();
    this.handleVoting('FAIL');
  };


  handleLabelClick = (value) => {

    const { bool_multi_class_detection=false } = this.props;

    if (bool_multi_class_detection) {
        console.log("MULTI_CLASS_DETECTION label click");
        this.setState({
                          multi_class_detection_label: value,
                      });

    } else {
        //this.resetState();
        this.handleVoting(value);
    }
  };






  // Navigation

  // POST IMAGE LABEL
  handlePreviousImage = () => {
    const { onPreviousImageCallback } = this.props;

    ////////////////////////////////////////////////////////////////////////////////////

    const { isReadOnly, image, userId, datasetId, boolSegmentationEnabled, list_of_labels, bool_pass_detection=false, bool_multi_class_detection=false } = this.props;

    if (!isReadOnly && boolSegmentationEnabled && (
         (bool_pass_detection===false && image.labelClass === "FAIL") ||
         (bool_pass_detection===true && image.labelClass === "PASS" ) ||
         (bool_multi_class_detection===true)
       )) {

        //console.log("PPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPPP");
        let image_space_list_of_labels = convert_list_of_labels_to_image_coordinates(this.state.list_of_labels, this.state.mainWidth, this.state.mainHeight)
        //console.log(`image_space_list_of_labels=${JSON.stringify(image_space_list_of_labels)}`);
        this.viewer_postImageLabel(image.id, image.labelClass, image_space_list_of_labels, userId, datasetId);
    }

    ////////////////////////////////////////////////////////////////////////////////////

    var { imageIndex } = this.props;
    // prevent error if next_index < 0
    if ((imageIndex-1) >= 0) {
        this.resetState();
    }
    onPreviousImageCallback();
  };


  // POST IMAGE LABEL
  handleNextImage = () => {
    const { onNextImageCallback } = this.props;

    ////////////////////////////////////////////////////////////////////////////////////

    const { isReadOnly, image, userId, datasetId, boolSegmentationEnabled, list_of_labels, bool_pass_detection=false, bool_multi_class_detection=false } = this.props;

    if (!isReadOnly && boolSegmentationEnabled && (
         (bool_pass_detection===false && image.labelClass === "FAIL") ||
         (bool_pass_detection===true && image.labelClass === "PASS" ) ||
         (bool_multi_class_detection===true)
       )) {

        //console.log("NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN");
        let image_space_list_of_labels = convert_list_of_labels_to_image_coordinates(this.state.list_of_labels, this.state.mainWidth, this.state.mainHeight)
        //console.log(`image_space_list_of_labels=${JSON.stringify(image_space_list_of_labels)}`);
        this.viewer_postImageLabel(image.id, image.labelClass, image_space_list_of_labels, userId, datasetId);
    }

    ////////////////////////////////////////////////////////////////////////////////////

    var { imageIndex, imageCount } = this.props;
    // prevent error if next_index > last_index
    if ((imageIndex+1) < imageCount) {
        this.resetState();
    }
    onNextImageCallback();
  };

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
/*
const shape = new Konva.Shape({
  width: stage.width(),
  height: stage.height(),
  // what is color of background ?
  fill: 'rgba(0,0,0,0.2)',
  sceneFunc: (ctx, shape) => {
    // draw background
    ctx.beginPath();
    ctx.moveTo(0, 0);
    ctx.lineTo(shape.width(), 0);
    ctx.lineTo(shape.width(), shape.height());
    ctx.lineTo(0, shape.height());
    ctx.lineTo(0, 0);

    // now cut circle from the background
    // we can do this by useing arc
    const x = 200;
    const y = 200;
    const radius = 10;
    // but it must be counter-clockwise
    // so the last argument true is very important here
    ctx.arc(200, 200, 100, 0, Math.PI * 2, true);
    ctx.fillStrokeShape(shape);
  },

  // remove shape from hit graph, so it is not visible for events
  listening: false
});
*/
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

  handleCropMouseDown = (event) => {

    //console.log("[HANDLE_CROP_MOUSE_DOWN]");

    const { x, y } = event.target.getStage().getPointerPosition();

    //console.log(`    is_crop_selected=${this.state.is_crop_selected}`);
    //console.log(`    crop_annotation_in_progress=${this.state.bool_crop_annotation_in_progress}`);


    // have to check to make sure polygon selected isnt just from clicking the corner of the current rectangle to close it
    if (this.state.is_crop_selected && (x!==this.state.last_crop_move_x && y!==this.state.last_crop_move_y )) {
        //console.log(`mouse_down_x=${x}`);
        //console.log(`last_move_x=${this.state.last_crop_move_x}`);
        //console.log(`mouse_down_y=${y}`);
        //console.log(`last_move_y=${this.state.last_crop_move_y}`);

        //console.log("[HANDLE_CROP_MOUSE_DOWN] polygon selected - return");

        // set is_crop_selected to false - next outside click will unfocus polygon
        this.setState({
          is_crop_selected: false,
        });

        return;
     }

     else if (x===this.state.last_crop_move_x && y===this.state.last_crop_move_y) {
        this.setState({
          focused_crop_x: -1,
          focused_crop_y: -1,
        });

     }



    if (this.state.bool_crop_annotation_in_progress === false) {

      const { x, y } = event.target.getStage().getPointerPosition();

      this.setState(prevState => ({
                                     bool_crop_annotation_in_progress: true,
                                     crop_in_progress: { x: x,
                                                         y: y,
                                                         width: 0,
                                                         height: 0,
                                                        }
                                 })

      );
      return;
    }

    // complete crop, as long as its at least 10 pixels x 10 pixels
    else if (this.state.bool_crop_annotation_in_progress === true) {

        const new_x = (this.state.crop_in_progress.width > 0)  ? (this.state.crop_in_progress.x) : (this.state.crop_in_progress.x + this.state.crop_in_progress.width);
        const new_y = (this.state.crop_in_progress.height > 0) ? (this.state.crop_in_progress.y) : (this.state.crop_in_progress.y + this.state.crop_in_progress.height);
        const new_width  = Math.abs(this.state.crop_in_progress.width)
        const new_height = Math.abs(this.state.crop_in_progress.height)

        const crop_in_progress_upright = { x: new_x,
                                           y: new_y,
                                           width:  new_width,
                                           height: new_height,
                                         }

        console.log(`crop_in_progress_upright=${JSON.stringify(crop_in_progress_upright)}`);

        //if (Math.abs(this.state.crop_in_progress.width) > 10 && Math.abs(this.state.crop_in_progress.height) > 10) {
        if (new_width > 10 && new_height > 10) {
            this.setState(prevState => ({
                                          bool_crop_annotation_in_progress: false,
                                          list_of_crops: [...prevState.list_of_crops, crop_in_progress_upright],
                                          crop_in_progress: null,
                                          is_crop_selected: false,

                                        })
                         );
        }

     }

  };




  handleCropMouseUp = (event) => {

  };




  handleCropMouseMove = (event) => {
    //console.log("cropMouseMove");


    if (this.state.bool_crop_annotation_in_progress === true) {

      const sx = this.state.crop_in_progress.x;
      const sy = this.state.crop_in_progress.y;
      const { x, y } = event.target.getStage().getPointerPosition();

      this.setState(prevState => ({
                                     crop_in_progress: { x: sx,
                                                         y: sy,
                                                         width: x-sx,
                                                         height: y-sy,
                                                       },
                                     last_move_x: x,
                                     last_move_y: y,
                                 })
                   );

      /*
      const current_crop_index = this.state.list_of_crops.length-1;
      const sx = this.state.list_of_crops[current_crop_index].x;
      const sy = this.state.list_of_crops[current_crop_index].y;
      const { x, y } = event.target.getStage().getPointerPosition();

      //console.log("[HANDLE_CROP_MOUSE_MOVE] UPDATE RECTANGLE");

      /// [...x] creates a deepcopy of x
      let newData = [...this.state.list_of_crops]
      newData[current_crop_index]= {
                                       x: sx,
                                       y: sy,
                                       width: x - sx,
                                       height: y - sy,
                                   }

      this.setState(prevState => ({
                                     list_of_crops: newData,
                                     last_crop_move_x: x,
                                     last_crop_move_y: y,
                                 })
      );
      return;
      */

     }


  }


  handleCropRectangleClick = (e) => {

    // this is important!
    //
    // in <Group> <Rect/> </Group
    //    Group.onMouseDown (e) => e.target is <Rect/>
    //    Group.onMouseDown (e) => e.eventTarget is <Group/>
    // this matters as Group has the x and y values
    // of the <Rect/> that references crop listed in list_of_crops
    const activeShape = e.currentTarget;

    //console.log("CROP_RECTANGLE_CLICK")
    //console.log(`e.parentNode=${e.parentNode}`)
    //console.log(`e.parentNode=${JSON.stringify(e.parentNode)}`)

    //console.log(`'RECTANGLE click activeShape=${JSON.stringify(activeShape)}`);
    //console.log(`    RECTANGLE click activeShape['attrs']=${JSON.stringify(activeShape['attrs'])}`);
    //console.log(`'RECTANGLE click activeShape['attrs']['x']=${JSON.stringify(activeShape['attrs']['x'])}`);
    //console.log(`'RECTANGLE click activeShape.name=${activeShape.name}`);
    //console.log(`'RECTANGLE click e=${e}`);

    if (this.state.is_crop_selected === false) {
        this.setState({
            is_crop_selected: true,
            //focusedName: activeShape.name(),
            focused_crop_x: activeShape['attrs']['x'],
            focused_crop_y: activeShape['attrs']['y'],
         });

    }

  };




  handleCropRectangleDelete = () => {

      /// [...x] creates a deepcopy of x
      let newData = [...this.state.list_of_crops]

      const x_to_delete = this.state.focused_crop_x;
      const y_to_delete = this.state.focused_crop_y;

      console.log(`x_to_delete=${x_to_delete}`);
      console.log(`y_to_delete=${y_to_delete}`);

      newData = newData.filter(function( obj ) {
          if (obj.x === x_to_delete && obj.y === y_to_delete) {
              return false;
          }
          return true;
      });


      this.setState(prevState => ({
                                     list_of_crops: newData,
                                     focused_crop_x: -1,
                                     focused_crop_y: -1,
                                 })
      );
      return;
  }




////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////


  return_bool_non_detection_image = () => {
    const { image, isReadOnly, boolSegmentationEnabled, bool_detection=false, bool_pass_detection=false, bool_multi_class_detection=false, bool_multi_class=false, } = this.props;

    const {labelClass } = image;

    // non_segmentation or read_only or non_detection
    if (boolSegmentationEnabled===false || isReadOnly || bool_detection===false) {
        return true;
    }

    // pass/fail detection + no label
    if (bool_detection && bool_multi_class===false && labelClass==='NONE') {
        console.log("[NON_DETECTION_IMAGE] PASS/FAIL CLASSIFICATION");
        return true;
    }
    // pass detection + fail label
    if (bool_detection && bool_pass_detection === true && labelClass==='FAIL') {
        return true;
    }
    // fail detection + pass label
    if (bool_detection && bool_pass_detection === false && bool_multi_class_detection===false && labelClass==='PASS') {
        return true;
    }
    // multi_class_detection + no_label
    if (bool_detection && bool_multi_class_detection===true && this.state.multi_class_detection_label===-1) {
        return true;
    }

    return false;

  }



  handleMouseDown = (event, showPredictions) => {

    console.log("mouseDown");

    const {isReadOnly} = this.props;

    // non_segmentation or read_only or non_detection
    if (isReadOnly) {
        //console.log("    readOnly");
        return;
    }

    if (this.state.bool_in_crop_mode) {
        //console.log("    crop");
        return this.handleCropMouseDown(event);
    }

    // disable labelling for non detection image
    const bool_non_detection_image = this.return_bool_non_detection_image();
    if (bool_non_detection_image) {
        console.log(`[HANDLE_MOUSE_DOWN] bool_non_detection_image=${bool_non_detection_image}`)
        return;
    }

    // disable labeling for reviewing prediction results in NetworkReview
    if (showPredictions===true) {
        return;
    }

    const { x, y } = event.target.getStage().getPointerPosition();


    //console.log(`[HANDLE_MOUSE_DOWN] is_label_selected=${this.state.is_label_selected}}`)
    //console.log(`[HANDLE_MOUSE_DOWN] bool_annotation_in_progress=${this.state.bool_annotation_in_progress}}`)


    // have to check to make sure polygon selected isnt just from clicking the corner of the current rectangle to close it
    if (this.state.is_label_selected && (x!==this.state.last_move_x && y!==this.state.last_move_y )) {
        //console.log(`mouse_down_x=${x}`);
        //console.log(`last_move_x=${this.state.last_move_x}`);
        //console.log(`mouse_down_y=${y}`);
        //console.log(`last_move_y=${this.state.last_move_y}`);

        //console.log("[HANDLE_MOUSE_DOWN] polygon selected - return");

        // set is_label_selected to false - next outside click will unfocus polygon
        this.setState({
          is_label_selected: false,
        });

        return;
     }
     else if (x===this.state.last_move_x && y===this.state.last_move_y) {
        this.setState({
          focused_x: -1,
          focused_y: -1,
        });

     }



    if (this.state.bool_annotation_in_progress === false) {

      const { x, y } = event.target.getStage().getPointerPosition();

      this.setState(prevState => ({
                                     bool_annotation_in_progress: true,
                                     label_in_progress: { x: x,
                                                          y: y,
                                                          width: 0,
                                                          height: 0,
                                                          detection_label_class: this.state.multi_class_detection_label,
                                                        }
                                 })

      );
      return;
    }

    // complete label, as long as its at least 10 pixels x 10 pixels
    // (this is important! avoids accidental 0x0 labels on accidental fast double click)
    else if (this.state.bool_annotation_in_progress === true) {

        //////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////

        const new_x = (this.state.label_in_progress.width > 0)  ? (this.state.label_in_progress.x) : (this.state.label_in_progress.x + this.state.label_in_progress.width);
        const new_y = (this.state.label_in_progress.height > 0) ? (this.state.label_in_progress.y) : (this.state.label_in_progress.y + this.state.label_in_progress.height);
        const new_width  = Math.abs(this.state.label_in_progress.width)
        const new_height = Math.abs(this.state.label_in_progress.height)

        const label_in_progress_upright = { x: new_x,
                                            y: new_y,
                                            width:  new_width,
                                            height: new_height,
                                            detection_label_class: this.state.label_in_progress.detection_label_class,

                                         }

        console.log(`label_in_progress_upright=${JSON.stringify(label_in_progress_upright)}`);

        if (new_width > 10 && new_height > 10) {
            this.setState(prevState => ({
                                          bool_annotation_in_progress: false,
                                          list_of_labels: [...prevState.list_of_labels, label_in_progress_upright],
                                          label_in_progress: null,
                                          is_label_selected: false,

                                        })
                         );
        }

        //////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////////////////////////

     }


  };






  handleMouseUp = (event, showPredictions) => {

      const {isReadOnly} = this.props;

      if (isReadOnly) {
          return;
      }

      if (this.state.bool_in_crop_mode) {
          return this.handleCropMouseUp(event);
      }

      // disable labeling for reviewing prediction results in NetworkReview
      if (showPredictions===true) {
          return;
      }
  };





  handleMouseMove = (event, showPredictions) => {
    //console.log("mouseMove");

    const {isReadOnly} = this.props;

    if (isReadOnly) {
        return;
    }

    if (this.state.bool_in_crop_mode) {
        return this.handleCropMouseMove(event);
    }

    // disable labelling for non detection image
    const bool_non_detection_image = this.return_bool_non_detection_image();
    if (bool_non_detection_image) {
        return;
    }

    // disable labeling for reviewing prediction results in NetworkReview
    if (showPredictions===true) {
        return;
    }


    if (this.state.bool_annotation_in_progress === true) {

      const sx = this.state.label_in_progress.x;
      const sy = this.state.label_in_progress.y;
      const { x, y } = event.target.getStage().getPointerPosition();

      this.setState(prevState => ({
                                     label_in_progress: { x: sx,
                                                          y: sy,
                                                          width: x-sx,
                                                          height: y-sy,
                                                          detection_label_class: this.state.multi_class_detection_label,
                                                        },
                                     last_move_x: x,
                                     last_move_y: y,
                                 })
                   );



     }


  }


  handleRectangleClick = (e) => {


    // this is important!
    //
    // in <Group> <Rect/> </Group
    //    Group.onMouseDown (e) => e.target is <Rect/>
    //    Group.onMouseDown (e) => e.eventTarget is <Group/>
    // this matters as Group has the x and y values
    // of the <Rect/> that references crop listed in list_of_crops
    const activeShape = e.currentTarget;

    //console.log(`'RECTANGLE click activeShape=${JSON.stringify(activeShape)}`);
    //console.log(`'RECTANGLE click activeShape['attrs']=${JSON.stringify(activeShape['attrs'])}`);
    //console.log(`'RECTANGLE click activeShape['attrs']['x']=${JSON.stringify(activeShape['attrs']['x'])}`);
    //console.log(`'RECTANGLE click activeShape.name=${activeShape.name}`);
    //console.log(`'RECTANGLE click e=${e}`);

    if (this.state.is_label_selected === false) {
        this.setState({
            is_label_selected: true,
            //focusedName: activeShape.name(),
            focused_x: activeShape['attrs']['x'],
            focused_y: activeShape['attrs']['y'],
         });

    }

  };





  handleRectangleDelete = () => {

      /// [...x] creates a deepcopy of x
      let newData = [...this.state.list_of_labels]

      const x_to_delete = this.state.focused_x;
      const y_to_delete = this.state.focused_y;

      console.log(`x_to_delete=${x_to_delete}`);
      console.log(`y_to_delete=${y_to_delete}`);

      newData = newData.filter(function( obj ) {
          if (obj.x === x_to_delete && obj.y === y_to_delete) {
              return false;
          }
          return true;
      });


      this.setState(prevState => ({
                                     list_of_labels: newData,
                                     focused_x: -1,
                                     focused_y: -1,
                                 })
      );
      return;
  }





   /*
    TODO: img src now passed to Viewer - OK for components/images/Image.js -- also needed for NetworkReview.js (& BrosweHistory.js?)

    const { image, userId, datasetId, } = this.props;

    const {
      id,
      labelClass,
      labelPolygons,
      predictionClass,
      predictionPolygons,
    } = image;
      this.setState({
                      //list_of_labels: [  { x: 200, y: 200, width: 0, height: 0, key: "200" }  ],

                      bool_start: false,
                      img_src: LOAD_TRAINING_IMAGE_FROM_SERVER_URL + `/${userId}/${datasetId}/${id}`,

                  });
   */


  render() {
    const { isReadOnly, boolSegmentationEnabled, image, img_src, imageIndex, imageCount, isLoading, userId, datasetId, boolMultiClass, bool_pass_detection=false, bool_history_image=false, bool_multi_class_detection=false } = this.props;

    //print_in_red(`bool_multi_class_detection=${bool_multi_class_detection}`)

    //print_in_red(`imgRef=${this.imgRef}`);
    //print_in_red(`img_src=${img_src}`);

    //print_in_red(`boolSegmentationEnabled=${boolSegmentationEnabled}`);
    //print_in_red(`bool_pass_detection=${bool_pass_detection}`);
    //print_in_red(`bool_history_image=${bool_history_image}`);

    if (!image) {
      return null;
    }

    const {
      id,
      labelClass,
      labelPolygons,
      predictionClass,
      predictionPolygons,
      filename,
    } = image;

    const {
      mainWidth,
      mainHeight,
      entities,
      is_label_selected,
      focusedName,
      toggleShowLabels,
      toggleShowPredictions,
      n_classes,
    } = this.state;

    //console.log(`predictionPolygons=${predictionPolygons}`)
    if (predictionPolygons) {
        predictionPolygons.map((polygon)=> console.log(`polygon=${JSON.stringify(polygon)}`) );
    }

    //if (this.state.list_of_labels.length === 0) {
    //  this.setState({
    //                  list_of_labels: [  { x: 69, y: 69, width: 200, height: 200, key: "0" }  ]
    //              });
    //}

    if (this.state.bool_start === true) {
      this.setState({
                      //list_of_labels: [  { x: 200, y: 200, width: 0, height: 0, key: "200" }  ],

                      bool_start: false,
                  });
    }

    //console.log(`xxxxxxxxxxxxxxxxxxxxxxxxxxxx`)
    //console.log(`n_classes=${n_classes}`)
    //console.log(`max_label=${max_label}`)
    //console.log(`xxxxxxxxxxxxxxxxxxxxxxxxxxxx`)

    // Step: Label

    //const annotationEntities = entities;
    //const annotationEntitiesList = Object.values(annotationEntities);

    const isFail = labelClass === 'FAIL';
    const isPass = labelClass === 'PASS';
    const showFailDetectionInstructions       = !isReadOnly && boolSegmentationEnabled && isFail && bool_pass_detection===false && bool_multi_class_detection===false;
    const showPassDetectionInstructions       = !isReadOnly && boolSegmentationEnabled && isPass && bool_pass_detection===true;
    const showMultiClassDetectionInstructions = !isReadOnly && boolSegmentationEnabled && bool_multi_class_detection===true;

    const showPredictions = boolSegmentationEnabled && isReadOnly;

    //print_in_red(`boolSegmentationEnabled=${boolSegmentationEnabled}`);
    //print_in_red(`image.predictionClass=${image.predictionClass}`);
    //print_in_red(`labelClass=${labelClass}`);
    //print_in_red(`bool_pass_detection=${bool_pass_detection}`);

    //const boolShowDetections = boolSegmentationEnabled &&
    //                           ( (bool_pass_detection===true  && (image.predictionClass==="PASS" || labelClass==="PASS"))
    //                          || (bool_pass_detection===false && (image.predictionClass==="FAIL" || labelClass==="FAIL"))
    //                          || (bool_multi_class_detection===true)
    //                          || (this.state.list_of_crops.length >=1 && this.state.bool_in_crop_mode===false)
    //                           );

    //print_in_red(`boolSegmentationEnabled=${boolSegmentationEnabled}`);
    //print_in_red(`bool_multi_class_detection=${bool_multi_class_detection}`);
    //print_in_red(`boolShowDetections=${boolShowDetections}`);

    const showComparison           = (isReadOnly && predictionClass && !bool_history_image) || (isReadOnly && bool_multi_class_detection && !bool_history_image);
    const showPredictionComparison = isReadOnly && predictionClass && bool_history_image;

    /*
    console.log(`boolSegmentationEnabled=${boolSegmentationEnabled}`);
    console.log(`isFail=${isFail}`);
    console.log("________________________");
    console.log("________________________");
    console.log(`showLabels=${showLabels}`);
    console.log(`showComparison=${showComparison}`);
    console.log(`predictionClass=${predictionClass}`);
    console.log("________________________");
    console.log("________________________");
    */

    // Step: Review
    //const labelEntities = labelClass === 'FAIL' ? labelPolygons || {} : {};
    //const predictionEntities =
    //  predictionClass === 'FAIL' ? predictionPolygons || {} : {};


    //console.log(`URL=${LOAD_TRAINING_IMAGE_FROM_SERVER_URL}/${userId}/${datasetId}/${id}`);


    let labelButtons = [];

    if (this.state.bool_all_classes_named===true && this.state.list_of_class_objects) {

        for (let i = 1; i <= this.state.list_of_class_objects.length; i++) {
            labelButtons.push(
                <Button
                  key={`label-${i}`}
                  onClick={() => {
                      this.handleLabelClick(i);
                      this.setState({
                                       label_selected: i,
                                    });

                  }}
                  parentClass={s[`labelButton${i}`]}
                  isActive={(labelClass===i || this.state.multi_class_detection_label===i)}
                >
                  {(this.state.bool_all_classes_named &&
                    this.state.list_of_class_objects &&
                    this.state.list_of_class_objects[i-1] &&
                    this.state.list_of_class_objects[i-1].name)
                   ? (this.state.list_of_class_objects[i-1].name+` [class ${i}]`)
                   : i
                  }
                </Button>
               );
        }
    } else {

        for (let i = 1; i <= n_classes; i++) {
            labelButtons.push(
                <Button
                  key={`label-${i}`}
                  onClick={() => {
                      this.handleLabelClick(i);
                      this.setState({
                                       label_selected: i,
                                    });

                  }}
                  parentClass={s[`labelButton${i}`]}
                  isActive={(labelClass===i || this.state.multi_class_detection_label===i)}
                >
                   { i }
                </Button>
               );
        }



    }

    //console.log(`this.state.list_of_predictions=${this.state.list_of_predictions}`);
    //console.log(`boolShowDetections=${boolShowDetections}`);
    //console.log(`showPredictionComparison=${showPredictionComparison}`);

    //console.log(`this.state.list_of_labels=${JSON.stringify(this.state.list_of_labels)}`);
    //console.log(`this.state.list_of_labels.length=${this.state.list_of_labels.length}`);

    //console.log(`this.state.list_of_crops=${JSON.stringify(this.state.list_of_crops)}`);
    //console.log(`this.state.list_of_crops.length=${this.state.list_of_crops.length}`);

    let labelFillColor = COLORS.red;
    if (this.state.label_in_progress!=null && bool_multi_class_detection===false) {

        if (bool_pass_detection) {
            labelFillColor = COLORS.green;
        }
    }
    //console.log(`bool_multi_class_detection==${bool_multi_class_detection}`);
    //console.log(`value.detection_label_class==${value.detection_label_class}`);

    // TODO: make this an array
    if (bool_multi_class_detection && this.state.label_in_progress!=null &&  this.state.label_in_progress.detection_label_class) {
        if (this.state.label_in_progress.detection_label_class === 1)
            labelFillColor = DETECTION_LABEL_COLORS.color1;
        if (this.state.label_in_progress.detection_label_class === 2)
            labelFillColor = DETECTION_LABEL_COLORS.color2;
        if (this.state.label_in_progress.detection_label_class === 3)
            labelFillColor = DETECTION_LABEL_COLORS.color3;
        if (this.state.label_in_progress.detection_label_class === 4)
            labelFillColor = DETECTION_LABEL_COLORS.color4;
        if (this.state.label_in_progress.detection_label_class === 5)
            labelFillColor = DETECTION_LABEL_COLORS.color5;
        if (this.state.label_in_progress.detection_label_class === 6)
            labelFillColor = DETECTION_LABEL_COLORS.color6;
        if (this.state.label_in_progress.detection_label_class === 7)
            labelFillColor = DETECTION_LABEL_COLORS.color7;
        if (this.state.label_in_progress.detection_label_class === 8)
            labelFillColor = DETECTION_LABEL_COLORS.color8;
        if (this.state.label_in_progress.detection_label_class === 9)
            labelFillColor = DETECTION_LABEL_COLORS.color9;

    }

    return (
      <div className={s.component}>

          <aside className={s.side}>
            <div className={s.side_section}>

            <div className={s.side_section_one}>

            <header className={s.header}>
              <Title parentClass={s.title}>
                Image <strong>{imageIndex + 1}</strong> of{' '}
                <span>{imageCount}</span>
              </Title>
              <span className={s.navigate} onClick={this.handlePreviousImage}>
                <Keyboard parentClass={s.keyboard}>←</Keyboard> Prev
              </span>
              <span className={s.navigate} onClick={this.handleNextImage}>
                Next <Keyboard parentClass={s.keyboard}>→</Keyboard>
              </span>
            </header>

            <pre className={s.id}>{filename}</pre>



            {(!isReadOnly && boolMultiClass===false) && (
              <Voting
                currentLabel={labelClass}
                isReadOnly={isReadOnly}
                isLoading={isLoading}
                onPassClick={this.handlePassClick}
                onFailClick={this.handleFailClick}
              />
            )}

            {/*************************************/}
            {/*************************************/}

            {(!isReadOnly && boolMultiClass===true && this.state.bool_all_classes_named===false) && (
                  <div>
                    <div className={s.labelButtons}>{labelButtons}</div>
                      <br />
                      <Button
                        isFullWidth={true}
                        key={`add-class`}
                        onClick={() => {
                            this.setState({ n_classes: n_classes+1 });
                        }}
                      >
                          Add Class
                      </Button>
                    </div>
            )}

            {(!isReadOnly && boolMultiClass===true && this.state.bool_all_classes_named===true) && (
                  <div>
                    <div className={s.namedLabelButtons}>{labelButtons}</div>
                      <br />
                    </div>
            )}

            {/*************************************/}
            {/*************************************/}

            {/* Network Review */}
            {showComparison && (
              <Comparison
                image={image}
                setDefectsVisibility={this.handleDetectionVisibility}
                showLabelDefects={toggleShowLabels}
                showPredictionDefects={toggleShowPredictions}
                bool_multi_class_detection={bool_multi_class_detection}
              />
            )}

            {/* History */}

            {showPredictionComparison && (
              <PredictionAside
                image={image}
                setDefectsVisibility={this.handleDefectsVisibility}
                showLabelDefects={toggleShowLabels}
                showPredictionDefects={toggleShowPredictions}
              />
            )}


            {showFailDetectionInstructions && (
              <div>
                <br/>
                <Instructions bool_fail_detection={true}/>
              </div>
            )}
            {showPassDetectionInstructions && (
              <div>
                <br/>
                <Instructions bool_pass_detection={true}/>
              </div>
            )}
            {showMultiClassDetectionInstructions && (
              <div>
                <br/>
                <Instructions bool_multi_class_detection={true}/>
              </div>
            )}
            {(!isReadOnly && !showFailDetectionInstructions && !showPassDetectionInstructions && !showMultiClassDetectionInstructions) && (
              <div>
                <br/>
                  <Instructions />
              </div> )}

            {(!isReadOnly) && (
              <div className={s.side_width}>
                <Toggles
                  currentValue={this.state.bool_in_crop_mode}
                  options={CROP_IMAGE_OPTIONS}
                  onChange={(new_toggle_value)=> {
                     print_in_red(new_toggle_value);

                     this.setState({
                         bool_in_crop_mode: new_toggle_value,
                     });
                  }}
                />
              </div>
            )}

            </div>



            {/*(!isReadOnly) && (

              <div className={s.setting_crop_image}>

                <hr className={s.hr_crop_image}/>
                <br/>

                <Title parentClass={s.title_crop_image} size="1" noMargin>
                  Crop Image (optional)
                </Title>
                <Content parentClass={s.content_crop_image}>
                  Crops are saved for all the images of an application.
                  When creating a new job, the job will retain the crop in production.
                  <ul>
                    <li> Toggle 'Label Image' to 'Crop Image' below </li>
                    <li> Click within image to crop. </li>
                    <li> Use delete key to remove crop.</li>
                  </ul>

                  <Toggles

                    currentValue={this.state.bool_in_crop_mode}
                    options={CROP_IMAGE_OPTIONS}
                    onChange={(new_toggle_value)=> {
                        print_in_red(new_toggle_value);

                        this.setState({
                            bool_in_crop_mode: new_toggle_value,
                        });


                    }}
                  />

                </Content>


                <br/>
                <hr className={s.hr_crop_image}/>




              </div>


            )*/}






            {(!isReadOnly || bool_history_image===true) && (

                  <div className={s.delete_button}>
                  <br />
                    <Button
                      isFullWidth={true}
                      key={`add-class`}
                      //parentClass={`delete_button`}
                      isTall={true}
                      onClick={() => {


                          ///////////////////////////////////////////////////////

                          // Delete training image

                          //console.log("DELETE CLICK!");

                          const { image, userId, datasetId, bool_history_image=false, } = this.props;

                          if (bool_history_image === true) {
                              console.log("delete history image1");
                              const { onDelete } = this.props;
                              console.log("delete history image2");
                              onDelete(image.filename);

                              this.setState({ bool_close_modal: true });

                              return;

                          } else {
                              console.log("delete training image1");
                              const { onDelete } = this.props;
                              console.log("delete training image2");
                              onDelete(image.id);

                              this.setState({ bool_close_modal: true });

                              return;


                          } /*

                              console.log(`image=${JSON.stringify(image)}`)
                              console.log(`userId=${userId}`)
                              console.log(`datasetId=${datasetId}`)

                              axios
                              .get(`${DELETE_TRAINING_IMAGE_URL}/${userId}/${datasetId}/${image.id}`)
                              .then((response) => {
                                  console.log('RECV DELETE_TRAINING_IMAGE');
                                  console.log(response.data);
                                  console.log('RECV DELETE_TRAINING_IMAGE');


                                  const { onDelete } = this.props;
                                  onDelete(image.id);
                                  this.setState({ bool_close_modal: true });

                              })
                              .catch((error) => {
                                  console.log("[ERROR] delete training image")
                              });

                          ///////////////////////////////////////////////////////
                          */

                      }}

                    >
                    Delete Image
                  </Button>
                </div>

            )}

          </div>
          </aside>


          <main className={s.main} ref={this.mainRef}>

              { /*TODO: add back for NetworkReview
                (isReadOnly && boolSegmentationEnabled) && (
                <ReviewTool
                  labelEntities={labelEntities}
                  predictionEntities={predictionEntities}
                />
              ) */}

              {/*(isReadOnly===false && boolSegmentationEnabled ) && (*/}

              {/*
              { (boolShowDetections===false && this.state.bool_in_crop_mode===false) && (

                  <img className={s.image} src={img_src} width={mainWidth} height={mainHeight} alt=""  />

              )}
              */}

              {/*
              { (this.state.bool_in_crop_mode===true) && (

                    <div>
                        <img
                                className={s.canvas__img}
                                ref={ this.imgRef }
                                width={ mainWidth }
                                height={ mainHeight }
                                src={ img_src }
                                alt=''
                                onLoad={ (e) => {
                                  console.log('crop_img_loaded');
                                  this.setState({ bool_crop_img_loaded: true})
                                }}
                        />

                        <Stage
                            className={s.stage}
                            width={mainWidth}
                            height={mainHeight}

                            onMouseDown={(event)=>this.handleCropMouseDown(event)}
                            onMouseUp={(event)=>this.handleCropMouseUp(event)}
                            onMouseMove={(event)=>this.handleCropMouseMove(event)}
                        >

                            <Layer>
                            {   this.state.bool_crop_img_loaded && (
                                    <Image image={ this.imgRef.current } width={ mainWidth } height={ mainHeight }/>
                                )
                            }

                            {   (this.state.list_of_crops.length>=1) && (

                                    this.state.list_of_crops.map(value => {

                                        //console.log("________________________________");
                                        //console.log(`____focused_x=${this.state.focused_crop_x}`);
                                        //console.log(`____value.x=${value.x}`);
                                        //console.log(`____focused_y=${this.state.focused_crop_y}`);
                                        //console.log(`____value.y=${value.y}`);

                                        //let fillValue = 15;
                                        let fillValue = 15;

                                        if (this.state.focused_crop_x === value.x && this.state.focused_crop_y === value.y) {
                                            fillValue = 60;
                                        }

                                        let fillColor = COLORS.white;

                                        return (
                                            <Rect
                                                x={value.x}
                                                y={value.y}
                                                width={value.width}
                                                height={value.height}
                                                fill={alphaColor(fillColor, fillValue)}
                                                stroke={fillColor}

                                                onMouseOver={ () => {

                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                        this.handleCropRectangleClick(e);
                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />
                                        );
                                    })
                                )
                            }

                            </Layer>
                        </Stage>
                    </div>

              )}
              */}

              {/**********************************************************************/}
              {/**********************************************************************/}
              {/**********************************************************************/}
              {/**********************************************************************/}


              { (true) && (

                    <div>
                        <img
                                className={s.canvas__img}
                                ref={ this.imgRef }
                                width={ mainWidth }
                                height={ mainHeight }
                                src={ img_src }
                                alt=''
                                onLoad={ (e) => {
                                  // console.log('loading canvas__img');
                                  this.setState({ bool_img_loaded: true})
                                }}
                        />


                        <Stage
                            className={s.stage}
                            width={mainWidth}
                            height={mainHeight}

                            onMouseDown={(event)=>this.handleMouseDown(event, showPredictions)}
                            onMouseUp={(event)=>this.handleMouseUp(event, showPredictions)}
                            onMouseMove={(event)=>this.handleMouseMove(event, showPredictions)}
                        >

                            <Layer>
                            {   this.state.bool_img_loaded && (

                                    <Rect
                                        x={0}
                                        y={0}
                                        width={mainWidth}
                                        height={mainHeight}
                                        fill={COLORS.black}
                                    />

                            )}

                            {   (this.state.bool_img_loaded && this.state.list_of_crops.length >=1 && this.state.bool_in_crop_mode===false) && (

                                      <Image image={ this.imgRef.current } width={ mainWidth } height={ mainHeight } opacity={0.2}/>
                            )}

                            {   (this.state.bool_img_loaded && (this.state.list_of_crops.length===0 || this.state.bool_in_crop_mode===true)) && (

                                      <Image image={ this.imgRef.current } width={ mainWidth } height={ mainHeight }/>
                            )}

                            {   (this.state.bool_img_loaded && this.state.list_of_crops.length >=1 && this.state.bool_in_crop_mode==false) && (

                                       <Group
                                           clipFunc={(ctx) => {

                                               this.state.list_of_crops.map(value => {

                                                   ctx.rect(value.x, value.y, value.width, value.height);


                                               })

                                           }}
                                        >

                                      <Image image={ this.imgRef.current } width={ mainWidth } height={ mainHeight }/>

                                    </Group>
                            )}

                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}

                            { (this.state.crop_in_progress != null) && (

                                            <Rect
                                                x={this.state.crop_in_progress.x}
                                                y={this.state.crop_in_progress.y}
                                                width={this.state.crop_in_progress.width}
                                                height={this.state.crop_in_progress.height}
                                                fill={alphaColor(COLORS.white, 15)}
                                                stroke={COLORS.white}

                                                onMouseOver={ () => {

                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                        // disable mouseclicks for reviewing prediction results in NetworkReview
                                                        //if (showPredictions===true) {
                                                        //    return;
                                                        //}

                                                        //this.handleCropRectangleClick(e);
                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />


                            )}



                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}


                            { (this.state.label_in_progress != null) && (

                                            <Rect
                                                x={this.state.label_in_progress.x}
                                                y={this.state.label_in_progress.y}
                                                width={this.state.label_in_progress.width}
                                                height={this.state.label_in_progress.height}
                                                fill={alphaColor(labelFillColor, 15)}
                                                stroke={labelFillColor}

                                                onMouseOver={ () => {

                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                        // disable mouseclicks for reviewing prediction results in NetworkReview
                                                        //if (showPredictions===true) {
                                                        //    return;
                                                        //}

                                                        //this.handleRectangleClick(e);
                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />


                            )}

                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}
                {/**************************************************/}

                            {/* (when labelling: always show labels), (NetworkReview: user can toggleShowLabels) */}
                            {   ( (this.state.list_of_labels.length>=1 && (isReadOnly===false)) ||
                                  (this.state.list_of_labels.length>=1 && (isReadOnly===true) && toggleShowLabels) ) && (


                                    this.state.list_of_labels.map(value => {

                                        //console.log("________________________________");
                                        //console.log(`____focused_x=${this.state.focused_x}`);
                                        //console.log(`____value.x=${value.x}`);
                                        //console.log(`____focused_y=${this.state.focused_y}`);
                                        //console.log(`____value.y=${value.y}`);

                                        let fillValue = 15;
                                        if (this.state.focused_x === value.x && this.state.focused_y === value.y) {
                                            fillValue = 60;
                                        }
                                        if (showPredictions===true) {
                                            fillValue = 30;
                                        }
                                        let fillColor = COLORS.red;
                                        if (isPass) {
                                            fillColor = COLORS.green;
                                        }
                                        //console.log(`bool_multi_class_detection==${bool_multi_class_detection}`);
                                        //console.log(`value.detection_label_class==${value.detection_label_class}`);
                                        if (bool_multi_class_detection && value.detection_label_class) {
                                            if (value.detection_label_class === 1)
                                                fillColor = DETECTION_LABEL_COLORS.color1;
                                            if (value.detection_label_class === 2)
                                                fillColor = DETECTION_LABEL_COLORS.color2;
                                            if (value.detection_label_class === 3)
                                                fillColor = DETECTION_LABEL_COLORS.color3;
                                            if (value.detection_label_class === 4)
                                                fillColor = DETECTION_LABEL_COLORS.color4;
                                            if (value.detection_label_class === 5)
                                                fillColor = DETECTION_LABEL_COLORS.color5;
                                            if (value.detection_label_class === 6)
                                                fillColor = DETECTION_LABEL_COLORS.color6;
                                            if (value.detection_label_class === 7)
                                                fillColor = DETECTION_LABEL_COLORS.color7;
                                            if (value.detection_label_class === 8)
                                                fillColor = DETECTION_LABEL_COLORS.color8;
                                            if (value.detection_label_class === 9)
                                                fillColor = DETECTION_LABEL_COLORS.color9;

                                        }



                                        return (
                                            <Group
                                              x={value.x}
                                              y={value.y}
                                              width={value.width}
                                              height={value.height}

                                              onMouseDown={ (e) => {

                                                        // disable mouseclicks for reviewing prediction results in NetworkReview
                                                        if (showPredictions!==true) {
                                                            this.handleRectangleClick(e);
                                                        }

                                                   }
                                              }
                                            >

                                            <Rect
                                                width={value.width}
                                                height={value.height}
                                                fill={alphaColor(fillColor, fillValue)}
                                                stroke={fillColor}

                                                onMouseOver={ () => {

                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                        // As <Group> has x/y of crop, <Group> onMouseDown used
                                                        // disable mouseclicks for reviewing prediction results in NetworkReview
                                                        //if (showPredictions===true) {
                                                        //    return;
                                                        //}
                                                        //this.handleRectangleClick(e);
                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />


                                              {/* text={`w ${(value.width * 14.0 / 1280).toFixed(2)}\nh ${(value.height * 10.3 / 960).toFixed(2)}`}*/}

                                              <Text
                                                fontSize={20}
                                                stroke={COLORS.red}
                                                fill={COLORS.red}
                                                strokeWidth={1}
                                                align={"center"}
                                              />

                                            </Group>



                                        );
                                    })
                                )
                            }


                        {/****************************************************************************/}
                        {/****************************************************************************/}

                        {/*
                        // TODO: show crop in training/labelling (from dataset.crop)
                        // TODO: show crop in training/network-review (from job.crop)

                        // TODO: show crop in production (just show image that was cropped in backend)
                        // TODO: dont show crop in history (just show image that was cropped in backend)

                        //{ (true) && (
                        */}

                            {   (this.state.list_of_crops.length>=1) && (

                                    this.state.list_of_crops.map((value, crop_i) => {

                                        //console.log("________________________________");
                                        //console.log(`____focused_x=${this.state.focused_crop_x}`);
                                        //console.log(`____value.x=${value.x}`);
                                        //console.log(`____focused_y=${this.state.focused_crop_y}`);
                                        //console.log(`____value.y=${value.y}`);

                                        //let fillValue = 15;
                                        let fillValue = 15;

                                        if (this.state.focused_crop_x === value.x && this.state.focused_crop_y === value.y) {
                                            fillValue = 60;
                                        }

                                        let fillColor = COLORS.white;

                                        if (this.state.bool_in_crop_mode) {

                                          // <Group>
                                          //
                                          //  <Text
                                          //    fontSize={20}
                                          //    text={"CROP1"}
                                          //    stroke={'#421'}
                                          //    strokeWidth={1}
                                          //   align={"center"}
                                          //  />
                                          //
                                          // </Group>


                                          return (

                                            <Group
                                              x={value.x}
                                              y={value.y}
                                              width={value.width}
                                              height={value.height}

                                              onMouseDown={ (e) => {

                                                      //console.log("GROUP MOUSEDOWN");
                                                      if (this.state.bool_in_crop_mode) {
                                                          //console.log(`    GROUP MOUSEDOWN2 e.target=${JSON.stringify(e.target)}`);
                                                          //console.log(`    GROUP MOUSEDOWN2 e.currentTarget=${JSON.stringify(e.currentTarget)}`);
                                                          //console.log(`    GROUP MOUSEDOWN2 e.evt=${JSON.stringify(e.evt)}`);
                                                          this.handleCropRectangleClick(e);
                                                      }
                                                   }
                                              }
                                            >

                                              <Rect
                                                  width={value.width}
                                                  height={value.height}
                                                  fill={alphaColor(fillColor, fillValue)}
                                                  stroke={fillColor}

                                                  onMouseOver={ () => {

                                                          if (this.state.bool_in_crop_mode) {
                                                              this.mainRef.current.style.cursor = 'pointer';
                                                          }
                                                      }
                                                  }

                                                  onMouseDown={ (e) => {

                                                          // As <Group> has x/y of crop, <Group> onMouseDown used
                                                          //if (this.state.bool_in_crop_mode) {
                                                          //    this.handleCropRectangleClick(e);
                                                          //}
                                                      }
                                                  }

                                                  onMouseLeave={ () => {

                                                          if (this.state.bool_in_crop_mode) {
                                                              this.mainRef.current.style.cursor = 'default';
                                                          }
                                                      }
                                                  }

                                                  onMouseOut={ () => {

                                                          if (this.state.bool_in_crop_mode) {
                                                              this.mainRef.current.style.cursor = 'default';
                                                          }
                                                      }
                                                  }

                                              />
                                              <Text
                                                fontSize={20}
                                                text={`CROP${crop_i}`}
                                                stroke={'#421'}
                                                strokeWidth={1}
                                                align={"center"}
                                              />

                                            </Group>


                                          );
                                        }
                                    })
                                )
                            }

                        {/****************************************************************************/}

                        {/*

                                              <Text
                                                text={'123'}
                                                fontSize={18}
                                                fontFamily={'Calibri'}
                                                fill={'#000'}
                                                width={130}
                                                padding={5}
                                                align={'center'}
                                              />
                                            </Group>
                        */}

                        {/****************************************************************************/}

                            { showPredictions && toggleShowPredictions && this.state.list_of_predictions.length>=1 && (


                                    this.state.list_of_predictions.map(value => {

                                        console.log(`value=${JSON.stringify(value)}`);

                                        let fillValue = 30;
                                        let fillColor = COLORS.orange
                                        if (bool_pass_detection) {
                                            fillColor = COLORS.green_prediction;
                                        }
                                        if (bool_multi_class_detection && value.detection_predicted_class) {
                                            if (value.detection_predicted_class === 1)
                                                fillColor = DETECTION_LABEL_COLORS.color1;
                                            if (value.detection_predicted_class === 2)
                                                fillColor = DETECTION_LABEL_COLORS.color2;
                                            if (value.detection_predicted_class === 3)
                                                fillColor = DETECTION_LABEL_COLORS.color3;
                                            if (value.detection_predicted_class === 4)
                                                fillColor = DETECTION_LABEL_COLORS.color4;
                                            if (value.detection_predicted_class === 5)
                                                fillColor = DETECTION_LABEL_COLORS.color5;
                                            if (value.detection_predicted_class === 6)
                                                fillColor = DETECTION_LABEL_COLORS.color6;
                                            if (value.detection_predicted_class === 7)
                                                fillColor = DETECTION_LABEL_COLORS.color7;
                                            if (value.detection_predicted_class === 8)
                                                fillColor = DETECTION_LABEL_COLORS.color8;
                                            if (value.detection_predicted_class === 9)
                                                fillColor = DETECTION_LABEL_COLORS.color9;

                                        }



                                        return (
                                            <Rect
                                                x={value.x}
                                                y={value.y}
                                                width={value.width}
                                                height={value.height}
                                                fill={alphaColor(fillColor, fillValue)}
                                                stroke={fillColor}

                                                onMouseOver={ () => {

                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                            />
                                        );
                                    })
                                )
                            }

                            {/****************************************************************************/}
                            {/****************************************************************************/}

                            </Layer>
                        </Stage>
                    </div>
                )}

          </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: selectors.isLoading(state),
  userId: selectors.getUserId(state),
});

export default connect(mapStateToProps, {
  postImageLabel,
})(Viewer);

