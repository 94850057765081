// ProductionView.js

import React, { Component } from 'react';

import Button from 'components/elements/Button';
import Toggles from 'components/form/Toggles';
import Toggles2 from 'components/form/Toggles2';

import ProductionResults from 'components/production/ProductionResults';
import RecentRecap from 'components/production/RecentRecap';
import RecentImages from 'components/production/RecentImages';
import Live from 'components/svgs/Live';

import moment from 'moment';

import axios from 'axios';

import s from './ProductionView.module.sass';

import Request from 'axios-request-handler';

import Title from 'components/elements/Title';
//import Message from 'components/elements/Message';
import Message3 from 'components/elements/Message3';


import { Stage, Layer, Rect, Image } from 'react-konva';

import { COLORS, DETECTION_LABEL_COLORS, alphaColor } from 'components/label/utils';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons'; // Import the required icon



import {
  SUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL,
  UNSUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL,
  SET_BOOL_STREAM_VIDEO_URL,
  CAPTURE_IMAGE_NO_INFERENCE_URL,
  GET_BOOL_PLC_TRIGGER_URL,
  SET_BOOL_PLC_TRIGGER_URL,
  GET_BOOL_PRODUCTION_MODE_URL,
  SET_BOOL_PRODUCTION_MODE_URL,
  GET_PRODUCTION_STATS_URL,
  REFRESH_PRODUCTION_STATS_URL,
  GET_IO_DEVICE_TYPE_URL,
} from 'pages/urls';


//TODO: variable?
//const IMAGE_RATIO = 1.33333

const print_in_red = (msg) => {
    console.log(`%c ${msg}`, 'background: red; color: white; display: block;');
}

const print_in_blue = (msg) => {
    console.log(`%c ${msg}`, 'background: blue; color: white; display: block;');
}

const print_in_orange = (msg) => {
    console.log(`%c ${msg}`, 'background: orange; color: white; display: block;');
}

//const image_capture_callback = (response) => {
//    console.log(response.data);
//}

const PLC_TRIGGER_OPTIONS = [
  { value: false, label: 'Camera Setup' },
  { value: true, label: 'Production Mode' },
];

/*
const PLC_TRIGGER_OPTIONS = [
  { value: false, label: 'Manual trigger' },
  { value: true, label: 'Auto trigger' },
];
*/
/*
const TRIGGER_OPTIONS2 = [
  { value: 1, label: 'SETUP' },
  { value: 2, label: 'STATS' },
];
*/

/*
const PRODUCTION_MODE_OPTIONS = [
  { value: false, label: 'Camera setup' },
  { value: true, label: 'Production' },
];
*/

const INFERENCE_OPTIONS = [
  { value: 'off', label: 'Capture Only' },
  { value: 'on', label: 'Neural Network ' },
];

const STREAM_VIDEO_OPTIONS = [
  { value: false, label: 'Live Video OFF' },
  { value: true, label: 'Live Video On' },
];





/*
function return_timestamp() {
  var date = new Date();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
*/

/*
components/production/ProductionResults

const componentClass = cx({
  [s.component]: true,
  [s[`type-${predictionType}`]]: predictionType,
});

<p className={cx(s.item, s.prediction)}>

.type-pass
  --prediction-color: var(--pass)
  box-shadow: 0 0 2em rgba($green, 0.5)

.type-fail
  --prediction-color: var(--fail)
  box-shadow: 0 0 2em rgba($red, 0.5)
*/


////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

/*
const image_url = `${LOAD_BINARY_IMAGE_THUMBNAIL_FROM_SERVER_URL}/${userId}/${datasetId}/${filename}`;

axios
  .get(image_url, { responseType: 'arraybuffer' })
  .then((response) => {

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });
*/

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////


const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}


class ProductionView extends Component {
  constructor(props) {
    super(props);
    const current_time_object = new Date().getTime();
    const current_time_str = moment.unix(current_time_object).format('h:mm:ss.SSS a')

    print_in_blue(current_time_str)
    this.state = {
      currentIndex: 0,
      classPrediction: "N/A",
      classPredictionNamedClass: "",
      classPredictionConfidence: "N/A",
      imageCurrentTimeStr: "",
      image_inspection_time: null,
      videoCurrentTimeStr: "",
      job_start_time_str: null,
      image_count: 0,
      failure_rate: 0,
      active_job_name: "",
      active_job_slot: "",
      bool_io_connected: false,
      recentImgSrc: "",
      xImgSrc: "",
      xFrameSrc: "",
      bool_stream_video: false,
      bool_PLC_trigger: null,
      bool_production_mode: null,

      bool_detection: false,
      bool_pass_detection: false,
      bool_multi_class_detection: false,

      bool_detection_img_loaded: false,
      mainWidth: 0,
      mainHeight: 0,

      toggleShowPredictions: true,

      list_of_predictions: [],

      bool_multi_camera: false,

      deviceType: 'MANUAL',

    };

    this.mainRef = React.createRef();
    this.imgRef  = React.createRef();


      ///////////////////////////////////////////////////////////

      // Get bool_PLC_trigger
      /*
      axios.get(GET_BOOL_PLC_TRIGGER_URL)
      .then((response) => {
          console.log("RECVD BOOL PLC TRIGGER");
          console.log(response.data)
          console.log(response.data['bool_PLC_trigger'])
          console.log(response.data.bool_PLC_trigger)
          console.log("RECVD BOOL PLC TRIGGER");

          if (response.data.bool_PLC_trigger === true) {
            this.setState({
                bool_PLC_trigger: true,
            });
          }
          if (response.data.bool_PLC_trigger === false) {
            this.setState({
                bool_PLC_trigger: false,
            });
          }

      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });
      */
      ///////////////////////////////////////////////////////////

      // Get bool_production_mode

      axios.get(GET_BOOL_PRODUCTION_MODE_URL)
      .then((response) => {
          //console.log("RECVD BOOL_PRODUCTION_MODE");
          //console.log(response.data)
          //console.log(response.data['bool_production_mode'])
          //console.log(response.data.bool_production_mode)
          //console.log("RECVD BOOL_PRODUCTION_MODE");

          if (response.data.bool_production_mode === true) {
            this.setState({
                bool_production_mode: true,
            });
          }
          if (response.data.bool_production_mode === false) {
            this.setState({
                bool_production_mode: false,
            });
          }

      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////

      // Get device_type

      axios.get(GET_IO_DEVICE_TYPE_URL)
      .then((response) => {
          console.log("RECVD IO DEVICE TYPE");
          console.log(response.data)
          console.log("RECVD IO DEVICE TYPE");


          if (response.data.device_type === 0) {
              this.setState({ deviceType: 'MANUAL' });
          }
          if (response.data.device_type === 4) {
              this.setState({ deviceType: '24V IO' });
          }
          if (response.data.device_type === 2) {
              this.setState({ deviceType: 'EtherNet/IP' });
          }
          if (response.data.device_type === 1) {
              this.setState({ deviceType: 'ODVA EtherNet/IP' });
          }
      })
      .catch((error) => {
         console.log(`[ERROR] ${error.message}`)
      });

      ///////////////////////////////////////////////////////////
      // Get production stats

      axios.get(GET_PRODUCTION_STATS_URL)
      .then((response) => {
          //console.log("RECVD PRODUCTION_STATS");
          //console.log(`response.data=${JSON.stringify(response.data)}`)
          //console.log(response.data['bool_production_mode'])
          //console.log(response.data.bool_production_mode)
          //console.log("RECVD PRODUCTION_STATS");

          this.setState({
              active_job_name: response.data.active_job_name,
              active_job_slot: response.data.active_job_slot,
          });

      })
      .catch((error) => {
         console.log(`[ERROR] get production stats error_msg=${error.message}`)
      });

      ///////////////////////////////////////////////////////////


    print_in_blue('CONSTRUCTOR CONSTRUCTOR CONSTRUCTOR')
    print_in_blue('CONSTRUCTOR CONSTRUCTOR CONSTRUCTOR')

    const reviews = new Request(SUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL);

    // 'long poll'
    // async continuous request sent from browser to backend
    // --> backend sleeps until an image is ready
    // --> when image is captured, backend responds to poll
    // --> ... image_capture_callback
    const interval_ms = 1
    reviews.poll(interval_ms).get((response) => {
        //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        //console.log("~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~")
        //print_in_orange(JSON.stringify(response.data));

        //for (var key in response.data) {
        //    if (key != "img")
        //        print_in_orange(`%c  ${key}: ${response.data[key]}`);
        //}
        const timestamp = response.data['timestamp']
        const inspection_time = response.data['inspection_time']
        const job_start_timestamp = response.data['JOB_start_time']

        const image_count  = response.data['image_count']
        const failure_rate = Math.round(response.data['failure_rate'] * 10000.0) / 100
        const active_job_name = response.data['JOB_name']
        const active_job_slot = response.data['JOB_slot']
        //console.log(`active_job_name=${active_job_name}`)
        //console.log(`active_job_slot=${active_job_slot}`)
        const bool_io_connected = response.data['bool_IO_connected']

        const current_time_str = moment.unix(timestamp).format('h:mm:ss.SSS a')
        const job_start_time_str = moment.unix(job_start_timestamp).format('MMM D, YY @ h:mm a')
        //print_in_blue(current_time_str)

        const b64Data = response.data['img']
        const blob = b64toBlob(b64Data, "image/jpeg");
        const blobUrl = URL.createObjectURL(blob);

        var imageUrl = "";
        var frameUrl = "";

        if (response.data['bool_image_triggered'] === true)
            imageUrl = blobUrl;

        //if (this.state.bool_stream_video == true)
        frameUrl = blobUrl;

        //console.log(`response=${JSON.stringify(response)}`);
        //console.log(`bool_detection=${response.data['bool_detection']}`)


        // only update xImgSrc when bool_image_triggered is True
        if (response.data['bool_image_triggered'] === true) {
            this.setState({
                classPrediction: response.data['class_prediction'],
                classPredictionNamedClass: response.data['class_prediction_named_class'],
                classPredictionConfidence: response.data['class_prediction_confidence'],
                bool_detection: response.data['bool_detection'],
                bool_pass_detection: response.data['bool_pass_detection'],
                bool_multi_class_detection: response.data['bool_multi_class_detection'],
                image_inspection_time: inspection_time,
                imageCurrentTimeStr: current_time_str,
                videoCurrentTimeStr: current_time_str,
                job_start_time_str: job_start_time_str,
                image_count: image_count,
                failure_rate: failure_rate,
                active_job_name: active_job_name,
                active_job_slot: active_job_slot,
                bool_io_connected: bool_io_connected,
                recentImgSrc: imageUrl,
                xImgSrc: imageUrl,
                xFrameSrc: frameUrl,
            });
            this.restore_predictions_from_backend(response.data['list_of_prediction_polygons'])

        }
        else {
            this.setState({
                //classPrediction: response.data['class_prediction'],
                //classPredictionConfidence: response.data['class_prediction_confidence'],
                videoCurrentTimeStr: current_time_str,
                recentImgSrc: "",
                xFrameSrc: frameUrl,
            });
       }

        //image_capture_notification_dict


        /*
        image_capture_notification_dict['class_prediction']="PASS"
        image_capture_notification_dict['class_prediction_confidence']=90
        image_capture_notification_dict['timestamp']=time.time()

        this.setState({
            classPrediction: "pass",
            classPredictionConfidence: 98,
            currentTimeStr: current_time_str,
        })
        */

        this.forceUpdate()
        // you can cancel polling by returning false
    });

  }



  restore_predictions_from_backend = (raw_list_of_predictions) => {

      //const mainWidth = this.mainRef.current.clientWidth;
      //const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

      console.log("LOAD IMAGE PREDICTIONS:");
      console.log(`raw_list_of_predictions=${JSON.stringify(raw_list_of_predictions)}`);
      console.log(`mainWidth=${this.state.mainWidth}`);
      console.log(`mainHeight=${this.state.mainHeight}`);


      if (raw_list_of_predictions) {

          let list_of_predictions_from_backend = (raw_list_of_predictions).map(value => {

              //const xp = Math.round((value.x / mainWidth) * 10000) / 10000;
              //const yp = Math.round((value.y / mainHeight) * 10000) / 10000;
              //const widthp = Math.round((value.width / mainWidth) * 10000) / 10000;
              //const heightp = Math.round((value.height / mainHeight) * 10000) / 10000;
              const x_pixels = value.x * this.state.mainWidth;
              const y_pixels = value.y * this.state.mainHeight;
              const width_pixels = value.width * this.state.mainWidth;
              const height_pixels = value.height * this.state.mainHeight;

              return {  x: x_pixels,
                        y: y_pixels,
                        width: width_pixels,
                        height: height_pixels,
                        detection_predicted_class: value.detection_predicted_class,  }
          });

          console.log(`list_of_predictions_from_backend=${JSON.stringify(list_of_predictions_from_backend)}`);

          this.setState({
              list_of_predictions: list_of_predictions_from_backend,
          });
      }
  };


// Lifecycle methods

  componentDidMount = () => {

      window.addEventListener('resize', this.handleResize);

      this.setMainDimensions();

      //if (this.state.bool_detection) {
      //    this.restore_predictions_from_backend(image);
      //}
  };


  componentDidUpdate(prevProps, prevState) {
      //const { image, isReadOnly } = this.props;

      if (this.state.xImgSrc !== prevState.xImgSrc) {

          this.setState({bool_detection_img_loaded: false})
      }
  }



  componentWillUnmount = () => {

      console.log("UNMOUNT");
      console.log("UNMOUNT");

      window.removeEventListener('resize', this.handleResize);

      const unsubscribe_url = (UNSUBSCRIBE_IMAGE_CAPTURE_NOTIFICATIONS_URL);

      axios.get(unsubscribe_url)
      .then((response) => {
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
          console.log("UNSUBSCRIBED UNSUBSCRIBED UNSUBSCRIBED");
      });

                  //this.setState({
                  //    bool_stream_video: false,
                  //});

                    axios
                    .get(`${SET_BOOL_STREAM_VIDEO_URL}/false`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');

                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_stream_video")
                    });


  }



  // Window events

  handleResize = () => {
    this.setMainDimensions();
  };


  // TODO: handle differently
  setMainDimensions = () => {
    if (!this.mainRef || !this.mainRef.current) {
      return;
    }

    const mainWidth = this.mainRef.current.clientWidth;
    const mainHeight = this.mainRef.current.clientHeight;
    //const mainHeight = Math.round(mainWidth / IMAGE_RATIO);

    this.setState({
        mainWidth,
        mainHeight,
    });

  };












  render() {


    print_in_red('____________________________')
    print_in_red('PRODUCTION VIEW render()')
    print_in_red(`mainWidth=${this.state.mainWidth}`);
    print_in_red(`mainHeight=${this.state.mainHeight}`);
    if (this.state.list_of_predictions) {
        this.state.list_of_predictions.map((prediction)=> print_in_red(`prediction=${JSON.stringify(prediction)}`) );
    }
    print_in_red(`this.state.bool_multi_class_detection=${this.state.bool_multi_class_detection}`);
    //print_in_red(`list_of_predictions=${this.state.list_of_predictions}`);
    print_in_red('____________________________')

    //print_in_red('____________________________')
    //print_in_red(`this.state.active_job_name=${this.state.active_job_name}`)
    //print_in_red(`this.state.active_job_slot=${this.state.active_job_slot}`)
    //print_in_red('____________________________')


    // TODO: this is temporary, for testing
    //if (this.state.list_of_predictions.length === 0) {
    //  this.setState({
    //                  list_of_predictions: [  { x: 69, y: 69, width: 200, height: 200, key: "0" }  ]
    //              });
    //}


    let figure_class_name = s.latest_na;

    if (this.state.classPrediction==='FAIL') {

        if (this.state.bool_detection) {
            figure_class_name = s.latest_fail_detection;
        } else {
            figure_class_name = s.latest_fail_classification;
        }
    }
    if (this.state.classPrediction==='PASS') {

        if (this.state.bool_detection) {
            figure_class_name = s.latest_pass_detection;
        } else {
            figure_class_name = s.latest_pass_classification;
        }
    }

    //? s.latest_fail :
    //            (this.state.classPrediction==='PASS'? s.latest_pass : s.latest_na)}
    //            >



    return (
      <div className={s.component}>
        <aside className={s.data}>

            {/*
            <Title size="2">Camera Mode:</Title>
            */}
              <div className={s.tool}>
                <Toggles2
                  currentValue={this.state.bool_production_mode}
                  options={PLC_TRIGGER_OPTIONS}

                  onChange={(new_toggle_value)=> {
                    print_in_red(new_toggle_value);
                    this.setState({
                        bool_production_mode: new_toggle_value,
                  });

                //if (new_toggle_value === true) {
                    axios
                    .get(`${SET_BOOL_PRODUCTION_MODE_URL}/${new_toggle_value}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');
                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_production_mode")
                    });
                //} else {
                //}

              }}

                />
              </div>

              {/* todo: update on change in setting + fix dispplay properties + camera onlline/offline
               (this.state.bool_production_mode == true) && (

                <Message3
                  value={this.state.deviceType}
                  title="Trigger Mode:"
                  hue="300"
                >
                </Message3>
              )*/}



            {/*
            <Title size="2">Camera Mode:</Title>
            */}

            {/*
              <div className={s.tool}>
                <Toggles2
                  currentValue={1}
                  options={TRIGGER_OPTIONS2}

                  onChange={(new_toggle_value)=> {
                    print_in_red(new_toggle_value);
                  }}
                />
              </div>

            */}


            {/*
            <br />
              <p className={s.refresh}> Enable PLC Trigger to connect </p>
            */}
            <br />
            <br />

    { (this.state.bool_production_mode === false && this.state.bool_multi_camera===false) &&

     <div>
       {/*
          <div className={s.live}>
            <header className={s.liveHeader}>
              <span className={s.time}>{this.state.videoCurrentTimeStr}</span>
              <Live />
            </header>
          </div>
       */}

      <figure className={s.liveX}>

            { (this.state.xFrameSrc !== "" && this.state.bool_stream_video === true)
              ?
              <div>
                <Live />
                <img alt="Live camera view" src={this.state.xFrameSrc} />
              </div>
              :

              <div className={s.live_image}>
                  CAMERA LIVE FEED OFF
              </div>
            }

      </figure>
            <br />


          <div className={s.tool}>
            <Toggles

              currentValue={this.state.bool_stream_video}
              options={STREAM_VIDEO_OPTIONS}
              onChange={(new_toggle_value)=> {
                  print_in_red(new_toggle_value);

                if (this.state.bool_production_mode === false) {

                  this.setState({
                      bool_stream_video: new_toggle_value,
                  });

                    axios
                    .get(`${SET_BOOL_STREAM_VIDEO_URL}/${new_toggle_value}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');

                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_stream_video")
                    });
                } else {
                    print_in_red("not displaying video: in production mode")
                }

              }}
            />
          </div>


          <br />
          <br />
	   <Button
              iconLeft="camera"
              isFullWidth
              isTall
              size="large"
              color="info"
              isDisabled={this.state.bool_stream_video}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false && this.state.bool_stream_video!==true) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              Capture Image
            </Button>
          <br />
          <br />

            <Message3
              value={this.state.active_job_name}
              title="Current Job"
              hue="200"
            >
              { this.state.job_start_time_str
                ?
                (
                  <p>
                    <br/>
                      Job Slot {this.state.active_job_slot} &nbsp; &nbsp; Start: {this.state.job_start_time_str}
                  </p>
                )
                :
                (
                  <p>
                    <br/>
                      Job Slot {this.state.active_job_slot}
                  </p>
                )

              }
            </Message3>


              {/*
              <div className={s.tool}>
                <Toggles
                  currentValue={this.state.bool_PLC_trigger}
                  options={PLC_TRIGGER_OPTIONS}

                  onChange={(new_toggle_value)=> {
                  print_in_red(new_toggle_value);
                  this.setState({
                      bool_PLC_trigger: new_toggle_value,
                  });

                //if (new_toggle_value === true) {
                    axios
                    .get(`${SET_BOOL_PLC_TRIGGER_URL}/${new_toggle_value}`)
                    .then((response) => {
                        console.log('_________________');
                        console.log(response);
                        console.log('_________________');
                    })
                    .catch((error) => {
                        console.log("[ERROR] set bool_PLC_trigger")
                    });
                //} else {
                //}



              }}

                />
              </div>
          <br />

              */}


      </div>
     }


     { (this.state.bool_production_mode === false && this.state.bool_multi_camera===true) &&

       <div>

         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM1
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB1
            </Button>
         </div>

         <br />
         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM2
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB2
            </Button>
         </div>

         <br />
         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM3
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB3
            </Button>
         </div>

         <br />
         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM4
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB4
            </Button>
         </div>

         <br />

         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM5
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB5
            </Button>
         </div>

         <br />

         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="success"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM6
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
                color='success'
            >
            JOB6
            </Button>
         </div>

         <br />

         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM7
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB7
            </Button>
         </div>

         <br />
         <div style={{ display: 'flex', width: '105%', alignItems: 'center' }}>
	   <Button
              style={{ flex: '1' }}

              iconLeft="camera"
              isFullWidth
              //isTall
              size="large"
              color="info"
              isDisabled={false}
              onClick={()=>{
                console.log("CLICK");

                if (this.state.bool_production_mode === false) {
                    axios
                    .get(CAPTURE_IMAGE_NO_INFERENCE_URL)
                    .then((response) => {
                        console.log('=================================================');
                        console.log(response);
                        console.log('=================================================');
                    })
                    .catch((error) => {
                        console.log("[ERROR] capture_image")
                    });
                } else {
                    print_in_red("NOT CAPTURING MANUAL IMAGE: [in production mode OR streaming video]")
                }
              }}
            >
              CAM8
            </Button>

            <div style={{ width: '10px' }} /> {/* Spacer */}

            <Button
                style={{ flex: '4' }}
                iconLeft={faChevronDown}
                size="large"
            >
            JOB8
            </Button>
         </div>

         <br />













       </div>
     }



        { this.state.bool_production_mode &&
          <div>

            <Title size="2">Camera Status:</Title>

            <Message3
              value={`ONLINE`}
              title="Camera Status"
              hue="135"
              //type="success"

            >
            </Message3>

            {this.state.bool_io_connected ?
              ( <Message3
                  value={`ONLINE`}
                  title="PLC Status"
                  hue="135"
                >
                </Message3> )
              :
              ( <Message3
                  value={`OFFLINE`}
                  title="PLC Status"
                  hue="200"
                >
                </Message3> )
            }

            <br />

            <Title size="2">Production Stats:</Title>

            <Message3
              value={this.state.active_job_name}
              title="Current Job"
              hue="200"
            >

              { this.state.job_start_time_str
                ?
                (
                  <p>
                    <br/>
                      Job Slot {this.state.active_job_slot} &nbsp; &nbsp; Start: {this.state.job_start_time_str}
                  </p>
                )
                :
                (
                  <p>
                    <br/>
                      Job Slot {this.state.active_job_slot}
                  </p>
                )

              }

            </Message3>

            {/*
            <Message
              value={`JAN 31, 2021`}
              title="START DATE"
              hue="200"
            >
            </Message>

            <Message
              value={`4:21 PM`}
              title="START TIME"
              hue="200"
            >
            </Message>
            */}

            <Message3
              value={this.state.image_count}
              title="Images Captured"
              hue="200"
            >
            </Message3>

            <Message3
              value={`${this.state.failure_rate}%`}
              title="Failure Rate"
              hue="200"
            >
            </Message3>

            {/*
            <Message2
              value={`4%`}
              title="FAILURE RATE"
              hue="200"
            >
            </Message2>
            */}

           <p className={s.refresh} onClick={()=>{
               console.log("REFESH CLICK!!");

               //////////////////////////////////////////////////////////////////////

               axios.get(REFRESH_PRODUCTION_STATS_URL)
               .then((response) => {
                   console.log("RECVD REFRESH PRODUCTION_STATS");
                })
               .catch((error) => {
                   console.log(`[ERROR] refresh production stats error_msg=${error.message}`)
               });

               //////////////////////////////////////////////////////////////////////


           }}>
                <u> Click Here to refresh production stats </u>
            </p>

            {/*

              <p>
                Since 1/31/21 @ 16:21
                <br/>
                Click to refresh
              </p>


             */}

          <br />

              {/*
              <div className={s.tool}>
                <Toggles
                  currentValue="off"
                  options={INFERENCE_OPTIONS}
                  onChange={null}
                />
              </div>
              */}



          {/*
          <RecentRecap
            timeframe="Past hour"
            imageCount={12008}
            passCount={8462}
            failCount={3555}
          />

          <RecentRecap
            timeframe="Past 24 hours"
            imageCount={287654}
            passCount={188466}
            failCount={98100}
          />
          */}
    </div>
      }

        </aside>

        <main className={s.main}>


                <figure className={figure_class_name} >

                {/*
                <figure className={this.state.classPrediction==='FAIL'? s.latest_fail :
                (this.state.classPrediction==='PASS'? s.latest_pass : s.latest_na)}
                >
                */}

                {/*<figure className={
                    this.state.classPrediction==='FAIL'
                    ?
                      s.latest_fail
                    :
                     ( this.state.classPrediction==='PASS'
                       ?
                       s.latest_pass
                       : (this.state.classPrediction==='NONE'
                          ?
                            s.latest_na
                          :
                            s.latest_multiclass
                         )
                     )}
                >*/}

	            {/* (this.state.xImgSrc != "")
                      ?

	              (<img alt="Live camera view" src={this.state.xImgSrc} />

	              :
	              <div className={s.camera_view}>
	                  AWAITING IMAGE CAPTURE
	              </div>
	            */}


	            {/* (this.state.xImgSrc != "")
                      ?

	              (<img alt="Live camera view" src={this.state.xImgSrc} />

	              :
	              <div className={s.camera_view}>
	                  AWAITING IMAGE CAPTURE
	              </div>
	            */}



	          { (this.state.xImgSrc == "") && (
	              <div className={s.camera_view} ref={this.mainRef}>
	                  AWAITING IMAGE CAPTURE
	              </div>
                  )}

	          { ( (this.state.xImgSrc != "") && (this.state.bool_detection!==true) ) && (
	              <img alt="Live camera view" src={this.state.xImgSrc} />
                  )}

	          { ( (this.state.xImgSrc != "") && (this.state.bool_detection===true) ) && (

                        <img
                                className={s.imgx}
                                ref={ this.imgRef }
                                width={ this.state.mainWidth }
                                height={ this.state.mainHeight }
                                src={ this.state.xImgSrc }
                                alt=''
                                onLoad={ (e) => {
                                  console.log('DETECTION IMG LOADED');
                                  console.log(`mainWidth=${this.state.mainWidth}`);
                                  console.log(`mainHeight=${this.state.mainHeight}`);
                                  this.setState({ bool_detection_img_loaded: true})
                                  //if (bool_loading_image===true) {
                                  //    console.log('yyy');
                                  //    update_bool_loading_image(false);
                                  //}
                                }}
                                  //forceUpdate();
                        />
                  )}
                       {/* this.state.bool_detection_img_loaded && (*/}

                        <Stage
                            width={this.state.mainWidth}
                            height={this.state.mainHeight}
                            className={s.stage}

                            onMouseDown={(event)=>{ }}
                            onMouseUp={(event)=>{ }}
                            onMouseMove={(event)=>{ }}
                        >

                            <Layer>
                            {   this.state.bool_detection_img_loaded && (
                                    <Image image={ this.imgRef.current } />

                                )
                            }


                            { this.state.toggleShowPredictions && this.state.list_of_predictions.length>=1 && (

                                    this.state.list_of_predictions.map(value => {

                                        //let fillValue = 30;
                                        let fillValue = 1;

                                        let fillColor = COLORS.orange;
                                        if (this.state.bool_pass_detection === true) {
                                            fillColor = COLORS.green_JT;
                                        }

                                        if (this.state.bool_multi_class_detection && value.detection_predicted_class) {
                                            if (value.detection_predicted_class === 1)
                                                fillColor = DETECTION_LABEL_COLORS.color1;
                                            if (value.detection_predicted_class === 2)
                                                fillColor = DETECTION_LABEL_COLORS.color2;
                                            if (value.detection_predicted_class === 3)
                                                fillColor = DETECTION_LABEL_COLORS.color3;
                                            if (value.detection_predicted_class === 4)
                                                fillColor = DETECTION_LABEL_COLORS.color4;
                                            if (value.detection_predicted_class === 5)
                                                fillColor = DETECTION_LABEL_COLORS.color5;
                                            if (value.detection_predicted_class === 6)
                                                fillColor = DETECTION_LABEL_COLORS.color6;
                                            if (value.detection_predicted_class === 7)
                                                fillColor = DETECTION_LABEL_COLORS.color7;
                                            if (value.detection_predicted_class === 8)
                                                fillColor = DETECTION_LABEL_COLORS.color8;
                                            if (value.detection_predicted_class === 9)
                                                fillColor = DETECTION_LABEL_COLORS.color9;

                                        }



                                        return (
                                            <Rect
                                                x={value.x}
                                                y={value.y}
                                                width={value.width}
                                                height={value.height}
                                                fill={alphaColor(fillColor, fillValue)}
                                                stroke={fillColor}

                                                /*
                                                onMouseOver={ () => {
                                                        this.mainRef.current.style.cursor = 'pointer';
                                                    }
                                                }

                                                onMouseDown={ (e) => {

                                                    }
                                                }

                                                onMouseLeave={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }

                                                onMouseOut={ () => {

                                                        this.mainRef.current.style.cursor = 'default';
                                                    }
                                                }
                                                */

                                            />
                                        );
                                    })
                                )
                            }


                            </Layer>
                        </Stage>
                </figure>


          <footer className={s.footer}>

                <div className={s.results}>

                { (this.state.classPredictionNamedClass && this.state.classPredictionNamedClass!="" && this.state.classPredictionNamedClass.length>0) ? (

                    <ProductionResults
                      predictionType={`${this.state.classPredictionNamedClass} [class ${this.state.classPrediction}]`}
                      confidenceScore={this.state.classPredictionConfidence}
                    />

                  ) : (

                    <ProductionResults
                      predictionType={this.state.classPrediction}
                      confidenceScore={this.state.classPredictionConfidence}
                      time={this.state.imageCurrentTimeStr}
                    />

                )}

                </div>

          </footer>
        </main>

        <RecentImages
          timestamp={this.state.imageCurrentTimeStr}
          imgSrc={this.state.recentImgSrc}
          predictionType={this.state.classPrediction}
          confidenceScore={this.state.classPredictionConfidence}
          inspectionTime={this.state.image_inspection_time}
        />
      </div>
    );
  }
}

export default ProductionView;
